<template>
	<v-hover v-slot="{hover}">

		<div class="cursorPointer">
			<app-icon class="component-container"
					  :class="hover ? 'red' : 'appWhite'"
					  :color="hover ? 'appWhite' : 'red'"
					  icon="close"
					  size="32"
					  title="Close"/>
		</div>

	</v-hover>
</template>

<script>
export default {
	name: "CloseIcon"
}
</script>

<style scoped>
.component-container {
	aspect-ratio: 1/1;
	border-radius: 8px;
	margin-left: 16px;
	padding: 12px;
	transition: 0.3s;
}
</style>
