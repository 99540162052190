<template>
	<div class="appGrey d-flex pa-4">

		{{ computedInit }}

		<!--Form-->
		<div class="mr-2" :style="$vuetify.breakpoint.width < 1366 ? 'width: 100%' : 'width: 50%'">
			<v-row no-gutters>

				<!--Email Subject-->
				<v-col :class="$vuetify.breakpoint.width < 600 ? '' : ''"
					   :cols="$vuetify.breakpoint.width < 600 ? '12' : '12'">
					<app-form-field form-type="textInput"
									:disabled="isReadOnly"
									:error="errors.massEmailSubject"
									:error-message="errors.massEmailSubjectErrorMessage"
									label="Subject"
									v-model.trim="form.massEmailSubject"/>
				</v-col>

				<!--Email Content-->
				<v-col :class="$vuetify.breakpoint.width < 600 ? '' : ''"
					   :cols="$vuetify.breakpoint.width < 600 ? '12' : '12'">
					<vue-editor @image-added="handleAddedImage"
								ref="myEditor"
								:class="['appWhite mt-4', computedTextEditorClass]"
								:disabled="isReadOnly"
								:editor-toolbar="richTextEditorOptions"
								:useCustomImageHandler="true"
								v-model="form.massEmailText"/>

					<!--Error-->
					<app-text v-if="errors.massEmailText" class="ml-4 pa-1" color="red" size="small">
						Description is required
					</app-text>

					<image-upload v-show="false"
								  @emitImageUpload="emittedImageUpload"
								  :external-image="selectedFile"
								  folder="massEmails"
								  :id-for-image="form.entityId"
								  image-for="massEmail"
								  style="position: absolute"/>
				</v-col>

				<!--Send To Details -------------------------------------------------------------------------------- -->

				<form-section-title class="mt-8" title="Who's this for?"
									description="Select from the options below which users you want to be included."/>

				<!--Checkboxes-->
				<div class="d-flex justify-space-around" style="width: 100%">
					<div v-for="item in ['Users', 'Levels', 'Types', 'All']" class="d-flex flex-column align-center">

						<!--Title-->
						<app-text>{{ item }}</app-text>

						<!--Checkbox-->
						<div @click="handleSendToTypeSelection(item)"
							 class="appWhite d-flex align-center justify-center rounded-lg mt-2"
							 style="height: 56px; width: 56px">
							<app-icon v-if="form.massEmailSendToType === item"
									  color="green" :disabled="isReadOnly" icon="check" size="40"/>
						</div>

					</div>
				</div>
				<!--Checkbox - Error Message-->
				<app-text v-if="errors.massEmailSendToType" class="text-center mt-2" color="red" size="small"
						  style="width: 100%">
					{{ errors.massEmailSendToTypeErrorMessage }}
				</app-text>

				<!--Users-->
				<v-col v-if="form.massEmailSendToType === 'Users'"
					   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
					   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
					<app-form-field form-type="autoComplete"
									:clearable="true"
									:deletable-chips="true"
									:disabled="isReadOnly"
									:error="errors.massEmailSendToListUsers"
									:error-message="errors.massEmailSendToListUsersErrorMessage"
									:items="usersData"
									item-text="userName"
									label="Users"
									:multiple="true"
									:return-object="true"
									:small-chips="true"
									v-model="form.massEmailSendToListUsers"/>
				</v-col>

				<!--User Levels-->
				<v-col v-if="form.massEmailSendToType === 'Levels'"
					   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
					   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
					<app-form-field form-type="autoComplete"
									:clearable="true"
									:deletable-chips="true"
									:disabled="isReadOnly"
									:error="errors.massEmailSendToListLevels"
									:error-message="errors.massEmailSendToListLevelsErrorMessage"
									:items="computedUserLevels"
									label="User Levels"
									:multiple="true"
									:small-chips="true"
									v-model="form.massEmailSendToListLevels"/>
				</v-col>

				<!--User Types-->
				<v-col v-if="form.massEmailSendToType === 'Types'"
					   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
					   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
					<app-form-field form-type="autoComplete"
									:clearable="true"
									:deletable-chips="true"
									:disabled="isReadOnly"
									:error="errors.massEmailSendToListTypes"
									:error-message="errors.massEmailSendToListTypesErrorMessage"
									:items="computedUserTypes"
									label="User Types"
									:multiple="true"
									:small-chips="true"
									v-model="form.massEmailSendToListTypes"/>
				</v-col>

				<!--Save Button-->
				<div v-if="!isReadOnly" class="d-flex justify-end mt-4" style="width: 100%">
					<app-btn @click.native="handleSaveItem"
							 color="green"
							 icon="send"
							 label="Send"/>
				</div>

			</v-row>
		</div>

		<!--Preview-->
		<div v-if="$vuetify.breakpoint.width >= 1366" class="appWhite preview-section rounded-lg ml-2 pa-4"
			 style="width: 50%">
			<div v-html="livePreview"></div>
		</div>

		<!--Dialogs ------------------------------------------------------------------------------------------------ -->

		<!--Image Pasted Warning Dialog-->
		<app-dialog :is-visible="isImagePastedWarningDialogVisible">
			<mass-email-image-paste-dialog @closeDialog="toggleImagePastedWarningDialog"/>
		</app-dialog>

	</div>
</template>

<script>
import {VueEditor} from "vue2-editor";
import MassEmailImagePasteDialog from "@/views/massEmails/massEmailImagePasteDialog/MassEmailImagePasteDialog.vue";

export default {

	name: "MassEmailsForm",

	components: {MassEmailImagePasteDialog, VueEditor},

	props: ['isReadOnly', 'formData', 'usersData'],

	data: () => ({
		errors: {
			massEmailSendToType: false,
			massEmailSendToTypeErrorMessage: '',
			massEmailSendToText: false,
			massEmailSendToTextErrorMessage: '',
			massEmailSendToListUsers: false,
			massEmailSendToListUsersErrorMessage: '',
			massEmailSendToListLevels: false,
			massEmailSendToListLevelsErrorMessage: '',
			massEmailSendToListTypes: false,
			massEmailSendToListTypesErrorMessage: '',

			massEmailText: false,
			massEmailTextErrorMessage: '',
		},
		form: {
			massEmailSubject: '',
			massEmailText: '',
			massEmailSendToListLevels: [],
			massEmailSendToListTypes: [],
			massEmailSendToListUsers: [],
			massEmailSendToType: '',
			createdDateTime: 0,
			createdUserId: '',
			createdUserName: '',
			modifiedDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',
			isDeleted: false,
			deletedDateTime: 0,
			deletedUserId: '',
			deletedUserName: '',
		},
		imageSrc: null,
		isImagePastedWarningDialogVisible: false,
		selectedFile: null,

		// Rich Text Editor Options
		editorEditor: null,
		editorCursorLocation: null,
		editorResetUploader: null,
	}),

	computed: {

		/**
		 * Computed Init
		 *
		 * If there is any form data, assign it to the form.
		 * If there is no form data, it will be blank (new).
		 */
		computedInit() {
			const t = this
			const FORM_DATA = t.$props.formData
			const USERS_DATA = t.$props.usersData

			if (FORM_DATA?.entityId) {
				// Set the form data, but reset the list of users to avoid duplication
				t.form = {...FORM_DATA, massEmailSendToListUsers: []}

				// Set the Users data from their IDs
				if (FORM_DATA?.massEmailSendToListUsers?.length) {
					USERS_DATA.forEach(user => {
						if (FORM_DATA.massEmailSendToListUsers.includes(user.entityId)) t.form.massEmailSendToListUsers.push(user)
					})
				}
			}
		},

		/**
		 * Computed User Levels
		 *
		 * Return a sorted list of all User Levels.
		 *
		 * @returns {Array} - The sorted list of User Levels
		 */
		computedUserLevels() {
			const t = this
			const USER_LEVELS = t.$props.usersData.map(user => user.userLevel)
			return USER_LEVELS.sort((a, b) => a > b ? 1 : -1)
		},

		/**
		 * Computed User Types
		 *
		 * Return a sorted list of all User Types.
		 *
		 * @returns {Array} - The sorted list of User Types
		 */
		computedUserTypes() {
			const t = this
			const USER_TYPES = t.$props.usersData.map(user => user.userType)
			return USER_TYPES.sort((a, b) => a > b ? 1 : -1)
		},

		livePreview() {
			let previewHtml = `<p>${this.form.massEmailText}</p>`
			if (this.imageSrc) previewHtml += `<img src="${this.imageSrc}" alt="Image preview" />`
			return previewHtml
		},

		/**
		 * Computed Text Editor Class
		 *
		 * Return a class name for the Text Editor UI, based on error and readOnly states.
		 *
		 * @returns {string} the class name to render
		 */
		computedTextEditorClass() {
			const t = this
			let computedClass

			if (t.errors.massEmailText) computedClass = 'richText-error'

			if (!t.errors.massEmailText && t.$props.isReadOnly) computedClass = 'richText-disabled'

			if (!t.errors.massEmailText && !t.$props.isReadOnly) computedClass = 'richText-enabled'

			return computedClass
		},

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Create Item
		 *
		 * Create the item in the DB.
		 */
		async createItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_create('massEmail', t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating Mass Email: ', RESPONSE.errors)
				t.$sharedState.errorMessage = 'There was a problem creating the Mass Email, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Emails Sent'

			t.$emit('emitReloadPage')
		},

		/**
		 * Emitted Image Upload
		 *
		 * When an image is uploaded, break apart the downloadURL and save what's required.
		 * Then, update the form with the image ID.
		 *
		 * @param payload - The image data and download URL
		 */
		async emittedImageUpload(payload) {
			const t = this

			// Set the temp profile picture to render in the UI
			t.tempProfilePicture = payload.downloadURL

			const {FOLDER_NAME, IMAGE_NAME, TOKEN} = t.MIX_extractImageDataFromUrl(payload.downloadURL)

			// Save the image data to the DB (Files collection), and add the returned image ID to the form
			t.form.userProfilePicture = await t.saveImageData(
				t.form.entityId,
				FOLDER_NAME,
				IMAGE_NAME,
				payload.image.size,
				TOKEN,
				payload.image.type
			)

			await t.handleAddedImage(payload)
		},

		/**
		 * Save Image Data
		 *
		 * Save the image data to the DB (Files collection).
		 *
		 * @param fileDocLink {string} - The file's document link
		 * @param fileFolder {string} - The storage folder
		 * @param fileName {string} - The file name
		 * @param fileSize {number} - The file size
		 * @param fileToken {string} - The file token
		 * @param fileType {string} - The file type
		 * @returns {Promise<*>} - The image ID
		 */
		async saveImageData(fileDocLink, fileFolder, fileName, fileSize, fileToken, fileType) {
			const t = this

			const IMAGE_DATA = {
				fileDocLink,
				fileFolder,
				fileName,
				fileSize,
				fileToken,
				fileType,
				createdDateTime: 0,
				createdUserId: '',
				createdUserName: '',
				modifiedDateTime: 0,
				modifiedUserId: '',
				modifiedUserName: '',
				isDeleted: false,
				deletedDateTime: 0,
				deletedUserId: '',
				deletedUserName: '',
			}

			const RESPONSE = await t.MIX_redis_create('file', IMAGE_DATA)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error saving File Data to DB:', RESPONSE.errors)
				t.$sharedState.errorMessage = 'There was a problem uploading your file, please try again.'
				return
			}

			return RESPONSE.data?.entityId
		},

		/**
		 * Handle Added Image
		 *
		 * When an image is added to the text editor, insert it at the cursor location.
		 * This will be processed by the image-upload component.
		 *
		 * @param image - The image data
		 * @param Editor - The text editor instance
		 * @param cursorLocation - The cursor location
		 * @param resetUploader - The image uploader reset function
		 */
		async handleAddedImage(image, Editor, cursorLocation, resetUploader) {
			const t = this

			if (!image?.downloadURL) {
				t.selectedFile = image
				t.editorEditor = Editor
				t.editorCursorLocation = cursorLocation
				t.editorResetUploader = resetUploader
			} else {

				t.editorEditor.insertEmbed(t.editorCursorLocation, 'image', image.downloadURL)
				t.editorResetUploader
			}
		},

		/**
		 * Handle Save Item
		 *
		 * Create/Update the item in the DB, after the form has passed validation.
		 */
		handleSaveItem() {
			const t = this

			// Only continue if the form has passed validation
			if (!t.validateForm()) return

			// Empty the List that are not selected
			if (t.form.massEmailSendToType !== 'Users') t.form.massEmailSendToListUsers = []
			if (t.form.massEmailSendToType !== 'Levels') t.form.massEmailSendToListLevels = []
			if (t.form.massEmailSendToType !== 'Types') t.form.massEmailSendToListTypes = []

			// If the selected SendTo Type is Users, just save the IDs
			if (t.form.massEmailSendToType === 'Users') t.form.massEmailSendToListUsers = t.form.massEmailSendToListUsers.map(user => user.entityId)

			if (!t.form?.entityId) t.createItem()
			else t.updateItem()
		},

		/**
		 * Handle Send To Type Selection
		 *
		 * When a user selects a SendTo Type, assign it and clear the SendTo List.
		 *
		 * @param item - The selected SendTo Type
		 */
		handleSendToTypeSelection(item) {
			const t = this

			// If the form is read only, don't allow changes
			if (t.$props.isReadOnly) return

			t.form.massEmailSendToType = item
		},

		/**
		 * Setup Editor
		 *
		 * Set up the text editor and prevent image pasting.
		 * This is to avoid users pasting images into the editor which are converted to base64 and can overload the server.
		 */
		setupEditor() {
			const t = this
			const EDITOR = t.$refs.myEditor.quill

			if (EDITOR) {

				// Listen for paste events
				EDITOR.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
					let ops = []

					delta.ops.forEach(op => {

						// Log or handle the image paste attempt
						if (op.insert && op.insert.image) t.toggleImagePastedWarningDialog()

						// If it's not an image, add it to the ops
						else ops.push(op)

					})

					delta.ops = ops

					return delta
				})
			}
		},

		/**
		 * Toggle Image Pasted Warning Dialog
		 *
		 * Toggle the warning dialog when an image is pasted into the text editor.
		 */
		toggleImagePastedWarningDialog() {
			const t = this
			t.isImagePastedWarningDialogVisible = !t.isImagePastedWarningDialogVisible
		},

		/**
		 * Update Item
		 *
		 * Update the item in the DB.
		 */
		async updateItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_update('massEmail', t.form.entityId, t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating Mass Email: ', RESPONSE.errors)
				t.$sharedState.errorMessage = 'There was a problem updating the Mass Email, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Updated Mass Email'

			t.$emit('emitReloadPage')
		},

		/**
		 * Validate Form
		 *
		 * Validate the required form fields and return a boolean if the form has passed, or not.
		 *
		 * @returns {boolean} - if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Title
			if (!t.form.massEmailSubject) {
				t.errors.massEmailSubject = true
				t.errors.massEmailSubjectErrorMessage = 'A Title is required.'
			}

			// Text
			// if (!t.form.massEmailText) {
			// 	t.errors.massEmailText = true
			// 	t.errors.massEmailTextErrorMessage = 'Text is required.'
			// }

			// Send To Type
			if (!t.form.massEmailSendToType) {
				t.errors.massEmailSendToType = true
				t.errors.massEmailSendToTypeErrorMessage = 'A Send To Type is required.'
			}

			// Send to List Users
			if (t.form.massEmailSendToType === 'Users' && !t.form.massEmailSendToListUsers.length) {
				t.errors.massEmailSendToListUsers = true
				t.errors.massEmailSendToListUsersErrorMessage = 'You must select at least one user'
			}

			// Send to List Levels
			if (t.form.massEmailSendToType === 'Levels' && !t.form.massEmailSendToListLevels.length) {
				t.errors.massEmailSendToListLevels = true
				t.errors.massEmailSendToListLevelsErrorMessage = 'You must select at least one user level'
			}

			// Send to List Types
			if (t.form.massEmailSendToType === 'Types' && !t.form.massEmailSendToListTypes.length) {
				t.errors.massEmailSendToListTypes = true
				t.errors.massEmailSendToListTypesErrorMessage = 'You must select at least one user type'
			}

			// Activity Description
			if (!t.form.massEmailText.trim() || t.form.massEmailText.replace(/\s/g, '') === '<p></p>') {
				t.errors.massEmailText = true
				t.errors.massEmailTextErrorMessage = 'Description is required'
			}

			return !Object.values(t.errors).includes(true)
		},

	},

	mounted() {
		this.setupEditor()
	}

}
</script>

<style scoped>

</style>
