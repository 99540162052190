<template>
	<div class="appGrey pa-4">

		{{ computedInit }}

		<form-section-title title="User Details"/>
		<v-row no-gutters>

			<!--UserName-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : ''"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								label="Full Name"
								v-model.trim="form.userName"/>
			</v-col>

			<!--Organisation-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								label="Organisation"
								v-model.trim="form.userOrganisation"/>
			</v-col>

			<!--Car Reg-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								label="Car Registration"
								v-model.trim="form.userCarRegistrationNumber"/>
			</v-col>

		</v-row>

		<!--Configuration-->
		<form-section-title class="mt-4" title="Configuration"/>
		<v-row no-gutters>

			<!--User Type-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 4">
				<app-form-field form-type="textInput"
								:disabled="true"
								label="User Type"
								v-model="form.userType"/>
			</v-col>

			<!--User Role-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'px-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 4">
				<app-form-field form-type="textInput"
								:disabled="true"
								label="User Role"
								v-model="form.userRole"/>
			</v-col>

			<!--User Status-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 4">
				<app-form-field form-type="autoComplete"
								:disabled="isReadOnly"
								:error="errors.userStatus"
								:error-message="errors.userStatusErrorMessage"
								label="User Status"
								:items="userStatusOptions"
								v-model="form.userStatus"/>
			</v-col>

			<!--User Notes-->
			<form-section-title class="mt-8" title="User Notes"/>
			<v-row no-gutters>
				<v-col :class="$vuetify.breakpoint.width < 600 ? '' : ''"
					   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
					<app-form-field form-type="textArea"
									:disabled="MIX_getCurrentUser().userLevel !== 'Staff-Admin' || isReadOnly"
									label="Notes"
									v-model="form.userNotes"/>
				</v-col>
			</v-row>

		</v-row>

		<!--Save Button-->
		<div class="d-flex justify-end mt-4">
			<app-btn v-if="!isReadOnly"
					 @click.native="handleSaveItem"
					 color="green"
					 icon="save"
					 icon-color="white"
					 label="Save"
					 label-color="white"/>
		</div>

	</div>
</template>

<script>

export default {

	name: "UserVisitorForm",

	props: ['userData', 'isReadOnly'],

	data: () => ({
		errors: {
			userName: false,
			userNameErrorMessage: '',
			userOrganisation: false,
			userOrganisationErrorMessage: '',
			userCarRegistrationNumber: false,
			userCarRegistrationNumberErrorMessage: '',
			userStatus: false,
			userStatusErrorMessage: '',
		},
		form: {
			entityId: '',
			userAcceptedTermsAndConditions: false,
			userCarRegistrationNumber: '',
			userLastSwappDateTime: 0,
			userLastSwappLocationId: '',
			userLastSwappMethod: '',
			userLastSwappSiteId: '',
			userLevel: '',
			userName: '',
			userNotes: '',
			userOrganisation: '',
			userPersonVisiting: '',
			userRole: '',
			userStatus: '',
			userSwappStatus: '',
			userType: '',

			createdDateTime: 0,
			createdUserId: '',
			createdUserName: '',
			modifiedDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',
			isDeleted: false,
			deletedDateTime: 0,
			deletedUserId: '',
			deletedUserName: '',
		},
	}),

	computed: {

		/**
		 * Computed Init
		 *
		 * If there is any form data, assign it to the form.
		 * If there is no form data, it will be blank (new).
		 */
		computedInit() {
			const t = this
			const FORM_DATA = t.$props.userData.userData

			if (FORM_DATA?.entityId) t.form = FORM_DATA
		},

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Handle Save Item
		 *
		 * Handle whether to create or update the item to the DB, after it has passed validation.
		 */
		handleSaveItem() {
			const t = this

			// Only save if the form is valid
			if (!t.validateForm()) return

			t.form.userCarRegistrationNumber = t.form.userCarRegistrationNumber.toUpperCase()
			t.form.userLevel = `${t.form.userType}-${t.form.userRole}`
			t.form.userName = t.MIX_formatCapitaliseFirstLetters(t.form.userName)
			t.form.userOrganisation = t.MIX_formatCapitaliseFirstLetters(t.form.userOrganisation)

			// Create
			if (!t.form.entityId) t.createItem()

			// Update
			if (t.form.entityId) t.updateItem()
		},

		/**
		 * Update Item
		 *
		 * Update the form and save to the DB.
		 */
		async updateItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_update('visitor', t.form.entityId, t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating Visitor: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem updating, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Updated Visitor'
			t.$emit('emitReloadPage')
		},

		/**
		 * Validate Form
		 *
		 * Validate the form as required.
		 *
		 * @returns {boolean} if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Name
			const USER_NAME = t.form.userName
			if (!USER_NAME) {
				t.errors.userName = true
				t.errors.userNameErrorMessage = 'Name required'
			}

			// Organisation
			const USER_ORGANISATION = t.form.userOrganisation
			if (!USER_ORGANISATION) {
				t.errors.userOrganisation = true
				t.errors.userOrganisationErrorMessage = 'Organisation required'
			}

			// Car Registration
			const USER_CAR_REGISTRATION_NUMBER = t.form.userCarRegistrationNumber
			if (!USER_CAR_REGISTRATION_NUMBER) {
				t.errors.userCarRegistrationNumber = true
				t.errors.userCarRegistrationNumberErrorMessage = 'Car Registration required'
			}

			// User Type
			if (!t.form.userType) {
				t.errors.userType = true
				t.errors.userTypeErrorMessage = 'User Type required'
			}

			// User Role
			if (!t.form.userRole) {
				t.errors.userRole = true
				t.errors.userRoleErrorMessage = 'User Role required'
			}

			// User Status
			if (!t.form.userStatus) {
				t.errors.userStatus = true
				t.errors.userStatusErrorMessage = 'User Status required'
			}

			return !Object.values(t.errors).includes(true)
		}

	},

}
</script>

<style scoped>

</style>
