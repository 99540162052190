<template>
	<div class="appGrey pa-4">

		{{ computedInit }}

		<!--Instructional Text-->
		<app-text>
			You are about to update the Quota for this Team.
			<br>
			<br>
			<span class="font-weight-bold">What do you want to set the new Quota to?</span>
		</app-text>

		<!--Quota Input-->
		<app-form-field form-type="textInput"
						class="mt-4"
						:error="errors.teamQuota"
						:error-message="errors.teamQuotaErrorMessage"
						type="number"
						v-model.number="newTeamQuota"/>

		<v-divider class="mt-4"/>

		<!--Action Buttons-->
		<div class="d-flex justify-space-between mt-4">

			<!--Cancel-->
			<app-btn @click.native="closeDialog"
					 color="grey"
					 icon="cancel"
					 label="Cancel"/>

			<!--Accept-->
			<app-btn @click.native="handleUpdateTeamQuota"
					 color="green"
					 icon="save"
					 label="Save"/>

		</div>

	</div>
</template>

<script>
export default {

	name: "EventQuotaDialog",

	props: ['teamQuota'],

	data: () => ({
		errors: {
			teamQuota: false,
			teamQuotaErrorMessage: '',
		},
		newTeamQuota: '',
	}),

	computed: {

		computedInit() {
			const t = this
			const TEAM_QUOTA = t.$props.teamQuota

			t.newTeamQuota = TEAM_QUOTA
		}

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Close Dialog
		 *
		 * Emit a message back to the parent component to close the dialog.
		 */
		closeDialog() {
			const t = this

			// Reset the times
			t.newTeamQuota = t.$props.teamQuota

			t.$emit('emitCloseDialog')
		},

		/**
		 * Handle Positive Action
		 *
		 * Emit a message back to the parent component to submit the Event.
		 */
		handleUpdateTeamQuota() {
			const t = this

			// Validate the form
			if (!t.validateForm()) return

			t.$emit('emitUpdatedTeamQuota', t.newTeamQuota)
		},

		/**
		 * Validate Form
		 *
		 * Validate the required form fields and return a boolean if the form has passed, or not.
		 *
		 * @returns {boolean} - if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			/// Quota
			if (t.newTeamQuota === null) {
				t.errors.teamQuota = true
				t.errors.teamQuotaErrorMessage = 'A Team must have a Quota'
			}
			// Else if the quota is not a positive whole number
			else if (!Number.isInteger(t.newTeamQuota) || t.newTeamQuota < 0) {
				t.errors.teamQuota = true
				t.errors.teamQuotaErrorMessage = 'A Team Quota must be a positive whole number'
			}

			return !Object.values(t.errors).includes(true)
		},

	},

}
</script>

<style scoped>

</style>
