<template>
	<div class="appGrey rounded-lg">

		<!--Header-->
		<div class="d-flex align-center primary pa-4">
			<app-text color="appWhite" size="normal">Export</app-text>
			<v-spacer/>
			<app-icon @click.native="closeDialog"
					  class="cursorPointer" color="white" icon="close" size="32"/>
		</div>

		<!--Body-->
		<div class="appGrey rounded-lg pa-4">

			<app-text>
				You can either export the SWAPP Tasks List data (as viewable in the table),
				or you can include the Tasks history.
			</app-text>

			<app-text class="mt-4" size="normal-bold">What would you like to do?</app-text>

			<v-divider class="greyD my-4"/>

			<!--Action Buttons-->
			<div class="d-flex">
				<app-btn @click.native="handleExportOption('ListData')"
						 :block="true"
						 class="pr-2"
						 color="green"
						 label="Just List Data"
						 style="width: 100%"/>

				<app-btn @click.native="handleExportOption('IncludeHistory')"
						 :block="true"
						 class="pl-2"
						 color="orange"
						 label="Include History"
						 style="width: 100%"/>
			</div>

		</div>

	</div>
</template>

<script>

export default {

	name: "SwappTasksExportDialog",

	data: () => ({}),

	methods: {

		/**
		 * Close Dialog.
		 *
		 * Emits 'closeDialog' event back to the parent to close the dialog.
		 */
		closeDialog() {
			this.$emit('closeDialog')
		},

		handleExportOption(selectedOption) {
			const t = this
			const SHOULD_INCLUDE_HISTORY = selectedOption === 'IncludeHistory'

			t.$emit('exportOption', {shouldIncludeHistory: SHOULD_INCLUDE_HISTORY})
		},
	},

}
</script>

<style scoped>

</style>
