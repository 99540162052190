<template>
	<div class="appWhite d-flex rounded-lg mt-4">

		<!--Priority Indicator-->
		<div :class="getNoticeColor(cardData.noticePriority)" class="rounded-l-lg" style="width: 16px"/>

		<div class="d-flex align-center pa-4">

			<!--Visibility Icon-->
			<app-icon class="mr-4"
					  :color="cardData.noticeVisibility === 'Visible' ? 'green' : 'red'"
					  :icon="cardData.noticeVisibility === 'Visible' ? 'success' : 'cancel'"
					  size="32"/>

			<!--Priority | Name | Date Created-->
			<div style="width: 100%">

				<app-text color="primary" size="normal-bold">{{ cardData.noticeTitle }}</app-text>

				<app-text size="small">{{cardData.noticeSendToType}}</app-text>

			</div>

		</div>

	</div>
</template>

<script>

export default {

	name: "NoticeBoardMobileCard",

	props: ['cardData'],

	data: () => ({}),

	methods: {

		/**
		 * Get Notice Color
		 *
		 * Return the color of the notice based on its priority.
		 *
		 * @param noticePriority {string} the priority of the notice
		 * @returns {string} the color of the notice
		 */
		getNoticeColor(noticePriority) {
			let noticeColor = 'grey'

			switch (noticePriority) {
				case 'High':
					noticeColor = 'red'
					break
				case 'Medium':
					noticeColor = 'orange'
					break
				case 'Low':
					noticeColor = 'green'
					break
				default:
					noticeColor = 'grey'
			}

			return noticeColor
		},

	},

}
</script>

<style scoped>

</style>
