<template>
	<div class="text-center">

		<div class="d-flex align-center text-center">

			<v-divider class="greyD"/>

			<!--Title-->
			<app-text class="mx-4" color="grey9" size="normal-bold">{{ title }}</app-text>

			<v-divider class="greyD"/>

		</div>

		<!--Description - Optional-->
		<app-text color="grey9" size="small">{{ description }}</app-text>

	</div>
</template>

<script>

export default {

	name: "PageBreakTitle",

	props: ['description', 'title'],

	data: () => ({}),

	methods: {},

}
</script>

<style scoped>

</style>
