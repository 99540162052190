<template>
	<div class="appWhite d-flex align-center rounded-lg mt-4 pa-4">

		<!--Status-->
		<div class="mr-4">
			<app-icon v-if="cardData.organisationData.organisationStatus === 'Active'" color="green" icon="success" size="32"/>
			<app-icon v-if="cardData.organisationData.organisationStatus === 'Inactive'" color="red" icon="cancel" size="32"/>
		</div>

		<!--Organisation | Manager-->
		<div>

			<!--Organisation-->
			<app-text color="primary" size="normal-bold">{{ cardData.organisationData.organisationName }}</app-text>

			<!--Manager-->
			<app-text v-for="item in cardData.usersData" :key="item.entityId" color="grey9" size="small">{{ item.userName }}</app-text>

		</div>

	</div>
</template>

<script>

export default {

	name: "OrganisationMobileCard",

	props: ['cardData'],

}
</script>

<style scoped>

</style>
