<template>
	<div class="appGrey pa-4">

		<!--Instructional Text-->
		<app-text>
			You are about to remove this Team from this Event.
			<br>
			<br>
			<span class="font-weight-bold">Are you sure you want to do this?</span>
		</app-text>

		<v-divider class="mt-4"/>

		<!--Action Buttons-->
		<div class="d-flex justify-space-between mt-4">

			<!--Cancel-->
			<app-btn @click.native="closeDialog"
					 color="grey"
					 icon="cancel"
					 label="Cancel"/>

			<!--Accept-->
			<app-btn @click.native="handleDeleteEventTeam"
					 color="red"
					 icon="delete"
					 label="Delete"/>

		</div>

	</div>
</template>

<script>
export default {

	name: "EventRemoveTeamDialog",

	methods: {

		/**
		 * Close Dialog
		 *
		 * Emit a message back to the parent component to close the dialog.
		 */
		closeDialog() {
			const t = this

			t.$emit('emitCloseDialog')
		},

		/**
		 * Handle Delete Event Team
		 *
		 * Emit a message back to the parent component to delete the EventTeam.
		 */
		handleDeleteEventTeam() {
			const t = this

			t.$emit('emitDeleteEventTeam')
		}

	},

}
</script>

<style scoped>

</style>
