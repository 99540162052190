<template>
	<div class="appGrey rounded-lg pa-4">

		<!--Icon | Title-->
		<div class="d-flex align-center">

			<app-icon class="mr-4" color="red" icon="error" size="32"/>

			<app-text size="medium">Ongoing Tasks</app-text>

		</div>

		<!--Text-->
		<app-text class="mt-4">These Tasks have already been started.</app-text>
		<app-text class="mt-4" size="normal-bold">What would you like to do?</app-text>

		<!--Action Buttons-->
		<div>
			<app-btn @click.native="handleContinueButton" :block="true" class="mt-4" color="green" label="Continue with tasks"/>
			<app-btn @click.native="handleStartAgain" :block="true" class="mt-4" color="orange" label="Start again"/>
			<app-btn @click.native="handleCancelButton" :block="true" class="mt-4" color="grey" label="Cancel"/>
		</div>


	</div>
</template>

<script>

export default {

	name: "SwappTasksListAlreadyActiveDialog",

	data: () => ({}),

	methods: {

		/**
		 * Emit event to continue the list where it left off
		 */
		handleContinueButton() {
			this.$emit('swappListActiveListContinue')
		},

		/**
		 * Emit event to start the list again
		 */
		handleStartAgain() {
			this.$emit('swappListActiveListStartAgain')
		},

		/**
		 * Emit event to close the dialog
		 */
		handleCancelButton() {
			this.$emit('closeDialog')
		},

	},

}
</script>

<style scoped>

</style>
