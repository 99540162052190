<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<div v-else class="pa-4">

		<page-title :divider="true"
					:icon="getPageData(userData.userStatus).icon"
					:title="getPageData(userData.userStatus).title"/>

		<div class="d-flex flex-column align-center">

			<!--User Name-->
			<app-text class="text-center mt-4" color="grey6" size="large-bold">
				Welcome <span class="primary--text">{{ userData.userName?.split(' ')[0] }}</span>
			</app-text>

			<!--Message-->
			<app-text class="text-center mt-4" v-html="getPageData(userData.userStatus).message1"/>
			<app-text class="text-center mt-4" v-html="getPageData(userData.userStatus).message2"/>

			<!--Refresh Button-->
			<app-btn v-if="userData.userStatus === 'Approved'"
					 @click.native="handleRefreshButton"
					 :block="true"
					 class="mt-8"
					 label="Refresh"
					 style="max-width: 512px"/>

		</div>

	</div>
</template>

<script>
export default {

	name: "Unauthorised",

	data: () => ({
		isLoading: true,
		userData: {},
	}),

	methods: {

		/**
		 * Get Page Data
		 *
		 * Return the correct page data for the given userStatus.
		 *
		 * @param userStatus - the status to return the data for
		 * @returns {object} - an object containing the page data
		 */
		getPageData(userStatus) {
			const statusData = {
				'Pending': {
					icon: 'onHold',
					title: 'Awaiting Approval',
					message1: 'Your account is <strong>Awaiting Approval</strong> by Admin.',
					message2: 'Please click Refresh to check if your account is ready.'
				},
				'Approved': {
					icon: 'success',
					title: 'Approved',
					message1: 'Your account has been <strong>Approval</strong>.',
					message2: 'Please click Refresh to continue.'
				},
				'Rejected': {
					icon: 'onHold',
					title: 'Rejected',
					message1: 'Your account has been <strong>Rejected</strong> by Admin.',
					message2: `Please contact <a href="mailto:stewards@wru.wales">stewards@wru.wales</a> for further assistance.`
				},
				'Suspended': {
					icon: 'onHold',
					title: 'Suspended',
					message1: 'Your account has been <strong>Suspended</strong> by Admin.',
					message2: `Please contact <a href="mailto:stewards@wru.wales">stewards@wru.wales</a> for further assistance.`
				},
				'Archived': {
					icon: 'onHold',
					title: 'Archived',
					message1: 'Your account has been <strong>Archived</strong> by Admin.',
					message2: `Please contact <a href="mailto:stewards@wru.wales">stewards@wru.wales</a> for further assistance.`
				},
			}

			return statusData[userStatus]
		},

		/**
		 * Handle Refresh Button
		 *
		 * Set the action for the button under different statuses.
		 */
		handleRefreshButton() {
			const t = this
			const USER_STATUS = t.userData.userStatus

			if (USER_STATUS === 'Approved') t.MIX_go('/')
		},

		/**
		 * Load Current User Data
		 *
		 * Load User data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadCurrentUserData() {
			const t = this
			const CURRENT_USER_AUTH = t.currentUserAuth

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getUsersWhere([{whereKey: 'userFbId', whereValue: CURRENT_USER_AUTH.uid}])

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting User data: ', RESPONSE.error)
				return
			}

			// Assign the data
			t.userData = RESPONSE.data[0]
			t.MIX_addToLocalStorage('currentUserData', RESPONSE.data[0])
		},

	},

	async created() {
		const t = this

		t.isLoading = true

		await t.loadCurrentUserData()

		t.isLoading = false
	}
}
</script>

<style scoped>

</style>
