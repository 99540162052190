<template>
    <div style="min-height: 100%">

        {{ computedInit }}

        <div>

            <!--Qualification Details-->
            <form-section-title title="Qualification Details"/>

            <!--Qualification Name-->
            <app-form-field form-type="textInput"
                                class="mt-4"
                                :clearable="true"
                                :disabled="isReadOnly"
                                :error="errors.qualificationName"
                                :error-message="errors.qualificationNameErrorMessage"
                                label="Qualification Name"
                                v-model="form.qualificationName"/>

            <!--Category Details-->
            <form-section-title class="mt-4" title="Category Details"/>

            <!--Category-->
            <app-form-field form-type="autoComplete"
                                class="mt-4"
                                :clearable="true"
                                :disabled="isReadOnly"
                                :error="errors.qualificationCategoryId"
                                :error-message="errors.qualificationCategoryIdErrorMessage"
                                :items="qualificationCategoriesData"
                                item-text="qualificationCategoryName"
                                label="Category"
                                :return-object="true"
                                v-model="displayCategoryObject"/>

            <!--Add your own Category-->
            <v-row v-if="!form.entityId" no-gutters class="d-flex align-center mt-4">
                <!--Text-->
                <v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'"
                        :cols="$vuetify.breakpoint.width < 600 ? 12 : 2">
                    <app-text size="small">Or add your own</app-text>
                </v-col>
                <!--Text input-->
                <v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'"
                    :cols="$vuetify.breakpoint.width < 600 ? 12 : 10">
                    <app-form-field :disabled="isReadOnly || !!qualificationCategoriesData.find(category => category.qualificationCategoryName === displayCategoryObject)?.entityId"
                                    form-type="textInput"
                                    :error="errors.qualificationTemporaryCategoryName"
                                    :error-message="errors.qualificationTemporaryCategoryNameErrorMessage"
                                    label="What's the new category name?"
                                    v-model="form.qualificationTemporaryCategoryName"/>
                </v-col>
            </v-row>

        </div>

        <!-- Save Button -->
		<div v-if="!isReadOnly" class="mb-4 mt-4 d-flex justify-end">
            <app-btn @click.native="handleSaveItem"
					 color="green"
					 icon="save"
					 label="Save"/>
        </div>

    </div>
</template>

<script>
export default {

    name: 'QualificationsPresetsForm',

    props: [ 'isReadOnly', 'formData', 'qualificationCategoriesData' ],

    data: () => ({
		errors: {
            qualificationName: false,
            qualificationNameErrorMessage: '',
            qualificationCategoryId: false,
            qualificationCategoryIdErrorMessage: '',
            qualificationTemporaryCategoryName: false,
            qualificationTemporaryCategoryNameErrorMessage: '',
		},

		form: {
			entityId: '',
            qualificationName: '',
            qualificationCategoryId: '',
            qualificationTemporaryCategoryName: '',

			createdDateTime: 0,
			createdUserId: '',
            createdUserName: '',

            modifiedDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',

			deletedDateTime: 0,
			deletedUserId: '',
			deletedUserName: '',
			isDeleted: false,
		},

        categoryForm: {
            entityId: '',
            qualificationCategoryName: '',
            qualificationCategoryTemporaryName: '',

            createdDateTime: 0,
			createdUserId: '',
            createdUserName: '',

            modifiedDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',

			deletedDateTime: 0,
			deletedUserId: '',
			deletedUserName: '',
			isDeleted: false,
        },

        doesCategoryExist: false,
        displayCategoryObject: {},

	}),

    computed: {

        /**
         * Computed Init
         *
         * If there is any form data, assign it to the form.
         * If there is no form data, it will be blank (new).
         */
        computedInit() {
            const t = this
            const FORM_DATA = t.$props.formData
            const CATEGORIES_DATA = t.$props.qualificationCategoriesData

            if (FORM_DATA?.entityId) {
                t.form = FORM_DATA
                t.displayCategoryObject = CATEGORIES_DATA.find(category => category.entityId === t.form.qualificationCategoryId)
            }
        },

    },

    methods: {

        /**
         * Check Category
         *
         * Check if the category exists in the DB.
         * If it does, show the error message.
         * If it doesn't, save the category as normal.
         */
        async checkCategory() {
            const t = this

            const CATEGORIES_DATA = t.$props.qualificationCategoriesData

            // Check if the category exists in the DB
            const existingCategory = CATEGORIES_DATA.find(category => category.qualificationCategoryName === t.form.qualificationTemporaryCategoryName)

            // If the category exists, show the error message
            if (existingCategory?.qualificationCategoryName) {
                t.doesCategoryExist = true
            } else {
                t.doesCategoryExist = false
                await t.saveCategory()
            }

        },

        /**
         * Clear Errors
         *
         * Clear all errors and their messages.
         */
        clearErrors() {
            const t = this

            for (const error in t.errors) {

                if (typeof t.errors[error] === 'string') t.errors[error] = ''
                if (typeof t.errors[error] === 'boolean') t.errors[error] = false
            }

        },

        /**
         * Create Item
         *
         * Create the form and save to the DB.
         */
        async createItem() {
            const t = this

            const RESPONSE = await t.MIX_redis_create('qualificationPreset', t.form)

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error creating Qualification: ', RESPONSE.error)
                t.$sharedState.errorMessage = 'There was a problem creating this Qualification, please try again.'
                return
            }

            t.$sharedState.successMessage = 'Created Qualification'
            t.$emit('reloadData', RESPONSE.data)
        },

        /**
         * Handle Save Item
         *
         * Handle whether to create or update the item to the DB, after it has passed validation.
         */
        async handleSaveItem() {
            const t = this

            // Clear doesCategoryExist before the check
			t.doesCategoryExist = false

            // If the user has typed their own category, check if it already exists
            if (t.form.qualificationTemporaryCategoryName) await t.checkCategory()

            // Only save if the form has passed validation
            if (!t.validateForm()) return

            // Save the ID from the object
            // If the category is selected from the list, set the ID to the form
            if (t.displayCategoryObject?.entityId) {
                t.form.qualificationCategoryId = t.displayCategoryObject.entityId || ''
                t.form.qualificationTemporaryCategoryName = ''
                t.doesCategoryExist = false
            }

            //Set the temporary category name back to blank
            t.form.qualificationTemporaryCategoryName = ''

            // Create
            if (!t.form.entityId && !t.doesCategoryExist) await t.createItem()

            // Update
            if (t.form.entityId && !t.doesCategoryExist) await t.updateItem()

        },

        /**
         * Save Category
         *
         * Save the new category to the DB.
         */
        async saveCategory() {
            const t = this

            // Set the category name to the temporary name
            t.categoryForm.qualificationCategoryName = t.form.qualificationTemporaryCategoryName

            const RESPONSE = await t.MIX_redis_create('qualificationCategory', t.categoryForm)

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error creating category: ', RESPONSE.error)
                t.$sharedState.errorMessage = 'There was a problem creating this category, please try again.'
                return
            }

            // Set the category ID to the form
            t.form.qualificationCategoryId = RESPONSE.data.entityId
        },

        /**
         * Update Item
         *
         * Update the form and save to the DB.
         */
        async updateItem() {
            const t = this

            const RESPONSE = await t.MIX_redis_update('qualificationPreset', t.form.entityId, t.form)

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error updating qualification: ', RESPONSE.error)
                t.$sharedState.errorMessage = 'There was a problem updating, please try again.'
                return
            }

            t.$sharedState.successMessage = 'Updated Qualification'
            t.$emit('reloadData')
        },

        /**
         * Validate Form
         *
         * Validate the form as required.
         *
         * @returns {boolean} if the form has passed validation, or not
         */
        validateForm() {
            const t = this

            t.clearErrors()

            // Qualification Name
            const QUALIFICATION_NAME = t.form.qualificationName
            if (!QUALIFICATION_NAME) {
                t.errors.qualificationName = true
                t.errors.qualificationNameErrorMessage = 'Please enter a qualification name'
            }

            // Qualification Category
            const QUALIFICATION_CATEGORY = t.displayCategoryObject?.entityId
            const QUALIFICATION_TEMPORARY_CATEGORY_NAME= t.form.qualificationTemporaryCategoryName
            if (!QUALIFICATION_CATEGORY && !QUALIFICATION_TEMPORARY_CATEGORY_NAME) {
                t.errors.qualificationCategoryId = true
                t.errors.qualificationCategoryIdErrorMessage = 'Please select or enter a category'
                t.errors.qualificationTemporaryCategoryName = true
                t.errors.qualificationTemporaryCategoryNameErrorMessage = 'Please select or enter a category'
            }

            // Category Name Already Exists
            if (QUALIFICATION_TEMPORARY_CATEGORY_NAME && t.doesCategoryExist) {
                t.errors.qualificationTemporaryCategoryName = true
                t.errors.qualificationTemporaryCategoryNameErrorMessage = 'This category already exists. Please select this category from list above.'
            }

            return !Object.values(t.errors).includes(true)
        },

    },

    watch: {

        /**
		 * Display Category Object
		 *
		 * Watch for changes to the displayCategoryObject and update the temporary name to blank if category is selected from the dropdown.
		 */
		displayCategoryObject() {
			const t = this

			t.form.qualificationTemporaryCategoryName = ''
		},

    },

}


</script>

<style scoped></style>
