<template>
	<div class="appGrey pa-4">

		<!--User Details ---------------- -->
		<div>

			<form-section-title title="User Details"/>

			<div v-for="(value, label) in computedUserData" :key="label" class="d-flex align-center">

				<!--Label-->
				<app-text class="text-right mr-4" color="grey9" size="small" style="width: 10ch">
					{{ label }}:
				</app-text>

				<!--Value-->
				<app-text>{{ value }}</app-text>

				<!--Options-->
				<app-text v-if="label === 'Name'" class="ml-4" color="grey9">
					({{ formData.userData.userInternalIdNumber }})
				</app-text>

			</div>

		</div>

		<!--Event Details ---------------- -->
		<div class="mt-8">

			<form-section-title title="Event Details"/>

			<div v-for="(value, label) in computedEventData" :key="label" class="d-flex align-center">

				<!--Label-->
				<app-text class="text-right mr-4" color="grey9" size="small" style="width: 15ch">
					{{ label }}:
				</app-text>

				<!--Value-->
				<app-text>{{ value }}</app-text>

			</div>

		</div>

		<!--Override Details ---------------- -->
		<div class="mt-8">

			<form-section-title description="Overrides are where the Team has different timings to the event times."
								title="Overrides Details"/>

			<app-text v-if="!computedTimeOverrideData">There were no overrides for this team.</app-text>

			<div v-for="(value, label) in computedTimeOverrideData" :key="label" class="d-flex align-center">

				<!--Label-->
				<app-text class="text-right mr-4" color="grey9" size="small" style="width: 20ch">
					{{ label }}:
				</app-text>

				<!--Value-->
				<app-text>{{ value }}</app-text>

			</div>

		</div>

		<!--Day Times ---------------- -->
		<form-section-title class="mt-8" title="On the Day Times"/>

		<!--Is No Show-->
		<div class="d-flex align-center">

			<app-text class="sidewaysText text-center" color="greyD" size="medium-bold">
				No Show
			</app-text>

			<app-text v-if="!formData.eventUserData.eventUserIsNoShow" color="grey9" style="height: 104px">
				This user was not a no-show.
			</app-text>

			<div v-if="formData.eventUserData.eventUserIsNoShow" style="width: 100%">

				<!--No Show-->
				<app-form-field form-type="select"
								:disabled="isReadOnly"
								label="Was the user a no-show?"
								:items="[true, false]"
								v-model="formData.eventUserData.eventUserIsNoShow"/>

				<!--No Show Reason-->
				<app-form-field form-type="textArea"
								class="mt-4"
								:disabled="isReadOnly"
								:error="errors.eventUserIsNoShowNotes"
								:error-message="errors.eventUserIsNoShowNotesErrorMessage"
								label="No Show Reason"
								v-model.trim="formData.eventUserData.eventUserIsNoShowNotes"/>
			</div>

		</div>

		<v-divider class="my-4"/>

		<!--Late Arrival-->
		<div class="d-flex align-center">

			<app-text class="sidewaysText text-center" color="greyD" size="medium-bold">
				Late Arrival
			</app-text>

			<app-text v-if="formData.eventUserData.eventUserLateArrivalTime === '0'" color="grey9" style="height: 144px">
				This user was not reported as a late arrival.
			</app-text>

			<div v-else style="width: 100%">

				<!--Late Arrival Time-->
				<app-form-field form-type="textInput"
								class="mt-4"
								:disabled="isReadOnly"
								label="Late Arrival Time"
								v-model="formData.eventUserData.eventUserLateArrivalTime"/>

				<!--Late Arrival Reason-->
				<app-form-field form-type="textArea"
								class="mt-4"
								:disabled="isReadOnly"
								label="Late Arrival Reason"
								v-model.trim="formData.eventUserData.eventUserLateArrivalNotes"/>
			</div>

		</div>

		<v-divider class="my-4"/>

		<!--Sign In Time-->
		<div class="d-flex align-center">

			<app-text class="sidewaysText text-center" color="greyD" size="medium-bold">
				Sign In
			</app-text>

			<app-text v-if="formData.eventUserData.eventUserSignInTime === '0'" color="grey9" style="height: 144px">
				This user was not signed in.
			</app-text>

			<div v-else style="width: 100%">

				<!--Sign In Time-->
				<app-form-field form-type="textInput"
								class="mt-4"
								:disabled="isReadOnly"
								label="Sign In Time"
								v-model="formData.eventUserData.eventUserSignInTime"/>

				<!--Sign In Reason-->
				<app-form-field form-type="textArea"
								class="mt-4"
								:disabled="isReadOnly"
								label="Sign In Reason"
								v-model.trim="formData.eventUserData.eventUserSignInNotes"/>
			</div>

		</div>

		<v-divider class="my-4"/>

		<!--Early Finish-->
		<div class="d-flex align-center">

			<app-text class="sidewaysText text-center" color="greyD" size="medium-bold">
				Early Finish
			</app-text>

			<app-text v-if="formData.eventUserData.eventUserEarlyFinishTime === '0'" color="grey9" style="height: 144px">
				This user was not marked as an early finish.
			</app-text>

			<div v-else style="width: 100%">

				<!--Early Finish Time-->
				<app-form-field form-type="textInput"
								class="mt-4"
								:disabled="isReadOnly"
								label="Early Finish Time"
								v-model="formData.eventUserData.eventUserEarlyFinishTime"/>

				<!--Early Finish Reason-->
				<app-form-field form-type="textArea"
								class="mt-4"
								:disabled="isReadOnly"
								label="Early Finish Reason"
								v-model.trim="formData.eventUserData.eventUserEarlyFinishNotes"/>
			</div>

		</div>

		<v-divider class="my-4"/>

		<!--Sign Out Time-->
		<div class="d-flex align-center">

			<app-text class="sidewaysText text-center" color="greyD" size="medium-bold">
				Sign Out
			</app-text>

			<app-text v-if="formData.eventUserData.eventUserSignOutTime === '0'" color="grey9" style="height: 144px">
				This user was not signed out.
			</app-text>

			<div v-else style="width: 100%">

				<!--Sign Out Time-->
				<app-form-field form-type="textInput"
								class="mt-4"
								:disabled="isReadOnly"
								label="Sign Out Time"
								v-model="formData.eventUserData.eventUserSignOutTime"/>

				<!--Sign Out Reason-->
				<app-form-field form-type="textArea"
								class="mt-4"
								:disabled="isReadOnly"
								label="Sign Out Reason"
								v-model.trim="formData.eventUserData.eventUserSignOutNotes"/>
			</div>

		</div>

		<!--Extensions & Transfers ---------------- -->
		<form-section-title class="mt-8" title="Extensions & Transfers"/>

		<!--Extensions-->
		<div class="d-flex align-center">

			<app-text class="sidewaysText text-center" color="greyD" size="medium-bold">
				Extension
			</app-text>

			<!--If there are no extensions, show the message-->
			<app-text v-if="!formData?.eventExtensionData?.entityId" class="my-8">
				There were no Extensions for this user.
			</app-text>

			<div v-if="formData?.eventExtensionData?.entityId" style="width: 100%">

				<!--Times From and To-->
				<div class="d-flex align-center">

					<!--From-->
					<app-form-field form-type="textInput"
									class="pr-2"
									:disabled="isReadOnly"
									label="Start"
									style="width: 100%"
									v-model="formData.eventExtensionData.eventExtensionStartTime"/>

					<!--To-->
					<app-form-field form-type="textInput"
									class="pl-2"
									:disabled="isReadOnly"
									label="Finish"
									style="width: 100%"
									v-model="formData.eventExtensionData.eventExtensionFinishTime"/>

				</div>

				<!--Role From and To-->
				<div class="d-flex align-center mt-4">

					<!--From-->
					<app-form-field form-type="textInput"
									class="pr-2"
									:disabled="isReadOnly"
									label="Role From"
									style="width: 100%"
									v-model.trim="formData.eventExtensionData.eventExtensionRoleFrom"/>

					<!--To-->
					<app-form-field form-type="textInput"
									class="pl-2"
									:disabled="isReadOnly"
									label="Role To"
									style="width: 100%"
									v-model.trim="formData.eventExtensionData.eventExtensionRoleTo"/>

				</div>

				<!--Notes-->
				<app-form-field form-type="textArea"
								class="mt-4"
								:disabled="isReadOnly"
								label="Extension Notes"
								v-model.trim="formData.eventExtensionData.eventExtensionNotes"/>

			</div>

		</div>

		<v-divider class="my-4"/>

		<!--Transfers-->
		<div class="d-flex align-center">

			<app-text class="sidewaysText text-center" color="greyD" size="medium-bold">
				Transfer
			</app-text>

			<!--If there are no extensions, show the message-->
			<app-text v-if="!formData?.eventTransferData?.entityId" class="my-8">
				There were no Transfers for this user.
			</app-text>

			<div v-if="formData?.eventTransferData?.entityId" style="width: 100%">

				<!--Times From and To-->
				<div class="d-flex align-center">

					<!--From-->
					<app-form-field form-type="textInput"
									class="pr-2"
									:disabled="isReadOnly"
									label="Start"
									style="width: 100%"
									v-model="formData.eventTransferData.eventTransferStartTime"/>

					<!--To-->
					<app-form-field form-type="textInput"
									class="pl-2"
									:disabled="isReadOnly"
									label="Finish"
									style="width: 100%"
									v-model="formData.eventTransferData.eventTransferFinishTime"/>

				</div>

				<!--Role From and To-->
				<div class="d-flex align-center mt-4">

					<!--From-->
					<app-form-field form-type="textInput"
									class="pr-2"
									:disabled="isReadOnly"
									label="Role From"
									style="width: 100%"
									v-model.trim="formData.eventTransferData.eventTransferRoleFrom"/>

					<!--To-->
					<app-form-field form-type="textInput"
									class="pl-2"
									:disabled="isReadOnly"
									label="Role To"
									style="width: 100%"
									v-model.trim="formData.eventTransferData.eventTransferRoleTo"/>

				</div>

				<!--Notes-->
				<app-form-field form-type="textArea"
								class="mt-4"
								:disabled="isReadOnly"
								label="Extension Notes"
								v-model.trim="formData.eventTransferData.eventTransferNotes"/>

			</div>

		</div>

	</div>
</template>

<script>

export default {

	name: "PayrollForm",

	props: ['eventData', 'formData', 'isReadOnly'],

	data: () => ({
		errors: {
			eventUserIsNoShowNotes: false,
			eventUserIsNoShowNotesErrorMessage: '',
		}
	}),

	computed: {

		computedEventData() {
			const t = this
			const EVENT_DATA = t.$props.eventData

			return {
				'Event Name': EVENT_DATA.eventName,
				'Event Date': t.MIX_formatDate(EVENT_DATA.eventDate, 'long'),
				'Event Time': EVENT_DATA.eventStartTime + ' - ' + EVENT_DATA.eventFinishTime,
				'Steward Times': EVENT_DATA.eventStewardsStartTime + ' - ' + EVENT_DATA.eventStewardsFinishTime,
				'Supervisor Times': EVENT_DATA.eventSupervisorsStartTime + ' - ' + EVENT_DATA.eventSupervisorsFinishTime,
			}
		},

		/**
		 * Computed Time Override Data
		 *
		 * Checks if there are any overrides for the individual, or Stewards/Supervisors Team times.
		 *
		 * @returns {Object|Boolean} - Returns an object with the override times or false if there are no overrides.
		 */
		computedTimeOverrideData() {
			const t = this;
			const FORM_DATA = t.$props.formData
			const EVENT_DATA = t.$props.eventData
			let overrideTimes = {}

			// Check for individual user time overrides
			if (FORM_DATA.eventUserData.eventUserStartTime && FORM_DATA.eventUserData.eventUserFinishTime) {
				overrideTimes['Individual Times'] = FORM_DATA.eventUserData.eventUserStartTime + ' - ' + FORM_DATA.eventUserData.eventUserFinishTime
			} else {
				// Check for Steward overrides
				if (EVENT_DATA.eventStewardsStartTime !== FORM_DATA.eventTeamData.eventTeamStewardsStartTime ||
					EVENT_DATA.eventStewardsFinishTime !== FORM_DATA.eventTeamData.eventTeamStewardsFinishTime) {
					overrideTimes['Team Steward Times'] = FORM_DATA.eventTeamData.eventTeamStewardsStartTime + ' - ' + FORM_DATA.eventTeamData.eventTeamStewardsFinishTime
				}

				// Check for Supervisor overrides
				if (EVENT_DATA.eventSupervisorsStartTime !== FORM_DATA.eventTeamData.eventTeamSupervisorsStartTime ||
					EVENT_DATA.eventSupervisorsFinishTime !== FORM_DATA.eventTeamData.eventTeamSupervisorsFinishTime) {
					overrideTimes['Team Supervisor Times'] = FORM_DATA.eventTeamData.eventTeamSupervisorsStartTime + ' - ' + FORM_DATA.eventTeamData.eventTeamSupervisorsFinishTime
				}
			}

			// If there are no overrides, return false
			if (!overrideTimes['Individual Times'] && !overrideTimes['Team Steward Times'] && !overrideTimes['Team Supervisor Times']) {
				overrideTimes = false
			}

			return overrideTimes
		},

		computedUserData() {
			const t = this
			const FORM_DATA = t.$props.formData

			return {
				'Name': FORM_DATA.userData.userName,
				'Role': FORM_DATA.userData.userRole,
				'Pay Grade': FORM_DATA.userData.userPayGrade,
				'Team': FORM_DATA.teamData.teamName
			}
		}


	},

	methods: {},

}
</script>

<style scoped>
.sidewaysText {
	transform: rotate(-90deg);
	width: 194px;
	margin-left: -76px;
	margin-right: -64px;
}
</style>
