<template>
	<div class="rounded-lg preEventCard-container">

		<!--Team Name | Confirmed | Quota | Balance-->
		<div class="d-flex align-center justify-space-between pa-2">

			<!--Team Name-->
			<app-text size="small">{{ cardData.teamData.teamName }}</app-text>

			<!--Confirmed/Quota | Balance-->
			<div class="d-flex">

				<!--Confirmed/Quota-->
				<app-text color="grey9" size="small">
					<strong>{{ cardData.eventTeamFiguresData.numberOfTeamMembers }}</strong>
					/
					{{ cardData.eventTeamData.eventTeamQuota }}
				</app-text>

				<!--Balance-->
				<app-text color="red" class="ml-2" size="small">
					({{ cardData.eventTeamFiguresData.teamBalance }})
				</app-text>

			</div>

		</div>

		<!--Status Bars-->
		<div>

			<pre-event-progress-bar color="green"
									label="Confirmed"
									:numerical-value="cardData.eventTeamFiguresData.numberOfConfirmedMembers"
									:percent-value="calculateConfirmedPercentage(cardData.eventTeamFiguresData.numberOfConfirmedMembers)"/>

			<pre-event-progress-bar color="orange"
									label="Available"
									:numerical-value="cardData.eventTeamFiguresData.numberOfAvailableMembers"
									:percent-value="calculatePercentage(cardData.eventTeamFiguresData.numberOfAvailableMembers)"/>

			<pre-event-progress-bar color="blue"
									label="Reserved"
									:numerical-value="cardData.eventTeamFiguresData.numberOfReservedMembers"
									:percent-value="calculatePercentage(cardData.eventTeamFiguresData.numberOfReservedMembers)"/>

			<pre-event-progress-bar color="red"
									label="Unavailable"
									:numerical-value="cardData.eventTeamFiguresData.numberOfUnavailableMembers"
									:percent-value="calculatePercentage(cardData.eventTeamFiguresData.numberOfUnavailableMembers)"/>

			<pre-event-progress-bar color="grey6"
									label="No Response"
									:numerical-value="cardData.eventTeamFiguresData.numberOfNoResponseMembers"
									:percent-value="calculatePercentage(cardData.eventTeamFiguresData.numberOfNoResponseMembers)"/>

		</div>

	</div>
</template>

<script>
import PreEventProgressBar from "@/views/preEventDashboard/preEventTeamCard/preEventProgressBar/PreEventProgressBar";

export default {

	name: "PreEventTeamCard",

	props: ['cardData'],

	components: {PreEventProgressBar},

	methods: {

		/**
		 * Calculate Confirmed Percentage
		 *
		 * Calculate the percentage of how many of the team members have a Confirmed status, out of the team quota.
		 *
		 * @param statusNumber - the number of team members with the given status
		 * @returns {number} - the percentage value
		 */
		calculateConfirmedPercentage(statusNumber) {
			const t = this
			const EVENT_TEAM_DATA = t.$props.cardData
			const TEAM_QUOTA = EVENT_TEAM_DATA.eventTeamData.eventTeamQuota

			return statusNumber / TEAM_QUOTA * 100
		},

		/**
		 * Calculate Percentage
		 *
		 * Calculate the percentage of how many of the team members have the given status,
		 * out of the number of team members.
		 *
		 * @param statusNumber - the number of team members with the given status
		 * @returns {number} - the percentage value
		 */
		calculatePercentage(statusNumber) {
			const t = this
			const EVENT_TEAM_DATA = t.$props.cardData
			const NUMBER_OF_TEAM_MEMBERS = EVENT_TEAM_DATA.eventTeamFiguresData.numberOfTeamMembers

			return statusNumber / NUMBER_OF_TEAM_MEMBERS * 100
		}

	},

}
</script>

<style scoped>
.preEventCard-container {
	background-color: var(--v-appWhite-base);
	border: 1px solid lightgrey;
	/*height: 200px;*/
	/*width: 200px;*/
}
</style>
