<template>
	<div class="appGrey" style="min-height: 100%">

		{{ computedInit }}

		<!--Form-->
		<v-row no-gutters>

			<!--Event Details -------------------------------------------------------------------------------------- -->
			<form-section-title title="Event (optional)"/>

			<!--Event-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : ''"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="select"
								:disabled="isReadOnly"
								:items="eventsData"
								item-text="eventName"
								label="Event"
								:return-object="true"
								v-model="selectedEventData"/>
			</v-col>

			<!--Accident Details ----------------------------------------------------------------------------------- -->
			<form-section-title class="mt-4" title="Accident Details"/>

			<!--Title-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : ''"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								:error="errors.accidentReportTitle"
								:error-message="errors.accidentReportTitleErrorMessage"
								label="Title (brief description)"
								placeholder="e.g. Slipped and fell in west stand"
								v-model.trim="form.accidentReportTitle"/>
			</v-col>

			<!--Name of Injured Party-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								:error="errors.accidentReportInjuredPartyName"
								:error-message="errors.accidentReportInjuredPartyNameErrorMessage"
								label="Name of Injured Party"
								v-model.trim="form.accidentReportInjuredPartyName"/>
			</v-col>

			<!--Injured Party's Address-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textArea"
								:disabled="isReadOnly"
								:error="errors.accidentReportInjuredPartyAddress"
								:error-message="errors.accidentReportInjuredPartyAddressErrorMessage"
								label="Injured Party's Address"
								v-model.trim="form.accidentReportInjuredPartyAddress"/>
			</v-col>

			<!--Type-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 4">
				<app-form-field form-type="select"
								:disabled="isReadOnly"
								:error="errors.accidentReportType"
								:error-message="errors.accidentReportTypeErrorMessage"
								:items="accidentTypeOptions"
								label="Type"
								v-model="form.accidentReportType"/>
			</v-col>

			<!--Date-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 px-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 4">
				<date-picker @emitDate="handleDateSelection($event, 'accidentReportDate')"
							 :date="form.accidentReportDate"
							 :disabled="isReadOnly"
							 :error="errors.accidentReportDate"
							 :error-message="errors.accidentReportDateErrorMessage"
							 label="Date"
							 :min-date="[7, 'days', 'past']"/>
			</v-col>

			<!--Time-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 4"
				   :title="!form.accidentReportDate && 'Select a Date first'">
				<time-picker2 @emitTime="handleTimeSelection($event, 'accidentReportTime')"
							  :disabled="!form.accidentReportDate || (form.accidentReportDate && isReadOnly) "
							  :error="errors.accidentReportTime"
							  :error-message="errors.accidentReportTimeErrorMessage"
							  label="Time"
							  :max="new Date().getHours() + ':' + new Date().getMinutes()"
							  :selectedDate="form.accidentReportDate"
							  :time="form.accidentReportTime"/>
			</v-col>

			<!--Accident Description-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textArea"
								:disabled="isReadOnly"
								:error="errors.accidentReportDescription"
								:error-message="errors.accidentReportDescriptionErrorMessage"
								label="Description of Accident"
								v-model.trim="form.accidentReportDescription"/>
			</v-col>

			<!--Location Details ----------------------------------------------------------------------------------- -->
			<form-section-title class="mt-4" title="Location Details"/>

			<!--Site-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="select"
								:disabled="isReadOnly"
								:error="errors.accidentReportLocationSite"
								:error-message="errors.accidentReportLocationSiteErrorMessage"
								:items="sitesData"
								item-text="siteName"
								label="Site"
								v-model="form.accidentReportLocationSite"/>
			</v-col>

			<!--Location-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								:error="errors.accidentReportLocation"
								:error-message="errors.accidentReportLocationErrorMessage"
								label="Location"
								v-model.trim="form.accidentReportLocation"/>
			</v-col>

			<!--Level-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="select"
								:disabled="isReadOnly"
								:error="errors.accidentReportLocationLevel"
								:error-message="errors.accidentReportLocationLevelErrorMessage"
								:items="computedLocationLevelItems"
								label="Level"
								v-model="form.accidentReportLocationLevel"/>
			</v-col>

			<!--Aisle-->
			<v-col v-if="['Level 3', 'Level 4', 'Level 6'].includes(form.accidentReportLocationLevel)"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="select"
								:disabled="isReadOnly"
								:error="errors.accidentReportLocationAisle"
								:error-message="errors.accidentReportLocationAisleErrorMessage"
								:items="computedLocationAisleItems"
								label="Aisle"
								v-model="form.accidentReportLocationAisle"/>
			</v-col>

			<!--Block-->
			<v-col v-if="['Level 3', 'Level 4', 'Level 6'].includes(form.accidentReportLocationLevel)"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="select"
								:disabled="isReadOnly"
								:error="errors.accidentReportLocationBlock"
								:error-message="errors.accidentReportLocationBlockErrorMessage"
								:items="computedLocationBlockItems"
								label="Block"
								v-model="form.accidentReportLocationBlock"/>
			</v-col>

			<!--Row-->
			<v-col v-if="['Level 3', 'Level 4', 'Level 6'].includes(form.accidentReportLocationLevel)"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="select"
								:disabled="isReadOnly"
								:error="errors.accidentReportLocationRow"
								:error-message="errors.accidentReportLocationRowErrorMessage"
								:items="computedLocationRowItems"
								label="Row"
								v-model="form.accidentReportLocationRow"/>
			</v-col>

			<!--Seat-->
			<v-col v-if="['Level 3', 'Level 4', 'Level 6'].includes(form.accidentReportLocationLevel)"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="select"
								:disabled="isReadOnly"
								:error="errors.accidentReportLocationSeat"
								:error-message="errors.accidentReportLocationSeatErrorMessage"
								:items="computedLocationSeatItems"
								label="Seat"
								v-model="form.accidentReportLocationSeat"/>
			</v-col>

			<!--Box-->
			<v-col v-if="['Level 5'].includes(form.accidentReportLocationLevel)"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="select"
								:disabled="isReadOnly"
								:error="errors.accidentReportLocationBox"
								:error-message="errors.accidentReportLocationBoxErrorMessage"
								:items="computedLocationBoxItems"
								label="Box"
								v-model="form.accidentReportLocationBox"/>
			</v-col>

			<!--Lounges-->
			<v-col v-if="['Lounges'].includes(form.accidentReportLocationLevel)"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="select"
								:disabled="isReadOnly"
								:error="errors.accidentReportLocationLounge"
								:error-message="errors.accidentReportLocationLoungeErrorMessage"
								:items="locationLoungeOptions"
								label="Lounges"
								v-model="form.accidentReportLocationLounge"/>
			</v-col>

			<!--Location Other-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textArea"
								:disabled="isReadOnly"
								:error="errors.accidentReportLocationOther"
								:error-message="errors.accidentReportLocationOtherErrorMessage"
								label="Location (other)"
								v-model.trim="form.accidentReportLocationOther"/>
			</v-col>

			<!--Treatment Details ---------------------------------------------------------------------------------- -->
			<form-section-title class="mt-4" title="Treatment Details"/>

			<!--Medical Treatment-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="select"
								:disabled="isReadOnly"
								:error="errors.accidentReportMedicalTreatment"
								:error-message="errors.accidentReportMedicalTreatmentErrorMessage"
								:items="medicalTreatmentOptions"
								label="Medical Treatment"
								v-model="form.accidentReportMedicalTreatment"/>
			</v-col>

			<!--PRF Number-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								:error="errors.accidentReportMedicalPrfNumber"
								:error-message="errors.accidentReportMedicalPrfNumberErrorMessage"
								label="PRF Number"
								v-model.trim="form.accidentReportMedicalPrfNumber"/>
			</v-col>

			<!--Nature of Treatment-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textArea"
								:disabled="isReadOnly"
								:error="errors.accidentReportMedicalAdministered"
								:error-message="errors.accidentReportMedicalAdministeredErrorMessage"
								label="Nature of Treatment"
								v-model.trim="form.accidentReportMedicalAdministered"/>
			</v-col>

			<!--Condition Details ---------------------------------------------------------------------------------- -->
			<form-section-title class="mt-4" title="Condition Details"/>

			<!--Lighting-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="select"
								:disabled="isReadOnly"
								:error="errors.accidentReportConditionLighting"
								:error-message="errors.accidentReportConditionLightingErrorMessage"
								:items="conditionsLightingOptions"
								label="Lighting"
								v-model="form.accidentReportConditionLighting"/>
			</v-col>

			<!--Weather-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="select"
								:disabled="isReadOnly"
								:error="errors.accidentReportConditionWeather"
								:error-message="errors.accidentReportConditionWeatherErrorMessage"
								:items="conditionsWeatherOptions"
								label="Weather"
								v-model="form.accidentReportConditionWeather"/>
			</v-col>

			<!--Housekeeping-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="select"
								:disabled="isReadOnly"
								:error="errors.accidentReportConditionHousekeeping"
								:error-message="errors.accidentReportConditionHousekeepingErrorMessage"
								:items="conditionsHousekeepingOptions"
								label="Housekeeping"
								v-model="form.accidentReportConditionHousekeeping"/>
			</v-col>

			<!--Witness Details ---------------------------------------------------------------------------------- -->
			<form-section-title class="mt-4" title="Witness Details"/>

			<!--Name-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								:error="errors.accidentReportWitnessName"
								:error-message="errors.accidentReportWitnessNameErrorMessage"
								label="Name"
								v-model.trim="form.accidentReportWitnessName"/>
			</v-col>

			<!--Relationship to Injured Party-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								:error="errors.accidentReportWitnessRelationship"
								:error-message="errors.accidentReportWitnessRelationshipErrorMessage"
								label="Relationship to Injured Party"
								v-model.trim="form.accidentReportWitnessRelationship"/>
			</v-col>

			<!--Address-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textArea"
								:disabled="isReadOnly"
								:error="errors.accidentReportWitnessAddress"
								:error-message="errors.accidentReportWitnessAddressErrorMessage"
								label="Address"
								v-model.trim="form.accidentReportWitnessAddress"/>
			</v-col>

			<!--Other Information-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textArea"
								:disabled="isReadOnly"
								:error="errors.accidentReportWitnessOtherInfo"
								:error-message="errors.accidentReportWitnessOtherInfoErrorMessage"
								label="Other Information"
								v-model.trim="form.accidentReportWitnessOtherInfo"/>
			</v-col>

		</v-row>

		<!--Save Button-->
		<div class="d-flex justify-end mt-4">
				<app-btn v-if="!isReadOnly"
						 @click.native="handleSaveItem"
						 color="green"
						 icon="save"
						 label="Save"/>
		</div>

	</div>
</template>

<script>
export default {

	name: "AccidentForm",

	props: ['eventsData', 'fullReportData', 'isReadOnly', 'sitesData', 'teamsData'],

	data: () => ({
		errors: {
			accidentReportInjuredPartyName: false,
			accidentReportInjuredPartyNameErrorMessage: '',
			accidentReportInjuredPartyAddress: false,
			accidentReportInjuredPartyAddressErrorMessage: '',
			accidentReportTitle: false,
			accidentReportTitleErrorMessage: '',
			accidentReportType: false,
			accidentReportTypeErrorMessage: '',
			accidentReportDate: false,
			accidentReportDateErrorMessage: '',
			accidentReportTime: false,
			accidentReportTimeErrorMessage: '',
			accidentReportDescription: false,
			accidentReportDescriptionErrorMessage: '',
			accidentReportLocationSite: false,
			accidentReportLocationSiteErrorMessage: '',
			accidentReportLocation: false,
			accidentReportLocationErrorMessage: '',
			accidentReportLocationLevel: false,
			accidentReportLocationLevelErrorMessage: '',
			accidentReportLocationAisle: false,
			accidentReportLocationAisleErrorMessage: '',
			accidentReportLocationBlock: false,
			accidentReportLocationBlockErrorMessage: '',
			accidentReportLocationRow: false,
			accidentReportLocationRowErrorMessage: '',
			accidentReportLocationSeat: false,
			accidentReportLocationSeatErrorMessage: '',
			accidentReportLocationBox: false,
			accidentReportLocationBoxErrorMessage: '',
			accidentReportLocationLounge: false,
			accidentReportLocationLoungeErrorMessage: '',
			accidentReportLocationOther: false,
			accidentReportLocationOtherErrorMessage: '',
			accidentReportMedicalTreatment: false,
			accidentReportMedicalTreatmentErrorMessage: '',
			accidentReportMedicalPrfNumber: false,
			accidentReportMedicalPrfNumberErrorMessage: '',
			accidentReportMedicalAdministered: false,
			accidentReportMedicalAdministeredErrorMessage: '',
			accidentReportConditionLighting: false,
			accidentReportConditionLightingErrorMessage: '',
			accidentReportConditionWeather: false,
			accidentReportConditionWeatherErrorMessage: '',
			accidentReportConditionHousekeeping: false,
			accidentReportConditionHousekeepingErrorMessage: '',
			accidentReportWitnessName: false,
			accidentReportWitnessNameErrorMessage: '',
			accidentReportWitnessRelationship: false,
			accidentReportWitnessRelationshipErrorMessage: '',
			accidentReportWitnessAddress: false,
			accidentReportWitnessAddressErrorMessage: '',
			accidentReportWitnessOtherInfo: false,
			accidentReportWitnessOtherInfoErrorMessage: '',
		},
		form: {
			entityId: '',

			accidentReportEvent: '',
			accidentReportInjuredPartyName: '',
			accidentReportInjuredPartyAddress: '',
			accidentReportType: '',
			accidentReportTitle: '',
			accidentReportDate: 0,
			accidentReportTime: '',
			accidentReportDescription: '',
			accidentReportLocationSite: '',
			accidentReportLocation: '',
			accidentReportLocationLevel: '',
			accidentReportLocationAisle: '',
			accidentReportLocationBlock: '',
			accidentReportLocationRow: '',
			accidentReportLocationSeat: '',
			accidentReportLocationBox: '',
			accidentReportLocationLounge: '',
			accidentReportLocationOther: '',
			accidentReportMedicalTreatment: '',
			accidentReportMedicalPrfNumber: '',
			accidentReportMedicalAdministered: '',
			accidentReportConditionLighting: '',
			accidentReportConditionWeather: '',
			accidentReportConditionHousekeeping: '',
			accidentReportWitnessName: '',
			accidentReportWitnessRelationship: '',
			accidentReportWitnessAddress: '',
			accidentReportWitnessOtherInfo: '',

			createdUserId: '',
			createdUserName: '',
			createdDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',
			modifiedDateTime: 0,
			isDeleted: false,
			deletedUserId: '',
			deletedUserName: '',
			deletedDateTime: 0,
		},

		locationLevelOptions: [
			'Level 1',
			'Level 2',
			'Level 3',
			'Level 4',
			'Level 5',
			'Level 6',
			'Level 7',
			'Roof',
			'Lounges',
			'Other'
		],
		locationLoungeOptions: [
			'Clubhouse',
			'Half Way Lounge',
			'International Player Lounge',
			'Millennium Lounge',
			'Presidents Lounge',
			'Riverside Lounge',
			'South Stand Lounge',
			'St Davids Lounge',
		],
		medicalTreatmentOptions: ['Yes', 'No'],
		conditionsLightingOptions: ['Well-lit', 'Dim', 'Dark', 'No Light', 'N/A'],
		conditionsWeatherOptions: ['Fine', 'Sunny', 'Showers', 'Rain', 'Wet', 'Snow', 'Ice', 'Hail', 'Thunder', 'Hot', 'Cold', 'N/A'],
		conditionsHousekeepingOptions: ['Clear', 'Minor Obstructions', 'Obstructions', 'Trip Hazards', 'N/A'],

		selectedEventData: {},
	}),

	computed: {

		/**
		 * Computed Init
		 *
		 * If there is any form data, assign it to the form.
		 * If there is no form data, it will be blank (new).
		 */
		computedInit() {
			const t = this
			const FORM_DATA = t.$props.fullReportData

			if (FORM_DATA?.reportData?.entityId) {
				t.form = FORM_DATA.reportData
				t.selectedEventData = FORM_DATA.eventData
			}
		},

		computedLocationLevelItems() {
			const t = this

			return t.locationLevelOptions
		},

		computedLocationAisleItems() {
			const t = this
			const LOCATION_LEVEL = t.form.accidentReportLocationLevel
			let aisles
			let aisle3 = []
			let aisle4 = []
			let aisle6 = []

			if (LOCATION_LEVEL === 'Level 3') {
				for (let i = 301; i < 342 + 1; i++) aisle3.push(i.toString())
				aisle3.push('Other...')
				aisles = aisle3
			}

			if (LOCATION_LEVEL === 'Level 4') {
				for (let i = 401; i < 438 + 1; i++) aisle4.push(i.toString())
				aisle4.push('Other...')
				aisles = aisle4
			}

			if (LOCATION_LEVEL === 'Level 6') {
				for (let i = 601; i < 638 + 1; i++) aisle6.push(i.toString())
				aisle6.push('Other...')
				aisles = aisle6
			}

			return aisles
		},

		computedLocationBlockItems() {
			const t = this
			const LOCATION_LEVEL = t.form.accidentReportLocationLevel
			let blocks
			let blocks3 = []
			let blocks4 = []
			let blocks6 = []

			if (LOCATION_LEVEL === 'Level 3') {
				for (let i = 1; i < 42 + 1; i++) blocks3.push('L' + i)
				blocks3.push('Other...')
				blocks = blocks3
			}

			if (LOCATION_LEVEL === 'Level 4') {
				for (let i = 1; i < 38 + 1; i++) blocks4.push('M' + i)
				for (let i = 1; i < 4 + 1; i++) blocks4.push('N' + i)
				blocks4.push('Other...')
				blocks = blocks4
			}

			if (LOCATION_LEVEL === 'Level 6') {
				for (let i = 1; i < 38 + 1; i++) blocks6.push('U' + i)
				for (let i = 1; i < 4 + 1; i++) blocks6.push('UN' + i)
				blocks6.push('Other...')
				blocks = blocks6
			}

			return blocks
		},

		computedLocationRowItems() {
			const t = this
			const LOCATION_LEVEL = t.form.accidentReportLocationLevel
			let row = []

			if (['Level 3', 'Level 4', 'Level 6'].includes(LOCATION_LEVEL)) {
				for (let i = 1; i < 32 + 1; i++) row.push(i.toString())
				row.push('Other...')
			}

			return row
		},

		computedLocationSeatItems() {
			const t = this
			const LOCATION_LEVEL = t.form.accidentReportLocationLevel
			let seat = []

			if (['Level 3', 'Level 4', 'Level 6'].includes(LOCATION_LEVEL)) {
				for (let i = 1; i < 30 + 1; i++) seat.push(i.toString())
				seat.push('Other...')
			}

			return seat
		},

		computedLocationBoxItems() {
			const t = this
			const LOCATION_LEVEL = t.form.accidentReportLocationLevel
			let box
			let box5 = []

			if (LOCATION_LEVEL === 'Level 5') {
				for (let i = 1; i < 117 + 1; i++) box5.push(i.toString())
				box5.push('Other...')
				box = box5
			}

			return box
		},

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Handle Date Picker
		 *
		 * Takes the emitted payload as $event data from the input field, and the field name,
		 * and sets the date to the specified form field.
		 *
		 * @param date the date to set
		 * @param field the form field to set
		 */
		handleDateSelection(date, field) {
			const t = this

			// Set the date to the current form field
			t.form[field] = Number(date)
		},

		handleSaveItem() {
			const t = this

			if (!t.validateForm()) return

			if (!t.form.entityId) t.saveItem()
			else if (t.form.entityId) t.updateItem()
		},

		/**
		 * Handle Time Selection
		 *
		 * Take the time from the picker and store it.
		 * Validate the time format, so if the min or max is set, the time must be within that range.
		 *
		 * @param time {string} the time to set
		 * @param min {string} the minimum time
		 * @param max {string} the maximum time
		 * @param field {string} the form field to set
		 */
		handleTimeSelection({time, min, max}, field) {
			const t = this

			// Clear any errors
			t.errors[field] = false
			t.errors[field + 'ErrorMessage'] = ''

			// Validate the time format
			if (min && time < min) {
				t.errors[field] = true
				t.errors[field + 'ErrorMessage'] = 'Time must be after ' + min
				return
			}

			// Validate the time format
			if (max && time > max) {
				t.errors[field] = true
				t.errors[field + 'ErrorMessage'] = 'Time must be before ' + max
				return
			}

			// Set the time to the current form field
			t.form[field] = time
		},

		/**
		 * Save Item
		 *
		 * Save the form to the DB.
		 */
		async saveItem() {
			const t = this
			const FORM_DATA = t.form

			const RESPONSE = await t.MIX_redis_create('accidentReport', FORM_DATA)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating Accident Report: ', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Created Report'

			t.$emit('emitReloadPage')
		},

		/**
		 * Update Item
		 *
		 * Update the form to the DB.
		 */
		async updateItem() {
			const t = this
			const FORM_DATA = t.form

			const RESPONSE = await t.MIX_redis_update('accidentReport', FORM_DATA.entityId, FORM_DATA)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating Accident Report: ', RESPONSE.error)
				return
			}

			t.$emit('emitReloadPage')
		},

		/**
		 * Validate Form
		 *
		 * Validate the form as required.
		 *
		 * @returns {boolean} if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Title
			if (!t.form.accidentReportTitle) {
				t.errors.accidentReportTitle = true
				t.errors.accidentReportTitleErrorMessage = 'Title is required'
			}

			// Injured Party - Name
			if (!t.form.accidentReportInjuredPartyName) {
				t.errors.accidentReportInjuredPartyName = true
				t.errors.accidentReportInjuredPartyNameErrorMessage = 'Name is required'
			}

			// Injured Party - Address
			if (!t.form.accidentReportInjuredPartyAddress) {
				t.errors.accidentReportInjuredPartyAddress = true
				t.errors.accidentReportInjuredPartyAddressErrorMessage = 'Address is required'
			}

			// Type
			if (!t.form.accidentReportType) {
				t.errors.accidentReportTypes = true
				t.errors.accidentReportTypeErrorMessage = 'Type is required'
			}

			// Date
			if (!t.form.accidentReportDate) {
				t.errors.accidentReportDate = true
				t.errors.accidentReportDateErrorMessage = 'Date is required'
			}

			// Time
			if (!t.form.accidentReportTime) {
				t.errors.accidentReportTime = true
				t.errors.accidentReportTimeErrorMessage = 'Time is required'
			}

			// Description
			if (!t.form.accidentReportDescription) {
				t.errors.accidentReportDescription = true
				t.errors.accidentReportDescriptionErrorMessage = 'Description is required'
			}

			// Location - Site
			if (!t.form.accidentReportLocationSite) {
				t.errors.accidentReportLocationSite = true
				t.errors.accidentReportLocationSiteErrorMessage = 'Site is required'
			}

			// Location - Location
			if (!t.form.accidentReportLocation) {
				t.errors.accidentReportLocation = true
				t.errors.accidentReportLocationErrorMessage = 'Location is required'
			}

			// Location - Level
			if (!t.form.accidentReportLocationLevel && t.form.accidentReportLocationLevel !== 'Other') {
				t.errors.accidentReportLocationLevel = true
				t.errors.accidentReportLocationLevelErrorMessage = 'Location Level is required'
			}

			// Location - Aisle
			if (!t.form.accidentReportLocationAisle && ['Level 3', 'Level 4', 'Level 6'].includes(t.form.accidentReportLocationLevel)) {
				t.errors.accidentReportLocationAisle = true
				t.errors.accidentReportLocationAisleErrorMessage = 'Location Aisle is required'
			}

			// Location - Block
			if (!t.form.accidentReportLocationRow && ['Level 3', 'Level 4', 'Level 6'].includes(t.form.accidentReportLocationLevel)) {
				t.errors.accidentReportLocationRow = true
				t.errors.accidentReportLocationRowErrorMessage = 'Location Block is required'
			}

			// Location - Row
			if (!t.form.accidentReportLocationBlock && ['Level 3', 'Level 4', 'Level 6'].includes(t.form.accidentReportLocationLevel)) {
				t.errors.accidentReportLocationBlock = true
				t.errors.accidentReportLocationBlockErrorMessage = 'Location Row is required'
			}

			// Location - Box
			if (!t.form.accidentReportLocationBox && ['Level 5'].includes(t.form.accidentReportLocationLevel)) {
				t.errors.accidentReportLocationBox = true
				t.errors.accidentReportLocationBoxErrorMessage = 'Location Box is required'
			}

			// Location - Seat
			if (!t.form.accidentReportLocationSeat && ['Level 3', 'Level 4', 'Level 6'].includes(t.form.accidentReportLocationLevel)) {
				t.errors.accidentReportLocationSeat = true
				t.errors.accidentReportLocationSeatErrorMessage = 'Location Seat is required'
			}

			// Location - Lounge
			if (!t.form.accidentReportLocationLounge && ['Lounges'].includes(t.form.accidentReportLocationLevel)) {
				t.errors.accidentReportLocationLounge = true
				t.errors.accidentReportLocationLoungeErrorMessage = 'Location Lounge is required'
			}

			// Location - Other
			if (!t.form.accidentReportLocationOther && [t.form.accidentReportLocationLevel, t.form.accidentReportLocationAisle, t.form.accidentReportLocationBlock, t.form.accidentReportLocationRow, t.form.accidentReportLocationSeat, t.form.accidentReportLocationBox].includes('Other...')) {
				t.errors.accidentReportLocationOther = true
				t.errors.accidentReportLocationOtherErrorMessage = 'Location Other description is required'
			}

			// Medical - Had Treatment
			if (!t.form.accidentReportMedicalTreatment) {
				t.errors.accidentReportMedicalTreatment = true
				t.errors.accidentReportMedicalTreatmentErrorMessage = 'Had Treatment is required'
			}

			// Medical - Treatment Administered
			if (t.form.accidentReportMedicalTreatment === 'Yes' && !t.form.accidentReportMedicalAdministered) {
				t.errors.accidentReportMedicalAdministered = true
				t.errors.accidentReportMedicalAdministeredErrorMessage = 'Treatment is required'
			}

			// Medical - Conditions Lighting
			if (!t.form.accidentReportConditionLighting) {
				t.errors.accidentReportConditionLighting = true
				t.errors.accidentReportConditionLightingErrorMessage = 'Lighting is required'
			}

			// Medical - Conditions Weather
			if (!t.form.accidentReportConditionWeather) {
				t.errors.accidentReportConditionWeather = true
				t.errors.accidentReportConditionWeatherErrorMessage = 'Weather is required'
			}

			// Medical - Conditions Housekeeping
			if (!t.form.accidentReportConditionHousekeeping) {
				t.errors.accidentReportConditionHousekeeping = true
				t.errors.accidentReportConditionHousekeepingErrorMessage = 'Housekeeping is required'
			}

			return !Object.values(t.errors).includes(true)
		}

	},

	watch: {

		/**
		 * Selected Event Data
		 *
		 * When an Event is selected from the dropdown, set the required form data.
		 * Also set the display data (formatted event date)
		 *
		 * @param eventData - the selected event data
		 */
		selectedEventData(eventData) {
			const t = this

			t.form.accidentReportEvent = eventData?.entityId
		}

	},

}
</script>

<style scoped>

</style>
