<template>
	<div class="appGrey pa-4">

		<!--Instructional Text-->
        <div class="text-center">
            <app-text>
			    You can’t assign a priority to this list that differs to it's existing tasks priorities.
			    <br>
			    <br>
			    Would you like to update all tasks to this priority instead?
		    </app-text>
        </div>


		<v-divider class="mt-4"/>

		<!--Action Buttons-->
		<div class="d-flex flex-column justify-space-between align-center mt-4">
			<!--Accept-->
			<app-btn @click.native="handleUpdateAllPriorities"
					 color="primary"
					 label="Update all tasks with new priority"/>
					 
			<!--Cancel-->
			<app-btn @click.native="closeDialog"
					 icon="cancel"
					 label="Cancel & don't update list priority"/>
		</div>
	</div>
</template>

<script>
export default {

	name: "ToDoListPriorityDialog",

	methods: {

		/**
		 * Close Dialog
		 *
		 * Emit a message back to the parent component to close the dialog.
		 */
         closeDialog() {
			const t = this

			t.$emit('emitCloseDialog')
		},

		/**
		 * Handle Update All Task Priorities
		 *
		 * Emit a message back to the parent component to update all tasks with the new priority.
		 */
		 handleUpdateAllPriorities() {
			const t = this

		 	t.$emit('emitUpdateAllTaskPriorities')
		}

	},

}
</script>



<style scoped>

</style>