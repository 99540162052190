<template>
	<div class="d-flex flex-column align-center white rounded-lg pa-4">

		<app-text color="primary" size="xlarge-bold">{{ usersData.length }}</app-text>

		<app-text class="mt-4">User accounts Pending</app-text>

	</div>
</template>

<script>
export default {

	name: "HomeUserAccountsPending",

	props: ['usersData'],

}
</script>

<style scoped>

</style>
