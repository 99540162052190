<template>
	<div>

		<!--Header-->
		<page-title :divider="true"
					icon="support"
					info="Have any comment or suggestions? Send us a message and we'll help where we can."
					title="App Support"/>

		<!--Form-->
		<v-row class="mt-4" no-gutters>

			<!--Title-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:error="errors.appSupportTitle"
								:error-message="errors.appSupportTitleErrorMessage"
								label="Title"
								v-model.trim="form.appSupportTitle"/>
			</v-col>

			<!--Support Type-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="select"
								:error="errors.appSupportType"
								:error-message="errors.appSupportTypeErrorMessage"
								:items="supportTypeOptions"
								label="Support Type"
								v-model="form.appSupportType"/>
			</v-col>

			<!--Description-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textArea"
								:error="errors.appSupportDescription"
								:error-message="errors.appSupportDescriptionErrorMessage"
								label="Description"
								rows="8"
								v-model.trim="form.appSupportDescription"/>
			</v-col>

		</v-row>

		<!--Save Button-->
		<div class="d-flex justify-end mt-4">
			<app-btn @click.native="handleSaveButton"
					 color="green"
					 icon="save"
					 label="Save"/>
		</div>

	</div>
</template>

<script>
export default {

	name: "AppSupport",

	data: () => ({
		errors: {
			appSupportDescription: false,
			appSupportDescriptionErrorMessage: '',
			appSupportTitle: false,
			appSupportTitleErrorMessage: '',
			appSupportType: false,
			appSupportTypeErrorMessage: '',
		},
		form: {
			entityId: '',
			appSupportTitle: '',
			appSupportType: '',
			appSupportDescription: '',
			appSupportUserName: '',
			appSupportUserEmail: '',
			appSupportUserInternalIdNumber: '',

			createdDateTime: 0,
			createdUserId: '',
			createdUserName: '',
			modifiedDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',
			isDeleted: false,
			deletedDateTime: 0,
			deletedUserId: '',
			deletedUserName: '',
		},
		supportTypeOptions: ['General Enquiry', 'Problem', 'Request', 'Suggestion'],
	}),

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		handleSaveButton() {
			const t = this
			const CURRENT_USER_DATA = t.MIX_getCurrentUser()

			// Only continue if the form is valid
			if (!t.validateForm()) return

			t.form.appSupportUserEmail = CURRENT_USER_DATA.userEmail
			t.form.appSupportUserInternalIdNumber = CURRENT_USER_DATA.userInternalIdNumber
			t.form.appSupportUserName = CURRENT_USER_DATA.userName

			t.saveItem(t.form)
		},

		resetForm() {
			const t = this

			t.form = {
				entityId: '',
				appSupportTitle: '',
				appSupportType: '',
				appSupportDescription: '',
				appSupportUserName: '',
				appSupportUserEmail: '',
				appSupportUserInternalIdNumber: '',
			}

		},

		/**
		 * Save Item
		 *
		 * Save/Update the form to the DB.
		 */
		async saveItem(formData) {
			const t = this

			const RESPONSE = await t.MIX_redis_create('appSupport', formData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating App Support', RESPONSE.error)
				t.$sharedState.errorMessage = 'Sorry, your message couldn\'t be sent. Please try again.'
				return
			}

			t.$sharedState.successMessage = 'Sent Request'
			t.resetForm()
		},

		/**
		 * Validate Form
		 *
		 * Validate the form as required.
		 *
		 * @returns {boolean} if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Title
			if (!t.form.appSupportTitle) {
				t.errors.appSupportTitle = true
				t.errors.appSupportTitleErrorMessage = 'Title is required'
			}

			// Type
			if (!t.form.appSupportType) {
				t.errors.appSupportType = true
				t.errors.appSupportTypeErrorMessage = 'Type is required'
			}

			// Description
			if (!t.form.appSupportDescription) {
				t.errors.appSupportDescription = true
				t.errors.appSupportDescriptionErrorMessage = 'Description is required'
			}

			return !Object.values(t.errors).includes(true)
		}

	},

}
</script>

<style scoped>

</style>
