<!--
TimePicker2

A custom time picker component that uses a native time picker input.
The native time picker is hidden and the time is displayed in a text field.
The user can enter the time manually.
The time is returned in HH:MM format.

Props:
- clearable: Boolean - Whether the text field is clearable.
- disabled: Boolean - Whether the text field is disabled.
- error: Boolean - Whether the text field has an error.
- errorMessage: String - The error message to display.
- label: String - The text field label.
- placeholder: String - The text field placeholder.
- prependInnerIcon: String - The icon to prepend inside the text field.
- time: String - The default time value.

Emits:
- emitTime: String - The formatted time value.

Notes:
Use this time picker instead of the TimePicker component as user have reported it is easier to use.
-->
<template>
	<v-text-field class="rounded-lg labelColor"
				  background-color="appWhite"
				  :clearable="clearable"
				  :disabled="disabled"
				  :error="!!error"
				  :error-messages="errorMessage"
				  flat
				  hide-details="auto"
				  :label="label"
				  outlined
				  :placeholder="placeholder || label"
				  :prepend-inner-icon="prependInnerIcon"
				  :value="formattedTime"
				  @input="onTextInput">

		<!-- Native time picker input -->
		<template v-slot:append>
			<input @input="handleTimeInput"
				   class="native-time-picker"
				   :min="min"
				   :max="max"
				   type="time"
				   v-model="pickerTime"/>
		</template>

	</v-text-field>
</template>

<script>
export default {
	name: "TimePicker2",

	props: [
		'clearable',
		'disabled',
		'error',
		'errorMessage',
		'label',
		'placeholder',
		'prependInnerIcon',
		'max',
		'min',
		'selectedDate',
		'time'
	],

	data: () => ({
		pickerTime: '',
		rawInput: '',
	}),

	computed: {

		/**
		 * Format Time
		 *
		 * Returns the formatted time for display in the text field.
		 *
		 * @returns {string} The formatted time.
		 */
		formattedTime() {
			const t = this

			// If rawInput has 4 digits, format it as HH:MM
			if (t.rawInput.length === 4) return t.rawInput.slice(0, 2) + ':' + t.rawInput.slice(2, 4)

			return t.time || ''
		},
	},

	methods: {

		/**
		 * On Text Input
		 *
		 * Formats the time input as HH:MM if 4 digits are entered.
		 * Emits the formatted time to the parent component.
		 *
		 * @param {string} value The input value.
		 */
		onTextInput(value) {
			const t = this

			if (/^\d{4}$/.test(value)) value = value.slice(0, 2) + ':' + value.slice(2, 4)

			t.$emit('emitTime', {time: value, min: t.min, max: t.max})
		},

		/**
		 * Handle Time Input
		 *
		 * Handles the input event from the native time picker.
		 */
		handleTimeInput() {
			this.$emit('emitTime', this.pickerTime)
		}
	}
}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
	border-color: transparent;
}

.labelColor >>> .v-label {
	color: var(--v-primary-base) !important;
	opacity: 0.5;
}

.native-time-picker {
	border: none;
	background: transparent;
	width: 100%;
	pointer-events: none;
	visibility: hidden
}
</style>
