<template>
	<nav class="topNav primary">

		<div v-for="item in computedMenu" :key="item.name" @click="MIX_go(item.path)"
			 class="topNav-item-container"
			 :class="$route.path === item.path && 'appWhite'">

			<!--Icon-->
			<app-icon :color="$route.path === item.path ? 'primary' : 'appWhite'"
					  :icon="item.icon"
					  size="48"/>

			<!--Name-->
			<app-text color="grey9" size="small">{{ item.name }}</app-text>

		</div>

	</nav>
</template>

<script>
export default {

	name: "AppTopNavigation",

	computed: {

		computedMenu() {
			let menu = []

			menu = [
				{name: 'Events', path: '/events', icon: 'events'},
				{name: 'Users', path: '/users', icon: 'users'},
				{name: 'Reporting', path: '/reporting', icon: 'eye'},
			]

			return menu
		}

	}

}
</script>

<style scoped>
.topNav {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	margin-top: 64px;
	z-index: 999;
}

.topNav:hover {
	cursor: pointer;
}

.topNav-item-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 8px;
	transition: 0.3s;
}

.topNav-item-container:hover {
	background: #222222;
}
</style>
