<!--
Page Title

Renders a full-width page title with an optional icon, info, and divider.
-->
<template>
	<div class="pt-container">

		<div class="d-flex align-center">

			<!--Icon-->
			<app-icon v-if="icon" :icon="icon" class="mr-4" color="grey6" size="48"/>

			<!--Text-->
			<app-text color="primary" size="large">{{ title }}</app-text>

			<!--ToolTip-->
			<div v-if="toolTip" class="ml-2">

				<!--Activator Icon-->
				<v-icon @click="showPageToolTip = !showPageToolTip" class="icons8-help"/>

				<!--Tooltip Body-->
				<v-tooltip bottom color="black"
						   max-width="512"
						   v-model="showPageToolTip"
						   z-index="999999">
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon
							   v-bind="attrs"
							   v-on="on">
						</v-btn>
					</template>

					<app-text>{{ toolTip }}</app-text>

				</v-tooltip>
			</div>

		</div>

		<!--Information Text-->
		<app-text v-if="info" class="mt-4" color="grey" size="small">{{ info }}</app-text>

		<v-divider v-if="divider" class="mt-4"/>

	</div>
</template>

<script>
export default {

	name: 'PageTitle',

	props: ['divider', 'icon', 'info', 'title', 'toolTip'],

	data: () => ({
		showPageToolTip: false,
	})

}
</script>

<style>
.pt-container {
	display: flex;
	flex-direction: column;
	width: 100%;
}
</style>
