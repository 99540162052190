<template>
	<div class="appWhite d-flex rounded-lg mt-4 pa-4">

		<!--Card Details-->
		<div class="d-flex flex-column mr-4" style="width: 100%">

			<app-text color="primary" size="normal-bold">{{ cardData.qualificationName }}</app-text>

			<v-divider class="greyD my-2"/>
			
            <app-text size="small">{{ getCategoryById(cardData.qualificationCategoryId) }}</app-text>
			
		</div>

	</div>
</template>

<script>

export default {

	name: "QualificationPresetMobileCard",

	props: [ 'cardData', 'qualificationCategoriesData' ],

	data: () => ({}),

	methods: {
        /**
		 * Get Category By ID
		 *
		 * Get the category name by its ID.
		 *
		 * @param qualificationCategoryId {string} the category ID
		 * @returns {string} the category name
		 */
		getCategoryById(qualificationCategoryId) {
			const t = this

			// Find the category
			const CATEGORY = t.qualificationCategoriesData.find(category => category.entityId === qualificationCategoryId)

			return CATEGORY?.qualificationCategoryName || 'None'
		},
    },

}
</script>

<style scoped>

</style>
