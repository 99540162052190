<template>
    <div :class="computedText">

        <slot></slot>

    </div>
</template>

<script>
export default {

    name: "AppText",

    props: ['color', 'size'],

    computed: {

        /**
         * Computed Text
         *
         * Set the text size and color from the props, or defaults if not props has been supplied.
         *
         * @returns {string} textStyle containing the size and color
         */
        computedText() {
            const t = this
            let textStyle

            switch (t.$props.size) {
                case 'small':
                    textStyle = 'small'
                    break
                case 'small-bold':
                    textStyle = 'small-bold'
                    break
                case 'normal-bold':
                    textStyle = 'normal-bold'
                    break
                case 'medium':
                    textStyle = 'medium'
                    break
                case 'medium-bold':
                    textStyle = 'medium-bold'
                    break
                case 'large':
                    textStyle = 'large'
                    break
                case 'large-bold':
                    textStyle = 'large-bold'
                    break
                case 'xlarge':
                    textStyle = 'xlarge'
                    break
                case 'xlarge-bold':
                    textStyle = 'xlarge-bold'
                    break
                default:
                    textStyle = 'normal'
                    break
            }

            // Set the text color, or the default if no color prop is present
            textStyle += t.$props.color ? ` ${t.$props.color}--text` : ' grey6--text'

            return textStyle
        }

    }

}
</script>

<style scoped>
.small {
    font-size: 12px;
}
.small-bold {
    font-size: 12px;
    font-weight: bold;
}
.normal {
    font-size: 16px;
}
.normal-bold {
    font-size: 16px;
    font-weight: bold;
}
.medium {
    font-size: 20px;
}
.medium-bold {
    font-size: 20px;
    font-weight: bold;
}
.large {
    font-size: 24px;
}
.large-bold {
    font-size: 24px;
    font-weight: bold;
}
.xlarge {
    font-size: 32px;
}
.xlarge-bold {
    font-size: 32px;
    font-weight: bold;
}
</style>

