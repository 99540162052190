<template>
	<div class="appWhite rounded-lg pa-4">

		<!--Event Name-->
		<app-text color="primary" size="normal-bold">{{ fullReportData.eventData.eventName }}</app-text>

		<!--Team Name-->
		<app-text color="grey9">{{ fullReportData.teamData.teamName }}</app-text>

		<!--Date Occurred-->
		<div class="d-flex align-center">
			<app-icon color="primary" icon="events" size="16"/>
			<app-text class="mt-1 ml-2" color="grey9" size="small">
				{{ MIX_formatDate(fullReportData.reportData.ejectionReportDate, 'long') }}
			</app-text>
		</div>

		<!--Steward Name-->
		<div class="d-flex align-center">
			<app-icon color="primary" icon="user" size="16"/>
			<app-text class="mt-1 ml-2" color="grey9" size="small">
				{{fullReportData.reportData.createdUserName}}
			</app-text>
		</div>

	</div>
</template>

<script>
export default {

	name: "EjectionMobileCard",

	props: ['fullReportData'],

}
</script>

<style scoped>

</style>
