<template>
	<div>
		<!--HIDDEN - File Upload button-->
		<input @change="handleUploadImage"
			   accept="image/*"
			   :disabled="disabled"
			   :multiple="multiple"
			   ref="fileInput"
			   style="display: none"
			   type="file">

		<!--VISIBLE - File Upload button-->
		<app-btn @click.native="onFileClick"
				 :block="block"
				 :color="color || 'primary'"
				 :disabled="disabled"
				 :icon="icon || 'upload'"
				 :icon-color="iconColor || 'white'"
				 :label="label"
				 :loading="isLoading"/>
	</div>
</template>

<script>
import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";

export default {

	name: "ImageUpload",

	props: ['block', 'color', 'disabled', 'folder', 'icon', 'iconColor', 'idForImage', 'imageFor', 'label', 'multiple', 'externalImage'],

	data: () => ({
		file: null,
		isLoading: false,
	}),

	methods: {

		/**
		 * Handle Upload Image
		 *
		 * Based on the imageFor prop, upload the image in the correct format.
		 *
		 * @param event - The event object
		 * @returns {Promise<void>}
		 */
		async handleUploadImage(event) {
			const t = this
			if (t.$props.imageFor === 'eventBackground') {
				// await t.uploadImage(event, 1, 960, 'large')
				// await t.uploadImage(event, 1, 600, 'medium')
				await t.uploadImage(event, 1, 400, 'small')
			}

			if (t.$props.imageFor === 'massEmail') {
				await t.uploadImage(event, 1, 960, 'large')
				// await t.uploadImage(event, 1, 600, 'medium')
				// await t.uploadImage(event, 1, 400, 'small')
			}

			if (t.$props.imageFor === 'observation') {
				await t.uploadImage(event, 1, 960, 'large')
				// await t.uploadImage(event, 1, 600, 'medium')
				// await t.uploadImage(event, 1, 400, 'small')
			}

			if (t.$props.imageFor === 'profilePicture') {
				// await t.uploadImage(event, 1, 960, 'large')
				// await t.uploadImage(event, 1, 600, 'medium')
				await t.uploadImage(event, 1, 400, 'small')
			}

			if (t.$props.imageFor === 'qualificationFile') {
				//await t.uploadImage(event, 1, 960, 'large')
				// await t.uploadImage(event, 1, 600, 'medium')
				await t.uploadImage(event, 1, 400, 'small')
			}

		},

		/**
		 * On File Click
		 *
		 * Auto-click the hidden button.
		 */
		onFileClick() {
			this.$refs.fileInput.click();
		},

		/**
		 * Upload Image
		 *
		 * Rename the file to make it unique, compress the file, and upload it to Firebase Storage.
		 *
		 * @param event {Event} - The event object
		 * @param maxSizeMB {Number} - The maximum size of the image in MB
		 * @param maxWidthOrHeight {Number} - The maximum width or height of the image
		 * @param sizeLabel {String} - The size label of the image
		 * @returns {Promise<void>}
		 */
		async uploadImage(event, maxSizeMB, maxWidthOrHeight, sizeLabel) {
			const t = this
			const FILE = t.$props.multiple ? event.target.files : event.target.files[0]
			const FOLDER = t.$props.folder
			const STORAGE = getStorage()

			// Rename the file to make it unique
			const FILE_NAME = t.renameFile(FILE, maxWidthOrHeight)

			// Create the file metadata
			const META_DATA = {
				contentType: 'image/jpeg'
			}

			// Upload file and metadata to the object 'images/mountains.jpg'
			const STORAGE_REF = ref(STORAGE, `${FOLDER}/${FILE_NAME}`)

			t.isLoading = true

			// Compress the file
			const COMPRESSED_FILE_RESULT = await t.MIX_compressImage(FILE, maxSizeMB, maxWidthOrHeight)
			if (COMPRESSED_FILE_RESULT.hasErrors) {
				console.error('Error Compressing Image: ', COMPRESSED_FILE_RESULT.errors)
				t.$sharedState.errorMessage = 'There was an error compressing the image before uploading. Please try again.'
				return
			}

			// Upload the file
			const UPLOAD_TASK = uploadBytesResumable(STORAGE_REF, COMPRESSED_FILE_RESULT.data, META_DATA)

			// Listen for state changes, errors, and completion of the upload.
			UPLOAD_TASK.on('state_changed',
				(snapshot) => {

					// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
					const PROGRESS = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

					switch (snapshot.state) {
						case 'paused':
							console.log('Upload is paused')
							break;
						case 'running':
							break;
					}

				},

				// Handle any errors
				// A full list of error codes is available at
				// https://firebase.google.com/docs/storage/web/handle-errors
				(error) => {
					let errorMessage
					console.error('Error Uploading Image: ', error)

					switch (error.code) {
						case 'storage/unauthorized':
							errorMessage = 'You do not have permission to do this.'
							console.error(errorMessage)
							break;
						case 'storage/canceled':
							errorMessage = 'Upload cancelled.'
							console.error(errorMessage)
							break;
						case 'storage/unknown':
							errorMessage = 'There was an unknown error with the storage. Please try again.'
							console.error(errorMessage)
							break;
						default:
							errorMessage = 'There was an unknown error uploading the image. Please try again.'
							console.error(errorMessage)
							break;
					}

					t.$sharedState.errorMessage = errorMessage
				},

				// On completion
				() => {

					t.isLoading = false

					// Upload completed successfully, now we can get the download URL
					getDownloadURL(UPLOAD_TASK.snapshot.ref).then(downloadURL =>
						t.$emit('emitImageUpload', {downloadURL, image: COMPRESSED_FILE_RESULT?.data}))

				}
			)
		},

		/**
		 * Rename File
		 *
		 * Rename and return the file name using:
		 *  - the original file name
		 *  - the size label
		 *  - the current datetime in millis
		 *  @example First.Last_small_1631234567890.jpg
		 *
		 * @param file the file to rename
		 * @param maxWidthOrHeight the size label to append to the file name
		 * @returns {string} new unique file name
		 */
		renameFile(file, maxWidthOrHeight) {

			// Grab the file name.
			// There may be multiple periods in the file name, so grab the last one
			const FILE_NAME_WITHOUT_EXTENSION = file.name.substring(0, file.name.lastIndexOf('.'))

			// Grab the file extension
			const EXTENSION = file.name.split('.').pop()

			// Get the current datetime in millis
			const MILLIS_NOW = new Date().getTime()

			return `${this.$props.idForImage}_${this.$props.folder}_${maxWidthOrHeight}_${MILLIS_NOW}.${EXTENSION}`
		},

	},

	watch: {

		/**
		 * External Image
		 *
		 * If an external image is passed in, upload it.
		 * This is in place for when an image needs uploading without directly using this upload component,
		 * currently, this is used in the MassEmailMobileCard component by the rich text editor to add an image.
		 *
		 * @param newExternalImage - The new external image
		 */
		externalImage(newExternalImage) {
			if (newExternalImage) {
				this.handleUploadImage({target: {files: [newExternalImage]}})
			}
		}

	},

}
</script>

<style scoped>

</style>
