<template>
	<v-hover v-slot="{hover}">

		<div class="d-flex cursorPointer">
			<app-icon class="editIcon-container"
					  :class="hover || isActive ? 'orange' : 'appWhite'"
					  :color="hover || isActive ? 'appWhite' : 'orange'"
					  icon="edit"
					  size="32"
					  title="Edit"/>
		</div>

	</v-hover>
</template>

<script>
export default {

	name: "EditIcon",

	props: ['isActive'],

}
</script>

<style scoped>
.editIcon-container {
    aspect-ratio: 1/1;
    border-radius: 8px;
    margin-left: 16px;
    padding: 12px;
    transition: 0.3s;
}
</style>
