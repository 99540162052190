<template>
	<div class="appGrey rounded-lg pa-4">

		<app-text>
			You are about to change
			<b>{{ userData.userData.userName }}</b>'s
			status from
			<b>{{ userData.eventUserData.eventUserStatus }}</b>
			to
			<b>{{ newUserStatus }}</b>
		</app-text>

		<app-text class="mt-4" size="normal-bold">Are you sure you want to do this?</app-text>

		<v-divider class="grey90 my-4"/>

		<div class="d-flex justify-space-between mt-4">
			<app-btn @click.native="handleCancelButton"
					 color="grey"
					 label="Cancel"/>

			<app-btn @click.native="handleConfirmButton"
					 color="green"
					 label="Yes"/>
		</div>

	</div>
</template>

<script>

export default {

	name: "EventUserStatusChangeDialog",

	props: ['newUserStatus', 'userData'],

	data: () => ({}),

	methods: {

		handleCancelButton() {
			this.$emit('closeDialog')
		},

		handleConfirmButton() {
			this.$emit('updateUserEventStatus')
		},
	},

}
</script>

<style scoped>

</style>
