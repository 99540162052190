<template>
	<div>

		{{ computedInit }}

		<!--Tasks Form-->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">
			<swapp-tasks-task-form v-if="isTaskFormVisible"
								   @emitReloadData="emittedReloadData"
								   @closeTaskForm="toggleTaskFormVisibility"
								   :form-data="selectedItem"
								   :swapp-tasks-list-data="swappTasksListData"
								   :users-data="usersData"/>
		</transition>

		<!--Page Content-->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">
			<div v-if="!isTaskFormVisible">

				<!--Tasks Library Bar-->
				<div class="d-flex">

					<!--Tasks Data-->
					<app-form-field form-type="autoComplete"
									:items="swappTasksLibraryTasksData"
									item-text="swappTasksTaskName"
									label="Select Tasks from Library"
									:multiple="true"
									:return-object="true"
									style="width: 100%"
									v-model.trim="selectedTasks"/>

					<!--Add Tasks-->
					<app-btn v-if="!isTaskFormVisible && selectedTasks.length"
							 @click.native="addSelectedLibraryTasks"
							 class="ml-4"
							 color="green"
							 icon="success"
							 label="Add"/>

					<!--Create New-->
					<app-btn @click.native="toggleTaskFormVisibility"
							 class="ml-4"
							 icon="add"
							 label="Create"/>

				</div>

				<!--Selected Tasks-->
				<form-section-title class="mt-8"
									description="These tasks have been added to the list."
									title="Selected Tasks"/>

				<v-data-table class="appWhite"
							  :headers="headers"
							  :items="computedTableData">

					<!--ID-->
					<template v-slot:item.entityId="{item}">
						<app-text size="small">{{ item.entityId }}</app-text>
					</template>

					<!--Task Name-->
					<template v-slot:item.swappTasksTaskName="{item}">
						<app-text size="small">{{ item.swappTasksTaskName }}</app-text>
					</template>

					<!--Action Button-->
					<template v-slot:item.action="{item}">
						<app-icon @click.native="handleOpenTaskForm(item)"
								  class="cursorPointer" color="primary" icon="arrowForward"/>
					</template>

				</v-data-table>

			</div>
		</transition>

	</div>
</template>

<script>

import SwappTasksTaskForm from "@/views/swappTasks/admin/swappTasksTaskForm/SwappTasksTaskForm.vue";

export default {

	name: "SwappTasksListTasks",

	components: {SwappTasksTaskForm},

	props: ['swappTasksLibraryTasksData', 'swappTasksListData', 'swappTasksListTasksData', 'usersData'],

	data: () => ({
		headers: [
			{text: 'Task', value: 'swappTasksTaskName', align: 'start', sortable: false},
			{text: 'Actions', value: 'action', align: 'center', sortable: false, width: '128px'},
		],
		isTaskFormVisible: false,
		selectedItem: {},
		selectedTasks: [],
	}),

	computed: {

		computedInit() {
			const t = this
			const LIST_TASKS_DATA = t.$props.swappTasksListTasksData
		},

		computedTableData() {
			const t = this
			let tableData = t.$props.swappTasksListTasksData

			tableData = tableData.sort((a, b) => a.swappTasksTaskName > b.swappTasksTaskName ? 1 : -1)

			return tableData
		}

	},

	methods: {

		/**
		 * Add Library Task to List
		 *
		 * Add the selected task to the list.
		 * Set the required data to convert this task to a Template task (Library  -> Template).
		 * Create the task in the DB.
		 *
		 * @param taskData {Object} - The task data
		 * @returns {Promise<void>}
		 */
		async addLibraryTaskToList(taskData) {
			const t = this

			// Set the required data to convert this task to a Template task (Library  -> Template)
			taskData.swappTasksListId = t.$props.swappTasksListData.entityId
			taskData.swappTasksTaskIsFromLibrary = false
			taskData.swappTasksTaskIsFromTemplate = true

			const RESPONSE = await t.MIX_redis_create('swappTasksTask', taskData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating Swapp Tasks Task: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem creating this Task, please try again.'
				return
			}
		},

		/**
		 * Add Selected Library Tasks
		 *
		 * Add the selected tasks to the list.
		 * Loop over the selected tasks and add them to the list (both DB and UI).
		 *
		 * @returns {Promise<void>}
		 */
		async addSelectedLibraryTasks() {
			const t = this

			for (const task of t.selectedTasks) {
				delete task.entityId

				await t.addLibraryTaskToList(task)

				// Add the selected tasks to the list
				t.$props.swappTasksListTasksData.push(task)
			}

			// Clear the selected tasks
			t.selectedTasks = []
		},

		/**
		 * Emitted Reload Data
		 *
		 * Emit the reload data event to the parent component.
		 */
		emittedReloadData() {
			const t = this

			t.$emit('reloadData')
			t.toggleTaskFormVisibility()
		},

		/**
		 * Handle Open Task Form
		 *
		 * Set the selectedItem and open the task form.
		 *
		 *
		 * @param item {Object} - The task item
		 */
		handleOpenTaskForm(item) {
			const t = this

			t.selectedItem = item
			t.toggleTaskFormVisibility()
		},

		/**
		 * Toggle Task Form Visibility
		 *
		 * Toggle the visibility of the task form.
		 */
		toggleTaskFormVisibility() {
			const t = this

			t.isTaskFormVisible = !t.isTaskFormVisible
		},

	},

}
</script>

<style scoped>

</style>
