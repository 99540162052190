<template>
  <div @click.native="handleListSelection(item)" class="appWhite d-flex rounded-lg mt-4">

    <div class="d-flex align-center pa-4" style="width: 100%">

      <!--List Status-->
		<div class="d-flex justify-center align-center mr-4">
			<app-icon v-if="cardData.toDoListStatus === true" color="green" icon="success" size="32"
						:title="cardData.toDoListStatus"/>
			<app-icon v-if="cardData.toDoListStatus === false" color="orange" icon="pending" size="32"
						:title="cardData.toDoListStatus"/>
		</div>

      	<div class="d-flex flex-column"  style="width: 100%">
			<!--Privacy | List Name-->
			<div class="d-flex align-center">
				<app-icon v-if="cardData.toDoListIsPrivate"
							class="mr-2" 
							color="primary"
							icon="closedEye"/>
				<app-icon v-else class="mr-2"
							color="primary"
							icon="eye"/>
				<app-text color="primary" size="normal">
					{{ cardData.toDoListName }}
				</app-text>
			</div>

        <v-divider class="my-1"/>

        <!--Priority | Due Date-->
        <div class="d-flex align-center justify-space-between">
			<!--Priority-->
			<v-chip :color="getToDoListColor(cardData.toDoListPriority)"
					class="white--text"
					label
					x-small>
			{{ cardData.toDoListPriority.toUpperCase() }}
			</v-chip>
			<!--Due Date-->
			<div>
				<!--If list has a due date and it is not expired-->
				<div v-if="cardData.toDoListDueDate && new Date().getTime() < cardData.toDoListDueDate" class="d-flex align-center">
					<app-icon class="mr-1 mb-1" color="primary" icon="events" size="20"/>
					<app-text v-if="cardData.toDoListDueDate" size="small" color="grey9">
							{{ MIX_formatDate(cardData.toDoListDueDate, 'short') }}
					</app-text>
				</div>
				
				<!--If list has a due date and it has expired-->
				<v-chip v-if="cardData.toDoListDueDate && new Date().getTime() > cardData.toDoListDueDate" class="px-1 white--text" :color="'red'" label small>
					<app-icon class="mr-1 mb-1" color="white" icon="events" size="20"/>
					<app-text size="small" color="white">
						{{ MIX_formatDate(cardData.toDoListDueDate, 'short') }}
					</app-text>
				</v-chip>

				<!--If list does not have a due date set-->
				<div v-if="!cardData.toDoListDueDate" class="d-flex align-center">
					<app-icon class="mr-1 mb-1" color="primary" icon="events" size="20"/>
					<app-text  size="small" color="grey9">
						No date set
					</app-text>
				</div>
			</div>
        </div>
        
			<!--Assigned To-->
			<div class="d-flex align-end justify-start">
				<app-icon class="mr-1" 
							color="primary" 
							icon="user" 
							size="20" />
				<app-text size="small" 
							color="grey9">
					{{ cardData.toDoListAssignedTo }}
				</app-text>
				<app-text v-if="!cardData.toDoListAssignedTo"
							size="small" 
							color="grey9">No user assigned
				</app-text>
			</div>
      	</div>		
    </div>


			


    </div>
</template>

<script>
export default {
  name: 'ToDoListMobileCard',

  props: ['cardData', 'getToDoListColor'],

  data: () => ({}),

  methods: {},
}
</script>

<style scoped></style>
