<template>
	<div class="appGrey rounded-lg pa-4">

		<app-text color="primary" size="normal-bold">Incomplete Tasks</app-text>
		<v-divider class="greyD my-4"/>

		<app-text>
			Some tasks are not complete and do not have an issue raised.
			<br>
			<br>
			If you are unable to complete a task, please select an issue on the card before continuing.
		</app-text>

		<v-divider class="greyD my-4"/>

		<!--Close Button-->
		<app-btn @click.native="closeDialog"
				 :block="true" class="pr-2" label="Close" style="width: 100%;"/>

	</div>
</template>

<script>

export default {

	name: "SwappTasksTasksNotDoneDialog",

	data: () => ({}),

	methods: {

		closeDialog() {
			this.$emit('closeDialog')
		},

	}

}
</script>

<style scoped>

</style>
