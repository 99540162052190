<template>
	<div class="appWhite rounded-lg mt-4 pa-4">

		<!--Site Name-->
		<app-text color="primary" size="normal-bold">{{ item.siteData.siteName }}</app-text>

		<!--Address-->
		<div class="d-flex align-end mt-1">
			<app-icon class="mr-2" color="primary" icon="home" size="16"/>
			<app-text size="small">{{ computedAddress }}</app-text>
		</div>

		<!--Telephone-->
		<div class="d-flex align-end mt-1">
			<app-icon class="mr-2" color="primary" icon="phone" size="16"/>
			<app-text size="small">{{ computedTelephone }}</app-text>
		</div>

	</div>
</template>

<script>
export default {

	name: "SitesMobileCard",

	props: ['item'],

	computed: {

		computedAddress() {
			const t = this
			const SITE_DATA = t.$props.item.siteData
			let address = ''

			if (SITE_DATA.siteAddressLine1) address += SITE_DATA.siteAddressLine1
			if (SITE_DATA.siteAddressLine2) address += ', ' + SITE_DATA.siteAddressLine2
			if (SITE_DATA.siteAddressTown) address += ', ' + SITE_DATA.siteAddressTown
			if (SITE_DATA.siteAddressCity) address += ', ' + SITE_DATA.siteAddressCity
			if (SITE_DATA.siteAddressCounty) address += ', ' + SITE_DATA.siteAddressCounty
			if (SITE_DATA.siteAddressPostcode) address += ', ' + SITE_DATA.siteAddressPostcode

			return address
		},

		computedTelephone() {
			const t = this
			const TELEPHONE = t.$props.item.siteData.siteTelephone

			return t.MIX_formatTelephoneNumber(TELEPHONE)
		},

	}

}
</script>

<style scoped>

</style>
