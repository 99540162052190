<template>
	<div class="appWhite rounded-lg pa-4">

		<!--Name-->
		<app-text size="medium-bold">{{ userData.userName }}</app-text>
		<app-text color="grey9" size="small">{{ userData.userInternalIdNumber }}</app-text>

		<v-divider class="greyD my-4"/>

		<!--Email-->
		<div class="d-flex align-center mt-4">
			<app-icon color="primary" icon="email" size="24" class="mr-2"/>
			<app-text>{{ userData.userEmail }}</app-text>
		</div>

		<!--Telephone-->
		<div class="d-flex align-center mt-4">
			<app-icon color="primary" icon="phone" size="24" class="mr-2"/>
			<app-text>{{ userData.userTelephone }}</app-text>
		</div>

		<v-divider class="greyD my-4"/>

		<app-btn @click.native="closeDialog" :block="true" class="mt-4" label="Close"/>

	</div>
</template>

<script>

export default {

	name: "EventUserInformation",

	props: ['userData'],

	data: () => ({}),

	methods: {

		closeDialog() {
			this.$emit('closeDialog');
		}

	},

}
</script>

<style scoped>

</style>
