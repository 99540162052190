<template>

	<page-loading-animation v-if="isLoading" is-loading="isLoading"/>

	<!--Dashboard-->
	<swapp-tasks-dashboard v-else-if="isTasksDashboardVisible"
						   @backToTableView="toggleDashboardVisibility"/>

	<!--Page Content-->
	<div v-else>

		<!--Header-->
		<page-title :divider="true"
					icon="tasks"
					info="Create and administer SWAPP Tasks for your venue."
					title="SWAPP Tasks"/>

		<!--Action Bar-->
		<div class="d-flex align-center mt-4">

			<!--Search-->
			<div @focusin="onSearchFocus" @focusout="onSearchBlur" style="width: 100%">
				<app-form-field form-type="textInput"
								append-icon="icons8-search"
								class="mr-4"
								:clearable="true"
								label="List Name"
								v-model.trim="searchByListName"/>
			</div>

			<!--Add Button-->
			<app-btn v-if="!isSearchFocused"
					 @click.native="openRightPanel({}, 'list&tasks')" class="mr-4" icon="add" label="Add"/>

			<!--Filter Button - with a numbered badge-->
			<div v-if="!isSearchFocused" style="position:relative;">
				<app-btn @click.native="toggleFiltersVisibility"
						 class="mr-4" color="appWhite" icon="filter" icon-color="primary"/>
				<span v-if="computedNumberOfActiveFilters > 0" class="badge">{{ computedNumberOfActiveFilters }}</span>
			</div>

			<!--More Actions Menu-->
			<more-actions-menu v-if="!isSearchFocused" @emitMenuSelection="handleEmittedMenuSelection"
							   :menuList="moreActionsMenuOption"/>

		</div>

		<!--Clear Filters-->
		<div v-if="computedNumberOfActiveFilters > 0" class="d-flex justify-end mt-4">
			<app-btn @click.native="clearFilters" icon="close" label="Clear Filters"/>
		</div>

		<!--Table-->
		<v-data-table v-if="$vuetify.breakpoint.width >= 600"
					  class="appWhite rounded-lg mt-4"
					  :headers="computedHeaders"
					  :items="computedTableData">

			<!--ID-->
			<template v-slot:item.entityId="{item}">
				<app-text size="small">{{ item.entityId }}</app-text>
			</template>

			<!--List Name-->
			<template v-slot:item.swappTasksListName="{item}">
				<app-text size="small">{{ item.swappTasksListName }}</app-text>
			</template>

			<!--Site-->
			<template v-slot:item.swappTasksListSite="{item}">
				<app-text size="small">
					{{ sitesData?.find(site => site.entityId === item.swappTasksListSite)?.siteName }}
				</app-text>
			</template>

			<!--Area-->
			<template v-slot:item.swappTasksListArea="{item}">
				<app-text size="small">{{ item.swappTasksListArea }}</app-text>
			</template>

			<!--Location-->
			<template v-slot:item.swappTasksListLocation="{item}">
				<app-text size="small">{{ item.swappTasksListLocation }}</app-text>
			</template>

			<!--Role-->
			<template v-slot:item.swappTasksListRole="{item}">
				<app-text size="small">{{ item.swappTasksListRole }}</app-text>
			</template>

			<!--Action Button-->
			<template v-slot:item.action="{item}">
				<app-icon @click.native="handleOpenRightPanel(item)"
						  class="cursorPointer" color="primary" icon="arrowForward"/>
			</template>

		</v-data-table>

		<!--Mobile Cards-->
		<div v-if="$vuetify.breakpoint.width < 600">

			<!--No data message-->
			<app-text v-if="!computedTableData.length"
					  class="text-center mt-4" color="grey9">You have no Lists to view
			</app-text>

			<swapp-tasks-list-mobile-card v-for="item in computedTableData" :key="item.entityId"
										  @click.native="openRightPanel(item, 'list&tasks')"
										  :cardData="item"
										  class="mt-4"/>

		</div>

		<!--Filters Panel ------------------------------------------------------------------------------------------ -->
		<filter-panel :is-filters-panel-visible="isFiltersPanelVisible" @toggle="toggleFiltersVisibility">
			<div class="pa-4">

				<!--Site-->
				<page-break-title title="Sites"/>
				<div class="mt-4">
					<app-form-field form-type="autoComplete"
									:clearable="true"
									:items="sitesData"
									item-text="siteName"
									label="Area"
									:multiple="true"
									:return-object="true"
									v-model="filterBySites"/>
				</div>

				<!--Area-->
				<page-break-title class="mt-8" title="Area"/>
				<div class="mt-4">
					<app-form-field form-type="textInput"
									label="Area"
									v-model.trim="filerByArea"/>
				</div>

				<!--Location-->
				<page-break-title class="mt-8" title="Location"/>
				<div class="mt-4">
					<app-form-field form-type="textInput"
									label="Location"
									v-model.trim="filerByLocation"/>
				</div>

				<!--Role-->
				<page-break-title class="mt-8" title="Role"/>
				<div class="mt-4">
					<app-form-field form-type="textInput"
									label="Role"
									v-model.trim="filerByRole"/>
				</div>

			</div>
		</filter-panel>

		<!--Right Side --------------------------------------------------------------------------------------------- -->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">
			<div v-if="isRightPanelVisible"
				 class="appGrey formShadow"
				 style="position:absolute; top:0; bottom: 0; right: 0; overflow: auto"
				 :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 50%'"
				 v-model="isRightPanelVisible">

				<!--List & Tasks-->
				<div v-if="formType === 'list&tasks'">

					<!--Header-->
					<div class="d-flex align-center primary pa-4">
						<app-text color="appWhite" size="normal">{{ selectedItem?.swappTasksListName || 'New' }}
						</app-text>
						<v-spacer/>
						<app-icon @click.native="closeRightPanel"
								  class="cursorPointer" color="white" icon="close" size="32"/>
					</div>

					<!--Action Bar-->
					<div class="d-flex align-center pa-4">

						<!--QR Code Link Button-->
						<app-btn @click.native="handleQrLinkButton" icon="qrCode"
								 icon-size="32"
								 :label="selectedItem?.swappTasksListQrId ? 'Re-link' : 'Link'"/>

						<v-spacer/>

						<edit-icon v-if="tabs === 'list'"
								   @click.native="editItem" :isActive="!isReadOnly"/>
						<delete-icon v-if="tabs === 'list' && selectedItem?.entityId"
									 @emitDeleteItem="deleteItem(selectedItem)"
									 itemName="swappTasksListName"
									 :selectedItem="selectedItem"/>

					</div>

					<!--Tabs-->
					<v-tabs v-model="tabs">

						<!--List-->
						<v-tab href="#list">
							<app-text size="small">List</app-text>
						</v-tab>

						<!--Tasks-->
						<v-tab :disabled="!selectedItem?.entityId" href="#tasks">
							<app-text size="small">Tasks</app-text>
						</v-tab>

						<!--History-->
						<v-tab :disabled="!selectedItem?.entityId" href="#history">
							<app-text size="small">History</app-text>
						</v-tab>

					</v-tabs>

					<!--Tabs Content-->
					<v-tabs-items v-model="tabs">

						<!--List-->
						<v-tab-item value="list">
							<swapp-tasks-list-form @reloadData="emittedReloadData"
												   class="appGrey pa-4"
												   :form-data="selectedItem"
												   :is-read-only="isReadOnly"
												   :qr-code-param="qrCodeParam"
												   :sites-data="sitesData"/>
						</v-tab-item>

						<!--Tasks-->
						<v-tab-item value="tasks">
							<swapp-tasks-list-tasks @reloadData="emittedReloadData"
													class="appGrey pa-4"
													:swapp-tasks-library-tasks-data="swappTasksLibraryTasksData"
													:swapp-tasks-list-data="selectedItem"
													:swapp-tasks-list-tasks-data="swappTasksListTasksData"
													:users-data="usersData"/>
						</v-tab-item>

						<!--History-->
						<v-tab-item value="history">
							<swapp-tasks-history @reloadData="emittedReloadData"
												 class="appGrey pa-4"
												 :list-data="selectedItem"/>
						</v-tab-item>

					</v-tabs-items>

				</div>

				<!--Tasks Library-->
				<div v-if="formType === 'library'">

					<!--Header-->
					<div class="d-flex align-center primary pa-4">
						<app-text color="appWhite" size="normal">Tasks Library</app-text>
						<v-spacer/>
						<app-icon @click.native="closeRightPanel"
								  class="cursorPointer" color="white" icon="close" size="32"/>
					</div>

					<!--Tabs-->
					<v-tabs v-model="tabs">

						<!--Tasks Library-->
						<v-tab href="#tasksLibrary">
							<app-text size="small">Tasks Library</app-text>
						</v-tab>

					</v-tabs>

					<!--Tabs Content-->
					<v-tabs-items v-model="tabs">

						<!--Tasks Library-->
						<v-tab-item value="tasksLibrary">
							<swapp-tasks-library @reloadData="emittedReloadData"
												 class="appGrey pa-4"
												 :swapp-tasks-library-tasks-data="swappTasksLibraryTasksData"
												 :users-data="usersData"/>
						</v-tab-item>

					</v-tabs-items>

				</div>

			</div>

		</transition>

		<!--Dialogs ------------------------------------------------------------------------------------------------ -->

		<!--Create List From QR Code Dialog-->
		<v-dialog max-width="512" persistent v-model="isCreateQrListDialogVisible">

			<div class="appGrey pa-4">

				<app-text>This QR Code is not assigned to an existing list.</app-text>
				<app-text class="mt-4" size="normal-bold">Would you like to create one?</app-text>

				<!--Action Buttons-->
				<div class="d-flex justify-space-between mt-4">

					<!--Cancel-->
					<app-btn @click.native="handleCreateListFromQrNoButton"
							 color="grey"
							 icon="cancel"
							 label="Cancel"/>

					<!--Yes-->
					<app-btn @click.native="handleCreateListFromQrCode"
							 color="green"
							 icon="success"
							 label="Yes"/>

				</div>

			</div>

		</v-dialog>

		<!--QR Code Reader Dialog-->
		<v-dialog v-if="isQrCodeReaderDialogVisible" fullscreen max-width="960" v-model="isQrCodeReaderDialogVisible">
			<div class="appGrey rounded-lg pa-4" style="height: 100%">

				<!--Header-->
				<div class="d-flex align-center">
					<div>
						<app-text color="grey" size="medium-bold">QR Sanner</app-text>
						<app-text color="grey9" size="small">Scan the QR code you wish to re-link</app-text>
					</div>
					<v-spacer/>
					<close-icon @click.native="toggleQrCodeReaderDialogVisibility"/>
				</div>

				<!--Error Messages-->
				<div v-if="cameraError" class="appGrey centerInPage text-center rounded-lg pa-4">
					<app-text class="" v-if="noFrontCamera">You don't seem to have a front camera on your device
					</app-text>
					<app-text class="" v-if="noRearCamera">You don't seem to have a rear camera on your device
					</app-text>
					<app-text class="mt-4" v-if="cameraError">{{ cameraError }}</app-text>
					<app-btn @click.native="resetCamera" :block="true" class="mt-4" label="Close"/>
				</div>

				<!--QR Reader-->
				<qrcode-stream v-if="!cameraError"
							   @decode="onDecode"
							   @init="onInit"
							   :camera="camera || null"
							   class="animate__animated animate__zoomIn animate__faster rounded-lg mt-4"
							   :track="paintOutline"
							   style="height: 80%">

					<!--Loader-->
					<div class="d-flex align-center justify-center" style="height: 100%; width: 100%">
						<app-text v-if="isLoading" size="xlarge">Loading...</app-text>
					</div>

					<!--Instructions-->
					<div class="d-flex align-center justify-center"
						 style="height: 100%; width: 100%; position:absolute; top:0; bottom:0">

						<!--QR Icon-->
						<app-icon color="#FFFFFF33" icon="qrCode" size="256" style="position:absolute;"
								  class="animate__animated animate__pulse animate__fast animate__infinite"/>

						<!--Instruction-->
						<app-text color="white" size="large">Scan your QR code</app-text>

					</div>

				</qrcode-stream>

				<!--Camera Buttons-->
				<div class="d-flex align-center justify-center mt-4">
					<app-btn @click.native="switchCamera('front')"
							 label="Front" height="32"/>
					<app-btn @click.native="switchCamera('default')"
							 class="mx-4" color="grey" label="Default" height="32"/>
					<app-btn @click.native="switchCamera('rear')"
							 class="mr-4" label="Rear" height="32"/>
				</div>

			</div>
		</v-dialog>

		<app-dialog :is-visible="isExportDialogVisible">
			<swapp-tasks-export-dialog @closeDialog="toggleExportDialogVisibility"
			@exportOption="handleEmittedExportOption"/>
		</app-dialog>

	</div>

</template>

<script>
import SwappTasksListForm from "@/views/swappTasks/admin/swappTasksListForm/SwappTasksListForm.vue";
import SwappTasksTasksForm from "@/views/swappTasks/admin/swappTasksListTasks/SwappTasksListTasks.vue";
import SwappTasksListTasks from "@/views/swappTasks/admin/swappTasksListTasks/SwappTasksListTasks.vue";
import UsersMobileCard from "@/views/users/userMobileCard/UserMobileCard.vue";
import SwappTasksListMobileCard from "@/views/swappTasks/admin/swappTasksListMobileCard/SwappTasksListMobileCard.vue";
import SwappTasksLibrary from "@/views/swappTasks/admin/swappTasksLibrary/SwappTasksLibrary.vue";
import {QrcodeStream} from 'vue-qrcode-reader'
import SwappTasksDashboard from "@/views/swappTasks/swappTasksDashboard/SwappTasksDashboard.vue";
import SwappTasksHistory from "@/views/swappTasks/admin/swappTasksHistory/SwappTasksHistory.vue";
import SwappTasksExportDialog from "@/views/swappTasks/admin/swappTasksExportDialog/SwappTasksExportDialog.vue";

export default {

	name: "SwappTasksAdmin",

	props: ['qrCodeParamProps', 'swappTasksListData'],

	components: {
		SwappTasksExportDialog,
		SwappTasksHistory,
		SwappTasksDashboard,
		SwappTasksLibrary,
		SwappTasksListTasks,
		SwappTasksListMobileCard,
		UsersMobileCard,
		SwappTasksTasksForm,
		SwappTasksListForm,
		QrcodeStream
	},

	data: () => ({
		exportWithHistory: false,
		filterBySites: [],
		filerByArea: '',
		filerByLocation: '',
		filerByRole: '',
		formType: '',
		isCreateQrListDialogVisible: false,
		isExportDialogVisible: false,
		isFiltersPanelVisible: false,
		isLoading: true,
		isQrCodeReaderDialogVisible: false,
		isReadOnly: false,
		isRightPanelVisible: false,
		isSearchFocused: false,
		isTasksDashboardVisible: false,
		moreActionsMenuOption: [
			{name: 'Dashboard', icon: ''},
			{name: 'Export', icon: 'export'},
			{name: 'Task Library', icon: ''},
		],
		qrCodeParam: '',
		searchByListName: '',
		selectedItem: {},
		tableHeaders: [
			{text: 'ID', value: 'entityId', align: 'start', sortable: false, hidden: true},
			{text: 'List Name', value: 'swappTasksListName', align: 'start', sortable: false},
			{text: 'Site', value: 'swappTasksListSite', align: 'start', sortable: false},
			{text: 'Area', value: 'swappTasksListArea', align: 'start', sortable: false},
			{text: 'Location', value: 'swappTasksListLocation', align: 'start', sortable: false},
			{text: 'Role', value: 'swappTasksListRole', align: 'start', sortable: false},
			{text: '', value: 'action', align: 'center', sortable: false, width: '48px'},
		],
		tabs: '',

		// QR Reader
		camera: 'rear',
		cameraError: '',
		noFrontCamera: false,
		noRearCamera: false,

		// Data
		sitesData: [],
		swappTasksLibraryTasksData: [],
		swappTasksListsData: [],
		swappTasksListTasksData: [],
		usersData: [],
	}),

	computed: {

		/**
		 * Computed Export CSV
		 *
		 * Return the data and headers for the CSV export
		 *
		 * @returns {{headers: {}, data: *[]}}
		 */
		computedExportCSV() {
			const t = this
			let data = []
			let headers = {}

			// Add the readable headers for the CSV columns
			headers = {
				entityId: 'ID',
				listQrId: 'QR ID',
				listName: 'List Name',
				listSite: 'Site',
				listArea: 'Area',
				listLocation: 'Location',
				listRole: 'Role',
			}

			if (t.exportWithHistory) {
				headers = {
					...headers,
					taskName: 'Task Name',
					textInstructionsIncluded: 'Text Instructions Included?',
					taskUserComments: 'User Comments Left?',
					taskUserIssue: 'User Issue',
					taskStatus: 'Task Status',
					taskCompletedDateTime: 'Completed Date Time',
					taskSupervisorIssue: 'Supervisor Issue',
				}
			}

			// Add the data
			t.computedTableData.forEach(entry => {

				const DATA_OBJECT = {
					entityId: entry?.entityId,
					listQrId: entry?.swappTasksListQrId,
					listName: entry?.swappTasksListName,
					listSite: t.sitesData?.find(site => site.entityId === entry?.swappTasksListSite)?.siteName,
					listArea: entry?.swappTasksListArea,
					listLocation: entry?.swappTasksListLocation,
					listRole: entry?.swappTasksListRole,
				}

				data.push(DATA_OBJECT)
			})

			return {headers, data}
		},

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this
			let headers = t.tableHeaders

			// Remove hidden headers
			headers = headers.filter(h => !h.hidden)

			return headers
		},

		/**
		 * Computed Number of Active Filters
		 *
		 * Count the number of active filters to display in the filters button badge.
		 *
		 * @returns {*}
		 */
		computedNumberOfActiveFilters() {
			const t = this
			return [
				t.filterBySites.length,
				t.filerByArea ? 1 : 0,
				t.filerByLocation ? 1 : 0,
				t.filerByRole ? 1 : 0
			].reduce((acc, curr) => acc + curr, 0)
		},

		/**
		 * Computed Table Data
		 *
		 * Sort and return the table data.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedTableData() {
			const t = this
			let tableData = t.swappTasksListsData

			// Search by List Name
			if (t.searchByListName) {
				const SEARCH_CRITERIA = t.searchByListName.toUpperCase()
				tableData = tableData.filter(list => list.swappTasksListName.toUpperCase().includes(SEARCH_CRITERIA))
			}

			// Filter by Site
			if (t.filterBySites.length) {
				const SITE_IDS = t.filterBySites.map(site => site.entityId)
				tableData = tableData.filter(list => SITE_IDS.includes(list.swappTasksListSite))
			}

			// Filter by Area
			if (t.filerByArea) {
				const SEARCH_CRITERIA = t.filerByArea.toUpperCase()
				tableData = tableData.filter(list => list.swappTasksListArea.toUpperCase().includes(SEARCH_CRITERIA))
			}

			// Filter by Location
			if (t.filerByLocation) {
				const SEARCH_CRITERIA = t.filerByLocation.toUpperCase()
				tableData = tableData.filter(list => list.swappTasksListLocation.toUpperCase().includes(SEARCH_CRITERIA))
			}

			// Filter by Role
			if (t.filerByRole) {
				const SEARCH_CRITERIA = t.filerByRole.toUpperCase()
				tableData = tableData.filter(list => list.swappTasksListRole.toUpperCase().includes(SEARCH_CRITERIA))
			}

			tableData = tableData.sort((a, b) => a.swappTasksListName > b.swappTasksListName ? 1 : -1)

			return tableData
		},

	},

	methods: {

		/**
		 * Clear Filters
		 *
		 * Clear all the filters.
		 */
		clearFilters() {
			const t = this

			t.filterBySites = []
			t.filerByArea = ''
			t.filerByLocation = ''
			t.filerByRole = ''
		},

		/**
		 * Clear QR Code Data
		 *
		 * Clear the QR Code parameters.
		 */
		clearQrCodeData() {
			const t = this

			t.qrCodeParam = ''
			t.$route.params.code = ''

			// Refresh the page to clear the URL param
			t.$router.push({path: '/swappTasks/'})
		},

		/**
		 * Close Right Panel
		 *
		 * Close the right panel.
		 */
		closeRightPanel() {
			const t = this

			// Clear the QR Code data if it exists so the url doesn't get stuck with the param
			if (t.qrCodeParam || t.$route.params.code) t.clearQrCodeData()

			t.tabs = ''
			t.selectedItem = {}
			t.isRightPanelVisible = false
		},

		/**
		 *
		 * Delete Item
		 *
		 * Delete the selected document by its ID.
		 *
		 * @param itemData {object} the item to delete
		 */
		async deleteItem(itemData) {
			const t = this

			const RESPONSE = await t.MIX_redis_delete('swappTasksList', itemData.entityId, itemData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error deleting Team', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Deleted Team'

			// Reload the data
			await t.loadData()

			// Close the panel
			t.closeRightPanel()
		},

		/**
		 * Edit Item
		 *
		 * Toggle the editability of the form.
		 */
		editItem() {
			const t = this

			t.isReadOnly = !t.isReadOnly
		},

		/**
		 * Emitted Reload Data
		 *
		 * Assign the emitted data to the selected item, and reload the data.
		 * This will enable the form to be kept open and updated, rather than close the form and open it again.
		 *
		 * @param payload {object} the new Swapp Tasks List data
		 */
		async emittedReloadData(payload) {
			const t = this

			// Payload - when refreshing from creating a new List
			if (payload?.entityId) t.selectedItem = payload

			// SelectedItem - when refreshing from creating a new Task
			if (t.selectedItem?.entityId) await t.loadSwappTasksListTasks(t.selectedItem, 'Template')

			t.clearQrCodeData()
			t.isReadOnly = true
			await t.reloadData()
		},

		/**
		 * Export Data
		 *
		 * Export the data to CSV.
		 */
		exportData() {
			const t = this

			t.MIX_exportDocuments(t.computedExportCSV.headers, 'SWAPP Tasks', t.computedExportCSV.data)
		},

		/**
		 * Handle Create List From QR Code
		 *
		 * Handle the YES button from the create list from QR Code dialog.
		 * Close the dialog and open the right panel.
		 */
		handleCreateListFromQrCode() {
			const t = this
			t.toggleIsCreateQrListDialogVisible()
			t.openRightPanel({}, 'list&tasks')
		},

		/**
		 * Handle Create List From QR No Button
		 *
		 * Handle the NO button from the create list from QR Code dialog.
		 * Close the dialog and clear the QR Code data.
		 */
		handleCreateListFromQrNoButton() {
			const t = this

			t.clearQrCodeData()
			t.toggleIsCreateQrListDialogVisible()
		},

		/**
		 * Handle Export Option
		 *
		 * Handle the selection of the export option from the Export dialog.
		 * If the option is to include history, set the exportWithHistory flag to true before exporting.
		 *
		 * @param option {string} the selected option
		 */
		async handleEmittedExportOption(option) {
			const t = this

			if (option?.shouldIncludeHistory) {
				t.exportWithHistory = true
				await t.loadFullSwappTasksData()
			}

			t.toggleExportDialogVisibility()
			t.exportData()
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this

			if (selection === 'Dashboard') t.toggleDashboardVisibility()
			if (selection === 'Export') t.toggleExportDialogVisibility()
			if (selection === 'Task Library') t.openRightPanel({}, 'library')
		},

		/**
		 * Handle Open Right Panel
		 *
		 * Handle the opening of the right panel.
		 * Load the tasks for the selected list.
		 *
		 * @param item {object} the selected key
		 */
		async handleOpenRightPanel(item) {
			const t = this

			// Load the Tasks for the selected List
			await t.loadSwappTasksListTasks(item, 'Template')

			t.openRightPanel(item, 'list&tasks')
		},

		/**
		 * Handle QR Code Loader
		 *
		 * Handle the loading of the QR Code.
		 * Check the props for a URL param, or List data.
		 *  - If there is no URL param, open the page as normal.
		 *  - If there is a URL param, either open the create dialog or the populated right panel.
		 */
		async handleQrCodeLoader() {
			const t = this
			t.qrCodeParam = t.$props.qrCodeParamProps
			const SWAPP_TASKS_LIST_DATA = t.$props.swappTasksListData

			// If there is no QR Code param (being opened from navigation, not through scanning),
			// return and open the page as normal
			if (!t.qrCodeParam) return

			// If there is no SWAPP Tasks List Data, open the create dialog
			if (!SWAPP_TASKS_LIST_DATA?.entityId) t.toggleIsCreateQrListDialogVisible()
			// If there is SWAPP Tasks List Data, open the populated right panel
			else {
				await t.loadSwappTasksListTasks(SWAPP_TASKS_LIST_DATA, 'Template')
				t.openRightPanel(SWAPP_TASKS_LIST_DATA, 'list&tasks')
			}
		},

		handleQrLinkButton() {
			const t = this

			t.toggleQrCodeReaderDialogVisibility()
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadLibraryTasksData(),
				t.loadSitesData(),
				t.loadSwappTasksListsData(),
				t.loadUsersData()
			])

			t.isLoading = false
		},

		/**
		 * Load Library Tasks Data
		 *
		 * Load the library tasks data from the DB.
		 *
		 * @returns {Promise<void>}
		 */
		async loadLibraryTasksData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getSwappTasksTasksWhere({
				whereKey: 'swappTasksTaskIsFromLibrary',
				whereValue: true
			})

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error loading Library Tasks: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem loading the Library Tasks, please try again.'
				return
			}

			t.swappTasksLibraryTasksData = RESPONSE.data
		},

		/**
		 * Load Sites Data
		 *
		 * Load the sites data from the DB.
		 *
		 * @returns {Promise<void>}
		 */
		async loadSitesData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getAll('site')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error loading Sites: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem loading the Sites, please try again.'
				return
			}

			t.sitesData = RESPONSE.data
		},

		/**
		 * Load Swapp Tasks Lists Data
		 *
		 * Load the SWAPP Tasks Lists data from the DB.
		 *
		 * @returns {Promise<void>}
		 */
		async loadSwappTasksListsData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getSwappTasksListWhere({
				whereKey: 'swappTasksListIsFromTemplate',
				whereValue: true
			})

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error loading SWAPP Tasks Lists: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem loading the Lists, please try again.'
				return
			}

			t.swappTasksListsData = RESPONSE.data
		},

		/**
		 * Load Swapp Tasks List Tasks
		 *
		 * Load the tasks for the selected list.
		 * The tasks will either be from the Template or the List.
		 *  - Tasks from the Template are the default tasks that are loaded when creating a new list.
		 *  - Tasks from the List are the NEW tasks that have been created when creating a NEW working List.
		 *
		 * @param listData {object} the list data
		 * @param getTasksFrom {string} the source of the tasks
		 * @returns {Promise<void>}
		 */
		async loadSwappTasksListTasks(listData, getTasksFrom) {
			const t = this

			const RESPONSE = await t.MIX_redis_getSwappTasksListTasks(listData?.entityId, getTasksFrom)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error loading SWAPP Tasks List Tasks: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem loading the Tasks, please try again.'
				return
			}

			t.swappTasksListTasksData = RESPONSE.data
		},

		/**
		 * Load Users Data
		 *
		 * Load the users data from the DB.
		 *
		 * @returns {Promise<void>}
		 */
		async loadUsersData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getAll('user')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error loading Users: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem loading the Users, please try again.'
				return
			}

			t.usersData = RESPONSE.data
		},

		/**
		 * Open Form
		 *
		 * Open the form dialog.
		 * If no item is passed in, the form will open as new (blank).
		 *
		 * @param item {object} the selected key
		 * @param formType {string} the type of form to open
		 */
		openRightPanel(item, formType) {
			const t = this

			if (item?.entityId) {
				t.selectedItem = {...item}
				t.isReadOnly = true
			} else {
				t.selectedItem = {}
				t.isReadOnly = false
			}

			// Set the form type to know which form to open
			t.formType = formType

			t.isRightPanelVisible = true
		},

		/**
		 * On Search Blur
		 *
		 * When the search field is blurred, set the isSearchFocused flag to false.
		 */
		onSearchBlur() {
			const t = this

			if (t.$vuetify.breakpoint.width < 600) t.isSearchFocused = false
		},

		/**
		 * On Search Focus
		 *
		 * When the search field is focused, set the isSearchFocused flag to true.
		 * This is used to expand the search when on smaller devices.
		 */
		onSearchFocus() {
			const t = this

			if (t.$vuetify.breakpoint.width < 600) t.isSearchFocused = true
		},

		/**
		 * Reload Data
		 *
		 * Call to reload the data.
		 */
		reloadData() {
			const t = this

			t.loadData()
		},

		/**
		 * Toggle Dashboard Visibility
		 *
		 * Toggle the visibility of the Dashboard.
		 */
		toggleDashboardVisibility() {
			const t = this

			t.isTasksDashboardVisible = !t.isTasksDashboardVisible
		},

		/**
		 * Toggle Export Dialog Visibility
		 *
		 * Toggle the visibility of the Export dialog.
		 */
		toggleExportDialogVisibility() {
			const t = this

			t.isExportDialogVisible = !t.isExportDialogVisible
		},

		/**
		 * Toggle Filters Visibility
		 *
		 * Toggle the visibility of the filters panel.
		 */
		toggleFiltersVisibility() {
			const t = this

			t.isFiltersPanelVisible = !t.isFiltersPanelVisible
		},

		/**
		 * Toggle Is Create QR List Dialog Visible
		 *
		 * Toggle the visibility of the create list from QR Code dialog.
		 */
		toggleIsCreateQrListDialogVisible() {
			const t = this
			t.isCreateQrListDialogVisible = !t.isCreateQrListDialogVisible
		},

		/**
		 * Toggle QR Code Reader Dialog Visibility
		 *
		 * Toggle the visibility of the QR Code reader dialog.
		 */
		toggleQrCodeReaderDialogVisibility() {
			this.isQrCodeReaderDialogVisible = !this.isQrCodeReaderDialogVisible
		},

		// QR Reader ----------------------------------------------------------------------

		/**
		 * On Decode
		 *
		 * When the read has decoded the QR code, strip the QR ID off the URL and save it.
		 *
		 * @param payLoad {string} the decoded QR code data
		 * @returns {Promise<void>}
		 */
		async onDecode(payLoad) {
			const t = this

			t.qrCodeParam = payLoad.split('/').pop()
		},

		/**
		 * On Init
		 *
		 * Initialise the QR code reader by checking for loading and camera errors.
		 * Any errors will stop the camera from loading.
		 *
		 * @param promise
		 * @returns {Promise<void>}
		 */
		async onInit(promise) {
			const t = this
			// t.isLoading = true

			// Initialisation Errors
			try {
				await promise
			} catch (error) {
				if (error.name === 'NotAllowedError') t.cameraError = "ERROR: you need to grant camera access permission"
				else if (error.name === 'NotFoundError') t.cameraError = "ERROR: no camera on this device"
				else if (error.name === 'NotSupportedError') t.cameraError = "ERROR: secure context required (HTTPS, localhost)"
				else if (error.name === 'NotReadableError') t.cameraError = "ERROR: is the camera already in use?"
				else if (error.name === 'OverconstrainedError') t.cameraError = "ERROR: installed cameras are not suitable"
				else if (error.name === 'StreamApiNotSupportedError') t.cameraError = "ERROR: Stream API is not supported in this browser"
				else if (error.name === 'InsecureContextError') t.cameraError = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.'
				else t.cameraError = `ERROR: Camera error (${error.name})`
			}

			// Camera Errors
			try {
				await promise
			} catch (error) {
				const triedFrontCamera = t.camera === 'front'
				const triedRearCamera = t.camera === 'rear'

				const cameraMissingError = error.name === 'OverconstrainedError'

				if (triedRearCamera && cameraMissingError) t.noRearCamera = true

				if (triedFrontCamera && cameraMissingError) t.noFrontCamera = true

				console.error(error)
			}

			t.isLoading = false
		},

		/**
		 * Paint Outline
		 *
		 * Track the QR code with a colored outline when it has been found
		 *
		 * @param detectedCodes
		 * @param ctx
		 */
		paintOutline(detectedCodes, ctx) {
			for (const detectedCode of detectedCodes) {
				const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

				ctx.strokeStyle = "red"

				ctx.beginPath();
				ctx.moveTo(firstPoint.x, firstPoint.y)
				for (const {x, y} of otherPoints) {
					ctx.lineTo(x, y);
				}
				ctx.lineTo(firstPoint.x, firstPoint.y)
				ctx.closePath()
				ctx.stroke()
			}
		},

		/**
		 * Reset Camera
		 *
		 * Reset the camera to be able to SWAPP again.
		 */
		resetCamera() {
			const t = this

			t.camera = 'off'
			t.toggleQrCodeReaderDialogVisibility()

			setTimeout(() => {
				t.camera = 'auto'

			}, 1000)

		},

		/**
		 * Switch Camera
		 *
		 * Switch the device's cameras between Front, Rear, and Default.
		 *
		 * @param camera the selection from the switcher buttons for which camera to use
		 */
		switchCamera(camera) {
			const t = this

			// Reset Errors
			t.noRearCamera = false
			t.noFrontCamera = false
			t.error = ''

			switch (camera) {
				case 'front':
					t.camera = 'front'
					break
				case 'rear':
					t.camera = 'rear'
					break
				default:
					t.camera = ''
			}

		},

	},

	async mounted() {
		const t = this

		t.handleQrCodeLoader()

		await t.loadData()
	},

}
</script>

<style scoped>

</style>
