<template>
	<div class="pa-4">

		<!--Information Text-->
		<app-text size="small">
			Please select the users you require passes for.
			Once you have applied, your status will show as Pending until it has been Approved, Rejected, or you’ve been
			placed on the Reserve List.
			<br>
			The WRU Press Team will contact you by email to confirm your application.
			<br>
			<span class="grey9--text">Please note: If there is a high demand, we may bring the deadline forward.</span>
		</app-text>

		<!--Selected User-->
		<form-section-title v-if="selectedUser?.userData?.entityId" class="mt-8" title="Select User"/>
		<div v-if="selectedUser?.userData?.entityId" class="d-flex align-center mt-4">

			<!--Checkbox-->
			<div @click="handleUserSelection(selectedUser)"
				 class="appWhite d-flex align-center justify-center rounded-lg mr-4"
				 style="aspect-ratio: 1/1; height: 56px; width: 56px">
				<app-icon color="green"
						  :icon="selectedUsers.includes(selectedUser.userData.entityId) ? 'check' : ''"
						  size="32"/>
			</div>

			<avatar class="mr-4" :file="selectedUser.fileData" :size="56" :user="selectedUser.userData"/>

			<!--Name | Position-->
			<div>

				<!--Name-->
				<app-text size="normal-bold">{{ selectedUser.userData.userName }}</app-text>

				<!--Position-->
				<app-text color="grey9" size="small">{{ selectedUser.userData.userPosition }}</app-text>

			</div>

			<v-spacer/>

			<!--Pass Status-->
			<div class="d-flex flex-column align-center">

				<!--Icon-->
				<app-icon :color="getStatusConfig(selectedUser).colour"
						  :icon="getStatusConfig(selectedUser).icon"
						  size="32"/>

				<!--Text-->
				<app-text size="small">{{ selectedUser.passData.accreditationPassStatus }}</app-text>

			</div>

		</div>

		<!--Users Applied-->
		<form-section-title class="mt-8"
							description="Other Users from your Organisation that have applied for passes."
							title="Users Applied"/>
		<app-text v-if="!computedUsersApplied.length" class="text-center" color="grey9">
			There are no other Users to show.
		</app-text>
		<div v-for="item in computedUsersApplied" :key="item.passData.entityId">

			<div class="d-flex align-center mt-4">

				<!--Checkbox-->
				<div @click="handleUserSelection(item)"
					 class="appWhite d-flex align-center justify-center rounded-lg mr-4"
					 style="aspect-ratio: 1/1; height: 56px; width: 56px">
					<app-icon color="green"
							  :icon="selectedUsers.includes(item.userData.entityId) ? 'check' : ''"
							  size="32"/>
				</div>

				<avatar class="mr-4" :file="item.fileData" :size="56" :user="item.userData"/>

				<!--Name | Position-->
				<div>

					<!--Name-->
					<app-text size="normal-bold">{{ item.userData.userName }}</app-text>

					<!--Position-->
					<app-text color="grey9" size="small">{{ item.userData.userPosition }}</app-text>

				</div>

				<v-spacer/>

				<!--Pass Status-->
				<div class="d-flex flex-column align-center">

					<!--Icon-->
					<app-icon :color="getStatusConfig(item).colour"
							  :icon="getStatusConfig(item).icon"
							  size="32"/>

					<!--Text-->
					<app-text size="small">{{ item.passData.accreditationPassStatus }}</app-text>

				</div>

			</div>

			<v-divider class="greyD mt-4"/>

		</div>

		<!--Users Not Applied-->
		<form-section-title class="mt-8"
							description="Other Users from your Organisation that have NOT applied for passes."
							title="Users Not Yet Applied"/>
		<app-text v-if="!computedUsersNotApplied.length" class="text-center" color="grey9">
			There are no other Users to show.
		</app-text>
		<div v-for="item in computedUsersNotApplied" :key="item.entityId">

			<div class="d-flex align-center mt-4">

				<!--Checkbox-->
				<div @click="handleUserSelection(item)"
					 class="appWhite d-flex align-center justify-center rounded-lg mr-4"
					 style="aspect-ratio: 1/1; height: 56px; width: 56px">
					<app-icon color="green"
							  :icon="selectedUsers.includes(item.userData.entityId) ? 'check' : ''"
							  size="32"/>
				</div>

				<avatar class="mr-4" :file="item.fileData" :size="56" :user="item.userData"/>

				<!--Name | Position-->
				<div>

					<!--Name-->
					<app-text size="normal-bold">{{ item.userData.userName }}</app-text>

					<!--Position-->
					<app-text color="grey9" size="small">{{ item.userData.userPosition }}</app-text>

				</div>

			</div>

			<v-divider class="greyD mt-4"/>

		</div>

		<!--Save Button-->
		<div class="d-flex justify-end mt-4">
			<app-btn @click.native="savePasses"
					 color="green"
					 :disabled="computedIsButtonDisabled"
					 icon="save"
					 label="Save"/>
		</div>

		<!--Dialogs ------------------------------------------------------------------------------------------------ -->
		<v-dialog max-width="512" v-model="isRemovedUsersDialogVisible">
			<div class="appGrey rounded-lg pa-4">

				<app-text>You are about to delete the passes for the following people:</app-text>

				<app-text v-for="item in removedUserNames" class="mt-4 ml-4" size="normal-bold">- {{ item }}</app-text>

				<app-text class="mt-4" size="normal-bold">Are you sure you want to delete these passes?</app-text>

				<div class="d-flex justify-space-between mt-4">

					<!--No-->
					<app-btn @click.native="isRemovedUsersDialogVisible = false"
							 color="grey"
							 icon="cancel"
							 label="No"/>

					<!--Yes-->
					<app-btn @click.native="confirmDeletePasses"
							 color="red"
							 icon="delete"
							 label="Yes"/>

				</div>

			</div>
		</v-dialog>

	</div>
</template>

<script>

export default {

	name: "MyAccreditationPassForm",

	props: ['accreditationData', 'selectedUser'],

	data: () => ({
		isRemovedUsersDialogVisible: false,
		removedUserNames: [],
		selectedUsers: [],
	}),

	computed: {

		/**
		 * Computed Is Button Disabled
		 *
		 * Return true if the selectedUsers array is the same as the original, false otherwise.
		 *
		 * @returns {boolean}
		 */
		computedIsButtonDisabled() {
			const t = this
			let isDisabled

			const ORIGINAL_SELECTED_USERS = t.$props.accreditationData.passesData.map(item => item.userData.entityId)
			const NEW_SELECTED_USERS = t.selectedUsers

			// If the selectedUsers array is the same as the original, do nothing
			isDisabled = JSON.stringify(ORIGINAL_SELECTED_USERS) === JSON.stringify(NEW_SELECTED_USERS)

			return isDisabled
		},

		/**
		 * Computed Users Applied
		 *
		 * Return the users that have applied for the accreditation pass.
		 * Also, remove the current user from the list as they are displayed separately.
		 *
		 * @returns {array} - An array of objects containing the pass data (fileData{}, passData{}, userData{})
		 */
		computedUsersApplied() {
			const t = this
			let tableData = t.$props.accreditationData.passesData || []

			// Remove the current user, if they are in the list
			if (t.selectedUser.userData?.entityId) tableData = tableData.filter(item => item.userData.entityId !== t.selectedUser.userData.entityId)

			// Sort by UserName
			tableData = tableData.sort((a, b) => a.userData.userName > b.userData.userName ? 1 : -1)

			return tableData
		},

		/**
		 * Computed Users Not Applied
		 *
		 * Return the users that have not applied for the accreditation pass.
		 * Also, remove the current user from the list as they are displayed separately.
		 *
		 * @returns {array} - An array of objects containing the Organisation Users data (fileData{}, userData{})
		 */
		computedUsersNotApplied() {
			const t = this
			let tableData = t.$props.accreditationData.organisationUsersData || []

			// Remove the current user, if they are in the list
			tableData = tableData.filter(item => item.userData.entityId !== t.selectedUser.userData?.entityId)

			// Sort by UserName
			tableData = tableData.sort((a, b) => a.userData.userName > b.userData.userName ? 1 : -1)

			return tableData
		},

	},

	methods: {

		/**
		 * Confirm Delete Passes
		 *
		 * On confirming the removal of passes, emit the savePasses event back to the parent.
		 */
		confirmDeletePasses() {
			const t = this

			t.isRemovedUsersDialogVisible = false

			t.$emit('emitSavePasses', t.selectedUsers)
		},

		/**
		 * Get Status Config
		 *
		 * Return the colour and icon for the pass status.
		 *
		 * @param item {Object} - The pass object {fileData{}, passData{}, userData{}
		 * @returns {{colour: string, icon: string}}
		 */
		getStatusConfig(item) {
			let color
			let icon

			switch (item.passData.accreditationPassStatus) {
				case 'Approved':
					color = 'green'
					icon = 'success'
					break
				case 'Rejected':
					color = 'red'
					icon = 'close'
					break
				case 'Pending':
					color = 'orange'
					icon = 'pending'
					break
				case 'Reserved':
					color = 'blue'
					icon = 'reserved'
					break
				default:
					color = 'grey9'
					icon = 'help'
					break
			}

			return {colour: color, icon: icon}
		},

		/**
		 * Handle User Selection
		 *
		 * Add or remove the selectedUser from the selectedUsers array.
		 *
		 * @param item {Object} - The pass object {fileData{}, passData{}, userData{}}
		 */
		handleUserSelection(item) {
			const t = this

			// If the user is already selected, remove them from the array
			if (t.selectedUsers.includes(item.userData.entityId)) t.selectedUsers = t.selectedUsers.filter(user => user !== item.userData.entityId)

			// Otherwise, add them to the array
			else t.selectedUsers.push(item.userData.entityId)
		},

		/**
		 * Save Passes
		 *
		 * Check if the selectedUsers array is different to the original.
		 * If new Users have been added, emit the savePasses event back to the parent for Pass creation.
		 * If Users have been removed, show the dialog and emit the savePasses event back to the parent for Pass deletion.
		 */
		savePasses() {
			const t = this

			// If the button is disabled, do nothing
			if (t.computedIsButtonDisabled) return

			const ORIGINAL_SELECTED_USERS = t.$props.accreditationData.passesData.map(item => item.userData.entityId)
			const NEW_SELECTED_USERS = t.selectedUsers

			// If the selectedUsers array is different to the original
			if (JSON.stringify(ORIGINAL_SELECTED_USERS) !== JSON.stringify(NEW_SELECTED_USERS)) {

				// Check for removed users
				const REMOVED_USER_IDS = ORIGINAL_SELECTED_USERS.filter(userId => !NEW_SELECTED_USERS.includes(userId))

				// Get the name(s) of the removed user(s)
				t.removedUserNames = t.$props.accreditationData.passesData
					.filter(item => REMOVED_USER_IDS.includes(item.userData.entityId))
					.map(item => item.userData.userName)

				// Show the dialog if there are removed users
				if (t.removedUserNames.length) t.isRemovedUsersDialogVisible = true
				else t.$emit('emitSavePasses', t.selectedUsers)
			}

		}

	},

	mounted() {
		const t = this

		t.selectedUsers = t.$props.accreditationData.passesData.map(item => item.userData.entityId)
	}

}
</script>

<style scoped>

</style>
