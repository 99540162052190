<template>
	<div class="appGrey" style="min-height: 100%">

		{{ computedInit }}

		<!--Ready Only Message-->
		<app-text v-if="readOnlyFormMessage" class="mb-4" color="red" size="small">{{ readOnlyFormMessage }}</app-text>

		<!--Form-->
		<v-row no-gutters>

			<!--Responding User-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : ''"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textInput"
								:disabled="true"
								label="Responder"
								v-model.trim="computedRespondingUser"/>
			</v-col>

			<!--Priority-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="select"
								:disabled="computedIsReadOnly"
								:error="errors.observationPriority"
								:error-message="errors.observationPriorityErrorMessage"
								:items="observationPriorityOptions"
								label="Priority"
								v-model="form.observationPriority"/>
			</v-col>

			<!--Status-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="select"
								:disabled="computedIsReadOnly"
								:error="errors.observationStatus"
								:error-message="errors.observationStatus"
								:items="observationStatusOptions"
								label="Status"
								v-model="form.observationStatus"/>
			</v-col>

			<!--Description-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textArea"
								:disabled="computedIsReadOnly"
								:error="errors.observationRespondingDescription"
								:error-message="errors.observationRespondingDescriptionErrorMessage"
								label="Description"
								:placeholder="`Leave a reply, including any actions taken to resolve this`"
								v-model.trim="form.observationRespondingDescription"/>
			</v-col>

		</v-row>

		<!--Save Button-->
		<div class="d-flex justify-end mt-4">
			<app-btn v-if="!computedIsReadOnly"
					 @click.native="handleSaveItem"
					 color="green"
					 icon="save"
					 label="Save"/>
		</div>

	</div>
</template>

<script>
export default {

	name: "ObservationRespondingForm",

	props: ['formData', 'isReadOnly'],

	data: () => ({
		errors: {
			observationRespondingDescription: false,
			observationRespondingDescriptionErrorMessage: '',
		},
		form: {
			entityId: '',
			observationStatus: '',
			observationType: '',
			observationTitle: '',
			observationReportingDescription: '',
			observationSite: '',
			observationLocation: '',
			observationDateOccurred: '',
			observationTimeOccurred: '',
			observationReportingStepsTaken: '',
			observationRespondingUser: '',
			observationRespondingDescription: '',
			observationPriority: '',

			createdUserId: '',
			createdDateTime: 0,
			createdUserName: '',
			modifiedUserId: '',
			modifiedDateTime: 0,
			modifiedUserName: '',
			isDeleted: false,
			deletedUserId: '',
			deletedDateTime: 0,
			deletedUserName: '',
		},
		readOnlyFormMessage: '',
	}),

	computed: {

		/**
		 * Computed Init
		 *
		 * If there is any form data, assign it to the form.
		 * If there is no form data, it will be blank (new).
		 */
		computedInit() {
			const t = this
			const formData = {...t.$props.formData}

			if (formData?.entityId) t.form = formData
		},

		/**
		 * Computed Is Read Only
		 *
		 * Return a boolean (and set the error message) denoting if the current user can edit this form.
		 * Conditions:
		 *  - Must be an Admin
		 *  - Observation must not be Resolved
		 *
		 * @returns {boolean} if the current user can edit this form.
		 */
		computedIsReadOnly() {
			const t = this
			const OBSERVATION_DATA = t.$props.formData
			const CURRENT_USER_DATA = t.MIX_getCurrentUser()

			let isReadOnly = false

			if (CURRENT_USER_DATA.userLevel !== 'Staff-Admin') {
				isReadOnly = true
				t.readOnlyFormMessage = 'You do not have access to edit this form.'
			} else if (OBSERVATION_DATA?.observationStatus === 'Resolved') {
				isReadOnly = true
				t.readOnlyFormMessage = 'This Observation has been Resolved and cannot be edited.'
			} else {
				isReadOnly = t.$props.isReadOnly
			}

			return isReadOnly
		},

		/**
		 * Computed Responding User
		 *
		 * Return the userName of the responding user, and set their ID to the form state to be saved.
		 *
		 * @returns {string} the userName of the responding user
		 */
		computedRespondingUser() {
			const t = this
			const CURRENT_USER_DATA = t.MIX_getCurrentUser()
			let userName

			t.form.observationRespondingUser = CURRENT_USER_DATA.entityId

			if (CURRENT_USER_DATA.userLevel === 'Staff-Admin') userName = CURRENT_USER_DATA.userName

			return userName
		},

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		async handleSaveItem() {
			const t = this

			if (!t.validateForm()) return

			await t.saveItem()

			t.$emit('emitReloadData')
		},

		/**
		 * Save Item
		 *
		 * Save/Update the form to the DB.
		 */
		async saveItem() {
			const t = this
			const FORM_DATA = t.form

			const RESPONSE = await t.MIX_redis_update('observation', FORM_DATA.entityId, FORM_DATA)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating Observation: ', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Updated Report'
		},

		/**
		 * Validate Form
		 *
		 * Validate the form as required.
		 *
		 * @returns {boolean} if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Description
			if (!t.form.observationRespondingDescription) {
				t.errors.observationRespondingDescription = true
				t.errors.observationRespondingDescriptionErrorMessage = 'Description is required'
			}

			return !Object.values(t.errors).includes(true)
		}

	},

}
</script>

<style scoped>

</style>
