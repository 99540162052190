<template>
	<div>

		{{ computedInit }}

		<!--Form-->
		<v-row no-gutters>

			<form-section-title title="Task Details"/>

			<!--Task Name-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : ''"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								:error="errors.swappTasksTaskName"
								:error-message="errors.swappTasksTaskNameErrorMessage"
								label="Task Name"
								v-model.trim="form.swappTasksTaskName"/>
			</v-col>

			<!--Task Description-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textArea"
								:disabled="isReadOnly"
								:error="errors.swappTasksTaskDescription"
								:error-message="errors.swappTasksTaskDescriptionErrorMessage"
								label="Task Description"
								v-model.trim="form.swappTasksTaskDescription"/>
			</v-col>

			<!--Report To-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="autoComplete"
								:deletable-chips="true"
								:disabled="isReadOnly"
								:error="errors.swappTasksTaskReportTo"
								:error-message="errors.swappTasksTaskReportToErrorMessage"
								:items="usersData"
								item-text="userName"
								label="Report To"
								:multiple="true"
								:return-object="true"
								:small-chips="true"
								v-model.trim="selectedReportToUsers"/>
			</v-col>

			<!--Helpful Information-->
			<form-section-title class="mt-8"
								description="Is there anything the end-user should know to help them with this task?"
								title="Information (optional)"/>
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : ''"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textArea"
								:disabled="isReadOnly"
								label="Helpful Information"
								v-model.trim="form.swappTasksTaskInformationText"/>
			</v-col>

		</v-row>

		<!--Add to Library Checkbox-->
		<div v-if="parentComponent !== 'Library'" class="d-flex justify-end">
			<v-checkbox hide-details
						label="Also add this Task to my Library"
						v-model="isAddToLibraryChecked"/>
		</div>

		<!--Action Buttons-->
		<div class="d-flex justify-space-between mt-4">

			<!--Cancel-->
			<app-btn @click.native="$emit('closeTaskForm')"
					 color="grey"
					 icon="close"
					 label="Cancel"/>

			<!--Save-->
			<app-btn v-if="!isReadOnly"
					 @click.native="handleSaveItem"
					 color="green"
					 icon="save"
					 label="Save"/>

		</div>

	</div>
</template>

<script>

export default {

	name: "SwappTasksTaskForm",

	props: ['isReadOnly', 'parentComponent', 'formData', 'swappTasksListData', 'usersData'],

	data: () => ({
		errors: {
			swappTasksTaskName: false,
			swappTasksTaskNameErrorMessage: '',
			swappTasksTaskDescription: false,
			swappTasksTaskDescriptionErrorMessage: '',
			swappTasksTaskReportTo: false,
			swappTasksTaskReportToErrorMessage: '',
		},
		form: {
			swappTasksListId: '',
			swappTasksTaskUserComment: '',
			swappTasksTaskUserCommentDateTime: 0,
			swappTasksTaskCompletedDateTime: 0,
			swappTasksTaskDescription: '',
			swappTasksTaskInformationText: '',
			swappTasksTaskIsFromLibrary: false,
			swappTasksTaskIsFromTemplate: false,
			swappTasksTaskSupervisorIssue: '',
			swappTasksTaskSupervisorIssueDateTime: 0,
			swappTasksTaskSupervisorIssueMessage: '',
			swappTasksTaskName: '',
			swappTasksTaskReportTo: [],
			swappTasksTaskStatus: 'Incomplete',
			swappTasksTaskUserIssue: '',
			swappTasksTaskUserIssueDateTime: 0,
			swappTasksUserId: '',

			createdUserId: '',
			createdDateTime: 0,
			createdUserName: '',
			modifiedUserId: '',
			modifiedDateTime: 0,
			modifiedUserName: '',
			isDeleted: false,
			deletedUserId: '',
			deletedDateTime: 0,
			deletedUserName: '',
		},
		isAddToLibraryChecked: true,
		selectedReportToUsers: [],
	}),

	computed: {

		computedInit() {
			const t = this
			const FORM_DATA = t.$props.formData
			const USERS_DATA = t.$props.usersData

			// If there is form data, set the form data to the form
			if (FORM_DATA?.entityId) {
				t.form = t.$props.formData
				t.selectedReportToUsers = USERS_DATA.filter(user => t.form.swappTasksTaskReportTo.includes(user.entityId))
			}
		},

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Create Item
		 *
		 * Create the form and save to the DB.
		 */
		async createItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_create('swappTasksTask', t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating Swapp Task: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem creating this Task, please try again.'
				return
			}

			t.$emit('emitReloadData')
			t.$sharedState.successMessage = 'Created Task'

			if (t.isAddToLibraryChecked) await t.createLibraryItem()
		},

		/**
		 * Create Library Item
		 *
		 * Create the form and save to the DB.
		 */
		async createLibraryItem() {
			const t = this

			t.form.swappTasksTaskIsFromLibrary = true
			t.form.swappTasksTaskIsFromTemplate = false

			const RESPONSE = await t.MIX_redis_create('swappTasksTask', t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating Swapp Task: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem creating this Task, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Created Task'
			t.$emit('emitReloadData')
		},

		/**
		 * Handle Save Item
		 *
		 * The form maybe from the Library, or from a List, so handle the save/update accordingly.
		 * To save to the Library set the swappTasksTaskIsFromLibrary to true.
		 */
		handleSaveItem() {
			const t = this

			// Only save if the form is valid
			if (!t.validateForm()) return

			// Assign the Task List ID
			t.form.swappTasksListId = t.$props.swappTasksListData?.entityId

			// Loop over the Report To Users and save as IDs
			t.form.swappTasksTaskReportTo = t.selectedReportToUsers.map(user => user.entityId)

			// If the parent component is the Library, save the Task to the Library
			if (t.$props.parentComponent === 'Library') {
				t.form.swappTasksRaskIsFromLibrary = true
				t.form.swappTasksRaskIsFromTemplate = false

				// Create
				if (!t.form.entityId) t.createLibraryItem()

				// Update
				if (t.form.entityId) t.updateItem()

				// Return so the Task is not saved to the List
				return
			}

			// If the parent component is NOT the Library, continue as normal
			t.form.swappTasksTaskIsFromTemplate = true

			// Create
			if (!t.form.entityId) t.createItem()

			// Update
			if (t.form.entityId) t.updateItem()
		},

		/**
		 * Update Item
		 *
		 * Update the form and save to the DB.
		 */
		async updateItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_update('swappTasksTask', t.form.entityId, t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating Task: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem updating, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Updated Task'
			t.$emit('emitReloadData')
		},

		/**
		 * Validate Form
		 *
		 * Validate the form and return true or false if it is valid, or not.
		 *
		 * @returns {boolean}
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Task Name
			if (!t.form.swappTasksTaskName) {
				t.errors.swappTasksTaskName = true
				t.errors.swappTasksTaskNameErrorMessage = 'Please provide a Task Name'
			}

			// Task Description
			if (!t.form.swappTasksTaskDescription) {
				t.errors.swappTasksTaskDescription = true
				t.errors.swappTasksTaskDescriptionErrorMessage = 'Please provide a Task Description'
			}

			// Report To
			if (!t.selectedReportToUsers.length) {
				t.errors.swappTasksTaskReportTo = true
				t.errors.swappTasksTaskReportToErrorMessage = 'Please provide a User to report to'
			}

			return !Object.values(t.errors).includes(true)
		}

	},

}
</script>

<style scoped>

</style>
