<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<!--No QR Code Param-->
	<div v-else-if="!qrCodeParamProps" style="height: 70vh">

		<!--Header-->
		<page-title :divider="true"
					icon="tasks"
					title="SWAPP Tasks"/>

		<div class="d-flex flex-column align-center justify-center" style="height: 100%">

			<app-icon class="pulseBig" color="primary" icon="qrCode" size="96"/>
			<app-text class="text-center mt-8">Scan a SWAPP Tasks QR Code to get started</app-text>

			<app-btn @click.native="MIX_go('/')" class="mt-8" label="Back to Home"/>

		</div>

	</div>

	<!--No Tasks List Data-->
	<div v-else-if="!swappTasksListData.entityId" style="height: 70vh">

		<!--Header-->
		<page-title :divider="true"
					icon="tasks"
					title="SWAPP Tasks"/>

		<div class="d-flex flex-column align-center justify-center" style="height: 100%">

			<app-icon class="pulseBig" color="red" icon="cancel" size="96"/>
			<app-text class="text-center mt-8">There are no Tasks for this QR Code.</app-text>

			<app-btn @click.native="MIX_go('/')" class="mt-8" label="Back to Home"/>

		</div>

	</div>

	<!--Page Content-->
	<div v-else>

		<!--Header | Page Info Icon-->
		<div class="d-flex align-center">

			<!--Header-->
			<page-title icon="tasks"
						info=""
						title="SWAPP Tasks"/>

			<app-icon @click.native="togglePageInfoDialogVisibility" color="grey9" icon="info" size="32"/>

		</div>

		<v-divider class="greyD mt-4"/>

		<!--Swapp Check-->
		<div v-if="componentView === 'Swapp Check'"
			 class="d-flex flex-column align-center">

			<!--Instructional Text-->
			<app-text class="mt-4" size="small">
				You are about to start a new SWAPP Tasks List.
				<br>
				Work through the tasks and check them off as you go.
				<br>
				You can leave a comment on a task if you need to, but you must raise an issue if you are unable to
				complete a task.
				<br>
				<br>
				Check the details below, and continue when you're ready.
			</app-text>

			<v-divider class="greyD mt-4" style="width: 100%"/>

			<!--Area-->
			<app-text class="mt-8" color="primary" size="medium-bold">{{ swappTasksListData.swappTasksListArea }}
			</app-text>

			<!--Location-->
			<app-text class="mt-8" size="medium-bold">{{ swappTasksListData.swappTasksListLocation }}</app-text>

			<!--Role-->
			<div class="d-flex align-center mt-8 ml-n8">
				<app-icon class="mr-2" icon="user"/>
				<app-text color="grey9">{{ swappTasksListData.swappTasksListRole }}</app-text>
			</div>

			<v-divider class="greyD mt-8" style="width: 100%"/>

			<!--Continue Button-->
			<app-text class="text-center mt-8" size="small">
				Clicking Continue will create a new List of Tasks.
				<br>
				Please ensure ready to start before continuing.
			</app-text>
			<app-btn @click.native="handleContinueButton" class="mt-8" icon="success" label="Yes, continue"/>

			<v-spacer/>

			<!--Not Correct Button-->
			<!--<app-text class="mt-8 mb-4" color="grey9">This isn't correct</app-text>-->

		</div>

		<!--No Tasks-->
		<div v-if="componentView === 'No Tasks'"
			 class="d-flex flex-column align-center justify-center"
			 style="height: 70vh">

			<app-icon class="pulseBig" color="orange" icon="cancel" size="96"/>
			<app-text class="text-center mt-8">There are no SWAPP Tasks for this QR Code.</app-text>

			<app-btn @click.native="MIX_go('/')" class="mt-8" label="Back to Home"/>

		</div>

		<!--Task Cards-->
		<div v-if="componentView === 'Task Cards'" class="d-flex flex-column justify-space-between"
			 style="height: calc(100vh - 208px)">

			<!--Cards-->
			<div>
				<v-expansion-panels v-for="item in swappTasksListTasksData.tasksData" :key="item.entityId" flat>
					<v-expansion-panel class="rounded-lg mt-4">

						<!--Header-->
						<v-expansion-panel-header class="d-flex rounded-lg pa-4" hide-actions>

							<!--Checkbox-->
							<div @click="handleCheckboxSelection(item)"
								 class="appGrey d-flex align-center justify-center rounded-lg mr-4"
								 style="aspect-ratio: 1/1; height: 56px; max-width: 56px">
								<app-icon color="green"
										  :icon="item.swappTasksTaskStatus === 'Complete' ? 'check' : ''"
										  size="32"/>
							</div>

							<!--Task Name | Icons-->
							<div>

								<!--Task Name-->
								<app-text color="primary">{{ item.swappTasksTaskName }}</app-text>

								<!--Icons-->
								<div class="mt-2">

									<!--Comment-->
									<div v-if="item.swappTasksTaskUserComment" class="d-flex align-center">
										<app-icon class="mr-2" color="orange" icon="comment" size="16" title="Comment"/>
										<app-text color="grey9" size="small">
											{{ MIX_formatDateTimeToTime(item.swappTasksTaskUserCommentDateTime) }}
										</app-text>
									</div>

									<!--Issue-->
									<div v-if="item.swappTasksTaskUserIssue" class="d-flex align-end">
										<app-icon class="mr-2" color="red" icon="error" size="16" title="Issue"/>
										<app-text color="grey9" size="small">
											{{ MIX_formatDateTimeToTime(item.swappTasksTaskUserIssueDateTime) }}
										</app-text>
									</div>

								</div>

							</div>

						</v-expansion-panel-header>

						<!--Content-->
						<v-expansion-panel-content>

							<!--Task Description-->
							<app-text size="small">{{ item.swappTasksTaskDescription }}</app-text>

							<v-divider class="appGrey my-4"/>

							<!--Card Actions-->
							<div class="d-flex justify-space-around">
								<app-icon @click.native="handleInformationButton(item)" color="green" icon="info"
										  size="32"/>
								<app-icon @click.native="handleCommentsButton(item)" color="orange" icon="comment"
										  size="32"/>
								<app-icon @click.native="handleIssuesButton(item)" color="red" icon="error" size="32"/>
							</div>

							<div v-if="item.swappTasksTaskCompletedDateTime">
								<v-divider class="appGrey my-4"/>
								<app-text color="grey" size="small">
									{{ item.createdUserName }}
									@
									{{ MIX_formatDate(item.swappTasksTaskCompletedDateTime, 'short') }}
								</app-text>
							</div>

						</v-expansion-panel-content>

					</v-expansion-panel>
				</v-expansion-panels>
			</div>

			<!--Action Button-->
			<app-btn @click.native="handleDoneButton" :block="true" class="mt-4" icon="success" label="Done"/>

		</div>

		<!--Dialogs ------------------------------------------------------------------------------------------------ -->

		<!--Text Information Dialog-->
		<v-dialog v-if="isTextInformationDialogVisible"
				  max-width="512"
				  v-model="isTextInformationDialogVisible">
			<swapp-tasks-text-info-dialog @closeDialog="toggleTextInformationDialogVisibility"
										  :task-data="selectedItem"/>
		</v-dialog>

		<!--Comments Dialog-->
		<v-dialog v-if="isCommentsDialogVisible"
				  max-width="512"
				  v-model="isCommentsDialogVisible">
			<swapp-tasks-comments-dialog @closeDialog="toggleCommentsDialogVisibility"
										 :task-data="selectedItem"/>
		</v-dialog>

		<!--Issues Dialog-->
		<v-dialog v-if="isIssuesDialogVisible"
				  max-width="512"
				  v-model="isIssuesDialogVisible">
			<swapp-tasks-issues-dialog @closeDialog="toggleIssuesDialogVisibility"
									   :task-data="selectedItem"
									   :users-data="usersData"/>
		</v-dialog>

		<!--Tasks Not Done Dialog-->
		<v-dialog v-if="isTasksNotDoneDialogVisible"
				  max-width="512"
				  v-model="isTasksNotDoneDialogVisible">
			<swapp-tasks-tasks-not-done-dialog @closeDialog="toggleTasksNotDoneDialogVisibility"/>
		</v-dialog>

		<!--List Already Active Dialog-->
		<v-dialog v-if="isListAlreadyActiveDialogVisible"
				  max-width="512"
				  v-model="isListAlreadyActiveDialogVisible">
			<swapp-tasks-list-already-active-dialog @closeDialog="toggleListAlreadyActiveDialog"
													@swappListActiveListContinue="emittedSwappListActiveListContinue"
													@swappListActiveListStartAgain="emittedSwappListActiveListStartAgain"/>
		</v-dialog>

		<!--Page Info Dialog-->
		<v-dialog v-if="isPageInfoDialogVisible"
				  max-width="512"
				  v-model="isPageInfoDialogVisible">
			<swapp-tasks-page-info-dialog @closeDialog="togglePageInfoDialogVisibility"/>
		</v-dialog>

	</div>

</template>

<script>

import SwappTasksTextInfoDialog
	from "@/views/swappTasks/components/swappTasksTextInfoDialog/SwappTasksTextInfoDialog.vue";
import SwappTasksCommentsDialog
	from "@/views/swappTasks/components/swappTasksCommentsDialog/SwappTasksCommentsDialog.vue";
import SwappTasksIssuesDialog from "@/views/swappTasks/components/swappTasksIssuesDialog/SwappTasksIssuesDialog.vue";
import SwappTasksTasksNotDoneDialog
	from "@/views/swappTasks/user/swappTasksTasksNotDoneDialog/SwappTasksTasksNotDoneDialog.vue";
import SwappTasksListAlreadyActiveDialog
	from "@/views/swappTasks/user/swappTasksListAlreadyActiveDialog/SwappTasksListAlreadyActiveDialog.vue";
import SwappTasksPageInfoDialog from "@/views/swappTasks/user/swappTasksPageInfoDialog/SwappTasksPageInfoDialog.vue";

export default {

	name: "SwappTasksUser",

	components: {
		SwappTasksPageInfoDialog,
		SwappTasksListAlreadyActiveDialog,
		SwappTasksTasksNotDoneDialog,
		SwappTasksIssuesDialog, SwappTasksCommentsDialog, SwappTasksTextInfoDialog
	},

	props: ['qrCodeParamProps', 'swappTasksListData'],

	data: () => ({
		componentView: 'Swapp Check',
		isCommentsDialogVisible: false,
		isIssuesDialogVisible: false,
		isListAlreadyActiveDialogVisible: false,
		isLoading: false,
		isPageInfoDialogVisible: false,
		isTasksNotDoneDialogVisible: false,
		isTextInformationDialogVisible: false,
		selectedItem: {},
		swappTasksListTasksData: [],

		// Data
		usersData: [],
	}),

	methods: {

		/**
		 * Check For Currently Active List
		 *
		 * Check if there is already an active working List, and return either the List data or an empty object.
		 *
		 * @returns {Promise<*>} - The List data or an empty object.
		 */
		async checkForCurrentlyActiveList() {
			const t = this

			const RESPONSE = await t.MIX_redis_checkForCurrentlyActiveSwappTasksList(t.$props.swappTasksListData.swappTasksListQrId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error checking for currently active Swapp Tasks List: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem loading this List, please try again.'
				return
			}

			// If there is an active list return true, otherwise return false
			return RESPONSE.data
		},

		/**
		 * Emitted Swapp List Active List Continue
		 *
		 * Handle the emitted event from the Swapp List Already Active Dialog Continue button.
		 * Close the dialog and show the Task Cards.
		 *
		 * @returns {Promise<void>}
		 */
		async emittedSwappListActiveListContinue() {
			const t = this

			t.toggleListAlreadyActiveDialog()

			if (t.swappTasksListTasksData?.tasksData?.length) t.componentView = 'Task Cards'
		},

		/**
		 * Emitted Swapp List Active List Start Again
		 *
		 * Handle the emitted event from the Swapp List Already Active Dialog Start Again button.
		 * Close the dialog, and load the data fresh for the Task Cards.
		 * @returns {Promise<void>}
		 */
		async emittedSwappListActiveListStartAgain() {
			const t = this

			t.toggleListAlreadyActiveDialog()

			await t.loadData()

			if (t.swappTasksListTasksData?.tasksData?.length) t.componentView = 'Task Cards'
		},

		/**
		 * Handle Continue Button
		 *
		 * Handle the Continue button click.
		 * Check if there is already an active working List, and if there is, show the dialog.
		 * If there is no active List, load the data and show the Task Cards.
		 *
		 * @returns {Promise<void>}
		 */
		async handleContinueButton() {
			const t = this
			const LIST_DATA = t.$props.swappTasksListData

			// Check to see if there are any Lists for this QR Code
			const DOES_LIST_HAVE_TASKS = await t.checkIfListHasTasks(LIST_DATA)
			if (!DOES_LIST_HAVE_TASKS) {
				t.componentView = 'No Tasks'
				return
			}

			// Check if there is already an active working List
			const ACTIVE_LIST = await t.checkForCurrentlyActiveList()

			// If there is an active list, show the dialog and set the data
			if (ACTIVE_LIST?.listData?.entityId) {
				t.swappTasksListTasksData = ACTIVE_LIST
				t.toggleListAlreadyActiveDialog()
				return
			}

			// If there is no active list, load the data and show the Task Cards
			await t.loadData()
			if (t.swappTasksListTasksData?.tasksData?.length) t.componentView = 'Task Cards'
		},

		/**
		 * Handle Checkbox Selection
		 *
		 * Handle the checkbox selection.
		 * Toggle the task status, and update the task status in the DB.
		 * Note: The event.cancelBubble = true is required to stop the expansion panel from opening on checkbox click.
		 *
		 * @param taskData - The task data.
		 */
		handleCheckboxSelection(taskData) {
			const t = this
			event.cancelBubble = true

			// Toggle the task status
			taskData.swappTasksTaskStatus = taskData.swappTasksTaskStatus === 'Complete' ? 'Incomplete' : 'Complete'
			taskData.swappTasksTaskCompletedDateTime = new Date().getTime()

			t.updateTaskStatus(taskData)
		},

		/**
		 * Handle Comments Button
		 *
		 * Handle the Comments button click.
		 * Set the selected item and toggle the Comments dialog visibility.
		 *
		 * @param taskData - The task data.
		 */
		handleCommentsButton(taskData) {
			const t = this
			t.selectedItem = taskData
			t.toggleCommentsDialogVisibility()
		},

		/**
		 * Handle Done Button
		 *
		 * Handle the Done button click.
		 * Check if all the tasks are complete, and if they are, show the success dialog.
		 * If any of the tasks are incomplete, check if they have an issue logged, if they don't show the dialog.
		 *
		 * @returns {Promise<void>}
		 */
		handleDoneButton() {
			const t = this

			// If any of the tasks are incomplete, check if they have an issue logged
			const ARE_ALL_TASKS_DONE = t.swappTasksListTasksData.tasksData.every(task =>
				task.swappTasksTaskStatus === 'Complete' || task.swappTasksTaskUserIssue)

			// If there are any incomplete tasks with no issue logged, show the dialog
			if (!ARE_ALL_TASKS_DONE) t.isTasksNotDoneDialogVisible = true

			// If all tasks are complete, show the success dialog
			else {
				t.$sharedState.successMessage = 'Saved Tasks'
				t.updateListStatus()
				t.MIX_go('/')
			}
		},

		/**
		 * Handle Information Button
		 *
		 * Handle the Information button click.
		 * Toggle the Text Information dialog visibility.
		 */
		handleInformationButton(taskData) {
			const t = this
			t.selectedItem = taskData
			t.toggleTextInformationDialogVisibility()
		},

		/**
		 * Handle Issues Button
		 *
		 * Handle the Issues button click.
		 * Set the selected item and toggle the Issues dialog visibility.
		 *
		 * @param taskData - The task data.
		 */
		handleIssuesButton(taskData) {
			const t = this
			t.selectedItem = taskData
			t.toggleIssuesDialogVisibility()
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadUsersData(),
				t.loadNewListFromQrCode(t.$props.swappTasksListData),
			])

			t.isLoading = false
		},

		async checkIfListHasTasks(listData) {
			const t = this

			const RESPONSE = await t.MIX_redis_getSwappTasksTasksWhere({
				whereKey: 'swappTasksListId',
				whereValue: listData.entityId
			})

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error loading Swapp Tasks List: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem loading this List, please try again.'
				return
			}

			// Set the Swapp Tasks List Data
			return !!RESPONSE.data?.length
		},

		/**
		 * Load New List From Qr Code
		 *
		 * Load the new List data from the DB.
		 *
		 * Note:
		 * The List and Tasks used to check off progress are different from the List and Tasks used to create a new List.
		 * Call to get a copy of the template List and Tasks.
		 *
		 * @param listData - The List data.
		 * @returns {Promise<void>}
		 */
		async loadNewListFromQrCode(listData) {
			const t = this

			const RESPONSE = await t.MIX_redis_getNewSwappTasksListFromQrCode(listData?.swappTasksListQrId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error loading Create New Swapp Tasks List: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem loading this List, please try again.'
				return
			}

			t.swappTasksListTasksData = RESPONSE.data
		},

		/**
		 * Load Users Data
		 *
		 * Load the users data from the DB.
		 *
		 * @returns {Promise<void>}
		 */
		async loadUsersData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getAll('user')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error loading Users: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem loading the Users, please try again.'
				return
			}

			t.usersData = RESPONSE.data
		},

		/**
		 * Toggle Comments Dialog Visibility
		 *
		 * Toggle the Comments dialog visibility.
		 */
		toggleCommentsDialogVisibility() {
			this.isCommentsDialogVisible = !this.isCommentsDialogVisible
		},

		/**
		 * Toggle Issues Dialog Visibility
		 *
		 * Toggle the Issues dialog visibility.
		 */
		toggleIssuesDialogVisibility() {
			this.isIssuesDialogVisible = !this.isIssuesDialogVisible
		},

		/**
		 * Toggle List Already Active Dialog
		 *
		 * Toggle the List Already Active dialog visibility.
		 */
		toggleListAlreadyActiveDialog() {
			this.isListAlreadyActiveDialogVisible = !this.isListAlreadyActiveDialogVisible
		},

		/**
		 * Toggle Page Info Dialog Visibility
		 *
		 * Toggle the Page Info dialog visibility.
		 */
		togglePageInfoDialogVisibility() {
			this.isPageInfoDialogVisible = !this.isPageInfoDialogVisible
		},

		/**
		 * Toggle Text Information Dialog Visibility
		 *
		 * Toggle the Text Information dialog visibility.
		 */
		toggleTextInformationDialogVisibility() {
			this.isTextInformationDialogVisible = !this.isTextInformationDialogVisible
		},

		/**
		 * Toggle Tasks Not Done Dialog Visibility
		 *
		 * Toggle the Tasks Not Done dialog visibility.
		 */
		toggleTasksNotDoneDialogVisibility() {
			this.isTasksNotDoneDialogVisible = !this.isTasksNotDoneDialogVisible
		},

		/**
		 * Update List Status
		 *
		 * Update the List status in the DB.
		 *
		 * Note: Not all the tasks need to be complete to update the List status, so long as the user has logged an issue.
		 *
		 * @returns {Promise<void>}
		 */
		async updateListStatus() {
			const t = this
			let listData = t.swappTasksListTasksData.listData

			listData.swappTasksListStatus = 'Complete'

			const RESPONSE = await t.MIX_redis_update('swappTasksList', listData.entityId, listData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating Swapp Tasks List Status: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem updating this List, please try again.'
				return
			}
		},

		async updateTaskStatus(taskData) {
			const t = this

			const RESPONSE = await t.MIX_redis_update('swappTasksTask', taskData.entityId, taskData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating Swapp Tasks Task Status: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem updating this Task, please try again.'
				return
			}
		},

	},

}
</script>

<style scoped>

</style>
