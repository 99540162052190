<template>
	<div class="appWhite d-flex align-center rounded-lg mt-4 pa-4">

		<!--Status-->
		<div class="mr-4">
			<app-icon v-if="cardData.carParkStatus === 'Open'" color="green" icon="success" size="48"/>
			<app-icon v-if="cardData.carParkStatus === 'Closed'" color="red" icon="cancel" size="48"/>
		</div>

		<!--Name | Spaces-->
		<div>

			<!--Name-->
			<app-text color="primary" size="normal-bold">{{ cardData.carParkName }}</app-text>

			<!--Regular Spaces-->
			<div class="d-flex align-center mt-1">
				<app-text class="mr-4" color="grey9" size="small" style="min-width: 64px; width: 64px">Regular</app-text>
				<app-text :color="cardData.carParkRegularSpacesAvailable > 0 ? 'green' : 'grey9'"
						  :size="cardData.carParkRegularSpacesAvailable > 0 ? 'normal-bold' : 'normal'">
					{{ cardData.carParkRegularSpacesAvailable }}
				</app-text>
				<app-text class="mx-1" color="grey9">/</app-text>
				<app-text :color="cardData.carParkRegularSpacesOutOfAction > 0 ? 'red' : 'grey9'"
						  :size="cardData.carParkRegularSpacesOutOfAction > 0 ? 'normal-bold' : 'normal'">
					{{ cardData.carParkRegularSpacesOutOfAction }}
				</app-text>
			</div>

			<!--Disabled Spaces-->
			<div class="d-flex align-center">
				<app-text class="mr-4" color="grey9" size="small" style="min-width: 64px; width: 64px">Accessible</app-text>
				<app-text :color="cardData.carParkDisabledSpacesAvailable > 0 ? 'green' : 'grey9'"
						  :size="cardData.carParkDisabledSpacesAvailable > 0 ? 'normal-bold' : 'normal'">
					{{ cardData.carParkDisabledSpacesAvailable }}
				</app-text>
				<app-text class="mx-1" color="grey9">/</app-text>
				<app-text :color="cardData.carParkDisabledSpacesOutOfAction > 0 ? 'red' : 'grey9'"
						  :size="cardData.carParkDisabledSpacesOutOfAction > 0 ? 'normal-bold' : 'normal'">
					{{ cardData.carParkDisabledSpacesOutOfAction }}
				</app-text>
			</div>

		</div>

	</div>
</template>

<script>

export default {

	name: "ParkingCarParkMobileCard",

	props: ['cardData'],

	data: () => ({}),

	methods: {},

}
</script>

<style scoped>

</style>
