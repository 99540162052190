<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<!--Page Content-->
	<div v-else>

		<!--Header-->
		<page-title :divider="true"
					icon="sites"
					info="View and administer your sites."
					title="Sites"/>

		<!--Action Bar-->
		<div class="d-flex align-center mt-4">

			<!--Search-->
			<app-form-field form-type="textInput"
							append-icon="icons8-search"
							:clearable="true"
							label="Site Name"
							style="width: 100%"
							v-model.trim="searchBySiteName"/>

			<!--Add Button-->
			<app-btn v-if="MIX_getCurrentUser().userLevel === 'Staff-Admin'"
					 @click.native="openRightPanel" class="ml-4" icon="add" label="Add"/>

			<!--Filter Button - with a numbered badge-->
			<div style="position:relative;">
				<app-btn @click.native="toggleFiltersVisibility"
						 class="ml-4" color="appWhite" icon="filter" icon-color="primary"/>
				<span v-if="computedNumberOfActiveFilters > 0" class="badge">{{ computedNumberOfActiveFilters }}</span>
			</div>

			<!--More Actions Menu-->
			<div v-if="['Staff-Admin', 'Staff-Manager'].includes(MIX_getCurrentUser().userLevel)" class="ml-4"/>
			<more-actions-menu v-if="['Staff-Admin', 'Staff-Manager'].includes(MIX_getCurrentUser().userLevel)"
							   @emitMenuSelection="handleEmittedMenuSelection"
							   :menuList="computedMoreActionsMenuOption"/>

		</div>

		<!--Clear Filters-->
		<div v-if="computedNumberOfActiveFilters > 0" class="d-flex justify-end mt-4">
			<app-btn @click.native="clearFilters" icon="close" label="Clear Filters"/>
		</div>

		<!--Table-->
		<v-data-table v-if="$vuetify.breakpoint.width >= 600"
					  class="appWhite rounded-lg mt-4"
					  :headers="computedHeaders"
					  :items="computedTableData">

			<!--ID-->
			<template v-slot:item.id="{item}">
				<app-text size="small">{{ item.siteData.id }}</app-text>
			</template>

			<!--Site Name-->
			<template v-slot:item.siteName="{item}">
				<app-text size="small">{{ item.siteData.siteName }}</app-text>
				<app-text color="grey9" size="small">{{ item.siteData.siteAddressLine1 }}</app-text>
			</template>

			<!--Telephone-->
			<template v-slot:item.siteTelephone="{item}">
				<app-text size="small">{{ MIX_formatTelephoneNumber(item.siteData.siteTelephone) }}</app-text>
			</template>

			<!--Contact Name-->
			<template v-slot:item.siteContact="{item}">
				<app-text size="small">
					{{ usersData.find(u => u.entityId === item.siteData.siteContact)?.userName }}
				</app-text>
				<app-text color="grey9" size="small">
					{{ usersData.find(u => u.entityId === item.siteData.siteContact)?.userPosition }}
				</app-text>
			</template>

			<!--Action Button-->
			<template v-slot:item.action="{item}">
				<div class="d-flex justify-end">
					<app-icon
						v-if="!locationsData.filter(location => location.locationSite === item?.siteData?.entityId)?.length"
						class="flash" color="red" icon="error" title="This Site has no Locations"/>
					<app-icon @click.native="openRightPanel(item)"
							  class="cursorPointer" color="primary" icon="arrowForward"/>
				</div>
			</template>

		</v-data-table>

		<!--Mobile Cards-->
		<div v-if="$vuetify.breakpoint.width < 600">

			<!--No data message-->
			<app-text v-if="!computedTableData.length"
					  class="text-center mt-4" color="grey9">You have no Sites to view
			</app-text>

			<sites-mobile-card v-for="item in computedTableData" :key="item.id"
							   @click.native="openRightPanel(item)"
							   :item="item"/>

		</div>

		<!--Filters Panel ------------------------------------------------------------------------------------------ -->
		<filter-panel :is-filters-panel-visible="isFiltersPanelVisible" @toggle="toggleFiltersVisibility">
			<div class="pa-4">

				<!--Address-->
				<page-break-title description="Line1/2 | Town | City | County | Postcode" title="Address"/>
				<div class="mt-4">
					<app-form-field form-type="textInput"
									label="Address"
									v-model.trim="filterByAddress"/>
				</div>

				<!--Contact-->
				<page-break-title class="mt-8" title="Contact"/>
				<div class="mt-4">
					<app-form-field form-type="textInput"
									label="Contact"
									v-model.trim="filterByContact"/>
				</div>

				<!--Locations-->
				<page-break-title class="mt-8" title="Locations"/>
				<div class="mt-4">
					<app-form-field form-type="autoComplete"
									:deletable-chips="true"
									:items="locationsData"
									item-text="locationName"
									label="Locations"
									:multiple="true"
									:return-object="true"
									:small-chips="true"
									v-model.trim="filterByLocations"/>
				</div>

			</div>
		</filter-panel>

		<!--Right Side ----------------------------------------------------------------------------------- Right Side-->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">

			<div v-if="isRightPanelVisible"
				 class="appGrey formShadow"
				 style="position:absolute; top:0; bottom: 0; right: 0; overflow: auto"
				 :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 50%'">

				<!--Header-->
				<div class="d-flex align-center primary pa-4">
					<app-text color="appWhite" size="normal">{{ (selectedItem?.siteData?.siteName) || 'New' }}
					</app-text>
					<v-spacer/>
					<app-icon @click.native="closeRightPanel" class="cursorPointer" color="white" icon="close"
							  size="32"/>
				</div>

				<!--Action Bar-->
				<div class="d-flex align-center pa-4">

					<v-spacer/>

					<edit-icon v-if="tabs === 'site' && ['Staff-Admin'].includes(MIX_getCurrentUser().userLevel)"
							   @click.native="editItem" :isActive="!isReadOnly"/>
					<delete-icon
						v-if="tabs === 'site' && selectedItem?.siteData?.entityId && ['Staff-Admin'].includes(MIX_getCurrentUser().userLevel)"
						@emitDeleteItem="deleteItem(selectedItem?.siteData)"
						item-name="siteName"
						:selected-item="selectedItem?.siteData"/>

				</div>

				<!--Tabs-->
				<v-tabs v-model="tabs">

					<!--Site-->
					<v-tab href="#site">
						<app-text size="small">Site</app-text>
					</v-tab>

					<!--Locations-->
					<v-tab v-if="selectedItem?.siteData?.entityId && ['Staff-Admin'].includes(MIX_getCurrentUser().userLevel)"
						   href="#locations">
						<app-text size="small">Locations</app-text>
					</v-tab>

				</v-tabs>

				<!--Tabs Content-->
				<v-tabs-items v-model="tabs">

					<!--Site-->
					<v-tab-item value="site">
						<site-form @emitReloadPage="emittedReloadPage"
								   class="pa-4"
								   :formData="selectedItem?.siteData"
								   :isReadOnly="isReadOnly"
								   :locationsData="locationsData"
								   :usersData="usersData"/>
					</v-tab-item>

					<!--Locations-->
					<v-tab-item value="locations">
						<site-locations-panel @emitReloadPage="emittedReloadPage"
											  :formData="selectedItem"/>
					</v-tab-item>

				</v-tabs-items>

			</div>

		</transition>

	</div>

</template>

<script>
import UsersMobileCard from "@/views/users/userMobileCard/UserMobileCard";
import UserForm from "@/views/users/userForm/UserForm";
import SitesMobileCard from "@/views/sites/siteMobileCard/SiteMobileCard";
import SiteForm from "@/views/sites/sitePanel/SitePanel";
import SiteLocationsPanel from "@/views/sites/siteLocationsPanel/SiteLocationsPanel";

export default {

	name: "Sites",

	components: {SiteLocationsPanel, SiteForm, SitesMobileCard, UserForm, UsersMobileCard},

	data: () => ({
		isFiltersPanelVisible: false,
		filterByAddress: '',
		filterByContact: '',
		filterByLocations: [],
		filterByStatuses: [],
		isLoading: true,
		isReadOnly: false,
		isRightPanelVisible: false,
		moreActionsMenuOption: [
			{name: 'Export', icon: 'export'}
		],
		searchContact: '',
		searchBySiteName: '',
		selectedItem: {},
		tableHeaders: [
			{text: 'ID', value: 'id', align: 'start', sortable: false, hidden: true},
			{text: 'Name', value: 'siteName', align: 'start', sortable: false},
			{text: 'Telephone', value: 'siteTelephone', align: 'start', sortable: false},
			{text: 'Contact Name', value: 'siteContact', align: 'start', sortable: false},
			{text: '', value: 'action', align: 'center', sortable: false, width: '48px'},
		],
		tabs: '',

		sitesData: [],
		locationsData: [],
		usersData: [],
	}),

	computed: {

		/**
		 * Computed Export CSV
		 *
		 * Return the data and headers for the CSV export
		 *
		 * @returns {{headers: {}, data: *[]}}
		 */
		computedExportCSV() {
			const t = this
			let data = []
			let headers = {}

			// Add the readable headers for the CSV columns
			headers = {
				entityId: 'ID',
				siteName: 'Site Name',
				siteTelephone: 'Site Telephone',
				siteAddressLine1: 'Site Address Line 1',
				siteAddressLine2: 'Site Address Line 2',
				siteAddressTown: 'Site Address Town',
				siteAddressCity: 'Site Address City',
				siteAddressCounty: 'Site Address County',
				siteAddressPostcode: 'Site Address Postcode',
				// siteAddressCoords: 'Site Address Coords',
				siteContact: 'Site Contact',
				siteNotes: 'Site Notes',
			}

			// Add the data
			t.computedTableData.forEach(entry => {

				const DATA_OBJECT = {
					entityId: entry?.siteData?.entityId,
					siteName: entry?.siteData?.siteName,
					siteTelephone: entry?.siteData?.siteTelephone,
					siteAddressLine1: entry?.siteData?.siteAddressLine1,
					siteAddressLine2: entry?.siteData?.siteAddressLine2,
					siteAddressTown: entry?.siteData?.siteAddressTown,
					siteAddressCity: entry?.siteData?.siteAddressCity,
					siteAddressCounty: entry?.siteData?.siteAddressCounty,
					siteAddressPostcode: entry?.siteData?.siteAddressPostcode,
					// siteAddressCoords: `${entry?.siteData?.siteAddressCoords?.latitude}, ${entry?.siteData?.siteAddressCoords?.longitude}`,
					siteContact: t.usersData.find(u => u.entityId === entry.siteData.siteContact)?.userName,
					siteNotes: entry?.siteData?.siteNotes,
				}

				data.push(DATA_OBJECT)
			})

			return {headers, data}
		},

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this
			const CURRENT_USER_DATA = t.MIX_getCurrentUser()
			let headers = t.tableHeaders

			// Remove hidden headers
			headers = headers.filter(h => !h.hidden)

			// Only those with access can view the actions column
			if (!['Staff-Admin', 'Staff-Manager'].includes(CURRENT_USER_DATA.userLevel)) headers = headers.filter(h => h.value !== 'action')

			return headers
		},

		/**
		 * Computed Number of Active Filters
		 *
		 * Count the number of active filters to display in the filters button badge.
		 *
		 * @returns {*}
		 */
		computedNumberOfActiveFilters() {
			const t = this
			return [
				t.filterByAddress ? 1 : 0,
				t.filterByContact ? 1 : 0,
				t.filterByLocations.length,
			].reduce((acc, curr) => acc + curr, 0)
		},

		computedMoreActionsMenuOption() {
			const t = this
			let menu = t.moreActionsMenuOption

			// Only those with access can view the actions column
			if (!['Staff-Admin', 'Staff-Manager'].includes(t.MIX_getCurrentUser().userLevel)) menu = menu.filter(m => m.name !== 'Export')

			return menu
		},

		/**
		 * Computed Table Data
		 *
		 * Sort and return the form data.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedTableData() {
			const t = this
			const LOCATIONS_DATA = t.locationsData
			const SITES_DATA = t.sitesData
			const USERS_DATA = t.usersData
			let tableData = []

			SITES_DATA.forEach(site => {

				const SITE_LOCATIONS_DATA = LOCATIONS_DATA.filter(location => location.locationSite === site.entityId)

				const SITE_LOCATIONS_OBJECT = {
					siteData: site,
					locationsData: SITE_LOCATIONS_DATA,
				}

				tableData.push(SITE_LOCATIONS_OBJECT)
			})

			// Search by Site Name
			if (t.searchBySiteName) {
				const SEARCH_CRITERIA = t.searchBySiteName.toUpperCase()
				tableData = tableData.filter(site => {
					const SITE_NAME = site.siteData.siteName.toUpperCase()
					return SITE_NAME.includes(SEARCH_CRITERIA)
				})
			}

			// Search by Address (AddressLine1, AddressLine2, Town, City, County, Postcode)
			if (t.filterByAddress) {
				const SEARCH_CRITERIA = t.filterByAddress.toUpperCase()
				tableData = tableData.filter(site => {
					const ADDRESS_LINE_1 = site.siteData.siteAddressLine1.toUpperCase()
					const ADDRESS_LINE_2 = site.siteData.siteAddressLine2.toUpperCase()
					const TOWN = site.siteData.siteAddressTown.toUpperCase()
					const CITY = site.siteData.siteAddressCity.toUpperCase()
					const COUNTY = site.siteData.siteAddressCounty.toUpperCase()
					const POSTCODE = site.siteData.siteAddressPostcode.toUpperCase()
					return ADDRESS_LINE_1.includes(SEARCH_CRITERIA) ||
						ADDRESS_LINE_2.includes(SEARCH_CRITERIA) ||
						TOWN.includes(SEARCH_CRITERIA) ||
						CITY.includes(SEARCH_CRITERIA) ||
						COUNTY.includes(SEARCH_CRITERIA) ||
						POSTCODE.includes(SEARCH_CRITERIA)
				})
			}

			// Search by Contact Name
			if (t.filterByContact) {
				const SEARCH_CRITERIA = t.filterByContact.toUpperCase()
				tableData = tableData.filter(site =>
					USERS_DATA.find(user =>
						user.entityId === site.siteData.siteContact)?.userName.toUpperCase().includes(SEARCH_CRITERIA))
			}

			// Search by Locations
			if (t.filterByLocations.length) {
				const SELECTED_SITE_IDS = t.filterByLocations.map(location => location.locationSite)
				tableData = tableData.filter(siteLocationObject =>
					SELECTED_SITE_IDS.includes(siteLocationObject.siteData.entityId)
				)
			}

			// Sort by Site Name
			tableData = tableData.sort((a, b) => a.siteData.siteName > b.siteData.siteName ? 1 : -1)

			return tableData
		},

	},

	methods: {

		/**
		 * Clear Filters
		 *
		 * Clear all the filters.
		 */
		clearFilters() {
			const t = this

			t.filterByAddress = ''
			t.filterByContact = ''
			t.filterByLocations = []
		},

		/**
		 * Close Right Panel
		 *
		 * Close the right panel.
		 */
		closeRightPanel() {
			const t = this

			t.tabs = ''
			t.selectedItem = {}
			t.isRightPanelVisible = false
		},

		/**
		 *
		 * Delete Item
		 *
		 * Delete the selected document by its ID.
		 *
		 * @param itemData {object} the item to delete
		 */
		async deleteItem(itemData) {
			const t = this

			const RESPONSE = await t.MIX_redis_delete('site', itemData.entityId, itemData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error deleting Site', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Deleted Site'

			// Reload the data
			await t.loadData()

			// Close the panel
			t.closeRightPanel()
		},

		/**
		 * Edit Item
		 *
		 * Toggle the editability of the form.
		 */
		editItem() {
			const t = this

			t.isReadOnly = !t.isReadOnly
		},

		/**
		 * Emitted Reload Data
		 *
		 * Reload the data to refresh the page.
		 */
		emittedReloadData() {
			const t = this

			t.loadData()
		},

		/**
		 * Emitted Reload Page
		 *
		 * Close the panel and Reload the data to refresh the page.
		 */
		emittedReloadPage() {
			const t = this

			t.closeRightPanel()
			t.loadData()
		},

		/**
		 * Export Data
		 *
		 * Export the data to CSV.
		 */
		exportData() {
			const t = this

			t.MIX_exportDocuments(t.computedExportCSV.headers, 'Sites', t.computedExportCSV.data)
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this

			if (selection === 'Export') t.exportData()
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadSitesData(),
				t.loadUsersData(),
				t.loadLocationsData(),
			])

			t.isLoading = false
		},

		/**
		 * Load Locations Data
		 *
		 * Load Location data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadLocationsData() {
			const t = this

			// Fetch the data
			// const RESPONSE = await t.MIX_redis_getAll('location')
			const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields(
				'Location',
				[],
				[
					{field: 'locationName'},
					{field: 'locationSite'}
				]
			)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Locations: ', RESPONSE.error)
				return
			}

			// Assign the data to the state
			t.locationsData = RESPONSE.data
		},

		/**
		 * Load Sites Data
		 *
		 * Load Sites data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadSitesData() {
			const t = this

			// Fetch the data
			// const RESPONSE = await t.MIX_redis_getAll('site')
			const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields(
				'Site',
				[],
				[
					{field: 'siteName'},
					{field: 'siteTelephone'},
					{field: 'siteContact'},
					{field: 'siteAddressLine1'},
					{field: 'siteAddressLine2'},
					{field: 'siteAddressTown'},
					{field: 'siteAddressCity'},
					{field: 'siteAddressCounty'},
					{field: 'siteAddressPostcode'},
					{field: 'siteAddressCoords'},
					{field: 'siteNotes'}
				]
			)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Sites: ', RESPONSE.error)
				return
			}

			// Assign the data to the state
			t.sitesData = RESPONSE.data
		},

		/**
		 * Load Users Data
		 *
		 * Load Users data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadUsersData() {
			const t = this

			// Fetch the data
			// const RESPONSE = await t.MIX_redis_getUsersWhere([{whereKey: 'userType', whereValue: 'Staff'}])
			const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields(
				'User',
				[{whereKey: 'userType', whereValue: 'Staff'}],
				[
					{field: 'userEmail'},
					{field: 'userName'},
					{field: 'userPosition'},
					{field: 'userTelephone'}
				]
			)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Users: ', RESPONSE.error)
				return
			}

			// Assign the data to the state
			t.usersData = RESPONSE.data?.sort((a, b) => a.userName > b.userName ? 1 : -1)
		},

		/**
		 * Open Form
		 *
		 * Open the form dialog.
		 * If no item is passed in, the form will open as new (blank).
		 *
		 * @param item the selected key
		 */
		openRightPanel(item) {
			const t = this

			// New
			if (!item?.siteData?.entityId) {
				t.isReadOnly = false
				t.selectedItem = {}
			}

			// Existing
			else {
				t.isReadOnly = true
				t.selectedItem = item
			}

			t.isRightPanelVisible = true
		},

		/**
		 * Toggle Filters Visibility
		 *
		 * Toggle the visibility of the filters panel.
		 */
		toggleFiltersVisibility() {
			const t = this

			t.isFiltersPanelVisible = !t.isFiltersPanelVisible
		},

	},

	async mounted() {
		const t = this

		await t.loadData()
	},

}
</script>

<style scoped>
</style>
