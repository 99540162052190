<!--
Time Picker component

This component is a wrapper around the Vuetify Time Picker component.
It is used to display a time picker dialog that is activated by clicking on a text field.
The time picker dialog is displayed when the text field is clicked and allows the user to select a time.
The selected time is returned as a string in the format "HH:mm" and displayed in the text field.

Props:
- clearable: Boolean, whether the text field is clearable
- disabled: Boolean, whether the text field is disabled
- error: Boolean, whether the text field has an error
- errorMessage: String, error message to display
- label: String, label for the text field
- placeholder: String, placeholder text for the text field
- prependInnerIcon: String, icon to prepend inside the text field
- max: String, maximum time allowed for selection (format: "HH:mm")
- min: String, minimum time allowed for selection (format: "HH:mm")
- selectedDate: String, selected date in ISO format (YYYY-MM-DD)
- time: String, initial time value to display in the text field (format: "HH:mm")

NB. This is no longer being used as it was reported to be awkward to use. Use the TimePicker2 component instead.
-->
<template>
	<v-dialog max-width="300"
			  :disabled="disabled"
			  v-model="dialog">

		<!--Activator-->
		<template v-slot:activator="{ on, attrs }">

			<div v-bind="attrs"
				 v-on="on">

				<v-text-field class="rounded-lg labelColor"
							  background-color="appWhite"
							  :clearable="clearable"
							  :disabled="disabled"
							  :error="error"
							  :error-messages="errorMessage"
							  flat
							  hide-details="auto"
							  :label="label"
							  outlined
							  :placeholder="placeholder || label"
							  :prepend-inner-icon="prependInnerIcon"
							  readonly
							  :value="time"/>

			</div>

		</template>

		<!--Content-->
		<v-time-picker @change="handleSelection"
					   color="grey6"
					   :disabled="disabled"
					   format="24hr"
					   header-color="primary"
					   :max="maxTime"
					   :min="minTime"
					   scrollable
					   v-model="pickerTime"/>

	</v-dialog>
</template>

<script>
export default {

	name: "TimePicker",

	props: [
		'clearable',
		'disabled',
		'error',
		'errorMessage',
		'label',
		'placeholder',
		'prependInnerIcon',
		'max',
		'min',
		'selectedDate',
		'time'
	],

	data: () => ({
		dialog: false,
		pickerTime: '',
	}),

	computed: {

		/**
		 * Computes the minimum time allowed for selection.
		 *
		 * @returns {string|null} The minimum time or null if not specified.
		 */
		minTime() {
			const t = this

			return t.min || null
		},

		/**
		 * Computes the maximum time allowed for selection.
		 *  - If no selectedDate prop is provided, any time is allowed
		 *  - If selectedDate prop is provided, but no max prop, any time is allowed
		 *  - If max prop is provided, but no selectedDate prop, time is always limited
		 *  - If selectedDate prop and max props are provided, limit time if the date is today
		 *  - If selectedDate prop and max props are provided, but the date is not today, any time is allowed
		 *
		 * @returns {string|null} The maximum time or null if not limited
		 */
		maxTime() {
			const t = this
			const SELECTED_DATE = t.$props.selectedDate

			const now = new Date()
			const selectedDateTime = SELECTED_DATE ? new Date(SELECTED_DATE) : null

			// No selected date or max prop, any time can be selected
			if (!selectedDateTime && !this.max || selectedDateTime && !this.max) return null

			// No selected date, but max prop provided, time is always limited
			else if (!selectedDateTime && this.max) return this.max

			// Selected date and max prop provided, limit time if the date is today
			else if (selectedDateTime && this.max) {
				const selectedDate = new Date(
					selectedDateTime.getFullYear(),
					selectedDateTime.getMonth(),
					selectedDateTime.getDate()
				)
				const currentDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())

				return selectedDate.getTime() === currentDate.getTime() ? this.max : null
			}
		}

	},

	methods: {

		handleSelection(time) {
			const t = this

			t.$emit("emitTime", t.pickerTime)
			t.dialog = false
		},

	},

}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
	border-color: transparent;
}

.labelColor >>> .v-label {
	color: var(--v-primary-base) !important;
	opacity: 0.5;
}
</style>
