<template>

	<div class="green fadeOut rounded-lg ma-4 pa-4"
		 style="z-index:999; position:absolute; bottom: 16px; right:16px; max-width: 512px">

		<!--Icon | Message-->
		<div class="d-flex align-center">

			<app-icon class="mr-4" color="white" icon="success" size="48"/>

			<!--Message-->
			<app-text color="white">{{ successMessage }}</app-text>

		</div>

	</div>

</template>

<script>
import sharedState from "@/sharedState";

export default {

	name: "SuccessMessage",

	props: ['successMessage'],

	data: () => ({
		sharedState,
	}),

	mounted() {
		setTimeout(() => {
			this.sharedState.successMessage = ''
		}, 4000)
	}

}
</script>

<style scoped>
.fadeOut {
    animation: fadeOut 2s forwards;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}
</style>
