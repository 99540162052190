<template>
	<div class="appGrey pa-4">

		<!--Show QR Code-->
		<app-btn @click.native="getQrCodeSelection"
				 :block="true"
				 icon="qrCode"
				 label="Show QR Code"/>

		<!--QR Code Preview-->
		<v-overlay :value="isQrCodePreviewVisible">
			<div class="appGrey d-flex flex-column rounded-lg">

				<div class="d-flex justify-space-between pa-4">
					<div>

						<!--Event-->
						<app-text color="primary" size="medium-bold">{{ selectedQrCode?.eventData?.eventName }}
						</app-text>

						<!--Organisation-->
						<app-text size="normal-bold">{{ selectedQrCode?.organisationData?.organisationName }}</app-text>

						<!--Car Park(s)-->
						<div class="d-flex">
							<app-text v-for="item in selectedQrCode?.parkingData" class="mt-1 mr-4" size="small">
								{{ item }}
							</app-text>
						</div>

					</div>

					<!--Close Button-->
					<close-icon @click.native="isQrCodePreviewVisible = false"/>

				</div>

				<v-divider class="greyD" style="width: 100%"/>

				<!--QR Code-->
				<div class="pa-8">
					<qrcode-vue background="#EEEEEE"
								foreground="black"
								level="H"
								size="280"
								:value="JSON.stringify(fullParkingData.parkingData.map(item => item.parkingSpaceData.entityId))"/>
				</div>

			</div>
		</v-overlay>

		<!--Parking Cards-->
		<div v-for="item in fullParkingData.parkingData" :key="item.parkingSpaceData.entityId"
			 class="appWhite rounded-lg my-4 pa-4">

			<!--Header-->
			<div class="d-flex align-center">

				<!--Car Park | Event | Organisation-->
				<div>

					<!--Car Park-->
					<app-text color="primary" size="medium-bold">
						{{ item.carParkData.carParkName }}
					</app-text>

					<!--Event-->
					<app-text color="grey9" size="small-bold">
						{{ fullParkingData.eventData.eventName }}
					</app-text>

					<!--Individual-->
					<app-text v-if="fullParkingData.individualData?.parkingSpaceIndividualName" color="grey9"
							  size="small">
						{{ fullParkingData.individualData?.parkingSpaceIndividualName }}
					</app-text>

					<!--Organisation-->
					<app-text v-if="fullParkingData.organisationData?.entityId" color="grey9" size="small">
						{{ fullParkingData.organisationData?.organisationName }}
					</app-text>

				</div>

				<v-spacer/>

				<!--Edit-->
				<edit-icon @click.native="handleItemSelection(item)"/>

				<!--Delete-->
				<delete-icon v-if="['Staff-Admin'].includes(MIX_getCurrentUser().userLevel)"
							 @emitDeleteItem="deleteItem(item)"
							 itemName=""
							 :selectedItem="item"/>

			</div>

			<!--Regular -------------------------------------------------------------------------------------------- -->

			<div v-if="isSectionVisible(item, 'Regular')" class="mb-4">
				<div class="d-flex align-center">
					<v-divider class="greyD"/>
					<app-text class="mx-4" color="grey9" size="normal-bold">Regular</app-text>
					<v-divider class="greyD"/>
				</div>

				<div class="d-flex justify-space-around mt-4">

					<!--Small-->
					<div v-if="item.parkingSpaceData.parkingSpaceNumberOfRegularSmallVehiclesRequired"
						 class="d-flex flex-column align-center">
						<app-icon color="primary" icon="car" size="48"/>
						<app-text>
						<span class="font-weight-bold">
							{{ item.parkingSpaceData.parkingSpaceNumberOfRegularSmallVehiclesRequired }}
						</span>
							Small
						</app-text>
						<app-text color="grey9" size="small">Cars/Motorbikes</app-text>
					</div>

					<!--Medium-->
					<div v-if="item.parkingSpaceData.parkingSpaceNumberOfRegularMediumVehiclesRequired"
						 class="d-flex flex-column align-center">
						<app-icon color="primary" icon="minibus" size="48"/>
						<app-text>
						<span class="font-weight-bold">
							{{ item.parkingSpaceData.parkingSpaceNumberOfRegularMediumVehiclesRequired }}
						</span>
							Medium
						</app-text>
						<app-text color="grey9" size="small">Vans/Minibuses</app-text>
					</div>

					<!--Large-->
					<div v-if="item.parkingSpaceData.parkingSpaceNumberOfRegularLargeVehiclesRequired"
						 class="d-flex flex-column align-center">
						<app-icon color="primary" icon="truck" size="48"/>
						<app-text>
						<span class="font-weight-bold">
							{{ item.parkingSpaceData.parkingSpaceNumberOfRegularLargeVehiclesRequired }}
						</span>
							Large
						</app-text>
						<app-text color="grey9" size="small">Coaches/Trucks</app-text>
					</div>

				</div>
			</div>

			<!--Disabled ------------------------------------------------------------------------------------------- -->

			<div v-if="isSectionVisible(item, 'Disabled')">

				<!--Title-->
				<div class="d-flex align-center">
					<v-divider class="greyD"/>
					<app-text class="mx-4" color="grey9" size="normal-bold">Accessible</app-text>
					<v-divider class="greyD"/>
				</div>

				<div class="d-flex justify-space-around mt-4">

					<!--Small-->
					<div v-if="item.parkingSpaceData.parkingSpaceNumberOfDisabledSmallVehiclesRequired"
						 class="d-flex flex-column align-center">

						<!--Icons-->
						<div class="d-flex">
							<app-icon color="blue" icon="disabled" size="24"/>
							<app-icon color="blue" icon="car" size="48"/>
						</div>

						<!--Number | Size-->
						<app-text>
						<span class="font-weight-bold">
							{{ item.parkingSpaceData.parkingSpaceNumberOfDisabledSmallVehiclesRequired }}
						</span>
							Small
						</app-text>

						<!--Example-->
						<app-text color="grey9" size="small">Cars/Motorbikes</app-text>

					</div>

					<!--Medium-->
					<div v-if="item.parkingSpaceData.parkingSpaceNumberOfDisabledMediumVehiclesRequired"
						 class="d-flex flex-column align-center">

						<!--Icons-->
						<div class="d-flex">
							<app-icon color="blue" icon="disabled" size="24"/>
							<app-icon color="blue" icon="minibus" size="48"/>
						</div>

						<!--Number | Size-->
						<app-text>
						<span class="font-weight-bold">
							{{ item.parkingSpaceData.parkingSpaceNumberOfDisabledMediumVehiclesRequired }}
						</span>
							Medium
						</app-text>

						<!--Example-->
						<app-text color="grey9" size="small">Vans/Minibuses</app-text>

					</div>

					<!--Large-->
					<div v-if="item.parkingSpaceData.parkingSpaceNumberOfDisabledLargeVehiclesRequired"
						 class="d-flex flex-column align-center">

						<!--Icons-->
						<div class="d-flex">
							<app-icon color="blue" icon="disabled" size="24"/>
							<app-icon color="blue" icon="truck" size="48"/>
						</div>

						<!--Number | Size-->
						<app-text>
						<span class="font-weight-bold">
							{{ item.parkingSpaceData.parkingSpaceNumberOfDisabledLargeVehiclesRequired }}
						</span>
							Large
						</app-text>

						<!--Example-->
						<app-text color="grey9" size="small">Coaches/Trucks</app-text>

					</div>

				</div>

			</div>

		</div>

	</div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {

	name: "EventParkingExisting",

	props: ['fullParkingData'],

	components: {QrcodeVue},

	data: () => ({
		isQrCodePreviewVisible: false,
		selectedQrCode: {},
	}),

	methods: {

		/**
		 *
		 * Delete Item
		 *
		 * Delete the selected document by its ID.
		 *
		 * @param itemData {object} the item to delete
		 */
		async deleteItem(itemData) {
			const t = this

			const RESPONSE = await t.MIX_redis_destroy('parkingSpace', itemData.parkingSpaceData?.entityId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error deleting Parking Space: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem deleting this Parking Space, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Deleted Parking Space'

			t.$emit('emitReloadPage')
		},

		/**
		 * Get QR Code Selection
		 *
		 * Get the selected QR Code data.
		 */
		getQrCodeSelection() {
			const t = this
			const FULL_PARKING_DATA = t.$props.fullParkingData

			t.selectedQrCode = {
				eventData: FULL_PARKING_DATA.eventData,
				organisationData: FULL_PARKING_DATA.organisationData,
				parkingData: FULL_PARKING_DATA.parkingData.map(item => item.carParkData.carParkName)
			}

			t.isQrCodePreviewVisible = true
		},

		/**
		 * Handle Item Selection
		 *
		 * Emit the selected Parking Space data.
		 *
		 * @param item - the selected Parking Space data
		 */
		handleItemSelection(item) {
			const t = this

			t.$emit('emitHandleSelectedParkingSpace',
				{
					eventData: t.$props.fullParkingData.eventData,
					organisationData: t.$props.fullParkingData.organisationData,
					parkingData: [item]
				}
			)
		},

		/**
		 * Is Section Visible
		 *
		 * Check if the section should be visible based on the number of vehicles required.
		 * If the total number of vehicles is more than 0, then the section should be visible.
		 *
		 * @param item - the item to check
		 * @param type - the type of vehicle
		 * @returns {boolean} - true if the section should be visible
		 */
		isSectionVisible(item, type) {
			const DATA = item.parkingSpaceData

			const SMALL = DATA[`parkingSpaceNumberOf${type}SmallVehiclesRequired`]
			const MEDIUM = DATA[`parkingSpaceNumberOf${type}MediumVehiclesRequired`]
			const LARGE = DATA[`parkingSpaceNumberOf${type}LargeVehiclesRequired`]

			return SMALL + MEDIUM + LARGE > 0
		}

	},

}
</script>

<style scoped>

</style>
