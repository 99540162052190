<template>

	<page-loading-animation v-if="isLoading" is-loading="isLoading"/>

	<!--No Team Message-->
	<div v-else-if="!MIX_getCurrentUser()?.userTeams.length">

		<!--Header-->
		<page-title :divider="true"
					icon="events"
					info="Please use the cards below to indicate your availability for each event. Select Yes if you are able to attend, and No if you cannot. If your situation changes, please let your supervisor know."
					title="Events"/>

		<!--Message-->
		<app-text class="text-center mt-4">
			You have not been added to a team yet.
			<br>
			<br>
			Please contact Admin on:
			<br>
			<br>
			<span class="primary--text">
				<a :href="`mailto:${appConfig.appBranding.stewardsHelpLink}`">
					{{ appConfig.appBranding.stewardsHelpLink }}</a>
			</span>
		</app-text>

	</div>

	<!--Page Content-->
	<div v-else>

		<!--Events-->
		<div v-if="eventsView === 'Events'">

			<!--Header-->
			<page-title :divider="true"
						icon="events"
						info="Please use the cards below to indicate your availability for each event. Select Yes if you are able to attend, and No if you cannot. If your situation changes, please let your supervisor know."
						title="Events"/>

			<!--Live Events ---------------------------------------------------------------------------------------- -->

			<!--Section Title-->
			<app-text class="mt-4" color="primary" size="large-bold">
				Live
				<span class="grey6--text">Events</span>
			</app-text>

			<!--No Events message-->
			<app-text v-if="!computedLiveEvents.length" class="text-center mt-8" color="grey9">
				There are no Live Events to show
			</app-text>

			<!--Event Cards-->
			<div v-for="event in computedLiveEvents">
				<event-availability-card v-for="item in event.userTeamsData" :key="item.eventUser.entityId"
										 @emitShowTeamAvailability="emittedShowTeamAvailability"
										 :event-data="event.eventData"
										 :event-team-data="item.eventTeam"
										 :event-user-data="item.eventUser"
										 :team-data="item.teamData"/>
			</div>

			<!--Pending Events ------------------------------------------------------------------------------------- -->

			<!--Section Title-->
			<app-text class="mt-8" color="primary" size="large-bold">
				Pending
				<span class="grey6--text">Events</span>
			</app-text>

			<!--Information-->
			<app-text class="mt-2" size="small">
				These are for information only. You cannot register your availability until these are live
			</app-text>

			<!--No Events message-->
			<app-text v-if="!computedPendingEvents.length" class="text-center mt-8" color="grey9">
				There are no Pending Events to show
			</app-text>

			<!--Event Cards-->
			<div v-for="event in computedPendingEvents">
				<event-availability-card v-for="item in event.userTeamsData" :key="item.entityId"
										 :disabled="true"
										 :event-data="event.eventData"
										 :event-team-data="item.eventTeam"
										 :event-user-data="item.eventUser"
										 :team-data="item.teamData"/>
			</div>

		</div>

		<!--Status Selection-->
		<event-status-selection v-if="eventsView === 'Status Selection'"
								@emitShowEventControlPanel="emittedShowEventControlPanel"
								@emitEventView="eventsView = $event"
								:usersFullEventData="usersFullEventData"/>

		<!--Control Panel-->
		<event-control-panel v-if="eventsView === 'Control Panel'"
							 @emitEventView="eventsView = $event"
							 @emitRefreshData="loadUsersFullEventData"
							 :usersFullEventData="getConfirmedUsersFullEventData()"/>

	</div>

</template>

<script>
import appConfig from '../../../../appConfig.json'
import EventAvailabilityCard from "@/views/events/steward/eventAvailabilityCard/EventAvailabilityCard";
import EventStatusSelection from "@/views/events/steward/eventStatusSelection/EventStatusSelection";
import EventControlPanel from "@/views/events/steward/eventControlPanel/EventControlPanel";

export default {

	name: "EventsSteward",

	components: {EventControlPanel, EventStatusSelection, EventAvailabilityCard},

	data: () => ({
		appConfig,
		eventsView: 'Events',
		isLoading: true,
		refreshInterval: null,
		selectedEvent: {},
		selectedTeam: {},

		// Data
		fullPendingEventsData: [],
		fullLiveEventsData: [],
		usersFullEventData: [],
	}),

	computed: {

		/**
		 * Computed Live Events
		 *
		 * Sort and return the Live Events by eventDate.
		 *
		 * @returns {Array} - sorted array of Live Events
		 */
		computedLiveEvents() {
			const t = this
			const LIVE_EVENTS_DATA = t.fullLiveEventsData

			// Sort by eventDate
			LIVE_EVENTS_DATA.sort((a, b) => a.eventData.eventDate > b.eventData.eventDate ? 1 : -1)

			return LIVE_EVENTS_DATA
		},

		/**
		 * Computed Pending Events
		 *
		 * Sort and return the Pending Events by eventDate.
		 *
		 * @returns {Array} - sorted array of Pending Events
		 */
		computedPendingEvents() {
			const t = this
			const PENDING_EVENTS_DATA = t.fullPendingEventsData

			// Sort by eventDate
			PENDING_EVENTS_DATA.sort((a, b) => a.eventData.eventDate > b.eventData.eventDate ? 1 : -1)

			return PENDING_EVENTS_DATA
		}

	},

	methods: {

		/**
		 * Clear Interval Data
		 *
		 * Clear the interval for fetching data.
		 */
		clearIntervalData() {
			const t = this

			// Clear the interval
			clearInterval(t.refreshInterval)
		},

		/**
		 * Emitted Show Team Availability
		 *
		 * Load the EventUser data for this Event & Team.
		 * Switch the view to display the Status Selection page.
		 *
		 * @param payload
		 */
		async emittedShowTeamAvailability(payload) {
			const t = this
			t.selectedEvent = payload.eventData
			t.selectedTeam = payload.teamData

			t.isLoading = true

			await t.loadUsersFullEventData()

			t.isLoading = false

			t.switchEventsView(payload.eventsView)
		},

		emittedShowEventControlPanel(payload) {
			const t = this

			t.switchEventsView(payload.eventsView)
		},

		getConfirmedUsersFullEventData() {
			const t = this
			const USERS_FULL_EVENT_DATA = t.usersFullEventData

			return USERS_FULL_EVENT_DATA.filter(ufed => ufed.eventUserData.eventUserStatus === 'Confirmed')
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadFullPendingEventsData(),
				t.loadFullLiveEventsData(),
			])

			t.isLoading = false
		},

		/**
		 * Load Full Pending Events Data
		 *
		 * Load Pending Events data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadFullPendingEventsData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getFullPendingEventsData(t.MIX_getCurrentUser().entityId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Pending Events: ', RESPONSE.error)
				return
			}

			// Assign the data to the state as an array
			t.fullPendingEventsData = RESPONSE.data
		},

		/**
		 * Load Full Live Events Data
		 *
		 * Load Live Events data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadFullLiveEventsData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getFullLiveEventsData(t.MIX_getCurrentUser().entityId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Live Events: ', RESPONSE.error)
				return
			}

			// Assign the data to the state as an array
			t.fullLiveEventsData = RESPONSE.data
		},

		/**
		 * Load Users Full Event Data
		 *
		 * Load all the data required for Events (Supervisors/Deputies only).
		 * The returned data is an array containing the data required for the various components to work:
		 *  - eventData
		 *  - eventTeamData
		 *  - eventUserData
		 *  - UserData
		 *
		 * NB... The data is pulled down together pre-sorted,
		 * so it's grouped by User and ready to use instead of relying on the User's device to compute the data.
		 *
		 * NB... This calls for data on an interval to keep the Control Panel updated.
		 *
		 * @returns {Promise<void>}
		 */
		async loadUsersFullEventData() {
			const t = this
			t.clearIntervalData()

			t.isLoading = true

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getEventUsersByEventAndTeam(t.selectedEvent.entityId, t.selectedTeam.entityId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Teams: ', RESPONSE.error)
				return
			}

			// Assign the data to the state as an array
			t.usersFullEventData = RESPONSE.data

			t.isLoading = false

			// Keep calling for data to keep the Control Panel updated
			t.refreshInterval = setInterval(async () => {

				// Fetch the data
				const RESPONSE = await t.MIX_redis_getEventUsersByEventAndTeam(t.selectedEvent.entityId, t.selectedTeam.entityId)

				// Handle any errors
				if (RESPONSE.hasErrors) {
					console.error('Error getting Teams: ', RESPONSE.error)
					return
				}

				// Assign the data to the state as an array
				t.usersFullEventData = RESPONSE.data

			}, (1 * 60 * 1000))

		},

		/**
		 * Switch Events View
		 *
		 * Switch components for the required  view.
		 *
		 * @param eventsView - a string name of the view to display
		 */
		switchEventsView(eventsView) {
			const t = this

			t.eventsView = eventsView
		}

	},

	async mounted() {
		const t = this

		await t.loadData()
	},

	beforeDestroy() {
		const t = this

		// Clear the interval
		t.clearIntervalData()
	}

}
</script>

<style scoped>

</style>
