<template>
	<div class="appWhite d-flex rounded-lg mt-4 pa-4">

		<!--Avatar | Status-->
		<div class="d-flex flex-column justify-space-between">

			<!--User Status | SWAPP Status-->
			<div class="d-flex flex-column justify-space-between mr-4" style="height: 100%">

				<app-icon v-if="user.userData.userStatus === 'Pending'" color="orange" icon="pending" size="32"/>
				<app-icon v-if="user.userData.userStatus === 'Approved'" color="green" icon="success" size="32"/>
				<app-icon v-if="user.userData.userStatus === 'Rejected'" color="red" icon="onHold" size="32"/>
				<app-icon v-if="user.userData.userStatus === 'Suspended'" color="red" icon="onHold" size="32"/>
				<app-icon v-if="user.userData.userStatus === 'Deleted'" color="red" icon="userDeleted" size="32"/>

				<app-icon v-if="user.userData.userSwappStatus === 'Out'" color="grey" icon="qrCode" size="32"/>
				<app-icon v-if="user.userData.userSwappStatus === 'In'" color="green" icon="qrCode" size="32"/>

			</div>

		</div>

		<!--Name | Steward Number | Type-->
		<div style="width: 100%">

			<!--UserName-->
			<app-text color="primary" size="normal-bold">{{ user.userData.userName }}</app-text>

			<!--Level-->
			<div class="d-flex mt-2">
				<app-text color="grey9" size="small-bold">{{ user.userData.userLevel }}</app-text>
				<app-text v-if="user.userData.userInternalIdNumber && user.userData.userInternalIdNumber !== 'S00000'"
						  class="ml-2" color="grey9" size="small">
					({{ user.userData.userInternalIdNumber }})
				</app-text>
			</div>

			<v-divider class="mt-2"/>

			<!--Position | Team Name | Organisation Name-->
			<div class="d-flex">

				<!--Position - If the user is Staff-->
				<app-text v-if="user.userData.userType === 'Staff'" class="mt-2" color="grey9" size="small">
					{{ user.userData.userPosition }}
				</app-text>

				<!--Team Name - If the user is a Steward-->
				<app-text v-if="user.userData.userType === 'Steward'" class="mt-2" color="grey9" size="small">
					{{ user.teamData.teamName || 'Not assigned to a Team' }}
				</app-text>

				<!--Organisation Name - If the user is an Organisation-->
				<app-text v-if="user.userData.userOrganisation" class="mt-2" color="grey9" size="small">
					Organisation
				</app-text>

			</div>

		</div>

	</div>
</template>

<script>
export default {

	name: "UsersMobileCard",

	props: ['user'],

}
</script>

<style scoped>

</style>
