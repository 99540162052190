<template>
	<div>

		<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

		<!--Page Content-->
		<div v-else>

			<!--Header-->
			<page-title :divider="true"
						icon="events"
						info="View and administer Events"
						title="Events"/>

			<!--Action Bar-->
			<div class="d-flex align-center mt-4">

				<!--Search-->
				<app-form-field form-type="textInput"
								append-icon="icons8-search"
								class="mr-4"
								:clearable="true"
								label="Event Name"
								style="width: 100%"
								v-model.trim="searchByEvent"/>

				<!--Add Button-->
				<app-btn @click.native="openRightPanel" class="mr-4" icon="add" label="Add"/>

				<!--Filter Button - with a numbered badge-->
				<div style="position:relative;">
					<app-btn @click.native="toggleFiltersVisibility"
							 class="mr-4" color="appWhite" icon="filter" icon-color="primary"/>
					<span v-if="computedNumberOfActiveFilters > 0" class="badge">{{ computedNumberOfActiveFilters }}</span>
				</div>

				<!--More Actions Menu-->
				<more-actions-menu @emitMenuSelection="handleEmittedMenuSelection" :menuList="moreActionsMenuOption"/>

			</div>

			<!--Clear Filters-->
			<div v-if="computedNumberOfActiveFilters > 0" class="d-flex justify-end mt-4">
				<app-btn @click.native="clearFilters" icon="close" label="Clear Filters"/>
			</div>

			<!--Table-->
			<v-data-table v-if="$vuetify.breakpoint.width >= 600"
						  class="appWhite rounded-lg mt-4"
						  :headers="computedHeaders"
						  :items="computedTableData">

				<!--ID-->
				<template v-slot:item.id="{item}">
					<app-text size="small">{{ item.id }}</app-text>
				</template>

				<!--Status-->
				<template v-slot:item.eventStatus="{item}">
					<app-icon v-if="item.eventStatus === 'Draft'"
							  color="red" icon="draft" size="32" :title="item.eventStatus"/>
					<app-icon v-if="item.eventStatus === 'Pending'"
							  color="orange" icon="pending" size="32" :title="item.eventStatus"/>
					<app-icon v-if="item.eventStatus === 'Live'"
							  color="green" icon="success" size="32" :title="item.eventStatus"/>
					<app-icon v-if="item.eventStatus === 'Archived'"
							  color="grey6" icon="archived" size="32" :title="item.eventStatus"/>
				</template>

				<!--Event Name-->
				<template v-slot:item.eventName="{item}">
					<app-text size="small">{{ item.eventName }}</app-text>
				</template>

				<!--Event Date-->
				<template v-slot:item.eventDate="{item}">
					<app-text size="small">{{ MIX_formatDate(item.eventDate, 'long') }}</app-text>
				</template>

				<!--Event Type-->
				<template v-slot:item.eventType="{item}">
					<app-text size="small">{{ item.eventType }}</app-text>
				</template>

				<!--Event Group-->
				<template v-slot:item.eventGroup="{item}">
					<app-text size="small">{{ item.eventGroup }}</app-text>
				</template>

				<!--Action Button-->
				<template v-slot:item.action="{item}">
					<app-icon @click.native="openRightPanel(item)"
							  class="cursorPointer" color="primary" icon="arrowForward"/>
				</template>

			</v-data-table>

			<!--Mobile Cards-->
			<div v-if="$vuetify.breakpoint.width < 600">

				<!--No data message-->
				<app-text v-if="!computedTableData.length"
						  class="text-center mt-4" color="grey9">You have no Events to view
				</app-text>

				<event-mobile-card v-for="item in computedTableData" :key="item.id"
								   @click.native="openRightPanel(item)"
								   :event="item"/>

			</div>

		</div>

		<!--Filters Panel ------------------------------------------------------------------------------------------ -->
		<filter-panel :is-filters-panel-visible="isFiltersPanelVisible" @toggle="toggleFiltersVisibility">
			<div class="pa-4">

				<!--Statuses-->
				<page-break-title title="Statuses"/>
				<div class="mt-4"
					 style="display: grid; grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); grid-gap: 16px">
					<app-btn @click.native="handleFilterStatusSelection('Draft')"
							 :block="true"
							 color="red"
							 label="Draft"
							 :outlined="!filterByStatuses.includes('Draft')"/>
					<app-btn @click.native="handleFilterStatusSelection('Pending')"
							 :block="true"
							 color="orange"
							 label="Pending"
							 :outlined="!filterByStatuses.includes('Pending')"/>
					<app-btn @click.native="handleFilterStatusSelection('Live')"
							 :block="true"
							 color="green"
							 label="Live"
							 :outlined="!filterByStatuses.includes('Live')"/>
					<app-btn @click.native="handleFilterStatusSelection('Archived')"
							 :block="true"
							 color="grey6"
							 label="Archived"
							 :outlined="!filterByStatuses.includes('Archived')"/>
				</div>

				<!--Venue-->
				<page-break-title class="mt-8" title="Venues"/>
				<div class="mt-4">
					<app-form-field form-type="autoComplete"
									:deletable-chips="true"
									:items="sitesData"
									item-text="siteName"
									label="Venues"
									:multiple="true"
									:small-chips="true"
									v-model="filterByVenues"/>
				</div>

				<!--Types-->
				<page-break-title class="mt-8" title="Types"/>
				<div class="mt-4">
					<app-form-field form-type="autoComplete"
									:deletable-chips="true"
									:items="eventTypeOptions"
									label="Types"
									:multiple="true"
									:small-chips="true"
									v-model="filterByTypes"/>
				</div>

				<!--Attendance-->
				<page-break-title class="mt-8" title="Attendance"/>
				<div class="d-flex">

					<!--Less Than-->
					<app-form-field form-type="textInput"
									class="mt-4 pr-2"
									label="Less Than"
									style="width: 100%"
									type="number"
									v-model="filterByAttendanceLessThan"/>

					<!--More Than-->
					<app-form-field form-type="textInput"
									class="mt-4 pl-2"
									label="More Than"
									style="width: 100%"
									type="number"
									v-model="filterByAttendanceMoreThan"/>
				</div>

			</div>
		</filter-panel>

		<!--Right Side --------------------------------------------------------------------------------------------- -->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">

			<div v-if="isRightPanelVisible"
				 class="appGrey formShadow"
				 style="position:absolute; top:0; bottom: 0; right: 0; overflow: auto"
				 :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 90%'">

				<!--Header-->
				<div class="d-flex align-center primary pa-4">
					<app-text color="appWhite" size="normal">{{ selectedItem?.eventName || 'New' }}</app-text>
					<v-spacer/>
					<app-icon @click.native="closeRightPanel" class="cursorPointer" color="white" icon="close"
							  size="32"/>
				</div>

				<!--Action Bar-->
				<div class="d-flex align-center pa-4">

					<!--Status Icon-->
					<app-icon v-if="selectedItem?.eventStatus === 'Draft'"
							  color="red" icon="draft" size="48" :title="selectedItem?.eventStatus"/>
					<app-icon v-if="selectedItem?.eventStatus === 'Pending'"
							  color="orange" icon="pending" size="48" :title="selectedItem?.eventStatus"/>
					<app-icon v-if="selectedItem?.eventStatus === 'Live'"
							  color="green" icon="success" size="48" :title="selectedItem?.eventStatus"/>
					<app-icon v-if="selectedItem?.eventStatus === 'Archived'"
							  color="grey6" icon="archived" size="48" :title="selectedItem?.eventStatus"/>

					<v-spacer/>

					<!--Publish Button-->
					<app-btn v-if="fullEventData?.eventData?.entityId && fullEventData?.fullEventData?.length"
							 @click.native="handlePublishEvent"
							 icon="send"
							 :label="selectedItem?.eventIsPublished ? 'RePublish' : 'Publish'"/>

					<edit-icon v-if="tabs === 'event'" @click.native="editItem" :isActive="!isReadOnly"/>
					<delete-icon v-if="tabs === 'event' && (selectedItem?.entityId) && ['Staff-Admin'].includes(MIX_getCurrentUser().userLevel)"
								 @emitDeleteItem="deleteItem(selectedItem?.entityId)"
								 itemName="eventName"
								 :selectedItem="selectedItem"/>

				</div>

				<!--Tabs-->
				<v-tabs v-model="tabs">

					<v-tab href="#event">
						<app-text size="small">Event</app-text>
					</v-tab>

					<v-tab v-if="selectedItem?.entityId" href="#teams">
						<app-text size="small">Teams</app-text>
					</v-tab>

				</v-tabs>

				<!--Tabs Content-->
				<v-tabs-items v-model="tabs">

					<!--Event Form-->
					<v-tab-item value="event">
						<event-form @emitReloadData="emittedReloadPage"
									class="pa-4"
									:fullEventData="fullEventData"
									:formData="selectedItem"
									:isReadOnly="isReadOnly"
									:sitesData="sitesData"/>
					</v-tab-item>

					<!--Teams Form-->
					<v-tab-item value="teams">
						<event-teams-form @emitReloadPage="emittedReloadPage"
										  @emitReloadData="emittedReloadData"
										  class="pa-4"
										  :eventData="selectedItem"
										  :fullEventData="fullEventData"
										  :isReadOnly="isReadOnly"
										  :teamsData="teamsData"/>
					</v-tab-item>

				</v-tabs-items>

			</div>

		</transition>

		<!--Modals ------------------------------------------------------------------------------------------------- -->

		<!--Publishing Dialog-->
		<app-dialog :is-visible="isPublishingDialogVisible">
			<event-publishing-dialog @emitCloseEventPublishingDialog="emittedCloseEventPublishingDialog"
									 @emitPublishEvent="emittedPublishEvent"/>
		</app-dialog>

		<!--Users on Multiple Teams Conflict Dialog-->
		<app-dialog :is-visible="isUsersOnMultipleTeamsConflictDialogVisible">
			<event-users-on-multiple-teams-conflict-dialog @closeDialog="closeUsersOnMultipleTeamsConflictDialog"
														   :conflict-data="usersOnMultipleTeamsConflictData"/>
		</app-dialog>

	</div>
</template>

<script>
import EventMobileCard from "@/views/events/admin/eventMobileCard/EventMobileCard.vue";
import EventForm from "@/views/events/admin/eventForm/EventForm.vue";
import EventTeamsForm from "@/views/events/admin/eventTeamsForm/EventTeamsForm.vue";
import EventPublishingDialog from "@/views/events/admin/eventPublishingDialog/EventPublishingDialog.vue";
import EventUsersOnMultipleTeamsConflictDialog
	from "@/views/events/admin/eventUsersOnMultipleTeamsConflictDialog/EventUsersOnMultipleTeamsConflictDialog.vue";

export default {

	name: "EventsAdmin",

	components: {
		EventUsersOnMultipleTeamsConflictDialog,
		EventPublishingDialog, EventTeamsForm, EventForm, EventMobileCard},

	data: () => ({
		filterByAttendanceMoreThan: null,
		filterByAttendanceLessThan: null,
		filterByStatuses: [],
		filterByVenues: [],
		filterByTypes: [],
		isFiltersPanelVisible: false,
		isLoading: true,
		isPublishingDialogVisible: false,
		isRightPanelVisible: false,
		isReadOnly: false,
		isUsersOnMultipleTeamsConflictDialogVisible: false,
		moreActionsMenuOption: [
			{name: 'Export', icon: 'export'}
		],
		searchByEvent: '',
		searchGroup: '',
		searchType: '',
		selectedItem: {},
		tableHeaders: [
			{text: 'ID', value: 'id', align: 'left', sortable: false, hidden: true},
			{text: 'Status', value: 'eventStatus', align: 'center', sortable: false, width: '10px'},
			{text: 'Event', value: 'eventName', align: 'left', sortable: false},
			{text: 'Date & Kick Off Time', value: 'eventDate', align: 'left', sortable: false},
			{text: 'Type', value: 'eventType', align: 'left', sortable: false},
			{text: 'Group', value: 'eventGroup', align: 'left', sortable: false},
			{text: '', value: 'action', align: 'right', sortable: false, width: '48px'},
		],
		tabs: '',
		usersOnMultipleTeamsConflictData: [],

		// Data
		fullEventData: {},
		eventsData: [],
		sitesData: [],
		teamsData: [],
	}),

	computed: {

		/**
		 * Computed Export CSV
		 *
		 * Return the data and headers for the CSV export
		 *
		 * @returns {{headers: {}, data: *[]}}
		 */
		computedExportCSV() {
			const t = this
			let data = []
			let headers = {}

			// Add the readable headers for the CSV columns
			headers = {
				entityId: 'ID',
				eventName: 'Name',
				eventDate: 'Date',
				eventStartTime: 'Start Time',
				eventFinishTime: 'Finish Time',
				eventVenue: 'Venue',
				eventType: 'Type',
				eventGroup: 'Group',
				eventAttendance: 'Attendance',
				eventIsPublished: 'Is Published',
				eventStatus: 'Status',
				eventStewardsStartTime: 'Stewards Start Time',
				eventStewardsFinishTime: 'Stewards Finish Time',
				eventStewardsShiftLength: 'Stewards Shift Length',
				eventSupervisorsStartTime: 'Supervisors Start Time',
				eventSupervisorsFinishTime: 'Supervisors Finish Time',
				eventSupervisorsShiftLength: 'Supervisors Shift Length',
				eventStaffInstructions: 'Staff Instructions',
			}

			// Add the data
			t.computedTableData.forEach(entry => {

				const DATA_OBJECT = {
					entityId: entry?.entityId,
					eventName: entry?.eventName,
					eventDate: t.MIX_formatDate(entry?.eventDate, 'numeric'),
					eventStartTime: entry?.eventStartTime,
					eventFinishTime: entry?.eventFinishTime,
					eventVenue: entry?.eventVenue,
					eventType: entry?.eventType,
					eventGroup: entry?.eventGroup,
					eventAttendance: entry?.eventAttendance,
					eventIsPublished: entry?.eventIsPublished,
					eventStatus: entry?.eventStatus,
					eventStewardsStartTime: entry?.eventStewardsStartTime,
					eventStewardsFinishTime: entry?.eventStewardsFinishTime,
					eventStewardsShiftLength: entry?.eventStewardsShiftLength,
					eventSupervisorsStartTime: entry?.eventSupervisorsStartTime,
					eventSupervisorsFinishTime: entry?.eventSupervisorsFinishTime,
					eventSupervisorsShiftLength: entry?.eventSupervisorsShiftLength,
					eventStaffInstructions: entry?.eventStaffInstructions,
				}

				data.push(DATA_OBJECT)
			})

			return {headers, data}
		},

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this
			const CURRENT_USER_DATA = t.MIX_getCurrentUser()
			let headers = t.tableHeaders

			// Remove hidden headers
			headers = headers.filter(h => !h.hidden)

			// Only those with access can view the actions column
			if (!['Staff-Admin', 'Staff-Manager'].includes(CURRENT_USER_DATA.userLevel)) headers = headers.filter(h => h.value !== 'action')

			return headers
		},

		/**
		 * Computed Number of Active Filters
		 *
		 * Count the number of active filters to display in the filters button badge.
		 *
		 * @returns {*}
		 */
		computedNumberOfActiveFilters() {
			const t = this
			return [
				t.filterByStatuses.length,
				t.filterByVenues.length,
				t.filterByTypes.length,
				t.filterByAttendanceLessThan ? 1 : 0,
				t.filterByAttendanceMoreThan ? 1 : 0
			].reduce((acc, curr) => acc + curr, 0)
		},

		/**
		 * Computed Table Data
		 *
		 * Sort and return the form data.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedTableData() {
			const t = this
			let eventsData = t.eventsData

			// Search by Name
			if (t.searchByEvent) {
				const SEARCH_EVENT = t.searchByEvent.toUpperCase()
				eventsData = eventsData.filter(e => {
					const EVENT_NAME = e.eventName.toUpperCase()
					return EVENT_NAME.includes(SEARCH_EVENT)
				})
			}

			// Hide "Archived" by default
			if (!t.filterByStatuses.includes('Archived')) eventsData = eventsData.filter(item => item.eventStatus !== 'Archived')

			// Filter by Statuses
			if (t.filterByStatuses.length) eventsData = eventsData.filter(item => t.filterByStatuses.includes(item.eventStatus))

			// Filter by Venues
			if (t.filterByVenues.length) eventsData = eventsData.filter(item => t.filterByVenues.includes(item.eventVenue))

			// Filter by Types
			if (t.filterByTypes.length) eventsData = eventsData.filter(item => t.filterByTypes.includes(item.eventType))

			// Filter by Attendance within a range
			if (t.filterByAttendanceLessThan && t.filterByAttendanceMoreThan) {
				eventsData = eventsData.filter(item => item.eventAttendance < t.filterByAttendanceLessThan && item.eventAttendance > t.filterByAttendanceMoreThan)
			} else {

				// Filter by Attendance "Less Than"
				if (t.filterByAttendanceLessThan) eventsData = eventsData.filter(item => item.eventAttendance < t.filterByAttendanceLessThan)

				// Filter by Attendance "More Than"
				if (t.filterByAttendanceMoreThan) eventsData = eventsData.filter(item => item.eventAttendance > t.filterByAttendanceMoreThan)
			}

			eventsData = eventsData.sort((a, b) => a.eventDate > b.eventDate ? 1 : -1)

			return eventsData
		},

	},

	methods: {

		checkForUsersOnMultipleTeams() {
			const t = this
			const FULL_EVENT_DATA = t.fullEventData // {eventData: {}, fileData: {}, fullEventData: [{eventTeamData: {}, fileData: {}, teamUsersData: [{eventUserData: {}, userData: {}}]}]}
			const TEAMS_DATA = t.teamsData
			let usersOnMultipleTeams = []
			let processedUserNames = new Set()

			// Extract USERS_DATA and EVENT_TEAMS_DATA from FULL_EVENT_DATA
			const USERS_DATA = FULL_EVENT_DATA.fullEventData.flatMap(event => event.teamUsersData.map(teamUser => teamUser.userData))
			const EVENT_TEAMS_DATA = FULL_EVENT_DATA.fullEventData.flatMap(event => event.eventTeamData)

			// Create a map for faster lookups
			const TEAMS_DATA_MAP = new Map(TEAMS_DATA.map(td => [td.entityId, td.teamName]))

			// Helper function to parse time strings into minutes
			const parseTime = timeStr => {
				const [hours, minutes] = timeStr.split(':').map(Number)
				return hours * 60 + minutes
			}

			// Helper function to get the start and finish times for a user on a specific team
			const getUserTimes = (event, team, user) => {
				const ROLE = user.userRole
				let startTime, finishTime

				// Event-level times
				if (ROLE === 'User') {
					startTime = event.eventStewardsStartTime
					finishTime = event.eventStewardsFinishTime
				} else {
					startTime = event.eventSupervisorsStartTime
					finishTime = event.eventSupervisorsFinishTime
				}

				// Override with team-level times if they exist
				if (team) {
					if (ROLE === 'User') {
						startTime = team.eventTeamStewardsStartTime || startTime
						finishTime = team.eventTeamStewardsFinishTime || finishTime
					} else {
						startTime = team.eventTeamSupervisorsStartTime || startTime
						finishTime = team.eventTeamSupervisorsFinishTime || finishTime
					}
				}

				// Override with user-level times if they exist
				if (user.eventUserStartTime) {
					startTime = user.eventUserStartTime
					finishTime = user.eventUserFinishTime
				}

				// return { startTime: parseTime(startTime), finishTime: parseTime(finishTime) }
				return { startTime, finishTime }
			}

			USERS_DATA.forEach(user => {

				// Check if user has already been processed to avoid duplicates
				if (processedUserNames.has(user.userName)) return

				// Filter user's teams from event teams data
				const USERS_EVENT_TEAMS = EVENT_TEAMS_DATA.filter(et => user.userTeams.includes(et.eventTeamTeamId))

				// If user is on multiple teams
				if (USERS_EVENT_TEAMS.length > 1) {
					let conflict = false
					let userTeamTimes = []

					// Collect user times for each team
					USERS_EVENT_TEAMS.forEach(team => {
						const event = FULL_EVENT_DATA.eventData
						const times = getUserTimes(event, team, user)
						userTeamTimes.push({
							teamName: TEAMS_DATA_MAP.get(team.eventTeamTeamId) || '',
							startTime: times.startTime,
							finishTime: times.finishTime,
							parsedStartTime: parseTime(times.startTime),
							parsedFinishTime: parseTime(times.finishTime)
						})
					})

					// Check for time conflicts
					for (let i = 0; i < userTeamTimes.length; i++) {
						for (let j = i + 1; j < userTeamTimes.length; j++) {
							const t1 = userTeamTimes[i]
							const t2 = userTeamTimes[j]
							if ((t1.startTime < t2.finishTime && t1.finishTime > t2.startTime) ||
								(t2.startTime < t1.finishTime && t2.finishTime > t1.startTime)) {
								conflict = true
								break
							}
						}
						if (conflict) break
					}

					if (conflict) {

						usersOnMultipleTeams.push({
							userName: user.userName,
							teamData: userTeamTimes.map(times => ({
								teamName: times.teamName,
								startTime: times.startTime,
								finishTime: times.finishTime
							}))
						})

						// Mark this user as processed
						processedUserNames.add(user.userName)
					}
				}
			})

			// Sort the users by name
			usersOnMultipleTeams.sort((a, b) => a.userName < b.userName ? -1 : 1)

			t.usersOnMultipleTeamsConflictData = usersOnMultipleTeams
			return usersOnMultipleTeams
		},

		/**
		 * Clear Filters
		 *
		 * Clear all the filters.
		 */
		clearFilters() {
			const t = this

			t.filterByAttendanceLessThan = null
			t.filterByAttendanceMoreThan = null
			t.filterByStatuses = []
			t.filterByVenues = []
			t.filterByTypes = []
		},

		closePublishingDialog() {
			const t = this

			t.isPublishingDialogVisible = false
		},

		/**
		 * Close Right Panel
		 *
		 * Close the right panel.
		 */
		closeRightPanel() {
			const t = this

			t.tabs = ''
			t.selectedItem = {}
			t.isRightPanelVisible = false
		},

		/**
		 * Close Users On Multiple Teams Conflict Dialog
		 *
		 * Close the dialog.
		 */
		closeUsersOnMultipleTeamsConflictDialog() {
			const t = this

			t.isUsersOnMultipleTeamsConflictDialogVisible = false
		},

		/**
		 * Delete Event
		 *
		 * Delete an Event and all associated records.
		 *  - Delete event
		 * 	- Delete eventTeams
		 * 	- Delete EventUsers
		 * 	- Delete Accreditations // TODO - not yet implemented
		 * 	- Notify all users of the event deletion
		 * 	  - Stewards
		 * 	  - Accreditation users // TODO - not yet implemented
		 *
		 * @params {string} id - the id of the event to delete
		 */
		async deleteItem(id) {
			const t = this

			const RESPONSE = await t.MIX_redis_deleteEvent(id)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				t.$sharedState.errorMessage = 'The was a problem deleting the event, please try again.'
				return
			}

			// Reload the data
			await t.loadData()
			t.closeRightPanel()
		},

		/**
		 * Edit Item
		 *
		 * Toggle the editability of the form.
		 */
		editItem() {
			const t = this

			t.isReadOnly = !t.isReadOnly
		},

		emittedCloseEventPublishingDialog() {
			const t = this

			t.isPublishingDialogVisible = false
		},

		emittedPublishEvent() {
			const t = this

			t.closePublishingDialog()
			t.publishEvent()
			t.closeRightPanel()
		},

		/**
		 * Emitted Reload Data
		 *
		 * Reload the data to refresh the page.
		 */
		emittedReloadData() {
			const t = this

			t.loadData()

			t.loadFullEventData(t.selectedItem.entityId)
		},

		/**
		 * Emitted Reload Page
		 *
		 * Close the right panel and reload the data to refresh the page.
		 */
		async emittedReloadPage() {
			const t = this

			t.closeRightPanel()
			await t.loadData()
		},

		/**
		 * Export Data
		 *
		 * Export the data to CSV.
		 */
		exportData() {
			const t = this

			t.MIX_exportDocuments(t.computedExportCSV.headers, 'Events', t.computedExportCSV.data)
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this

			if (selection === 'Export') t.exportData()
		},

		/**
		 * Handle Filter Status Selection
		 *
		 * Add or remove the selected status from the filterStatuses array.
		 *
		 * @param status {string} the status to add or remove
		 */
		handleFilterStatusSelection(status) {
			const t = this

			if (t.filterByStatuses.includes(status)) t.filterByStatuses = t.filterByStatuses.filter(s => s !== status)
			else t.filterByStatuses.push(status)
		},

		handlePublishEvent() {
			const t = this

			// Check for users on multiple teams with conflicting times
			const USERS_ON_MULTIPLE_TEAMS = t.checkForUsersOnMultipleTeams()

			// If there are users on multiple teams with conflicting times, show the dialog
			if (USERS_ON_MULTIPLE_TEAMS.length) t.isUsersOnMultipleTeamsConflictDialogVisible = true

			// Otherwise, continue with publishing the event
			if (!USERS_ON_MULTIPLE_TEAMS.length) t.openPublishingDialog()
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadEventsData(),
				t.loadSitesData(),
				t.loadTeamsData(),
			])

			t.isLoading = false
		},

		/**
		 * Load Events Data
		 *
		 * Load Events data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadEventsData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields(
				'Event',
				[],
				[]
			)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Events: ', RESPONSE.error)
				return
			}

			// Assign the data to the state
			t.eventsData = RESPONSE.data
		},

		/**
		 * Load Sites Data
		 *
		 * Load Sites data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadSitesData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields(
				'Site',
				[],
				[{field: 'siteName'}]
			)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Sites: ', RESPONSE.error)
				return
			}

			// Assign the data to the state
			t.sitesData = RESPONSE.data
		},

		/**
		 * Load Full Event Data
		 *
		 * Load all the data require for the Events from the database.
		 *
		 * @param eventId {string} the ID of the Event
		 * @returns {Promise<void>}
		 */
		async loadFullEventData(eventId) {
			const t = this

			t.isLoading = true

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getFullEventDataByEventId(eventId)

			t.isLoading = false

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Events: ', RESPONSE.error)
				return
			}

			// Assign the data to the state
			t.fullEventData = RESPONSE.data
		},

		/**
		 * Load Teams Data
		 *
		 * Load Teams data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadTeamsData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields(
				'Team',
				[],
				[
					{field: 'teamName'},
					{field: 'teamQuota'}
				]
			)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Teams: ', RESPONSE.error)
				return
			}

			// Assign the data to the state
			t.teamsData = RESPONSE.data
		},

		openPublishingDialog() {
			const t = this

			t.isPublishingDialogVisible = true
		},

		/**
		 * Open Form
		 *
		 * Open the form dialog.
		 * If no item is passed in, the form will open as new (blank).
		 *
		 * @param item the selected key
		 */
		async openRightPanel(item) {
			const t = this

			// If an item is passed in, load the full data
			if (item?.entityId) {
				t.isReadOnly = true
				await t.loadFullEventData(item.entityId)
			}
			// Otherwise, reset the form data
			else {
				t.isReadOnly = false
				t.fullEventData = {}
			}

			t.selectedItem = item

			t.isRightPanelVisible = true
		},

		async publishEvent() {
			const t = this
			const EVENT_DATA = t.selectedItem

			t.isLoading = true

			// Create EventUser docs
			const RESPONSE = await t.MIX_redis_publishEvent(EVENT_DATA.entityId)

			t.isLoading = false

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating Event Users: ', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Event Published'
		},

		/**
		 * Toggle Filters Visibility
		 *
		 * Toggle the visibility of the filters panel.
		 */
		toggleFiltersVisibility() {
			const t = this

			t.isFiltersPanelVisible = !t.isFiltersPanelVisible
		},

	},

	async mounted() {
		const t = this

		await t.loadData()
	},

}
</script>

<style scoped>
</style>
