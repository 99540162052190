<template>
	<div>

		<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

		<div v-else>

			<!--Header | View Type-->
			<div :class="$vuetify.breakpoint.width < 600 ? '' : 'd-flex align-center justify-space-between'">

				<!--Header-->
				<page-title icon="car"
							info="Create and manage your parking."
							title="Parking"/>

				<!--View Type-->
				<app-form-field form-type="select"
								:class="$vuetify.breakpoint.width < 600 ? 'mt-4' : ''"
								:clearable="true"
								:items="['Dashboard', 'Car Parks', 'Parking Spaces']"
								label="Select a View"
								style="min-width: 216px"
								v-model="viewType"/>

			</div>

			<v-divider class="mt-4"/>

			<div v-if="!viewType" class="d-flex align-center justify-center"
				 :style="$vuetify.breakpoint.width < 600 ? 'height: 50vh' : 'height: 70vh'">
				<div>
					<page-start-information title="What do you want to do?"
											:points="['Select a Type to get started']"/>
				</div>
			</div>

			<!--Dashboard-->
			<div v-if="viewType === 'Dashboard'" class="mt-4">

				<!--Event Selection-->
				<app-form-field form-type="select"
								:clearable="false"
								:items="eventsData"
								item-text="eventName"
								label="Select an Event to view the current figures"
								:return-object="true"
								v-model="selectedDashboardEvent"/>

				<!--No parking message - if an Event has been selected, but there is no parking data-->
				<div v-if="selectedDashboardEvent?.entityId && !eventParkingDashboardData?.length">
					<v-divider class="my-4"/>
					<app-text class="text-center" color="grey9">You have no Parking for this Event</app-text>
				</div>

				<parking-dashboard :dashboardData="eventParkingDashboardData"/>

			</div>

			<!--Car Parks ------------------------------------------------------------------------------------------ -->
			<car-parks-page v-if="viewType === 'Car Parks'"/>

			<!--Parking Spaces ------------------------------------------------------------------------------------- -->
			<parking-spaces-page v-else-if="viewType === 'Parking Spaces'"/>

			<!--Configuration-->
			<div v-else-if="viewType === 'Configuration'">
				<parking-configuration class="mt-4"/>
			</div>

		</div>

	</div>
</template>

<script>

import CarParksPage from "@/views/parking/admin/parkingCarParks/CarParksPage.vue";
import ParkingSpacesPage from "@/views/parking/admin/parkingSpaces/ParkingSpacesPage.vue";
import ParkingDashboardCard from "@/views/parking/components/ParkingDashboardCard.vue";
import ParkingDashboard from "@/views/parking/admin/parkingDashboard/ParkingDashboard.vue";
import ParkingConfiguration from "@/views/parking/admin/parkingConfiguration/ParkingConfiguration.vue";

export default {

	name: "ParkingAdmin",

	components: {ParkingConfiguration, ParkingDashboard, ParkingDashboardCard, ParkingSpacesPage, CarParksPage},

	data: () => ({
		dashboardInterval: null,
		isLoading: true,
		selectedDashboardEvent: {},
		viewType: '',

		// Data
		eventsData: [],
		eventParkingDashboardData: [],
	}),

	computed: {},

	methods: {

		/**
		 * Clear Dashboard Data
		 *
		 * Clear the dashboard data and stop the interval.
		 * Runs when the viewType isn't Dashboard, and when the component is destroyed.
		 */
		clearDashboardData() {
			const t = this
			t.selectedDashboardEvent = {}
			t.eventParkingDashboardData = []
			clearInterval(t.dashboardInterval)
		},

		/**
		 * Get Total Color
		 *
		 * Get the color for the total arrived/required.
		 *
		 * @param item - The item to get the color for
		 * @returns {string} - The color
		 */
		getTotalColor(item) {
			const t = this
			let totalArrived = t.getTotalNumberOfVehicles(item).arrived
			let totalRequired = t.getTotalNumberOfVehicles(item).required
			let color = 'grey'

			if (totalArrived < totalRequired) color = 'green'
			if (totalArrived === totalRequired - 1) color = 'orange'
			if (totalArrived === totalRequired || totalArrived > totalRequired) color = 'red'

			return color
		},

		/**
		 * Get Total Number Of Vehicles
		 *
		 * Get the total number of vehicles arrived and required.
		 *
		 * @param item - The item to get the total for
		 * @returns {{arrived: number, required: number}} - The total arrived and required
		 */
		getTotalNumberOfVehicles(item) {
			const PARKING_DATA = item.parkingSpaceData
			let totalArrived = 0
			let totalRequired = 0

			totalArrived += PARKING_DATA.parkingSpaceNumberOfRegularSmallVehiclesArrived + PARKING_DATA.parkingSpaceNumberOfRegularMediumVehiclesArrived + PARKING_DATA.parkingSpaceNumberOfRegularLargeVehiclesArrived
			totalRequired += PARKING_DATA.parkingSpaceNumberOfRegularSmallVehiclesRequired + PARKING_DATA.parkingSpaceNumberOfRegularMediumVehiclesRequired + PARKING_DATA.parkingSpaceNumberOfRegularLargeVehiclesRequired

			return {arrived: totalArrived, required: totalRequired}
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadEventsData(),
			])

			t.isLoading = false
		},

		/**
		 * Load Events Data
		 *
		 * Load the required data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadEventsData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getAll('event')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Events data: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem loading this page, please try again.'
				return
			}

			// Assign the data
			t.eventsData = RESPONSE.data
		},

		/**
		 * Load Event Parking Dashboard Data
		 *
		 * Load the parking data for the Parking Dashboard, by Event ID.
		 *
		 * @returns {Promise<void>}
		 */
		async loadEventParkingDashboardData() {
			const t = this
			const SELECTED_EVENT = t.selectedDashboardEvent

			t.isLoading = true

			const RESPONSE = await t.MIX_redis_getParkingDashboardDataByEventId(SELECTED_EVENT.entityId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Event Parking Dashboard data: ', RESPONSE.error)
				t.isLoading = false
				t.$sharedState.errorMessage = 'There was a problem loading the dashboard, please try again.'
				return
			}

			// Assign the data
			t.eventParkingDashboardData = RESPONSE.data

			// Sort the data by Car Park Name
			t.eventParkingDashboardData = t.eventParkingDashboardData.sort((a, b) => a.carParkData.carParkName > b.carParkData.carParkName ? 1 : -1)

			t.isLoading = false

			// Start the interval to update the data every 1 minute.
			// Stops when the component is changed or destroyed.
			t.dashboardInterval = setInterval(async () => {
				const RESPONSE = await t.MIX_redis_getParkingDashboardDataByEventId(SELECTED_EVENT.entityId)

				// Handle any errors
				if (RESPONSE.hasErrors) {
					console.error('Error getting Event Parking Dashboard data: ', RESPONSE.error)
					t.isLoading = false
					t.$sharedState.errorMessage = 'There was a problem loading the dashboard, please try again.'
					return
				}

				// Assign the data
				t.eventParkingDashboardData = RESPONSE.data

				// Sort the data by Car Park Name
				t.eventParkingDashboardData = t.eventParkingDashboardData.sort((a, b) => a.carParkData.carParkName > b.carParkData.carParkName ? 1 : -1)
			}, 60 * 1000)
		},

	},

	watch: {

		/**
		 * View Type
		 *
		 * When the view type changes, reset the selected dashboard event.
		 *
		 * @param viewType - The new view type
		 */
		viewType(viewType) {
			const t = this

			if (viewType !== 'Dashboard') t.clearDashboardData()
		},

		/**
		 * Selected Dashboard Event
		 *
		 * When the selected dashboard event changes, load the event parking data.
		 */
		selectedDashboardEvent() {
			const t = this

			if (t.viewType === 'Dashboard') t.loadEventParkingDashboardData()
		}
	},

	created() {
		const t = this

		t.loadData()
	},

	beforeDestroy() {
		const t = this

		t.clearDashboardData()
	}

}
</script>

<style scoped>
</style>
