<template>
	<div class="appGrey pa-4">

		{{ computedInit }}

		<!--Instructions-->
		<app-text class="mb-4">
			Complete the form to mark
			<strong>{{ selectedItem.userData.userName }}</strong>
			as a No-Show to say they didn't turn up when they were expected to.
		</app-text>

		<!--Instructions - Already Marked-->
		<app-text v-if="selectedItem[`${viewType}Data`][`${viewType}IsNoShow`]" class="mb-4" color="red">
			<strong>{{ selectedItem.userData.userName }}</strong>
			has already been marked as a No Show, but you can update the notes if you wish.
		</app-text>

		<!--Notes-->
		<app-form-field form-type="textArea"
						:error="errors.noShowNotes"
						:error-message="errors.noShowNotesErrorMessage"
						label="Notes"
						placeholder="Please leave some notes about why they're being marked a No-Show."
						v-model="noShowNotes"/>

		<v-divider class="mt-4"/>

		<!--Action Buttons-->
		<div class="d-flex justify-space-between mt-4">

			<!--Cancel-->
			<app-btn @click.native="closeDialog"
					 color="grey"
					 icon="cancel"
					 label="Cancel"/>

			<!--Yes-->
			<app-btn @click.native="handleYesButton"
					 color="green"
					 icon="success"
					 label="No Show"/>

		</div>

	</div>
</template>

<script>
export default {

	name: "EventControlPanelNoShowDialog",

	props: ['selectedItem', 'viewType'],

	data: () => ({
		errors: {
			noShowNotes: false,
			noShowNotesErrorMessage: '',
		},
		noShowNotes: '',
	}),

	computed: {

		/**
		 * Computed Init
		 *
		 * Initialise the component.
		 */
		computedInit() {
			const t = this
			const VIEW_TYPE = t.$props.viewType
			const NO_SHOW_NOTES = t.$props.selectedItem[`${VIEW_TYPE}Data`][`${VIEW_TYPE}IsNoShowNotes`]

			t.noShowNotes = NO_SHOW_NOTES || ''
		},

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Close Dialog
		 *
		 * Emit back to the parent to close the dialog box.
		 */
		closeDialog() {
			const t = this

			t.$emit('emitCloseEventControlPanelNoShowDialog')
		},

		/**
		 * Handle Yes Button
		 *
		 * Emit back to the parent to update the No Show Notes.
		 */
		handleYesButton() {
			const t = this

			// Only continue if the form is valid.
			if (!t.validateForm()) return

			t.$emit('emitEventControlPanelNoShow', {noShowNotes: t.noShowNotes})
		},

		/**
		 * Validate Form
		 *
		 * Validate the form and return true or false if it is valid, or not.
		 *
		 * @returns {boolean}
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			if (!t.noShowNotes) {
				t.errors.noShowNotes = true
				t.errors.noShowNotesErrorMessage = 'Notes are required.'
			}

			return !Object.values(t.errors).includes(true)
		}

	},

}
</script>

<style scoped>

</style>
