<template>
	<div>

		<parking-admin v-if="MIX_getCurrentUser().userType === 'Staff'"/>

		<parking-steward v-if="MIX_getCurrentUser().userType === 'Steward'"/>

		<parking-organisation v-if="MIX_getCurrentUser().userType === 'Organisation'"/>

	</div>
</template>

<script>

import ParkingAdmin from "@/views/parking/admin/ParkingAdmin.vue";
import ParkingSteward from "@/views/parking/steward/ParkingSteward.vue";
import ParkingOrganisation from "@/views/parking/organisation/ParkingOrganisation.vue";

export default {

	name: "Parking",

	components: {ParkingSteward, ParkingAdmin, ParkingOrganisation},

	data: () => ({}),

	methods: {},

}
</script>

<style scoped>

</style>
