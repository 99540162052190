<template>
	<div class="parkingDashboardGrid-container">

		<div v-for="item in dashboardData" class="appWhite rounded-lg mt-4 pa-4">

			<!--Car Park Name-->
			<app-text color="primary" size="medium-bold">{{ item.carParkData.carParkName }}</app-text>

			<!--<v-divider v-if="$vuetify.breakpoint.width >= 624" class="my-4"/>-->

			<!--Parking Figures-->
			<!--<div v-if="$vuetify.breakpoint.width >= 512" class="d-flex align-center justify-center mt-2">-->

			<!--	&lt;!&ndash;Available/Unavailable&ndash;&gt;-->
			<!--	<div>-->
			<!--		<div v-for="type in availableVsOutOfAction" :key="type.key" class="d-flex align-center justify-end">-->
			<!--			<div class="d-flex ml-1">-->
			<!--				<app-text color="grey9" size="small">Available</app-text>-->
			<!--				<app-text class="text-right mx-1" color="green" size="small-bold" style="width: 2ch">-->
			<!--					{{ item.carParkData[type.availableKey] }}-->
			<!--				</app-text>-->
			<!--				<app-text color="greyD" size="small">|</app-text>-->
			<!--				<app-text class="text-right mx-1" color="red" size="small" style="width: 2ch">-->
			<!--					{{ item.carParkData[type.outOfActionKey] }}-->
			<!--				</app-text>-->
			<!--				<app-text color="grey9" size="small">Out of Action</app-text>-->
			<!--			</div>-->
			<!--		</div>-->
			<!--	</div>-->

			<!--	<v-divider class="greyD mx-4" vertical/>-->

			<!--	&lt;!&ndash;Regular/Accessible Labels&ndash;&gt;-->
			<!--	<div class="d-flex flex-column align-center">-->
			<!--		<app-text color="grey9" size="small-bold">Regular</app-text>-->
			<!--		<app-text color="grey9" size="small-bold">Accessible</app-text>-->
			<!--	</div>-->

			<!--	<v-divider class="greyD mx-4" vertical/>-->

			<!--	&lt;!&ndash;Required/Arrived&ndash;&gt;-->
			<!--	<div>-->
			<!--		<div v-for="type in requiredVsArrived" :key="type.key" class="d-flex align-center justify-start">-->
			<!--			<div class="d-flex ml-1">-->
			<!--				<app-text color="grey9" size="small">Arrived</app-text>-->
			<!--				<app-text class="text-right mx-1" size="small-bold" style="width: 2ch">-->
			<!--					{{ item.parkingSpaceData[type.arrivedKey] }}-->
			<!--				</app-text>-->
			<!--				<app-text color="greyD" size="small">|</app-text>-->
			<!--				<app-text class="text-right mx-1" size="small-bold" style="width: 2ch">-->
			<!--					{{ item.parkingSpaceData[type.requiredKey] }}-->
			<!--				</app-text>-->
			<!--				<app-text color="grey9" size="small">Required</app-text>-->
			<!--			</div>-->
			<!--		</div>-->
			<!--	</div>-->

			<!--</div>-->

			<v-divider class="my-4"/>

			<!--Regular-->
			<div style="display: grid; grid-template-columns: 1fr auto 1fr auto 1fr">

				<!-- Small -->
				<parking-dashboard-card size="Small"
										icon="car"
										:arrived="item.parkingSpaceData.totalNumberOfRegularSmallVehiclesArrived"
										:required="item.parkingSpaceData.totalNumberOfRegularSmallVehiclesRequired"/>

				<v-divider vertical/>

				<!-- Medium -->
				<parking-dashboard-card size="Medium"
										icon="minibus"
										:arrived="item.parkingSpaceData.totalNumberOfRegularMediumVehiclesArrived"
										:required="item.parkingSpaceData.totalNumberOfRegularMediumVehiclesRequired"/>

				<v-divider vertical/>

				<!-- Large -->
				<parking-dashboard-card size="Large"
										icon="truck"
										:arrived="item.parkingSpaceData.totalNumberOfRegularLargeVehiclesArrived"
										:required="item.parkingSpaceData.totalNumberOfRegularLargeVehiclesRequired"/>
			</div>

			<v-divider class="my-4"/>

			<!--Disabled-->
			<div style="display: grid; grid-template-columns: 1fr auto 1fr auto 1fr">

				<!-- Small -->
				<parking-dashboard-card size="Small"
										icon="car"
										:disabled="true"
										:arrived="item.parkingSpaceData.totalNumberOfDisabledSmallVehiclesArrived"
										:required="item.parkingSpaceData.totalNumberOfDisabledSmallVehiclesRequired"/>

				<v-divider vertical/>

				<!-- Medium -->
				<parking-dashboard-card size="Medium"
										icon="minibus"
										:disabled="true"
										:arrived="item.parkingSpaceData.totalNumberOfDisabledMediumVehiclesArrived"
										:required="item.parkingSpaceData.totalNumberOfDisabledMediumVehiclesRequired"/>

				<v-divider vertical/>

				<!-- Large -->
				<parking-dashboard-card size="Large"
										icon="truck"
										:disabled="true"
										:arrived="item.parkingSpaceData.totalNumberOfDisabledLargeVehiclesArrived"
										:required="item.parkingSpaceData.totalNumberOfDisabledLargeVehiclesRequired"/>

			</div>

		</div>

	</div>
</template>

<script>
import ParkingDashboardCard from "@/views/parking/components/ParkingDashboardCard.vue";

export default {

	name: "ParkingDashboard",

	components: {ParkingDashboardCard},

	props: ['dashboardData'],

	data: () => ({
		requiredVsArrived: [
			{
				key: 'regular',
				label: 'Regular',
				color: 'grey9',
				size: 'small',
				arrivedKey: 'totalNumberOfRegularVehiclesArrived',
				requiredKey: 'totalNumberOfRegularVehiclesRequired'
			},
			{
				key: 'disabled',
				label: 'Accessible',
				color: 'grey9',
				size: 'small',
				arrivedKey: 'totalNumberOfDisabledVehiclesArrived',
				requiredKey: 'totalNumberOfDisabledVehiclesRequired'
			},
		],
		availableVsOutOfAction: [
			{
				key: 'regular',
				label: 'Regular',
				availableKey: 'totalNumberOfRegularSpacesAvailable',
				outOfActionKey: 'totalNumberOfRegularSpacesOutOfAction'
			},
			{
				key: 'disabled',
				label: 'Accessible',
				availableKey: 'totalNumberOfDisabledSpacesAvailable',
				outOfActionKey: 'totalNumberOfDisabledSpacesOutOfAction'
			}
			// Add more parking types if needed
		]
	}),

}
</script>

<style scoped>
.parkingDashboardGrid-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(343px, 1fr));
	grid-gap: 16px;
}
</style>
