<template>
	<div class="appGrey pa-4">

		{{ computedInit }}

		<!--PAY GRADE ---------------------------------------------------------------------------------------------- -->
		<form-section-title title="Pay Grade"/>
		<app-form-field form-type="textInput"
						:disabled="isReadOnly"
						:error="errors.payRatePayGrade"
						:error-message="errors.payRatePayGradeErrorMessage"
						label="Pay Rate Pay Grade"
						v-model.trim="form.payRatePayGrade"/>

		<!--HOURLY RATES ------------------------------------------------------------------------------------------- -->
		<form-section-title class="mt-8" title="Hourly Rates"/>
		<div class="d-flex align-center">
			<div class="mr-4">

				<!--Hourly Rate-->
				<div class="d-flex align-center">
					<app-text>£</app-text>
					<app-form-field form-type="textInput"
									class="mx-4"
									:disabled="isReadOnly"
									:error="errors.payRateHourlyRate"
									:error-message="errors.payRateHourlyRateErrorMessage"
									label="Hourly Rate"
									style="width: 144px"
									v-model.trim="form.payRateHourlyRate"/>
				</div>

				<!--payRateHolidayRate-->
				<div class="d-flex align-center mt-4">
					<app-text>£</app-text>
					<app-form-field form-type="textInput"
									class="mx-4"
									:disabled="isReadOnly"
									:error="errors.payRateHolidayRate"
									:error-message="errors.payRateHolidayRateErrorMessage"
									label="Holiday Rate"
									style="width: 144px"
									v-model.trim="form.payRateHolidayRate"/>
				</div>

			</div>

			<!--Total Hourly Rate-->
			<div class="d-flex align-center">
				<app-text>£</app-text>
				<app-form-field form-type="textInput"
								class="mx-4"
								:disabled="true"
								:error="errors.payRateHourlyRate"
								:error-message="errors.payRateHourlyRateErrorMessage"
								label="Hourly Total"
								style="width: 144px"
								:value="computedHourlyTotal"/>
			</div>

		</div>

		<!--FEES and ALLOWANCES ------------------------------------------------------------------------------------ -->
		<form-section-title class="mt-8" title="Fees and Allowances"/>

		<!--Fees-->
		<div class="d-flex align-center">
			<app-text>£</app-text>
			<app-form-field form-type="textInput"
							class="mx-4"
							:disabled="isReadOnly"
							:error="errors.payRateFee"
							:error-message="errors.payRateFeeErrorMessage"
							label="Shift Fee"
							style="width: 144px"
							v-model.trim="form.payRateFee"/>
		</div>

		<!--Allowance | Duration-->
		<div class="d-flex align-center mt-4">

			<app-text>£</app-text>

			<!--payRateAllowanceRate-->
			<app-form-field form-type="textInput"
							class="mx-4"
							:disabled="isReadOnly"
							:error="errors.payRateAllowanceRate"
							:error-message="errors.payRateAllowanceRateErrorMessage"
							label="Allowance"
							style="width: 144px"
							v-model.trim="form.payRateAllowanceRate"/>

			<app-text>every</app-text>

			<!--payRateAllowanceDuration-->
			<app-form-field form-type="textInput"
							class="mx-4"
							:disabled="isReadOnly"
							:error="errors.payRateAllowanceDuration"
							:error-message="errors.payRateAllowanceDurationErrorMessage"
							label="Duration"
							style="width: 144px"
							v-model.trim="form.payRateAllowanceDuration"/>

			<app-text>hours</app-text>

		</div>

		<!--Save Button-->
		<div class="d-flex justify-end mt-4">
			<app-btn @click.native="handleSaveButton"
					 color="green"
					 icon="save"
					 label="Save"/>
		</div>

	</div>
</template>

<script>

export default {

	name: "payrollPayRatesForm",

	props: ['formData', 'isReadOnly'],

	data: () => ({
		errors: {
			payRateAllowanceDuration: false,
			payRateAllowanceDurationErrorMessage: '',
			payRateAllowanceRate: false,
			payRateAllowanceRateErrorMessage: '',
			payRateFee: false,
			payRateFeeErrorMessage: '',
			payRateHolidayRate: false,
			payRateHolidayRateErrorMessage: '',
			payRateHourlyRate: false,
			payRateHourlyRateErrorMessage: '',
			payRatePayGrade: false,
			payRatePayGradeErrorMessage: '',
		},
		form: {
			payRateAllowanceDuration: '',
			payRateAllowanceRate: '',
			payRateFee: '',
			payRateHolidayRate: '',
			payRateHourlyRate: '',
			payRatePayGrade: '',

			createdDateTime: 0,
			createdUserId: '',
			createdUserName: '',
			modifiedDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',
			isDeleted: false,
			deletedDateTime: 0,
			deletedUserId: '',
			deletedUserName: ''
		},
	}),

	computed: {

		computedHourlyTotal() {
			const t = this

			return (Number(t.form.payRateHourlyRate) + Number(t.form.payRateHolidayRate))?.toFixed(2)
		},

		computedInit() {
			const t = this
			const FORM_DATA = t.$props.formData

			if (FORM_DATA?.entityId) {
				t.form = FORM_DATA
			}
		}

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		async createItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_create('payRate', t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating payRate: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was an error creating the Pay Rate. Please try again.'
				return
			}

			t.$sharedState.successMessage = 'Pay Rate created'

			t.$emit('refreshData')
		},

		handleSaveButton() {
			const t = this

			if (!t.validateForm()) return

			// New
			if (!t.form?.entityId) t.createItem()

			// Update
			else t.updateItem()
		},

		async updateItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_update('payRate', t.form.entityId, t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating payRate: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was an error updating the Pay Rate. Please try again.'
				return
			}

			t.$sharedState.successMessage = 'Pay Rate updated'

			t.$emit('refreshData')
		},

		/**
		 * Validate Form
		 *
		 * Validate the form as required.
		 *
		 * @returns {boolean} if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Pay Grade
			if (!t.form.payRatePayGrade) {
				t.errors.payRatePayGrade = true
				t.errors.payRatePayGradeErrorMessage = 'Pay Grade is required'
			}

			// Hourly Rate
			t.validateNumericField(t.form.payRateHourlyRate, 'payRateHourlyRate')

			// Holiday Rate
			t.validateNumericField(t.form.payRateHolidayRate, 'payRateHolidayRate')

			// Fee
			t.validateNumericField(t.form.payRateFee, 'payRateFee')

			// Allowance Rate
			t.validateNumericField(t.form.payRateAllowanceRate, 'payRateAllowanceRate')

			// Allowance Duration
			// Must be present
			if (!t.form.payRateAllowanceDuration) {
				t.errors.payRateAllowanceDuration = true
				t.errors.payRateAllowanceDurationErrorMessage = 'Allowance Duration is required'
			}
			// Must be a number
			else if (isNaN(t.form.payRateAllowanceDuration)) {
				t.errors.payRateAllowanceDuration = true
				t.errors.payRateAllowanceDurationErrorMessage = 'Allowance Duration must be a number'
			}
			// Must be a positive number
			else if (Number(t.form.payRateAllowanceDuration) < 0) {
				t.errors.payRateAllowanceDuration = true
				t.errors.payRateAllowanceDurationErrorMessage = 'Allowance Duration must be a positive number'
			}
			// If a decimal point is present, it must contain at least one decimal point
			else if (t.form.payRateAllowanceDuration.includes('.') && t.form.payRateAllowanceDuration.split('.')[1].length < 1) {
				t.errors.payRateAllowanceDuration = true
				t.errors.payRateAllowanceDurationErrorMessage = 'Must contain a number if a decimal is present'
			}

			return !Object.values(t.errors).includes(true)
		},

		validateNumericField(value, fieldName) {
			const t = this

			// Must be present
			if (!value) {
				t.errors[fieldName] = true
				t.errors[`${fieldName}ErrorMessage`] = 'This field is required'
			}

			// Must be a number
			else if (isNaN(value)) {
				t.errors[fieldName] = true
				t.errors[`${fieldName}ErrorMessage`] = 'This field must be a number'
			}

			// Must be a positive number
			else if (Number(value) < 0) {
				t.errors[fieldName] = true
				t.errors[`${fieldName}ErrorMessage`] = 'This field must be a positive number'
			}

			// Must be a valid number with up to two decimal places
			else {
				const REGEX = /^\d+(\.\d{2})?$/
				if (!REGEX.test(value)) {
					t.errors[fieldName] = true
					t.errors[`${fieldName}ErrorMessage`] = 'This field must be a valid number with up to two decimal places'
				}
				else return true
			}

			return false
		}

	},

}
</script>

<style scoped>

</style>
