<template>
	<div class="appGrey pa-4">

		<!--Instructions-->
		<app-text>
			You are about to SWAPP In
			<strong>{{ selectedItem.userData.userName }}</strong>,
			but they will still need to be Signed In to work.
		</app-text>

		<!--Instructions - Already SWAPPed In-->
		<!--ViewType will be one of:
			- eventUser
			- eventExtension
			- eventTransfer
		-->
		<app-text v-if="selectedItem && selectedItem.userData && selectedItem.userData.userSwappStatus === 'In'"
				  class="mt-4" color="red">
			<strong>{{ selectedItem.userData.userName }}</strong>
			was already SWAPPed in at
			<strong>{{ MIX_formatDateTimeToTime(selectedItem.userData.userLastSwappDateTime) }}</strong>.
			Continuing with this will override their SWAPP In time.
		</app-text>

		<v-divider class="mt-4"/>

		<!--Action Buttons-->
		<div class="d-flex justify-space-between mt-4">

			<!--Cancel-->
			<app-btn @click.native="closeDialog"
					 color="grey"
					 icon="cancel"
					 label="Cancel"/>

			<!--Yes-->
			<app-btn @click.native="handleYesButton"
					 color="green"
					 icon="success"
					 label="SWAPP"/>

		</div>

	</div>
</template>

<script>
export default {

	name: "EventControlPanelSwappDialog",

	props: ['selectedItem', 'viewType'],

	methods: {

		closeDialog() {
			const t = this

			t.$emit('emitCloseEventControlPanelSwappDialog')
		},

		handleYesButton() {
			const t = this

			t.$emit('emitEventControlPanelSwapp')
		},

	},

}
</script>

<style scoped>

</style>
