<template>
	<div class="appWhite d-flex align-center rounded-lg pa-4">

		<app-icon v-if="cardData.accreditationEventData?.accreditationEventStatus === 'Live'"
				  class="mr-4" color="green" icon="success" size="32" title="Live"/>
		<app-icon
			v-if="!cardData.accreditationEventData?.entityId || cardData.accreditationEventData?.accreditationEventStatus === 'Pending'"
			class="mr-4" color="orange" icon="pending" size="32" title="Pending"/>

		<div style="width: 100%">

			<app-text color="primary" size="normal-bold">{{ cardData.eventData.eventName }}</app-text>

			<app-text color="grey9" size="small">{{ cardData.eventData.eventType }}</app-text>

			<v-divider class="my-2" aria-colcount="greyD"/>

			<app-text color="grey9" size="small">{{ MIX_formatDate(cardData.eventData.eventDate, 'long') }}</app-text>

		</div>

	</div>
</template>

<script>

export default {

	name: "AccreditationEventMobileCard",

	props: ['cardData'],

	data: () => ({}),

	methods: {},

}
</script>

<style scoped>

</style>
