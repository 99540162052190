<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<!--Page Content-->
	<div v-else class="appGrey pa-4">

		<!--Title-->
		<form-section-title class="mr-4"
							description="Use the add button to add more tasks and use the menu to administer them."
							title="Tasks"/>

		<!--Search | Add Button-->
		<div v-if="!isTaskFormVisible" class="d-flex justify-space-between align-center mt-4">

			<!--Search-->
			<div :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'">
				<app-form-field form-type="textInput"
								append-icon="icons8-search"
								label="Task Name"
								v-model.trim="searchByTaskName"/>
			</div>

			<!--Add Button-->
			<app-btn @click.native="handleAddNewTask"
					 :color="primary"
					 icon="add"
					 :label="$vuetify.breakpoint.width < 600 ? '' : 'Add'"/>

		</div>

		<!--Task Form-->
		<transition  enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">
		<to-do-task-form v-if="isTaskFormVisible"
						 @emitReloadData="emittedReloadData"
						 @closeTaskForm="toggleTaskFormVisibility"
						 class="mt-4"
						 :formData="selectedItem"
						 :listData="selectedList"
						 :isReadOnly="false"
						 :usersData="usersData"/>
		</transition>

		<!--No Tasks Wording-->
		<div v-if="!computedTableData.length" class="d-flex align-center justify-center"
				 :style="$vuetify.breakpoint.width < 600 ? 'height: 50vh' : 'height: 70vh'">
				<div>
					<page-start-information title="There are no tasks yet to display"
											:points="['Add a task to get started']"/>
				</div>
			</div>

		<!--Task Cards-->
			<div v-for="item in computedTableData" v-if="!isTaskFormVisible" :key="item.entityId">
				<v-expansion-panels flat>
					<v-expansion-panel class="rounded-lg mt-4">

						<!--Header-->
						<v-expansion-panel-header>

							<div class="d-flex">

								<!--Incomplete/Complete Checkbox-->
								<div @click="handleIsComplete($event, item)"
									class="appGrey d-flex align-center justify-center rounded-lg mr-4"
									style="aspect-ratio: 1/1; min-height: 56px; max-height: 56px; min-width: 56px; max-width: 56px; color: white; border: 1px solid grey">
									<app-icon :color="item.toDoTaskStatus !== 'Complete' ? 'red' : 'green'"
											:icon="item.toDoTaskStatus !== 'Complete' ? 'close' : 'check'" size="32"/>
								</div>

								<div class="d-flex flex-column justify-space-between">

									<!--Task Private | Task Name-->
									<div class="d-flex align-center">
										<div>
											<app-icon v-if="item.toDoTaskIsPrivate"
														class="mr-2" 
														icon="closedEye" 
														color="primary"/>
											<app-icon v-else 
														class="mr-2"
														icon="eye" 
														color="grey9"/>
										</div>
										<app-text size="normal-bold">{{ item.toDoTaskName }}</app-text>
									</div>
									
									<div  class="d-flex justify-space-between align-center">

										<!--Task Priority-->
										<v-chip class="white--text"
												:color="item.toDoTaskPriority === 'Low' ? 'green'
												:item.toDoTaskPriority === 'Medium' ? 'orange' : 'red'"
												label
												x-small>{{ item.toDoTaskPriority.toUpperCase() }}
										</v-chip>

										<!--Task Due Date-->
										<div class="d-flex align-center ml-1">

											<!--If task has a due date and it is not expired-->
											<div v-if="item.toDoTaskDueDate && new Date().getTime() < item.toDoTaskDueDate"
												class="d-flex align-center">
												<app-icon icon="events" size="20" color="primary"/>
												<app-text v-if="$vuetify.breakpoint.width < 600" class="ml-1" size="small">
													{{ MIX_formatDate(item.toDoTaskDueDate, 'numeric') }}
												</app-text>
												<app-text v-if="$vuetify.breakpoint.width > 600" class="ml-1" size="small">
													{{ MIX_formatDate(item.toDoTaskDueDate, 'short') }}
												</app-text>
											</div>

											<!--If task has a due date and it has expired-->
											<v-chip
												v-if="item.toDoTaskDueDate && new Date().getTime() > item.toDoTaskDueDate"
												class="ml-1 white--text"
												:color="'red'"
												label
												small>
												<app-icon icon="events" color="white" size="24"/>
												{{ MIX_formatDate(item.toDoTaskDueDate, 'numeric') }}
											</v-chip>

										</div>
									</div>
									<!--Task Assigned To-->
									<div v-if="item.toDoTaskAssignedTo" class="d-flex align-center">
										<app-icon icon="user" size="24" color="primary"/>
										<app-text size="small">
											{{ usersData.find((user) => user.entityId === item.toDoTaskAssignedTo)?.userName }}
										</app-text>
									</div>
								</div>
							</div>

							<!--More Actions Menu-->
							<div class="d-flex justify-end">
								<more-actions-menu @emitMenuSelection="handleEmittedMenuSelection($event, item)"
												:menuList="moreActionsMenuOption"/>
							</div>

						</v-expansion-panel-header>

						<!--Task Name Card Expanded Description-->
						<v-expansion-panel-content class="content">

							<!--Description-->
							<app-text class="mt-2" size="small">
								{{ item.toDoTaskDescription }}
							</app-text>
						</v-expansion-panel-content>

					</v-expansion-panel>
				</v-expansion-panels>
			</div>

	</div>

</template>

<script>
import ToDoTaskForm from './toDoTaskForm/ToDoTaskForm.vue'

export default {

	name: 'ToDoTasks',

	components: {ToDoTaskForm},

	props: ['formData', 'isReadOnly', 'selectedList', 'usersData'],

	data: () => ({
		isLoading: false,
		isTaskFormVisible: false,
		moreActionsMenuOption: [
			{name: 'Edit', icon: 'edit'},
			{name: 'Delete', icon: 'delete'},
		],
		searchByTaskName: '',
		searchByTaskPriority: '',
		selectedItem: {},
		selectedListData: {},
	}),

	computed: {

		/**
		 * Computed Table Data
		 *
		 * Sort and return the table data.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedTableData() {
			const t = this
			const CURRENT_USER_DATA = t.MIX_getCurrentUser()
			let tableData = t.$props.formData

			// Search by Task Name
			if (t.searchByTaskName) tableData = tableData.filter((item) => item.toDoTaskName.toUpperCase().includes(t.searchByTaskName.toUpperCase()))

			// Sort by ToDo Task Name
			tableData = tableData.sort((a, b) => (a.toDoTaskName > b.toDoTaskName ? 1 : -1))

			// Check current user to show private tasks
			if (CURRENT_USER_DATA.entityId !== t.selectedList.createdUserId) tableData = tableData.filter((item) => item.toDoTaskIsPrivate === false)

			return tableData
		},
	},

	methods: {

		/**
		 * Are All Tasks Complete
		 *
		 * Check if all tasks are complete and update the list status.
		 *
		 */
		 async areAllTasksComplete() {
			const t = this
			const tableData = t.$props.formData

			//Check if all tasks are complete
			const allTasksComplete = tableData.every((item) => item.toDoTaskStatus === 'Complete')

			//Change list status to complete
			if (allTasksComplete) {
				t.selectedList.toDoListStatus = true
			} else {
				t.selectedList.toDoListStatus = false
			}

			// Set the list status
			const RESPONSE = await t.MIX_redis_update('toDoList', t.selectedList.entityId, t.selectedList)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating list', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem updating the list, please try again.'
				t.isLoading = false
				return
			}

		},

		/**
		 * Handle Delete Item
		 *
		 * Delete the selected task item by it's ID.
		 *
		 * @param taskData {object} the item to delete
		 */
		async deleteItem(taskData) {
			const t = this
			t.isLoading = true

			const RESPONSE = await t.MIX_redis_delete('toDoTask', taskData.entityId, taskData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error deleting task', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem deleting the task, please try again.'
				t.isLoading = false
				return
			}

			t.$sharedState.successMessage = 'Deleted Task'

			// Reload the data
			t.emittedReloadData()

			// Close the panel
			t.closeRightPanel()
			t.isLoading = false
		},

		/**
		 * Emitted Reload Page
		 *
		 * Close the right panel and reload the data to refresh the page.
		 */
		emittedReloadData() {
			const t = this

			t.isTaskFormVisible = false

			t.$emit('emitReloadData')
		},

		/**
		 * Handle Add New Task
		 *
		 * Set the task form visibility to true and set the isReadOnly boolean to false.
		 *
		 */
		handleAddNewTask() {
			const t = this
			t.isTaskFormVisible = true
			t.isReadOnly = false
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection	{string} the menu selection
		 * @param taskData {object} the item to edit or delete
		 */
		handleEmittedMenuSelection(selection, taskData) {
			const t = this

			if (selection === 'Edit') {
				const t = this
				t.selectedItem = taskData
				t.isTaskFormVisible = true
			} else if (selection === 'Delete') {
				t.deleteItem(taskData)
			}
		},

		/**
		 * Handle ToDo Task status
		 *
		 * Toggle the toDoTaskStatus string between completed and incomplete.
		 * @param event {Event} - The event object
		 * @param item {Object} - The item object
		 */
		async handleIsComplete(event, item) {
			const t = this
			
			event.cancelBubble = true

			item.toDoTaskStatus = item.toDoTaskStatus !== 'Complete' ? 'Complete' : 'Incomplete'
			const RESPONSE = await t.MIX_redis_update('toDoTask', item.entityId, item)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating task', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem updating the task, please try again.'
				t.isLoading = false
				return
			}
			
			t.areAllTasksComplete()
			
		},

		/**
		 * Toggle Task Form Visibility
		 *
		 * Toggle the task form visibility between true and false.
		 *
		 */
		toggleTaskFormVisibility() {
			this.isTaskFormVisible = !this.isTaskFormVisible
			this.isReadOnly = false
		},
	},
}
</script>

<style></style>
