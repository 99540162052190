<template>
	<div>
		<v-btn :block="block"
			   class="appButton-container"
			   :color="color || 'primary'"
			   depressed
			   :disabled="disabled"
			   :height="height || 56"
			   :loading="loading"
			   :outlined="outlined">

			<app-icon v-if="icon" :icon="icon" :size="iconSize" :color="iconColor || 'white'"/>

			<div v-if="icon && label" class="mx-1"/>

			<app-text class="text-capitalize" :color="outlined ? color : labelColor || 'white'" :size="labelSize">
				{{ label }}
			</app-text>

		</v-btn>
	</div>
</template>

<script>
export default {

	name: "AppBtn",

	props: [
		'block',
		'color',
		'disabled',
		'height',
		'icon',
		'iconColor',
		'iconSize',
		'label',
		'labelColor',
		'labelSize',
		'loading',
		'outlined', // Overwrites filled colour with an outline, sets text colour to the 'color' prop
	],

}
</script>

<style scoped>
.appButton-container {
	border-radius: 8px;
}
</style>
