<template>
	<div>

		<!--Header-->
		<page-title :divider="true"
					icon="accreditation"
					info="Create and manage your Accreditation Events."
					title="Accreditation"/>

		<div class="d-flex justify-center mt-4">
			<div class="d-flex flex-column align-center" style="width: 960px">

				<app-text size="large-bold">Vodafone Summer Series 2023</app-text>

				<app-text class="text-center mt-4">
					Welcome to the Media Accreditation procedure for the 'VODAFONE SUMMER SERIES 2023’
					<br>
					<br>
					WALES FIXTURES AT PRINCIPALITY STADIUM
					<br>
					<br>
					SATURDAY, Sth AUGUST-WALES v ENGLAND (5.30pm)
				</app-text>

				<ul>
					<li class="mt-4">
						<app-text>
							️Please note the deadline for media accreditation Is 10 working days prior to match day
							(unless otherwise stated) Requests made after the deadline will not be accepted
						</app-text>
					</li>
					<li class="mt-4">
						<app-text>
							All photographers, written media and non-rights holders must apply through this portal
						</app-text>
					</li>
					<li class="mt-4">
						<app-text>
							Please note ALL photographers must read the Photographers Agreement. By clicking to accept
							T&Cs you are bound by the regulations within the agreement. This file can be downloaded and
							printed by clicking on WRU File Downloads in the left hand menu followed by File Downloads -
							VODAFONE SUMMER SERIES 2023" (Photographers Agreement)
						</app-text>
					</li>
				</ul>
				<app-text class="mt-4">
					If you have any questions at this stage please click the FAQS or the Contact Us links on the left
					hand side
				</app-text>

				<!--Terms & Conditions-->
				<form-section-title class="mt-4" title="Terms & Conditions"/>
				<div class="appWhite rounded-lg pa-4" style="height: 224px; overflow: auto; width: 100%">
					<app-text>{{ termsAndConditions }}</app-text>
				</div>

				<!--Checkbox | Continue Button-->
				<div class="d-flex justify-space-between mt-4" style="width: 100%">

					<!--Checkbox-->
					<div class="d-flex align-center">
						<div @click="handleCheckboxSelection"
							 class="appWhite d-flex align-center justify-center rounded-lg mr-4"
							 style="height: 56px; width: 56px">
							<app-icon color="green"
									  :icon="hasAcceptedTCs ? 'check' : ''"
									  size="32"/>
						</div>
						<app-text>I accept the Terms & Conditions</app-text>
					</div>

					<!--Continue Button-->
					<app-btn @click.native="handleContinue"
							 color="green"
							 :disabled="!hasAcceptedTCs"
							 icon="success"
							 label="Continue"/>

				</div>

			</div>
		</div>

	</div>
</template>

<script>

export default {

	name: "MyAccreditationEventLandingPage",

	props: ['fullEventData'],

	data: () => ({
		hasAcceptedTCs: false,
		termsAndConditions: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ac orci interdum, faucibus arcu ac,\n' +
			'malesuada odio. Donec rhoncus fermentum tellus varius laoreet. Fusce ultricies ut enim a commodo.\n' +
			'Nulla egestas neque vel sapien vestibulum, a pharetra massa eleifend. Vestibulum pulvinar bibendum\n' +
			'tortor vel scelerisque. Orci varius natoque penatibus et magnis dis parturient montes, nascetur\n' +
			'ridiculus mus. Morbi tincidunt odio urna, ac suscipit arcu volutpat sit amet.\n' +
			'Pellentesque scelerisque pretium neque, a malesuada nisl lacinia lacinia. In hac habitasse platea\n' +
			'dictumst. Duis sed lacus justo. Phasellus condimentum lectus vitae nisi volutpat accumsan eget ac\n' +
			'massa. Maecenas a bibendum lectus. Nullam at massa ut ligula vestibulum posuere. Vestibulum ante\n' +
			'ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;',
	}),

	methods: {

		handleCheckboxSelection() {
			const t = this

			t.hasAcceptedTCs = !t.hasAcceptedTCs
		},

		handleContinue() {
			const t = this

			// If the user hasn't accepted the T&Cs, don't continue
			if (!t.hasAcceptedTCs) return

			t.$emit('saveEventTCs', t.$props.fullEventData)
		},

	},

}
</script>

<style scoped>

</style>
