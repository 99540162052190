<template>
	<div class="appGrey rounded-lg pa-4">

		<!--Icon | Title-->
		<div class="d-flex align-center">

			<!--Icon-->
			<app-icon class="mr-4" color="grey9" icon="error" size="32"/>

			<!--Title - User Only-->
			<app-text v-if="['Staff-User'].includes(MIX_getCurrentUser().userLevel)"
					  color="primary" size="normal-bold">Need to Raise an Issue?
			</app-text>

			<!--Title - Admin and Supervisor Only-->
			<app-text v-if="!['Staff-User'].includes(MIX_getCurrentUser().userLevel)"
					  color="primary" size="normal-bold">Issue Raised
			</app-text>

		</div>

		<v-divider class="greyD my-4"/>

		<!--Instructional Text - User Only-->
		<app-text v-if="['Staff-User'].includes(MIX_getCurrentUser().userLevel)">
			Please select a reason from the list below why you are unable to complete this task.
		</app-text>

		<!--Instructional Text - Admin and Supervisor Only-->
		<app-text v-if="!['Staff-User'].includes(MIX_getCurrentUser().userLevel) && form.swappTasksTaskUserIssue">
			This task was marked as being unable to complete, for the following reason:
		</app-text>
		<app-text v-if="!['Staff-User'].includes(MIX_getCurrentUser().userLevel) && !form.swappTasksTaskUserIssue"
				  class="text-center" color="grey9">
			No issue raised
		</app-text>

		<!--Issue Input - User Only-->
		<app-form-field v-if="['Staff-User'].includes(MIX_getCurrentUser().userLevel)"
						form-type="autoComplete"
						class="mt-4"
						:error="errors.swappTasksTaskUserIssue"
						:error-message="errors.swappTasksTaskUserIssueErrorMessage"
						:items="swappTasksUserIssuesOptions"
						label="Select an Issue"
						v-model="form.swappTasksTaskUserIssue"/>

		<!--Reason Text - Admin and Supervisor Only-->
		<app-text v-if="!['Staff-User'].includes(MIX_getCurrentUser().userLevel)" class="mt-4" size="normal-bold">
			{{ form.swappTasksTaskUserIssue }}
		</app-text>

		<v-divider class="greyD my-4"/>

		<!--Action Buttons - User Only-->
		<div class="d-flex justify-space-between">

			<!--Close-->
			<app-btn @click.native="handleCancelButton"
					 :block="true" class="pr-2" color="grey" label="Close" style="width: 100%;"/>

			<!--Save-->
			<app-btn v-if="['Staff-User'].includes(MIX_getCurrentUser().userLevel)"
					 @click.native="handleSaveButton"
					 :block="true" class="pl-2" color="green" label="Save" style="width: 100%;"/>

		</div>

	</div>
</template>

<script>

export default {

	name: "SwappTasksIssuesDialog",

	props: ['taskData'],

	data: () => ({
		errors: {
			swappTasksTaskUserIssue: false,
			swappTasksTaskUserIssueErrorMessage: '',
		},
		form: {
			swappTasksTaskUserIssue: '',
		},
	}),

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Close Dialog
		 *
		 * Emit back to the parent to close the dialog.
		 */
		closeDialog() {
			const t = this

			t.$emit('closeDialog')
		},

		/**
		 * Handle Cancel Button
		 *
		 * Handle the cancel button being clicked.
		 * Rest the form and call to close the dialog.
		 */
		handleCancelButton() {
			const t = this

			// Reset the form
			t.form.swappTasksTaskUserIssue = ''

			t.closeDialog()
		},

		/**
		 * Handle Save Button
		 *
		 * Handle the save button being clicked.
		 * Validate the form and then call to update the item.
		 */
		handleSaveButton() {
			const t = this

			// Validate the form before proceeding
			if (!t.validateForm()) return

			t.form.swappTasksTaskUserIssueDateTime = new Date().getTime()

			t.updateItem()
		},

		async sendIssueEmail() {
			const t = this

			const RESPONSE = await t.MIX_redis_sendSwappTaskIssueEmail(t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error sending Swapp Tasks Task Issue Email: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem sending the issue email, please try again.'
				return
			}
		},

		/**
		 * Update Item
		 *
		 * Update the item in the database.
		 */
		async updateItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_update('swappTasksTask', t.form.entityId, t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating Swapp Tasks Task: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem updating this Task, please try again.'
				return
			}

			t.sendIssueEmail()

			t.$sharedState.successMessage = 'Issue Saved'
			t.closeDialog()
		},

		/**
		 * Validate Form
		 *
		 * Validate the form as required.
		 *
		 * @returns {boolean} if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Issue
			if (!t.form.swappTasksTaskUserIssue) {
				t.errors.swappTasksTaskUserIssue = true
				t.errors.swappTasksTaskUserIssueErrorMessage = 'Please select an issue'
			}

			return !Object.values(t.errors).includes(true)
		}

	},

	mounted() {
		const t = this

		// Set the form data
		t.form = t.$props.taskData
	},

}
</script>

<style scoped>

</style>
