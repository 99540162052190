<template>
	<div class="d-flex align-center">

		<v-progress-linear :background-color="color"
						   background-opacity="0.1"
						   :color="color"
						   height="24"
						   :style="['No Response', 'Due'].includes(label) && 'border-bottom-left-radius: 8px'"
						   :value="percentValue">

			<!--Label-->
			<template v-slot:default="{ value }">
				<app-text color="grey3" size="small" style="opacity: 0.5">{{ label }}</app-text>
			</template>

		</v-progress-linear>

		<!--Row Amount-->
		<div class="d-flex align-center justify-center text-center">
			<app-text :color="color" class="mx-2" size="small" style="width: 16px">{{ numericalValue }}</app-text>
		</div>

	</div>
</template>

<script>
export default {

	name: "PreEventProgressBar",

	props: [
		'color',
		'label',
		'numericalValue',
		'percentValue',
	],

}
</script>

<style scoped>

</style>
