<template>
	<div>

		<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

		<!--No QR Code Param-->
		<div v-else-if="!qrCodeParamProps" style="height: 70vh">

			<!--Header-->
			<page-title :divider="true"
						icon="tasks"
						title="SWAPP Tasks"/>

			<div class="d-flex flex-column align-center justify-center" style="height: 100%">

				<app-icon class="pulseBig" color="primary" icon="qrCode" size="96"/>
				<app-text class="text-center mt-8">Scan a SWAPP Tasks QR Code to get started</app-text>

				<app-btn @click.native="MIX_go('/')" class="mt-8" label="Back to Home"/>

			</div>

		</div>

		<!--No Tasks List Data-->
		<div v-else-if="!swappTasksListData.entityId" style="height: 70vh">

			<!--Header-->
			<page-title :divider="true"
						icon="tasks"
						title="SWAPP Tasks"/>

			<div class="d-flex flex-column align-center justify-center" style="height: 100%">

				<app-icon class="pulseBig" color="red" icon="cancel" size="96"/>
				<app-text class="text-center mt-8">There are no Tasks for this QR Code.</app-text>

				<app-btn @click.native="MIX_go('/')" class="mt-8" label="Back to Home"/>

			</div>

		</div>

		<!--Page Content-->
		<div v-else>

			<!--Header | Page Info Icon-->
			<div class="d-flex align-center">

				<!--Header-->
				<page-title icon="tasks"
							info=""
							title="SWAPP Tasks"/>

				<!--Page Info Icon-->
				<app-icon @click.native="togglePageInfoDialogVisibility" color="grey9" icon="info" size="32"/>

			</div>

			<v-divider class="greyD mt-4"/>

			<!--Swapp Check-->
			<div v-if="componentView === 'Swapp Check'"
				 class="d-flex flex-column align-center">

				<!--Instructional Text-->
				<app-text class="mt-4" size="small">
					As a Supervisor, you can view the history of this SWAPP Task List, including it's tasks, comments,
					and
					issues.
					<br>
					<br>
					Check the details below, and continue when you're ready.
				</app-text>

				<v-divider class="greyD mt-4" style="width: 100%"/>

				<!--Area-->
				<app-text class="mt-8" color="primary" size="medium-bold">
					{{ swappTasksListData.swappTasksListArea }}
				</app-text>

				<!--Location-->
				<app-text class="mt-8" size="medium-bold">{{ swappTasksListData.swappTasksListLocation }}</app-text>

				<!--Role-->
				<div class="d-flex align-center mt-8 ml-n8">
					<app-icon class="mr-2" icon="user"/>
					<app-text color="grey9">Supervisor</app-text>
				</div>

				<v-divider class="greyD mt-8" style="width: 100%"/>

				<!--Continue Button-->
				<app-btn @click.native="handleContinueButton" class="mt-8" icon="success" label="Yes, continue"/>

				<!--Not Correct Button-->
				<!--<app-text class="mt-8 mb-4" color="grey9">This isn't correct</app-text>-->

			</div>

			<!--No Lists-->
			<div v-if="componentView === 'No Lists'"
				 class="d-flex flex-column align-center justify-center"
				 style="height: 70vh">

				<app-icon class="pulseBig" color="orange" icon="cancel" size="96"/>
				<app-text class="text-center mt-8">There are no SWAPP Tasks for this QR Code.</app-text>

				<app-btn @click.native="MIX_go('/')" class="mt-8" label="Back to Home"/>

			</div>

			<!--Lists View-->
			<div v-else-if="componentView === 'Lists View'">
				<div v-for="item in computedFullSwappTasksData"
					 class="">

					<!--Date Page Break-->
					<app-text class="mt-8" color="primary" size="normal-bold">
						{{ MIX_formatDate(item.createdDateTime, 'short') }}
					</app-text>
					<v-divider class="greyD mt-2"/>

					<!--Cards-->
					<div v-for="list in item.listsData" :key="item?.listData?.entityId"
						 @click="handleListSelection(list)"
						 class="appWhite d-flex align-center rounded-lg mt-4 pa-4">

						<!--List Details-->
						<div style="width: 100%">

							<!--Status Icon | Number of Tasks Completed-->
							<div class="d-flex align-center">

								<!--Status Icon-->
								<app-icon class="mr-2"
										  :color="getCardDetails(list).areAllTasksComplete ? 'green' : 'red'"
										  :icon="getCardDetails(list).areAllTasksComplete ? 'success' : 'cancel'"
										  size="32"/>

								<!--Number of Tasks Completed-->
								<app-text size="normal-bold">
									{{ getCardDetails(list).numberOfTasksCompleted }}
								</app-text>

								<app-text class="mx-1">/</app-text>

								<!--Total Number of Tasks-->
								<app-text size="normal-bold">
									{{ getCardDetails(list).numberOfTasks }}
								</app-text>

								<app-text class="ml-2">Tasks Completed</app-text>

							</div>

							<v-divider class="greyD my-2"/>

							<!--Created By User-->
							<div class="d-flex align-end">
								<app-icon class="mr-2" color="primary" icon="user" size="20"/>
								<app-text color="grey9" size="small">{{ list.listData.createdUserName }}</app-text>
							</div>

							<!--Created Date and Time-->
							<div class="d-flex align-end">
								<app-icon class="mr-2" color="primary" icon="events" size="20"/>
								<app-text color="grey9" size="small">
									{{ `${MIX_formatDate(list.listData.createdDateTime, 'short')} @ ${MIX_formatDateTimeToTime(list.listData.createdDateTime)}`
									}}
								</app-text>
							</div>

							<!--Comments-->
							<div v-if="getCardDetails(list).numberOfTasksWithUserComments !== 0"
								 class="d-flex align-end">
								<app-icon class="mr-2" color="orange" icon="comment" size="20"/>
								<app-text color="grey9" size="small">
									{{ getCardDetails(list).numberOfTasksWithUserComments }} Comment left
								</app-text>
							</div>

							<!--User Issues-->
							<div v-if="getCardDetails(list).numberOfTasksWithUserIssues !== 0"
								 class="d-flex align-end">
								<app-icon class="mr-2" color="red" icon="error" size="20"/>
								<app-text color="grey9" size="small">
									{{ getCardDetails(list).numberOfTasksWithUserIssues }} User Issue raised
								</app-text>
							</div>

							<!--Supervisor Issues-->
							<div v-if="getCardDetails(list).numberOfTasksWithSupervisorIssues !== 0"
								 class="d-flex align-end">
								<app-icon class="mr-2" color="red" icon="error" size="20"/>
								<app-text color="grey9" size="small">
									{{ getCardDetails(list).numberOfTasksWithSupervisorIssues }} Supervisor Issue raised
								</app-text>
							</div>

						</div>

					</div>

				</div>
			</div>

			<!--Tasks View-->
			<div v-else-if="componentView === 'Tasks View'">

				<!--Back Arrow-->
				<div @click="componentView = 'Lists View'" class="d-flex align-center mt-4">

					<app-icon class="mr-2" color="primary" icon="arrowBack"/>
					<app-text>Back to Lists</app-text>

				</div>

				<!--Cards-->
				<v-expansion-panels v-for="item in selectedListItem.tasksData" :key="item.entityId" flat>
					<v-expansion-panel class="rounded-lg mt-4">

						<!--Header-->
						<v-expansion-panel-header class="d-flex align-start rounded-lg pa-4" hide-actions>

							<!--Checkbox-->
							<div @click="handleCheckboxSelection(item)"
								 class="appGrey d-flex align-center justify-center rounded-lg mr-4"
								 style="aspect-ratio: 1/1; height: 56px; max-width: 56px">
								<app-icon color="green"
										  :icon="item.swappTasksTaskStatus === 'Complete' ? 'check' : ''"
										  size="32"/>
							</div>

							<!--Task Name | Icons-->
							<div>

								<!--Task Name-->
								<app-text color="primary" size="normal-bold">{{ item.swappTasksTaskName }}</app-text>

								<!--Icons-->
								<div
									v-if="item.swappTasksTaskUserComment || item.swappTasksTaskUserIssue || item.swappTasksTaskSupervisorIssue"
									class="mt-2">

									<!--Comment-->
									<div v-if="item.swappTasksTaskUserCommentDateTime" class="d-flex align-center">
										<app-icon class="mr-2" color="orange" icon="comment" size="16" title="Comment"/>
										<app-text color="grey9" size="small">
											{{ MIX_formatDate(item.swappTasksTaskUserCommentDateTime, 'short') }}
											@
											{{ MIX_formatDateTimeToTime(item.swappTasksTaskUserCommentDateTime) }}
										</app-text>
									</div>

									<!--User Issue-->
									<div v-if="item.swappTasksTaskUserIssue" class="d-flex align-center mt-1">
										<app-icon class="mr-2" color="red" icon="error" size="16" title="Issue"/>
										<app-text color="grey9" size="small">
											{{ MIX_formatDate(item.swappTasksTaskUserIssueDateTime, 'short') }}
											@
											{{ MIX_formatDateTimeToTime(item.swappTasksTaskUserIssueDateTime) }}
										</app-text>
									</div>

									<!--Supervisor Issue-->
									<div v-if="item.swappTasksTaskSupervisorIssue" class="d-flex align-center mt-1">
										<app-icon class="flash mr-2" color="red" icon="error" size="16" title="Issue"/>
										<app-text color="grey9" size="small">
											{{ MIX_formatDate(item.swappTasksTaskSupervisorIssueDateTime, 'short') }}
											@
											{{ MIX_formatDateTimeToTime(item.swappTasksTaskSupervisorIssueDateTime) }}
										</app-text>
									</div>

								</div>

								<v-divider class="greyD my-2"/>

								<!--Completed By User-->
								<div class="d-flex align-center mt-2">
									<app-icon class="mr-2" color="primary" icon="user" size="16"/>
									<app-text color="grey9" size="small">{{ item.createdUserName }}</app-text>
								</div>

								<!--Completed DateTime-->
								<div v-if="item.swappTasksTaskCompletedDateTime !== 0" class="d-flex align-center mt-2">
									<app-icon class="mr-2" color="primary" icon="events" size="16"/>
									<app-text color="grey9" size="small">
										{{ MIX_formatDate(item.swappTasksTaskCompletedDateTime, 'short') }}
									</app-text>
									<app-text class="mx-2" color="grey9" size="small">@</app-text>
									<app-text color="grey9" size="small">
										{{ MIX_formatDateTimeToTime(item.swappTasksTaskCompletedDateTime) }}
									</app-text>
								</div>

							</div>

						</v-expansion-panel-header>

						<!--Content-->
						<v-expansion-panel-content>

							<!--Task Description-->
							<app-text size="small">{{ item.swappTasksTaskDescription }}</app-text>

							<v-divider class="appGrey my-4"/>

							<!--Card Actions-->
							<div class="d-flex justify-space-around">
								<app-icon @click.native="handleInformationButton(item)" color="green" icon="info"
										  size="32"/>
								<app-icon @click.native="handleCommentsButton(item)"
										  :color="item.swappTasksTaskUserComment ? 'orange' : 'grey'"
										  icon="comment"
										  size="32"/>
								<app-icon @click.native="handleIssuesButton(item)"
										  :class="item.swappTasksTaskUserIssue && 'flash'"
										  :color="item.swappTasksTaskUserIssue ? 'red' : 'grey'"
										  icon="error" size="32"/>
							</div>

							<v-divider class="appGrey my-4"/>

							<!--Report Issue Button-->
							<app-btn @click.native="handleReportSupervisorIssue(item)"
									 :block="true" class="mt-4" color="red" height="40" icon="error" label="Report an Issue"/>

						</v-expansion-panel-content>

					</v-expansion-panel>
				</v-expansion-panels>

			</div>

			<!--Dialogs -------------------------------------------------------------------------------------------- -->

			<!--Text Information Dialog-->
			<v-dialog v-if="isTextInformationDialogVisible"
					  max-width="512"
					  v-model="isTextInformationDialogVisible">
				<swapp-tasks-text-info-dialog @closeDialog="toggleTextInformationDialogVisibility"
											  :task-data="selectedTaskItem"/>
			</v-dialog>

			<!--Comments Dialog-->
			<v-dialog v-if="isCommentsDialogVisible"
					  max-width="512"
					  v-model="isCommentsDialogVisible">
				<swapp-tasks-comments-dialog @closeDialog="toggleCommentsDialogVisibility"
											 :task-data="selectedTaskItem"/>
			</v-dialog>

			<!--Issues Dialog - User-->
			<v-dialog v-if="isUserIssuesDialogVisible"
					  max-width="512"
					  v-model="isUserIssuesDialogVisible">
				<swapp-tasks-issues-dialog @closeDialog="toggleUserIssuesDialogVisibility"
										   :task-data="selectedTaskItem"/>
			</v-dialog>

			<!--Issues Dialog - Supervisor-->
			<v-dialog v-if="isSupervisorIssuesDialogVisible"
					  max-width="512"
					  v-model="isSupervisorIssuesDialogVisible">
				<swapp-tasks-supervisor-issues-dialog @closeDialog="toggleSupervisorIssuesDialogVisibility"
													  :task-data="selectedTaskItem"/>
			</v-dialog>

			<!--Page Info Dialog-->
			<v-dialog v-if="isPageInfoDialogVisible"
					  max-width="512"
					  v-model="isPageInfoDialogVisible">
				<swapp-tasks-page-info-dialog @closeDialog="togglePageInfoDialogVisibility"/>
			</v-dialog>

		</div>

	</div>
</template>

<script>
import SwappTasksTextInfoDialog
	from "@/views/swappTasks/components/swappTasksTextInfoDialog/SwappTasksTextInfoDialog.vue";
import SwappTasksIssuesDialog from "@/views/swappTasks/components/swappTasksIssuesDialog/SwappTasksIssuesDialog.vue";
import SwappTasksCommentsDialog
	from "@/views/swappTasks/components/swappTasksCommentsDialog/SwappTasksCommentsDialog.vue";
import SwappTasksSupervisorIssuesDialog
	from "@/views/swappTasks/supervisor/swappTasksSupervisorIssueDialog/SwappTasksSupervisorIssueDialog.vue";
import SwappTasksPageInfoDialog from "@/views/swappTasks/user/swappTasksPageInfoDialog/SwappTasksPageInfoDialog.vue";

export default {

	name: "SwappTasksSupervisor",

	components: {
		SwappTasksPageInfoDialog,
		SwappTasksSupervisorIssuesDialog,
		SwappTasksCommentsDialog, SwappTasksIssuesDialog, SwappTasksTextInfoDialog
	},

	props: ['qrCodeParamProps', 'swappTasksListData'],

	data: () => ({
		componentView: 'Swapp Check',
		isLoading: false,
		isCommentsDialogVisible: false,
		isUserIssuesDialogVisible: false,
		isSupervisorIssuesDialogVisible: false,
		isPageInfoDialogVisible: false,
		isTextInformationDialogVisible: false,
		selectedListItem: {},
		selectedTaskItem: {},

		// Data
		fullSwappTasksData: {},
	}),

	computed: {

		/**
		 * Computed Full Swapp Tasks Data
		 *
		 * Compare the List's createdDateTimes and return an array of the full Swapp Tasks Data (listData and tasksData).
		 * Group the returned array by the List's createdDateTimes.
		 *
		 * @returns {Array}
		 */
		computedFullSwappTasksData() {
			const t = this

			// Create a helper function to convert epoch to a YYYY-MM-DD string
			function epochToDateString(epoch) {
				const EPOCH_DATE = new Date(epoch)
				return EPOCH_DATE.toISOString().split('T')[0]
			}

			// Reduce the fullSwappTasks array to a grouped object
			const GROUPED = t.fullSwappTasksData.reduce((accumulator, current) => {

				// Convert the current list's epoch to a date string
				const DATE_STRING = epochToDateString(current.listData.createdDateTime)

				// If the group by this date doesn't exist, create it
				if (!accumulator[DATE_STRING]) {
					accumulator[DATE_STRING] = {
						createdDateTime: current.listData.createdDateTime,
						listsData: []
					}
				}

				// Push the current list into the group's listsData array
				accumulator[DATE_STRING].listsData.push(current)

				return accumulator
			}, {})

			// Convert the grouped object back into an array
			const newGroupedArray = Object.keys(GROUPED).map(date => ({
				createdDateTime: GROUPED[date].createdDateTime,
				listsData: GROUPED[date].listsData
			}))

			// Sort the ListsData array by the createdDateTime
			newGroupedArray.forEach(group => {
				group.listsData.sort((a, b) => a.listData.createdDateTime < b.listData.createdDateTime ? 1 : -1)
			})

			// Sort the newGroupedArray by the createdDateTime
			newGroupedArray.sort((a, b) => a.createdDateTime < b.createdDateTime ? 1 : -1)

			// Sort the array by the createdDateTime
			return newGroupedArray
		},

	},

	methods: {

		/**
		 * Get Card Details
		 *
		 * Get the card figures details for the list.
		 *
		 * @param listData - The list data.
		 * @returns {{numberOfTasksWithUserComments: number, numberOfTasksWithSupervisorIssues: number, numberOfTasksWithUserIssues: number, numberOfTasksCompleted: number, numberOfTasks: number, areAllTasksComplete: boolean}}
		 */
		getCardDetails(listData) {
			const NUMBER_OF_TASKS = listData.tasksData.length
			const NUMBER_OF_TASKS_COMPLETED = listData.tasksData.filter(task => task.swappTasksTaskStatus === 'Complete').length
			const ARE_ALL_TASKS_COMPLETE = NUMBER_OF_TASKS === NUMBER_OF_TASKS_COMPLETED
			const NUMBER_OF_TASKS_WITH_USER_COMMENTS = listData.tasksData.filter(task => task.swappTasksTaskUserComment).length
			const NUMBER_OF_TASKS_WITH_USER_ISSUES = listData.tasksData.filter(task => task.swappTasksTaskUserIssue).length
			const NUMBER_OF_TASKS_WITH_SUPERVISOR_ISSUES = listData.tasksData.filter(task => task.swappTasksTaskSupervisorIssue).length

			return {
				numberOfTasksCompleted: NUMBER_OF_TASKS_COMPLETED,
				numberOfTasks: NUMBER_OF_TASKS,
				areAllTasksComplete: ARE_ALL_TASKS_COMPLETE,
				numberOfTasksWithUserComments: NUMBER_OF_TASKS_WITH_USER_COMMENTS,
				numberOfTasksWithUserIssues: NUMBER_OF_TASKS_WITH_USER_ISSUES,
				numberOfTasksWithSupervisorIssues: NUMBER_OF_TASKS_WITH_SUPERVISOR_ISSUES,
			}
		},

		handleListSelection(listData) {
			const t = this

			t.selectedListItem = listData

			t.componentView = 'Tasks View'
		},

		/**
		 * Handle Checkbox Selection
		 *
		 * Handle the checkbox selection.
		 * Toggle the task status, and update the task status in the DB.
		 * Note: The event.cancelBubble = true is required to stop the expansion panel from opening on checkbox click.
		 *
		 * @param taskData - The task data.
		 */
		handleCheckboxSelection(taskData) {
			const t = this
			event.cancelBubble = true

			// Toggle the task status
			taskData.swappTasksTaskStatus = taskData.swappTasksTaskStatus === 'Complete' ? 'Incomplete' : 'Complete'

			t.updateTaskStatus(taskData)
		},

		/**
		 * Handle Comments Button
		 *
		 * Handle the Comments button click.
		 * Set the selected item and toggle the Comments dialog visibility.
		 *
		 * @param taskData - The task data.
		 */
		handleCommentsButton(taskData) {
			const t = this
			t.selectedTaskItem = taskData
			t.toggleCommentsDialogVisibility()
		},

		async handleContinueButton() {
			const t = this
			const LIST_DATA = t.$props.swappTasksListData

			await t.loadAllSwappTasksListsByQrCode(LIST_DATA.swappTasksListQrId)

			if (!t.fullSwappTasksData.length) t.componentView = 'No Lists'

			else t.componentView = 'Lists View'
		},

		/**
		 * Handle Information Button
		 *
		 * Handle the Information button click.
		 * Toggle the Text Information dialog visibility.
		 */
		handleInformationButton(taskData) {
			const t = this
			t.selectedTaskItem = taskData
			t.toggleTextInformationDialogVisibility()
		},

		/**
		 * Handle Issues Button
		 *
		 * Handle the Issues button click.
		 * Set the selected item and toggle the Issues dialog visibility.
		 *
		 * @param taskData - The task data.
		 */
		handleIssuesButton(taskData) {
			const t = this
			t.selectedTaskItem = taskData
			t.toggleUserIssuesDialogVisibility()
		},

		handleReportSupervisorIssue(taskData) {
			const t = this
			t.selectedTaskItem = taskData
			t.toggleSupervisorIssuesDialogVisibility()
		},

		async loadAllSwappTasksListsByQrCode(qrCodeId) {
			const t = this

			const RESPONSE = await t.MIX_redis_getAllSwappTasksDataByQrCode(qrCodeId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error loading Swapp Tasks List: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem loading this List, please try again.'
				return
			}

			// Set the Swapp Tasks List Data
			t.fullSwappTasksData = RESPONSE.data
		},

		/**
		 * Toggle Comments Dialog Visibility
		 *
		 * Toggle the Comments dialog visibility.
		 */
		toggleCommentsDialogVisibility() {
			this.isCommentsDialogVisible = !this.isCommentsDialogVisible
		},

		/**
		 * Toggle Supervisor Issues Dialog Visibility
		 *
		 * Toggle the Issues dialog visibility.
		 */
		toggleSupervisorIssuesDialogVisibility() {
			this.isSupervisorIssuesDialogVisible = !this.isSupervisorIssuesDialogVisible
		},

		/**
		 * Toggle Page Info Dialog Visibility
		 *
		 * Toggle the Page Info dialog visibility.
		 */
		togglePageInfoDialogVisibility() {
			this.isPageInfoDialogVisible = !this.isPageInfoDialogVisible
		},

		/**
		 * Toggle User Issues Dialog Visibility
		 *
		 * Toggle the Issues dialog visibility.
		 */
		toggleUserIssuesDialogVisibility() {
			this.isUserIssuesDialogVisible = !this.isUserIssuesDialogVisible
		},

		/**
		 * Toggle Text Information Dialog Visibility
		 *
		 * Toggle the Text Information dialog visibility.
		 */
		toggleTextInformationDialogVisibility() {
			this.isTextInformationDialogVisible = !this.isTextInformationDialogVisible
		},

		async updateTaskStatus(taskData) {
			const t = this

			const RESPONSE = await t.MIX_redis_update('swappTasksTask', taskData.entityId, taskData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating Swapp Tasks Task Status: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem updating this Task, please try again.'
				return
			}
		},

	},

}
</script>

<style scoped>

</style>
