<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<!--Page Content-->
	<div v-else>

		<div class="pa-4">

			<!--Searches & Filters-->
			<v-row no-gutters>

				<!--User Name-->
				<v-col :cols="$vuetify.breakpoint.width < 600 ? '12' : '4'"
					   :class="$vuetify.breakpoint.width < 600 ? '' : 'mt-4 pr-2'">
					<app-form-field form-type="textInput"
									:clearable="true"
									label="User Name"
									v-model="searchByUserName"/>
				</v-col>

				<!--Organisation Name-->
				<v-col :cols="$vuetify.breakpoint.width < 600 ? '12' : '4'"
					   :class="$vuetify.breakpoint.width < 600 ? '' : 'mt-4 px-2'">
					<app-form-field form-type="textInput"
									:clearable="true"
									label="Organisation Name"
									v-model="searchByOrganisationName"/>
				</v-col>

				<!--Print Status-->
				<v-col :cols="$vuetify.breakpoint.width < 600 ? '12' : '4'"
					   :class="$vuetify.breakpoint.width < 600 ? '' : 'mt-4 pl-2'">
					<app-form-field form-type="select"
									label="Print Status"
									:items="['Printed', 'Ready to Print']"
									v-model="filterByPrintStatus"/>
				</v-col>

			</v-row>

			<!--Select All - Checkbox-->
			<div class="d-flex align-end">

				<!--Checkbox-->
				<div class="d-flex align-center mt-4">
					<div @click="handleSelectAll"
						 class="appWhite d-flex align-center justify-center rounded-lg mr-4"
						 style="aspect-ratio: 1/1; width: 56px">
						<app-icon color="green"
								  :icon="isSelectAllActive ? 'check' : ''"
								  size="32"/>
					</div>
					<app-text>Select All ({{ computedTableData.length }})</app-text>
				</div>

				<v-spacer/>

				<!--More Actions Menu-->
				<more-actions-menu @emitMenuSelection="handleEmittedMenuSelection"
								   :menuList="moreActionsMenuOption"/>

			</div>

			<!--No Passes Message-->
			<div v-if="!computedTableData.length" class="text-center mt-4">
				<app-text color="grey9">
					There are no passes to show
					<span v-if="filterByPrintStatus === 'Printed'"><br>(Passes must be approved before printing)</span>
				</app-text>
			</div>

			<!--Passes Ready to Print-->
			<div v-for="item in computedTableData" :key="item.userData.entityId"
				 class="d-flex flex-column">

				<v-divider class="greyD my-4"/>

				<!--Checkbox | User Details | Buttons-->
				<div class="d-flex align-center">

					<!--Checkbox-->
					<div @click="handleUserSelection(item)"
						 class="appWhite d-flex align-center justify-center rounded-lg mr-4"
						 style="aspect-ratio: 1/1; width: 56px">
						<app-icon color="green"
								  :icon="selectedUsers.includes(item) ? 'check' : ''"
								  size="32"/>
					</div>

					<!--User Details-->
					<div>
						<app-text size="normal-bold">{{ item.userData.userName }}</app-text>
						<app-text color="grey9" size="small-bold">
							{{ item.organisationData.organisationName }}
						</app-text>
						<app-text color="grey9" size="small">{{ item.userData.userPosition }}</app-text>
					</div>

					<v-spacer/>

				</div>

			</div>

			<v-divider class="greyD my-4"/>

			<!--Print Button-->
			<div class="d-flex justify-end">
				<app-btn @click.native="printPasses"
						 color="green"
						 :disabled="getPrintButtonConfig().isDisabled"
						 icon="print"
						 :label="getPrintButtonConfig().label"
						 :label-color="getPrintButtonConfig().labelColor"/>
			</div>

		</div>

		<!--Dialogs ------------------------------------------------------------------------------------------------ -->

		<!--Print Preview-->
		<v-dialog fullscreen v-model="isPassPrintPreviewDialogVisible">
			<div style="min-height: 100vh" class="white">
				<accreditation-print-pass_principality v-if="['principality-swapp', 'swapp-vindico'].includes(appName)"
													   @closeDialog="handleCloseDialog"
													   :event-data="eventData"
													   :event-image-data="eventImageData"
													   :passesData="selectedUsers"/>
				<accreditation-print-pass_glamorgan-cricket v-if="['glamorgan-cricket-swapp'].includes(appName)"
															@closeDialog="handleCloseDialog"
															:event-data="eventData"
															:event-image-data="eventImageData"
															:passesData="selectedUsers"/>
			</div>
		</v-dialog>

		<!--Update Print Status-->
		<v-dialog max-width="512" v-model="isUpdatePrintStatusDialogVisible">
			<div class="appGrey rounded-lg">

				<!--Toolbar-->
				<v-toolbar class="appGrey rounded-t-lg">
					<app-text size="medium-bold">Update Print Status</app-text>
					<v-spacer/>
					<close-icon @click.native="isUpdatePrintStatusDialogVisible = false"/>
				</v-toolbar>

				<!--Content-->
				<div class="pa-4">

					<!--Nothing Selected-->
					<div v-if="!selectedUsers.length">

						<!--Icon | Text-->
						<div class="d-flex align-center">
							<app-icon class="mr-4" color="red" icon="cancel" size="48"/>
							<app-text class="text-center">You have not selected any Users to update</app-text>
						</div>

						<!--Ok Button-->
						<div class="d-flex justify-space-between mt-4">
							<app-btn @click.native="isUpdatePrintStatusDialogVisible = false"
									 :block="true"
									 color="appGrey"
									 label="Close"
									 style="width: 100%"/>
						</div>

					</div>

					<!--Users Selected-->
					<div v-else>

						<!--Icon | Text-->
						<app-text>
							You are about to update the Print Status for <b>{{ selectedUsers.length }}</b> passes.
						</app-text>

						<app-text class="mt-4">
							Would you like to set them as <b>Printed</b>, or <b>Not Printed</b>?
						</app-text>

						<!--Buttons-->
						<div class="d-flex justify-space-between mt-4">

							<!--Printed-->
							<app-btn @click.native="updatePrintStatuses(true)"
									 color="green"
									 icon="success"
									 icon-color="white"
									 label="Printed"
									 label-color="white"
									 :label-size="$vuetify.breakpoint.width < 416 && 'small'"/>

							<!--Not Printed-->
							<app-btn @click.native="updatePrintStatuses(false)"
									 color="red"
									 icon="cancel"
									 icon-color="white"
									 label="Not Printed"
									 label-color="white"
									 :label-size="$vuetify.breakpoint.width < 416 && 'small'"/>

						</div>

					</div>

				</div>

			</div>
		</v-dialog>

		<!--Print Status Change Confirmation-->
		<v-dialog max-width="512" v-model="isPrintStatusChangeConfirmationDialogVisible">
			<div class="appGrey rounded-lg">

				<!--Toolbar-->
				<v-toolbar class="appGrey rounded-t-lg">
					<app-text size="medium-bold">Update Print Statuses</app-text>
					<v-spacer/>
					<close-icon @click.native="isPrintStatusChangeConfirmationDialogVisible = false"/>
				</v-toolbar>

				<!--Content-->
				<div class="pa-4">

					<div class="d-flex align-center">
						<app-icon class="mr-2" color="orange" icon="info" size="32"/>
						<app-text color="grey9" size="small">Please Note: The system only knows the passes were sent for
							printing, not if they were successful.
						</app-text>
					</div>

					<app-text class="mt-4">Would you like to update the following passes to <b>printed</b>?</app-text>

					<ul class="mt-4">
						<li v-for="item in selectedUsers" class="mt-1">
							<app-text>{{ item.userData.userName }}</app-text>
						</li>
					</ul>

					<v-divider class="greyD mt-4"/>

					<div class="d-flex justify-space-between mt-4">

						<app-btn @click.native="handlePrintPassChangeConfirmation(false)"
								 color="red"
								 icon="cancel"
								 icon-color="white"
								 label="No"
								 label-color="white"/>

						<app-btn @click.native="handlePrintPassChangeConfirmation(true)"
								 color="green"
								 icon="success"
								 icon-color="white"
								 label="Yes"
								 label-color="white"/>

					</div>

				</div>

			</div>
		</v-dialog>

	</div>

</template>

<script>

import AccreditationPrintPass_principality
	from "@/views/accreditation/staff/accreditationPrintPass/AccreditationPrintPass_principality.vue";
import AccreditationPrintPass_glamorganCricket
	from "@/views/accreditation/staff/accreditationPrintPass/AccreditationPrintPass_glamorganCricket.vue";

export default {

	name: "AccreditationPrintManager",

	components: {AccreditationPrintPass_principality, AccreditationPrintPass_glamorganCricket},

	props: ['accreditationData'],

	data: () => ({
		filterByPrintStatus: 'Ready to Print',
		isLoading: true,
		isPrintStatusChangeConfirmationDialogVisible: false,
		isPassPrintPreviewDialogVisible: false,
		isSelectAllActive: false,
		isUpdatePrintStatusDialogVisible: false,
		moreActionsMenuOption: [
			{name: 'Update Print Status', icon: 'print'},
		],
		passesAlreadyPrinted: [],
		passesReadyToPrint: [],
		searchByOrganisationName: '',
		searchByUserName: '',
		selectedUsers: [],
		tabs: '',

		// Data
		eventData: {},
		eventImageData: {},
	}),

	computed: {

		/**
		 * Computed Table Data
		 */
		computedTableData() {
			const t = this
			let tableData = []

			// Filter by Print Status
			if (t.filterByPrintStatus === 'Printed') tableData = t.passesAlreadyPrinted
			if (t.filterByPrintStatus === 'Ready to Print') tableData = t.passesReadyToPrint

			// Search by UserName
			if (t.searchByUserName) {
				const USER_NAME_SEARCH = t.searchByUserName.toUpperCase()
				tableData = tableData.filter(item => {
					const USER_NAME = item.userData.userName.toUpperCase()
					return USER_NAME.includes(USER_NAME_SEARCH)
				})
			}

			// Search by OrganisationName
			if (t.searchByOrganisationName) {
				const ORGANISATION_NAME_SEARCH = t.searchByOrganisationName.toUpperCase()
				tableData = tableData.filter(item => {
					const ORGANISATION_NAME = item.organisationData.organisationName.toUpperCase()
					return ORGANISATION_NAME.includes(ORGANISATION_NAME_SEARCH)
				})
			}

			// If the select all checkbox is active, add all table users to the selectedUsers array
			if (t.isSelectAllActive) t.selectedUsers = tableData

			// Sort by UserName
			tableData = tableData.sort((a, b) => a.userData.userName > b.userData.userName ? 1 : -1)

			return tableData
		},

	},

	methods: {

		/**
		 * Get Print Button Config
		 *
		 * Get the print button's config based on the print status and the number of users in the selectedUsers array.
		 *
		 * @returns {{isDisabled: boolean, label: string, labelColor: string}}
		 */
		getPrintButtonConfig() {
			const {
				filterByPrintStatus: PRINT_STATUS,
				selectedUsers: SELECTED_USERS
			} = this
			let isDisabled = false
			let label = ''
			let labelColor = ''

			if (PRINT_STATUS === 'Ready to Print') {
				if (SELECTED_USERS.length) {
					label = `Print Selected (${SELECTED_USERS.length})`
					labelColor = 'white'
				} else {
					isDisabled = true
					label = `Print`
					labelColor = 'grey9'
				}
			}

			if (PRINT_STATUS === 'Printed') {
				if (SELECTED_USERS.length) {
					label = `Reprint Selected (${SELECTED_USERS.length})`
					labelColor = 'white'
				} else {
					isDisabled = true
					label = `Reprint`
					labelColor = 'grey9'
				}
			}

			return {isDisabled, label, labelColor}
		},

		handleCloseDialog() {
			const t = this

			t.isPassPrintPreviewDialogVisible = false

			t.isPrintStatusChangeConfirmationDialogVisible = true
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this

			if (selection === 'Update Print Status') t.handleUpdatePassPrintStatus()
		},

		handlePrintPassChangeConfirmation(isPrinted) {
			const t = this

			if (isPrinted) t.updatePrintStatuses(isPrinted)
			else t.$emit('reloadData')

			t.isPrintStatusChangeConfirmationDialogVisible = false
		},

		handleUpdatePassPrintStatus() {
			const t = this

			t.isUpdatePrintStatusDialogVisible = true
		},

		/**
		 * Handle Select All
		 *
		 * If select all is active, add all users to the selectedUsers array, otherwise clear the selectedUsers array.
		 */
		handleSelectAll() {
			const t = this

			t.isSelectAllActive = !t.isSelectAllActive

			// If select all is active, add all users to the selectedUsers array, otherwise clear the selectedUsers array
			if (t.isSelectAllActive) t.selectedUsers = t.computedTableData
			else t.selectedUsers = []
		},

		/**
		 * Handle User Selection
		 *
		 * If the user is already in the selectedUsers array, remove them, otherwise add them.
		 * If all users are selected, check the select all checkbox, otherwise uncheck it.
		 *
		 * @param userPassData {Object} - The user's pass data
		 */
		handleUserSelection(userPassData) {
			const t = this

			// If the user is already in the selectedUsers array, remove them
			if (t.selectedUsers.includes(userPassData)) t.selectedUsers = t.selectedUsers.filter(item => item !== userPassData)

			// Otherwise, add them to the selectedUsers array
			else t.selectedUsers.push(userPassData)

			// If all users are selected, check the select all checkbox, otherwise uncheck it
			t.isSelectAllActive = t.selectedUsers.length === t.passesReadyToPrint.length
		},

		printPasses() {
			const t = this

			// If there are no passes in the selectedUsers array, return
			if (!t.selectedUsers.length) return

			t.isPassPrintPreviewDialogVisible = true
		},

		updatePrintStatuses(isPrinted) {
			const t = this

			t.selectedUsers.forEach(item => item.passData.accreditationPassPrinted = isPrinted)

			const PASS_IDS = t.selectedUsers.map(item => item.passData.entityId)

			t.$emit('emitUpdatePassPrintStatuses', {passIds: PASS_IDS, isPrinted})
		}

	},

	mounted() {
		const t = this

		t.isLoading = true

		t.eventData = t.$props.accreditationData.eventData
		t.eventImageData = t.$props.accreditationData.eventImageData

		// Organisation's Passes Data
		const ORGANISATION_PASSES_DATA = t.$props.accreditationData.passesData

		// Users Passes Data
		ORGANISATION_PASSES_DATA.forEach(op => {
			op.userPassData.forEach(up => {

				const {passData: PASS_DATA} = up

				// Ensure passData exists
				if (!PASS_DATA) return

				const PASS_INFO = {
					organisationData: op.organisationData,
					passData: PASS_DATA,
					userData: up.userData
				}

				if (PASS_DATA.accreditationPassStatus === 'Approved') {

					// Ready to Print - Approved, but not printed
					if (!PASS_DATA.accreditationPassPrinted) t.passesReadyToPrint.push(PASS_INFO)

					// Already Printed - Approved and printed
					else t.passesAlreadyPrinted.push(PASS_INFO)
				}

			})
		})

		t.isLoading = false

	}

}
</script>

<style scoped>

</style>
