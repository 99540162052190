<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<!--Page Content-->
	<div v-else>

		<!--Header-->
		<page-title :divider="true"
					icon="organisation"
					info="View and administer Organisations"
					title="Organisations"/>

		<!--Action Bar-->
		<div class="d-flex align-center mt-4">

			<!--Search-->
			<app-form-field form-type="textInput"
							append-icon="icons8-search"
							class="mr-4"
							:clearable="true"
							label="Organisation Name"
							style="width: 100%"
							v-model.trim="searchByOrganisationName"/>

			<!--Add Button-->
			<app-btn @click.native="openRightPanel" class="mr-4" icon="add" label="Add"/>

			<!--Filter Button - with a numbered badge-->
			<div style="position:relative;">
				<app-btn @click.native="toggleFiltersVisibility"
						 class="mr-4" color="appWhite" icon="filter" icon-color="primary"/>
				<span v-if="computedNumberOfActiveFilters > 0" class="badge">{{ computedNumberOfActiveFilters }}</span>
			</div>

			<!--More Actions Menu-->
			<more-actions-menu @emitMenuSelection="handleEmittedMenuSelection" :menuList="moreActionsMenuOption"/>

		</div>

		<!--Clear Filters-->
		<div v-if="computedNumberOfActiveFilters > 0" class="d-flex justify-end mt-4">
			<app-btn @click.native="clearFilters" icon="close" label="Clear Filters"/>
		</div>

		<!--Table-->
		<v-data-table v-if="$vuetify.breakpoint.width >= 600"
					  class="appWhite rounded-lg mt-4"
					  :headers="computedHeaders"
					  :items="computedTableData">

			<!--ID-->
			<template v-slot:item.id="{item}">
				<app-text size="small">{{ item.id }}</app-text>
			</template>

			<!--Organisation Name-->
			<template v-slot:item.organisationName="{item}">
				<app-text size="small">{{ item.organisationData.organisationName }}</app-text>
			</template>

			<!--Manager's Name-->
			<template v-slot:item.managerName="{item}">
				<app-text size="small">{{ getManagersDetails(item)?.userData?.userName }}</app-text>
			</template>

			<!--Manager's Email-->
			<template v-slot:item.managerEmail="{item}">
				<app-text size="small">{{ getManagersDetails(item)?.userData?.userEmail }}</app-text>
			</template>

			<!--Action Button-->
			<template v-slot:item.action="{item}">
				<app-icon @click.native="openRightPanel(item)"
						  class="cursorPointer" color="primary" icon="arrowForward"/>
			</template>

		</v-data-table>

		<!--Mobile Cards-->
		<div v-if="$vuetify.breakpoint.width < 600">

			<!--No Organisations Message-->
			<app-text v-if="!computedTableData.length" class="text-center mt-4" color="grey9">
				You have no Organisations to view
			</app-text>

			<organisation-mobile-card v-for="item in computedTableData" :key="item.organisationData.entityId"
									  @click.native="openRightPanel(item)"
									  :cardData="item"
									  :readOnly="isReadOnly"/>

		</div>

		<!--Filters Panel ------------------------------------------------------------------------------------------ -->
		<filter-panel :is-filters-panel-visible="isFiltersPanelVisible" @toggle="toggleFiltersVisibility">
			<div class="pa-4">

				<!--Statuses-->
				<page-break-title title="Statuses"/>
				<div class="mt-4"
					 style="display: grid; grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); grid-gap: 16px">
					<app-btn @click.native="handleFilterStatusSelection('Active')"
							 :block="true"
							 color="green"
							 label="Active"
							 :outlined="!filterByStatuses.includes('Active')"/>
					<app-btn @click.native="handleFilterStatusSelection('Locked')"
							 :block="true"
							 color="red"
							 label="Locked"
							 :outlined="!filterByStatuses.includes('Locked')"/>
				</div>

				<!--Manager's Name-->
				<page-break-title class="mt-8" title="Manager's Name"/>
				<div class="mt-4">
					<app-form-field form-type="textInput"
									label="Manager's Name"
									v-model.trim="filterByManagersName"/>
				</div>

			</div>
		</filter-panel>

		<!--Right Side --------------------------------------------------------------------------------------------- -->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">

			<div v-if="isRightPanelVisible"
				 class="appGrey formShadow"
				 style="position:absolute; top:0; bottom: 0; right: 0; overflow: auto"
				 :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 50%'">

				<!--Header-->
				<div class="d-flex align-center primary pa-4">
					<app-text color="appWhite" size="normal">
						{{ selectedItem?.organisationData?.organisationName || 'New' }}
					</app-text>
					<v-spacer/>
					<app-icon @click.native="closeRightPanel" class="cursorPointer" color="white" icon="close"
							  size="32"/>
				</div>

				<!--Action Bar-->
				<div class="d-flex align-center pa-4">

					<v-spacer/>

					<edit-icon @click.native="editItem" :isActive="!isReadOnly"/>
					<delete-icon v-if="selectedItem?.organisationData?.entityId && ['Staff-Admin'].includes(MIX_getCurrentUser().userLevel)"
								 @emitDeleteItem="deleteItem(selectedItem?.organisationData)"
								 itemName="organisationName"
								 :selectedItem="selectedItem?.organisationData"/>

				</div>

				<!--Tabs-->
				<v-tabs v-model="tabs">

					<!--Overview-->
					<v-tab @click="selectedUser = {}" href="#overview">
						<app-text size="small">Overview</app-text>
					</v-tab>

					<!--Members-->
					<v-tab v-if="selectedItem?.organisationData?.entityId" @click="selectedUser = {}" href="#members">
						<app-text size="small">Members</app-text>
					</v-tab>

					<!--User Form-->
					<v-tab v-if="selectedUser?.userData?.entityId" href="#userForm">
						<app-text size="small">User Form</app-text>
					</v-tab>

				</v-tabs>

				<!--Tabs Content-->
				<v-tabs-items v-model="tabs">

					<!--Overview-->
					<v-tab-item value="overview">
						<organisation-form @emitReloadPage="emittedReloadPage"
										   class="pa-4"
										   :formData="selectedItem"
										   :isReadOnly="isReadOnly"/>
					</v-tab-item>

					<!--Members-->
					<v-tab-item value="members">
						<organisation-members @emitReloadPage="emittedReloadPage"
											  @emitSelectedUser="emittedHandleSelectedUser"
											  class="pa-4"
											  :formData="selectedItem"
											  :isReadOnly="isReadOnly"/>
					</v-tab-item>

					<!--User Form-->
					<v-tab-item class="appGrey" value="userForm">
						<organisation-user-form @emitReloadPage="emittedReloadPage"
												class="pa-4"
												:formData="selectedUser"
												:isReadOnly="isReadOnly"/>
					</v-tab-item>

				</v-tabs-items>

			</div>

		</transition>

	</div>

</template>

<script>
import OrganisationForm from "@/views/organisations/components/organisationForm/OrganisationForm.vue";
import OrganisationMobileCard from "@/views/organisations/components/organisationMobileCard/OrganisationMobileCard.vue";
import OrganisationMembers from "@/views/organisations/components/organisationMembers/OrganisationMembers.vue";
import OrganisationUserForm from "@/views/organisations/components/organisationUserForm/OrganisationUserForm.vue";

export default {

	name: "Organisations",

	components: {OrganisationUserForm, OrganisationMembers, OrganisationMobileCard, OrganisationForm},

	data: () => ({
		filterByManagersName: '',
		filterByStatuses: [],
		isFiltersPanelVisible: false,
		isRightPanelVisible: false,
		isLoading: true,
		isReadOnly: false,
		moreActionsMenuOption: [
			{name: 'Export', icon: 'export'},
		],
		searchByOrganisationName: '',
		searchByManagerName: '',
		selectedItem: {},
		selectedUser: {},
		tableHeaders: [
			{text: 'ID', value: 'id', align: 'left', sortable: false, hidden: true},
			{text: 'Organisation', value: 'organisationName', align: 'left', sortable: false},
			{text: 'Manager\'s Name', value: 'managerName', align: 'left', sortable: false},
			{text: 'Manager\'s Email', value: 'managerEmail', align: 'left', sortable: false},
			{text: '', value: 'action', align: 'center', sortable: false, width: '48px'},
		],
		tabs: '',

		fullOrganisationsData: [],
	}),

	computed: {

		/**
		 * Computed Export CSV
		 *
		 * Return the data and headers for the CSV export
		 *
		 * @returns {{headers: {}, data: *[]}}
		 */
		computedExportCSV() {
			const t = this
			let data = []
			let headers = {}

			// Add the readable headers for the CSV columns
			headers = {
				entityId: 'ID',
				organisationName: 'Name',
				organisationStatus: 'Status',
				organisationManagers: 'Manager',
				organisationDefaultZones1: 'Default Zone 1',
				organisationDefaultZones2: 'Default Zone 2',
				organisationDefaultZones3: 'Default Zone 3',
				organisationDefaultZones4: 'Default Zone 4',
				organisationDefaultZones5: 'Default Zone 5',
			}

			// Add the data
			t.computedTableData.forEach(entry => {

				const DATA_OBJECT = {
					entityId: entry?.organisationData?.entityId,
					organisationName: entry?.organisationData?.organisationName,
					organisationStatus: entry?.organisationData?.organisationStatus,
					organisationManagers: entry?.usersData[0]?.userData?.userName,
					organisationDefaultZones1: JSON.parse(entry?.organisationData?.organisationDefaultZones)[0].hasAccess,
					organisationDefaultZones2: JSON.parse(entry?.organisationData?.organisationDefaultZones)[1].hasAccess,
					organisationDefaultZones3: JSON.parse(entry?.organisationData?.organisationDefaultZones)[2].hasAccess,
					organisationDefaultZones4: JSON.parse(entry?.organisationData?.organisationDefaultZones)[3].hasAccess,
					organisationDefaultZones5: JSON.parse(entry?.organisationData?.organisationDefaultZones)[4].hasAccess,
				}

				data.push(DATA_OBJECT)
			})

			return {headers, data}
		},

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this
			const CURRENT_USER_DATA = t.MIX_getCurrentUser()
			let headers = t.tableHeaders

			// Remove hidden headers
			headers = headers.filter(h => !h.hidden)

			// Only those with access can view the actions column
			if (!['Staff-Admin', 'Staff-Manager'].includes(CURRENT_USER_DATA.userLevel)) headers = headers.filter(h => h.value !== 'action')

			return headers
		},

		/**
		 * Computed Number of Active Filters
		 *
		 * Count the number of active filters to display in the filters button badge.
		 *
		 * @returns {*}
		 */
		computedNumberOfActiveFilters() {
			const t = this
			return [
				t.filterByManagersName ? 1 : 0,
				t.filterByStatuses.length,
			].reduce((acc, curr) => acc + curr, 0)
		},

		/**
		 * Computed Table Data
		 *
		 * Sort and return the table data.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedTableData() {
			const t = this
			let tableData = t.fullOrganisationsData

			// Search by Organisation Name
			if (t.searchByOrganisationName) {
				const SEARCH_ORGANISATION_NAME = t.searchByOrganisationName.toUpperCase()
				tableData = tableData.filter(item => {
					const ORGANISATION_NAME = item.organisationData.organisationName.toUpperCase()
					return ORGANISATION_NAME.includes(SEARCH_ORGANISATION_NAME)
				})
			}

			// Filter by Statuses
			if (t.filterByStatuses.length) tableData = tableData.filter(item => t.filterByStatuses.includes(item.organisationData.organisationStatus))

			// Search by Manager Name
			if (t.filterByManagersName) {
				const SEARCH_CRITERIA = t.filterByManagersName.toUpperCase()
				tableData = tableData.filter(item => {
					const MANAGER_NAME = t.getManagersDetails(item)?.userData?.userName.toUpperCase()
					return MANAGER_NAME.includes(SEARCH_CRITERIA)
				})
			}

			tableData = tableData.sort((a, b) => a.organisationData.organisationName > b.organisationData.organisationName ? 1 : -1)

			return tableData
		},

	},

	methods: {

		/**
		 * Clear Filters
		 *
		 * Clear all the filters.
		 */
		clearFilters() {
			const t = this

			t.filterByManagersName = ''
			t.filterByStatuses = []
		},

		/**
		 * Close Right Panel
		 *
		 * Close the right panel.
		 */
		closeRightPanel() {
			const t = this

			t.tabs = ''
			t.selectedItem = {}
			t.isRightPanelVisible = false
		},

		/**
		 *
		 * Delete Item
		 *
		 * Delete the selected document by its ID.
		 *
		 * @param itemData {object} the item to delete
		 */
		async deleteItem(itemData) {
			const t = this

			// Stringify the default zones as they are stored as a string in the DB
			itemData.organisationDefaultZones = JSON.stringify(itemData.organisationDefaultZones)

			const RESPONSE = await t.MIX_redis_delete('organisation', itemData.entityId, itemData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error deleting Organisation', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Deleted Organisation'

			// Reload the data
			await t.loadData()

			// Close the panel
			t.closeRightPanel()
		},

		/**
		 * Edit Item
		 *
		 * Toggle the editability of the form.
		 */
		editItem() {
			const t = this

			t.isReadOnly = !t.isReadOnly
		},

		emittedHandleSelectedUser(user) {
			const t = this

			t.selectedUser = user
			t.tabs = 'userForm'
		},

		/**
		 * Emitted Reload Data
		 *
		 * Reload the data to refresh.
		 */
		emittedReloadData() {
			const t = this

			t.loadData()
		},

		/**
		 * Emitted Reload Page
		 *
		 * Close the panel and Reload the data to refresh the page.
		 */
		emittedReloadPage() {
			const t = this

			t.closeRightPanel()
			t.loadData()
		},

		/**
		 * Export Data
		 *
		 * Export the data to CSV.
		 */
		exportData() {
			const t = this

			t.MIX_exportDocuments(t.computedExportCSV.headers, 'Organisations', t.computedExportCSV.data)
		},

		/**
		 * Get Manager's Details
		 *
		 * Get and return the User data for the Manager.
		 *
		 * @param item {object} the full item to get the manager's details for.
		 * @returns {object} the user object for the manager (fileData{}, userData{})
		 */
		getManagersDetails(item) {
			const USER_DATA = item.usersData.find(user => user.userData.entityId === item.organisationData.organisationManagers[0])

			return USER_DATA
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this

			if (selection === 'Export') t.exportData()
		},

		/**
		 * Handle Filter Status Selection
		 *
		 * Add or remove the selected status from the filterStatuses array.
		 *
		 * @param status {string} the status to add or remove
		 */
		handleFilterStatusSelection(status) {
			const t = this

			if (t.filterByStatuses.includes(status)) t.filterByStatuses = t.filterByStatuses.filter(s => s !== status)
			else t.filterByStatuses.push(status)
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadFullOrganisationsData(),
			])

			t.isLoading = false
		},

		/**
		 * Load Full Organisations Data
		 *
		 * Load the data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadFullOrganisationsData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getFullOrganisationsData()

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Organisations: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'The was a problem getting the Organisations, please try again.'
				return
			}

			// Assign the data to the state
			t.fullOrganisationsData = RESPONSE.data
		},

		/**
		 * Open Form
		 *
		 * Open the form dialog.
		 * If no item is passed in, the form will open as new (blank).
		 *
		 * @param item the selected key
		 */
		openRightPanel(item) {
			const t = this

			// Existing item
			if (item?.organisationData?.entityId) {
				t.isReadOnly = true
				t.selectedItem = item
			}

			// New item
			else {
				t.isReadOnly = false
				t.selectedItem = {}
			}

			t.isRightPanelVisible = true
		},

		/**
		 * Toggle Filters Visibility
		 *
		 * Toggle the visibility of the filters panel.
		 */
		toggleFiltersVisibility() {
			const t = this

			t.isFiltersPanelVisible = !t.isFiltersPanelVisible
		},

	},

	async mounted() {
		const t = this

		await t.loadData()
	},

}
</script>

<style scoped>
</style>
