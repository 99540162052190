<template>
	<v-dialog class="appDialog-container" persistent v-model="isVisible" max-width="512">

		<template v-slot:default>

			<!--<v-toolbar color="primary">-->
			<!--	<app-text color="white" size="medium">Toolbar</app-text>-->
			<!--</v-toolbar>-->

			<slot></slot>

		</template>

	</v-dialog>
</template>

<script>
export default {

	name: "AppDialog",

	props: ['isVisible'],

}
</script>

<style scoped>
.appDialog-container {
	position: relative;
	z-index: 9999999;
}
</style>
