<template>
	<v-dialog fullscreen
			  persistent
			  v-model="dialog">

		<div class="d-flex flex-column justify-center align-center primary" style="height: 100vh">

			<img :src="require('@/assets/images/company-logo-white.svg')" width="300" height="300" alt="Company Logo"/>

			<v-divider class="mt-8" style="width: 100%"/>

			<div class="rounded-lg white mt-8 pa-4">
				<app-text class="text-center" color="primary" size="large-bold">{{ maintenanceData.downForMaintenanceTitle }}</app-text>
			</div>

			<app-text class="text-center mt-8" color="white">{{ maintenanceData.downForMaintenanceMessage }}</app-text>

		</div>

	</v-dialog>
</template>

<script>

export default {

	name: "DownForMaintenance",

	props: ['maintenanceData'],

	data: () => ({
		dialog: true,
	}),

	methods: {},

}
</script>

<style scoped>

</style>
