<template>
	<div class="appGrey">

		{{ computedInit }}

		<!--Form-->
		<v-row no-gutters>

			<!--Event Details -------------------------------------------------------------------------------------- -->
			<div style="width: 100%">

				<form-section-title title="Event Details"/>

				<!--Event Selection-->
				<app-form-field form-type="autoComplete"
								:disabled="isReadOnly"
								:error="errors.selectedEvent"
								:error-message="errors.selectedEventErrorMessage"
								:items="eventsData"
								item-text="eventName"
								label="Select an Event"
								:return-object="true"
								v-model="selectedEvent"/>

			</div>

			<!--Car Park Details ------------------------------------------------------------------------------------ -->
			<div class="mt-8" style="width: 100%">

				<form-section-title title="Car Park Details"/>

				<!--Car Park Selection-->
				<app-form-field form-type="select"
								:disabled="form.entityId && !!form.parkingSpaceReservedList.length"
								:error="errors.selectedCarPark"
								:error-message="errors.selectedCarParkErrorMessage"
								:hint="form.entityId && !!form.parkingSpaceReservedList.length ? 'You cannot change the Car Park as you have Reserved Spaces.' : ''"
								:items="carParksData"
								item-text="carParkName"
								label="Select a Car Park"
								:persistent-hint="true"
								:return-object="true"
								v-model.trim="selectedCarPark"/>

			</div>

			<!--Recipient Details ---------------------------------------------------------------------------------- -->
			<div class="mt-8" style="width: 100%">

				<form-section-title description="Create parking for organisations or individuals"
									title="Recipient Details"/>

				<app-text>What type of recipient is this?</app-text>

				<!--Checkboxes-->
				<div class="d-flex justify-space-around mt-4" style="width: 100%">
					<div v-for="option in ['Organisation', 'Individual', 'Reserved']" :key="option"
						 class="d-flex flex-column align-center">

						<!--Title-->
						<app-text color="grey9">{{ option }}</app-text>

						<!--Checkbox-->
						<div @click="handleRecipientTypeSelection(option)"
							 class="appWhite d-flex align-center justify-center rounded-lg mt-1"
							 style="height: 56px; width: 56px">

							<!--Icon-->
							<app-icon v-if="recipientType === option" class="pa-2" color="green" icon="check"
									  size="32"/>

						</div>

					</div>
				</div>

				<!--Organisation-->
				<app-form-field v-if="recipientType === 'Organisation'"
								form-type="autoComplete"
								class="mt-4"
								:disabled="isReadOnly"
								:error="errors.selectedOrganisation"
								:error-message="errors.selectedOrganisationErrorMessage"
								:items="organisationsData"
								item-text="organisationName"
								label="Select an Organisation"
								:return-object="true"
								v-model="selectedOrganisation"/>

				<!--Individual-->
				<v-row v-if="recipientType === 'Individual'" no-gutters>

					<!--Individual's Name-->
					<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
						   :cols="$vuetify.breakpoint.width < 600 ? '12' : '6'">
						<app-form-field form-type="textInput"
										:disabled="isReadOnly"
										:error="errors.parkingSpaceIndividualName"
										:error-message="errors.parkingSpaceIndividualNameErrorMessage"
										label="Individual's Name"
										v-model.trim="form.parkingSpaceIndividualName"/>
					</v-col>

					<!--Individual's Email-->
					<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
						   :cols="$vuetify.breakpoint.width < 600 ? '12' : '6'">
						<app-form-field form-type="textInput"
										:disabled="isReadOnly"
										:error="errors.parkingSpaceIndividualEmail"
										:error-message="errors.parkingSpaceIndividualEmailErrorMessage"
										type="email"
										label="Individual's Email"
										v-model.trim="form.parkingSpaceIndividualEmail"/>
					</v-col>

				</v-row>

				<!--Reserved Spaces-->
				<div v-if="recipientType === 'Reserved'" class="d-flex flex-column" style="width: 100%">

					<!--Reserved Spaces - List-->
					<v-expansion-panels class="mt-4" flat>
						<v-expansion-panel class="appWhite rounded-lg">

							<!--Header-->
							<v-expansion-panel-header hide-actions>
								<app-text class="text-center" color="grey9" size="normal-bold">Manage Spaces</app-text>
							</v-expansion-panel-header>

							<!--Content-->
							<v-expansion-panel-content>

								<!--No Reserved Spaces message-->
								<app-text class="text-center" color="grey9">
									{{ computedReservedSpaces.message }}
								</app-text>

								<!--Error Messages-->
								<app-text class="text-center" color="red" size="small"
										  v-html="errors.reservedSpaceMissingDataErrorMessage"/>
								<app-text class="text-center" color="red" size="small"
										  v-html="errors.reservedSpaceInvalidEmailErrorMessage"/>

								<!--Rows-->
								<div v-if="computedReservedSpaces.visibility">
									<div v-for="(item, index) in form.parkingSpaceReservedList" :key="index"
										 class="d-flex align-center mt-8">

										<!--Number-->
										<app-text class="mr-4" size="normal-bold">{{ item.reservedNumber }}</app-text>

										<!--Name | Email-->
										<v-row no-gutters>

											<!--Name-->
											<v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'"
												   :cols="$vuetify.breakpoint.width < 600 ? '12' : '6'">
												<app-form-field form-type="textInput"
																:disabled="isReadOnly"
																label="Name"
																v-model.trim="item.reservedName"/>
											</v-col>

											<!--Email Address-->
											<v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'pl-2'"
												   :cols="$vuetify.breakpoint.width < 600 ? '12' : '6'">
												<app-form-field form-type="textInput"
																:disabled="isReadOnly"
																label="Email"
																v-model.trim="item.reservedEmail"/>
											</v-col>

										</v-row>

									</div>
								</div>

							</v-expansion-panel-content>

						</v-expansion-panel>
					</v-expansion-panels>

				</div>

			</div>

			<!--Parking Spaces ------------------------------------------------------------------------------------- -->
			<div v-if="isVehicleSectionVisible" class="mt-8" style="width: 100%">

				<form-section-title description="Enter the numbers required for each type of vehicle"
									title="Parking Spaces"/>
				<app-text v-if="errors.noVehiclesError" color="red" size="small">
					{{ errors.noVehiclesErrorMessage }}
				</app-text>

				<!--Vehicle Types-->
				<div class="mt-4" style="width: 100%">

					<!--Title-->
					<div class="d-flex align-center">
						<v-divider class="greyD"/>
						<app-text class="mx-4" color="grey9" size="normal-bold">Regular</app-text>
						<v-divider class="greyD"/>
					</div>

					<!--Types of Vehicles-->
					<div class="text-center mt-4"
						 style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 16px">

						<!--Small-->
						<div>
							<app-icon color="primary" icon="car" size="48"/>
							<app-text size="normal-bold">Small</app-text>
							<app-text class="my-2" color="grey9" size="small">Motorbikes/Cars</app-text>
							<app-form-field form-type="textInput"
											:disabled="isReadOnly"
											:error="errors.parkingSpaceNumberOfRegularSmallVehiclesRequired"
											:error-message="errors.parkingSpaceNumberOfRegularSmallVehiclesRequiredErrorMessage"
											type="number"
											v-model.number.trim="form.parkingSpaceNumberOfRegularSmallVehiclesRequired"/>
						</div>

						<!--Medium-->
						<div>
							<app-icon color="primary" icon="minibus" size="48"/>
							<app-text size="normal-bold">Medium</app-text>
							<app-text class="my-2" color="grey9" size="small">Vans, Minibuses</app-text>
							<app-form-field form-type="textInput"
											:disabled="isReadOnly"
											:error="errors.parkingSpaceNumberOfRegularMediumVehiclesRequired"
											:error-message="errors.parkingSpaceNumberOfRegularMediumVehiclesRequiredErrorMessage"
											type="number"
											v-model.number.trim="form.parkingSpaceNumberOfRegularMediumVehiclesRequired"/>
						</div>

						<!--Large-->
						<div>
							<app-icon color="primary" icon="truck" size="48"/>
							<app-text size="normal-bold">Large</app-text>
							<app-text class="my-2" color="grey9" size="small">Coaches, Trucks</app-text>
							<app-form-field form-type="textInput"
											:disabled="isReadOnly"
											:error="errors.parkingSpaceNumberOfRegularLargeVehiclesRequired"
											:error-message="errors.parkingSpaceNumberOfRegularLargeVehiclesRequiredErrorMessage"
											type="number"
											v-model.number.trim="form.parkingSpaceNumberOfRegularLargeVehiclesRequired"/>
						</div>

					</div>

				</div>

				<!--Disabled Vehicle Types-->
				<div class="mt-8" style="width: 100%">

					<!--Title-->
					<div class="d-flex align-center">
						<v-divider class="greyD"/>
						<app-text class="mx-4" color="grey9" size="normal-bold">Accessible</app-text>
						<v-divider class="greyD"/>
					</div>

					<!--Types of Vehicles-->
					<div class="text-center mt-4"
						 style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 16px">

						<!--Small-->
						<div>

							<!--Icons-->
							<div class="d-flex justify-center">
								<app-icon color="blue" icon="disabled" size="24"/>
								<app-icon color="blue" icon="car" size="48"/>
							</div>

							<!--Size-->
							<app-text size="normal-bold">Small</app-text>

							<!--Example-->
							<app-text class="my-2" color="grey9" size="small">Motorbikes/Cars</app-text>

							<!--Input Field-->
							<app-form-field form-type="textInput"
											:disabled="isReadOnly"
											:error="errors.parkingSpaceNumberOfDisabledSmallVehiclesRequired"
											:error-message="errors.parkingSpaceNumberOfDisabledSmallVehiclesRequiredErrorMessage"
											type="number"
											v-model.number.trim="form.parkingSpaceNumberOfDisabledSmallVehiclesRequired"/>

						</div>

						<!--Medium-->
						<div>

							<!--Icons-->
							<div class="d-flex justify-center">
								<app-icon color="blue" icon="disabled" size="24"/>
								<app-icon color="blue" icon="minibus" size="48"/>
							</div>

							<!--Size-->
							<app-text size="normal-bold">Medium</app-text>

							<!--Example-->
							<app-text class="my-2" color="grey9" size="small">Vans, Minibuses</app-text>

							<!--Input Field-->
							<app-form-field form-type="textInput"
											:disabled="isReadOnly"
											:error="errors.parkingSpaceNumberOfDisabledMediumVehiclesRequired"
											:error-message="errors.parkingSpaceNumberOfDisabledMediumVehiclesRequiredErrorMessage"
											type="number"
											v-model.number.trim="form.parkingSpaceNumberOfDisabledMediumVehiclesRequired"/>

						</div>

						<!--Large-->
						<div>

							<!--Icons-->
							<div class="d-flex justify-center">
								<app-icon color="blue" icon="disabled" size="24"/>
								<app-icon color="blue" icon="truck" size="48"/>
							</div>

							<!--Size-->
							<app-text size="normal-bold">Large</app-text>

							<!--Example-->
							<app-text class="my-2" color="grey9" size="small">Coaches, Trucks</app-text>

							<!--Input Field-->
							<app-form-field form-type="textInput"
											:disabled="isReadOnly"
											:error="errors.parkingSpaceNumberOfDisabledLargeVehiclesRequired"
											:error-message="errors.parkingSpaceNumberOfDisabledLargeVehiclesRequiredErrorMessage"
											type="number"
											v-model.number.trim="form.parkingSpaceNumberOfDisabledLargeVehiclesRequired"/>

						</div>

					</div>

				</div>

			</div>

			<!--Notes ---------------------------------------------------------------------------------------------- -->
			<form-section-title class="mt-8" title="Notes"/>

			<!--Notes-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : ''"
				   :cols="$vuetify.breakpoint.width < 600 ? '12' : '12'">
				<app-form-field form-type="textArea"
								:disabled="isReadOnly"
								label="Notes (optional)"
								v-model.trim="form.parkingSpaceNotes"/>
			</v-col>

		</v-row>

		<!--Save Button-->
		<div v-if="!isReadOnly" class="d-flex justify-end mt-4">
				<app-btn @click.native="handleSaveItem"
						 color="green"
						 icon="save"
						 label="Save"/>
		</div>

	</div>
</template>

<script>

export default {

	name: "EventParkingForm",

	props: ['carParksData', 'eventsData', 'formData', 'isReadOnly', 'organisationsData'],

	data: () => ({
		errors: {
			noVehiclesError: '',
			noVehiclesErrorMessage: false,
			parkingSpaceIndividualEmail: false,
			parkingSpaceIndividualEmailErrorMessage: '',
			parkingSpaceIndividualName: false,
			parkingSpaceIndividualNameErrorMessage: '',
			parkingSpaceNumberOfDisabledLargeVehiclesRequired: false,
			parkingSpaceNumberOfDisabledLargeVehiclesRequiredErrorMessage: '',
			parkingSpaceNumberOfDisabledMediumVehiclesRequired: false,
			parkingSpaceNumberOfDisabledMediumVehiclesRequiredErrorMessage: '',
			parkingSpaceNumberOfDisabledSmallVehiclesRequired: false,
			parkingSpaceNumberOfDisabledSmallVehiclesRequiredErrorMessage: '',
			parkingSpaceNumberOfRegularLargeVehiclesRequired: false,
			parkingSpaceNumberOfRegularLargeVehiclesRequiredErrorMessage: '',
			parkingSpaceNumberOfRegularMediumVehiclesRequired: false,
			parkingSpaceNumberOfRegularMediumVehiclesRequiredErrorMessage: '',
			parkingSpaceNumberOfRegularSmallVehiclesRequired: false,
			parkingSpaceNumberOfRegularSmallVehiclesRequiredErrorMessage: '',
			selectedCarPark: false,
			selectedCarParkErrorMessage: '',
			selectedEvent: false,
			selectedEventErrorMessage: '',
			selectedOrganisation: false,
			selectedOrganisationErrorMessage: '',
		},
		form: {
			parkingSpaceCarParkId: '',
			parkingSpaceDaysRequired: [],
			parkingSpaceEventId: '',
			parkingSpaceIndividualEmail: '',
			parkingSpaceIndividualName: '',
			parkingSpaceNotes: '',
			parkingSpaceNumberOfDisabledLargeVehiclesArrived: 0,
			parkingSpaceNumberOfDisabledLargeVehiclesRequired: 0,
			parkingSpaceNumberOfDisabledMediumVehiclesArrived: 0,
			parkingSpaceNumberOfDisabledMediumVehiclesRequired: 0,
			parkingSpaceNumberOfDisabledSmallVehiclesArrived: 0,
			parkingSpaceNumberOfDisabledSmallVehiclesRequired: 0,
			parkingSpaceNumberOfRegularLargeVehiclesArrived: 0,
			parkingSpaceNumberOfRegularLargeVehiclesRequired: 0,
			parkingSpaceNumberOfRegularMediumVehiclesArrived: 0,
			parkingSpaceNumberOfRegularMediumVehiclesRequired: 0,
			parkingSpaceNumberOfRegularSmallVehiclesArrived: 0,
			parkingSpaceNumberOfRegularSmallVehiclesRequired: 0,
			parkingSpaceNumberOfReservedSpaces: 0,
			parkingSpaceOrganisationId: '',
			parkingSpaceParkingType: 'Event Parking',
			parkingSpaceReservedList: [],
			parkingSpaceStatus: '',
			parkingSpaceUserId: '',
			parkingSpaceVisitorName: '',

			createdDateTime: 0,
			createdUserId: '',
			createdUserName: '',
			modifiedDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',
			deletedDateTime: 0,
			deletedUserId: '',
			deletedUserName: '',
			isDeleted: false,
		},
		recipientType: 'Organisation',
		selectedEvent: {},
		selectedOrganisation: {},
		selectedCarPark: {},

		isVehicleSectionVisible: true,
		isReservedSpacesInUse: false,
		originalReservedList: null,

		isCarParkChanged: false,
	}),

	computed: {

		/**
		 * Computed Init
		 *
		 * If there is any form data, assign it to the form.
		 * If there is no form data, it will be blank (new).
		 */
		computedInit() {
			const t = this
			const FORM_DATA = t.$props.formData

			// Existing form
			if (FORM_DATA?.parkingData?.length) {

				// Assign the form data
				t.form = FORM_DATA.parkingData[0].parkingSpaceData
				t.selectedEvent = FORM_DATA.eventData
				t.selectedOrganisation = FORM_DATA.organisationData
				t.selectedCarPark = FORM_DATA.parkingData[0].carParkData

				// Assign the Recipient Type
				if (t.form.parkingSpaceOrganisationId) t.recipientType = 'Organisation'
				else if (t.form.parkingSpaceIndividualEmail) t.recipientType = 'Individual'
				else if (t.form.parkingSpaceReservedList.length) {
					t.recipientType = 'Reserved'
					t.isVehicleSectionVisible = false
				}

				// Convert the stringified Reserved List to an array
				if (typeof t.form.parkingSpaceReservedList === 'string' && t.form.parkingSpaceReservedList.length) {
					try {
						t.form.parkingSpaceReservedList = JSON.parse(t.form.parkingSpaceReservedList)
					} catch (error) {
						console.error('Error parsing parkingSpaceReservedList: ', error)
					}
				}

			}

		},

		computedReservedSpaces() {
			const t = this
			const SELECTED_CAR_PARK = t.selectedCarPark
			const SELECTED_EVENT = t.selectedEvent
			const ARE_RESERVED_SPACES_IN_USE = t.isReservedSpacesInUse
			let message
			let visibility = false

			// If no Event or Car Park has been select
			if (!SELECTED_EVENT?.entityId || !SELECTED_CAR_PARK?.entityId) {
				message = 'Please select an Event and Car Park to check Reserved Spaces'
				visibility = false
			}

			// If a Car Park has been selected but no Event
			if (!SELECTED_EVENT?.entityId && SELECTED_CAR_PARK?.entityId) {
				message = 'Please select an Event to check if reserved spaces are in use'
				visibility = false
			}

			// If reserved spaces are in use, and the current form's reservedList
			if (ARE_RESERVED_SPACES_IN_USE) {
				message = 'These Reserved Spaces have already been modified'
				visibility = true
			}

			// If the selected Car Park does not have any Reserved Spaces
			if (SELECTED_CAR_PARK.carParkNumberOfReservedSpaces === 0) {
				message = 'The selected Car Park does not have any Reserved Spaces'
				visibility = false
			}

			// If an Event has been selected, and the Car Park has Reserved Spaces, and Reserved Spaces are not in use
			if (SELECTED_EVENT?.entityId && SELECTED_CAR_PARK?.entityId && !ARE_RESERVED_SPACES_IN_USE) {
				// message = 'Reserved spaces are not in use'
				visibility = true
			}

			return {message, visibility}
		},

	},

	methods: {

		async checkReservedSpaces() {
			const t = this
			const EVENT_ID = t.selectedEvent.entityId
			const CAR_PARK_ID = t.selectedCarPark.entityId

			if (t.selectedEvent?.entityId && t.selectedCarPark?.entityId && t.recipientType === 'Reserved') {

				const RESPONSE = await t.MIX_redis_checkIfReservedSpacesInUse(EVENT_ID, CAR_PARK_ID)

				// Handle any errors
				if (RESPONSE.hasErrors) {
					console.error('Error checking Reserved Spaces in use', RESPONSE.error)
					return
				}

				// Perform a check in the database if reserved spaces are already in use
				const reservedSpacesInUse = RESPONSE.data

				// If reserved spaces are in use, show a message
				t.isReservedSpacesInUse = !!reservedSpacesInUse
			}

		},

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Handle Recipient Type Selection
		 *
		 * Assign the selected recipient type to the form.
		 * If the recipient type is an organisation, clear the individual fields.
		 * If the recipient type is an individual, clear the organisation fields.
		 *
		 * @param recipientType {string} - The selected recipient type
		 */
		handleRecipientTypeSelection(recipientType) {
			const t = this

			// If the form is read only, do not allow changes
			if (t.isReadOnly) return

			t.recipientType = recipientType

			// Organisation
			if (recipientType === 'Organisation') {
				t.form.parkingSpaceIndividualEmail = ''
				t.form.parkingSpaceIndividualName = ''

				t.isVehicleSectionVisible = true
			}

			// Individual
			if (recipientType === 'Individual') {
				t.selectedOrganisation = {}
				t.form.parkingSpaceOrganisationId = ''

				t.isVehicleSectionVisible = true
			}

			// Reserved
			if (recipientType === 'Reserved') {
				t.selectedOrganisation = {}
				t.form.parkingSpaceOrganisationId = ''

				t.form.parkingSpaceIndividualEmail = ''
				t.form.parkingSpaceIndividualName = ''

				t.isVehicleSectionVisible = false
			}

		},

		/**
		 * Handle Save Item
		 *
		 * Create or Update the current document, after it has been validated.
		 */
		handleSaveItem() {
			const t = this
			const RECIPIENT_TYPE = t.recipientType

			// Save the IDs from the selected objects
			t.form.parkingSpaceEventId = t.selectedEvent?.entityId
			t.form.parkingSpaceOrganisationId = t.selectedOrganisation?.entityId || ''
			t.form.parkingSpaceCarParkId = t.selectedCarPark?.entityId

			if (!t.validateForm()) return

			if (RECIPIENT_TYPE === 'Individual') {
				t.form.parkingSpaceNumberOfReservedSpaces = 0
				t.form.parkingSpaceReservedList = ''
			}

			if (RECIPIENT_TYPE === 'Organisation') {
				t.form.parkingSpaceNumberOfReservedSpaces = 0
				t.form.parkingSpaceReservedList = ''
			}

			if (RECIPIENT_TYPE === 'Reserved') {
				t.form.parkingSpaceNumberOfReservedSpaces = t.selectedCarPark?.carParkNumberOfReservedSpaces
				t.form.parkingSpaceReservedList = JSON.stringify(t.form.parkingSpaceReservedList)
			}

			// Create
			if (!t.form.entityId) t.createItem()

			// Update
			else if (t.form.entityId) t.updateItem()
		},

		/**
		 * Create Item
		 *
		 * Create a new document to the DB.
		 */
		async createItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_create('parkingSpace', t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating Parking Space: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem saving, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Created Parking Spaces'

			t.$emit('emitReloadPage')
		},

		/**
		 * Update Item
		 *
		 * Update an existing document to the DB.
		 */
		async updateItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_update('parkingSpace', t.form.entityId, t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating Parking Space: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem updating, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Updated Parking Spaces'

			t.$emit('emitReloadPage')
		},

		/**
		 * Validate Form
		 *
		 * Validate the form as required.
		 *
		 * @returns {boolean} if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Select Event
			if (!t.selectedEvent?.entityId) {
				t.errors.selectedEvent = true
				t.errors.selectedEventErrorMessage = 'An Event is Required'
			}

			// Selected Car Park
			if (!t.selectedCarPark?.entityId) {
				t.errors.selectedCarPark = true
				t.errors.selectedCarParkErrorMessage = 'A Car Park is Required'
			}

			// Select Organisation
			if (t.recipientType === 'Organisation' && !t.selectedOrganisation?.entityId) {
				t.errors.selectedOrganisation = true
				t.errors.selectedOrganisationErrorMessage = 'An Organisation is Required'
			}

			// Individual Email
			if (t.recipientType === 'Individual') {
				const USER_EMAIL = t.form.parkingSpaceIndividualEmail
				if (!USER_EMAIL) {
					t.errors.parkingSpaceIndividualEmail = true
					t.errors.parkingSpaceIndividualEmailErrorMessage = 'An Email is required'
				}
				// Must be a (simple) valid email address
				else if (!t.MIX_isEmailValid(USER_EMAIL)) {
					t.errors.parkingSpaceIndividualEmail = true
					t.errors.parkingSpaceIndividualEmailErrorMessage = 'Email not valid'
				}

				// Individual Name
				if (t.recipientType === 'Individual' && !t.form.parkingSpaceIndividualName) {
					t.errors.parkingSpaceIndividualName = true
					t.errors.parkingSpaceIndividualNameErrorMessage = 'A Name is required'
				}
			}

			// Regular Vehicles Validation
			if (['Individual', 'Organisation'].includes(t.recipientType)) {

				t.validateVehicleNumber("RegularSmall")
				t.validateVehicleNumber("RegularMedium")
				t.validateVehicleNumber("RegularLarge")

				// Disabled Vehicles Validation
				t.validateVehicleNumber("DisabledSmall")
				t.validateVehicleNumber("DisabledMedium")
				t.validateVehicleNumber("DisabledLarge")

				// If there are no regular or disabled small, medium, or large vehicles
				if (![t.form.parkingSpaceNumberOfRegularSmallVehiclesRequired,
					t.form.parkingSpaceNumberOfRegularMediumVehiclesRequired,
					t.form.parkingSpaceNumberOfRegularLargeVehiclesRequired,
					t.form.parkingSpaceNumberOfDisabledSmallVehiclesRequired,
					t.form.parkingSpaceNumberOfDisabledMediumVehiclesRequired,
					t.form.parkingSpaceNumberOfDisabledLargeVehiclesRequired
				].some(value => value > 0)
				) {
					t.errors.noVehiclesError = true
					t.errors.noVehiclesErrorMessage = "At least one vehicle is required"
				}

			}

			return !Object.values(t.errors).includes(true)
		},

		/**
		 * Validate Vehicle Number
		 *
		 * Validate the number of vehicles required.
		 * Must be either 0, or a positive integer.
		 *
		 * @param vehicleType the type of vehicle to validate
		 */
		validateVehicleNumber(vehicleType) {
			const t = this
			const FORM_FIELD = `parkingSpaceNumberOf${vehicleType}VehiclesRequired`
			const FIELD_VALUE = t.form[FORM_FIELD]
			const REGEX = /^\d+$/

			if (!REGEX.test(FIELD_VALUE)) {
				t.errors[FORM_FIELD] = true
				t.errors[`${FORM_FIELD}ErrorMessage`] = "Must be 0 or a positive number"
			}
		},

		handleSelectedCarPark() {
			const t = this
			const SELECTED_CAR_PARK = t.selectedCarPark

			if (!t.originalReservedList) t.originalReservedList = t.form.parkingSpaceReservedList

			if (!t.form.entityId) {
				t.form.parkingSpaceReservedList =
					typeof SELECTED_CAR_PARK.carParkReservedList === 'string'
						? JSON.parse(SELECTED_CAR_PARK.carParkReservedList)
						: SELECTED_CAR_PARK.carParkReservedList
			} else {
				if (!t.form.parkingSpaceReservedList.length) {
					t.form.parkingSpaceReservedList =
						typeof SELECTED_CAR_PARK.carParkReservedList === 'string'
							? JSON.parse(SELECTED_CAR_PARK.carParkReservedList)
							: SELECTED_CAR_PARK.carParkReservedList
				}

				if (t.form.parkingSpaceCarParkId === SELECTED_CAR_PARK.entityId) {
					if (JSON.stringify(t.form.parkingSpaceReservedList) !== JSON.stringify(t.originalReservedList)) {
						t.isCarParkChanged = true
					}
				} else {
					if (JSON.stringify(t.form.parkingSpaceReservedList) !== JSON.stringify(SELECTED_CAR_PARK.carParkReservedList)) {
						t.form.parkingSpaceReservedList =
							typeof SELECTED_CAR_PARK.carParkReservedList === 'string'
								? JSON.parse(SELECTED_CAR_PARK.carParkReservedList)
								: SELECTED_CAR_PARK.carParkReservedList
						t.isCarParkChanged = false
					}
				}
			}

			t.checkReservedSpaces()
		},

	},

	watch: {

		selectedEvent: 'checkReservedSpaces',

		selectedCarPark(newSelectedCarPark) {
			const t = this

			// If the car park has changed, reset the reserved spaces
			if (t.isCarParkChanged) {
				t.form.parkingSpaceReservedList = t.originalReservedList
				t.isCarParkChanged = false
			} else {
				t.handleSelectedCarPark()
			}
		},

		recipientType: 'checkReservedSpaces',

	}

}
</script>

<style scoped>

</style>
