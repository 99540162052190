<template>
	<v-menu content-class="rounded-lg" min-width="256" offset-y>

		<!--Menu Icon-->
		<template v-slot:activator="{ on, attrs }">
			<div v-if="button" class="appWhite cursorPointer rounded-lg" v-bind="attrs" v-on="on" style="width: 56px">
				<app-icon class="pa-3" color="primary" icon="menuDots" size="32"/>
			</div>
			<div v-if="!button" v-bind="attrs" v-on="on">
				<app-icon class="pa-3" color="primary" icon="menuDots" size="32"/>
			</div>
		</template>

		<!--Menu-->
		<v-list class="d-flex flex-column align-start">

			<!--Menu Items from data-->
			<v-list-item v-for="item in menuList" :key="item.name" class="pa-0" style="width: 100%">

				<v-btn @click="handleSelection(item.name)"
					   class="d-flex justify-start text-capitalize" color="appWhite" depressed style="width: 100%">
					<app-icon class="mr-4" color="primary" :icon="item.icon"/>
					<app-text>{{ item.name }}</app-text>
				</v-btn>

			</v-list-item>

		</v-list>

	</v-menu>
</template>

<script>
export default {

	name: "MoreActionsMenu",

	props: {
		button: {type: Boolean, default: true},
		menuList: {type: Array, default: []},
	},

	data: () => ({
		isMenuVisible: false,
	}),

	methods: {

		handleSelection(selection) {
			const t = this

			t.$emit('emitMenuSelection', selection)
		}

	},

}
</script>

<style scoped>

</style>
