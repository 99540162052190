import { render, staticRenderFns } from "./MyOrganisationMobileCard.vue?vue&type=template&id=53db9498&scoped=true"
import script from "./MyOrganisationMobileCard.vue?vue&type=script&lang=js"
export * from "./MyOrganisationMobileCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53db9498",
  null
  
)

export default component.exports