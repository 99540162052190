<template>
	<v-app-bar app clipped-left color="white" height="64">

		<!--Menu Icon-->
		<v-app-bar-nav-icon @click.stop="$emit('emitOpenNavigationDrawer')">
			<app-icon color="primary" icon="menuLines" size="32"/>
		</v-app-bar-nav-icon>

		<v-spacer/>

		<!--Company Logo-->
		<v-img @click.native="MIX_go('/')"
			   :src="require('@/assets/images/company-logo.svg')"
			   contain
			   height="48"
			   max-width="200"
			   style="width: calc(100% - 96px)"/>

		<v-spacer/>

		<v-menu rounded offset-y>
			<template v-slot:activator="{ on }">
				<div v-on="on">
					<avatar :file="fullUserData?.fileData" size="32" :user="fullUserData?.userData"/>
				</div>
			</template>
			<v-list class="">

				<!--App Support-->
				<v-list-item @click="MIX_go('/appSupport')">
					<v-list-item-title class="d-flex align-center">
						<app-icon class="mr-4" color="primary" icon="support"/>
						<app-text>App Support</app-text>
					</v-list-item-title>
				</v-list-item>

				<!--My Profile-->
				<v-list-item @click="MIX_go('/myProfile')">
					<v-list-item-title class="d-flex align-center">
						<app-icon class="mr-4" color="primary" icon="user"/>
						<app-text>My Profile</app-text>
					</v-list-item-title>
				</v-list-item>

				<!--Sign Out-->
				<v-list-item @click="handleSignOutUser">
					<v-list-item-title class="d-flex align-center">
						<app-icon class="mr-4" color="primary" icon="logOut"/>
						<app-text>Sign Out</app-text>
					</v-list-item-title>
				</v-list-item>

				<app-text class="text-center pa-4" color="grey9" size="small">App Version {{appVersion}}</app-text>

			</v-list>
		</v-menu>


	</v-app-bar>
</template>

<script>
export default {

	name: "AppBar",

	props: ['fullUserData'],

	data: () => ({}),

	methods: {

		async handleSignOutUser() {
			const t = this

			const RESPONSE = await t.MIX_auth_signOutUser()

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error Signing Out User: ', RESPONSE.error)
				return
			}

			t.MIX_go('/signIn')
		}

	},

}
</script>

<style scoped>

</style>
