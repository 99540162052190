<template>

	<page-loading-animation v-if="isLoading" is-loading="isLoading"/>

	<!--Page Content-->
	<div v-else>



		<v-data-table class="appWhite rounded-lg"
					  :headers="computedHeaders"
					  :items="computedTableData">

			<!--ID-->
			<template v-slot:item.entityId="{ item }">
				<app-text>{{ item.entityId }}</app-text>
			</template>

			<!--Status-->
			<template v-slot:item.swappDirection="{ item }">
				<v-chip class="d-flex justify-center"
						:color="item.swappDirection === 'In' ? 'green' : 'red'"
						small
						style="width: 48px"
						text-color="white">
					{{ item.swappDirection }}
				</v-chip>
			</template>

			<!--Date-->
			<template v-slot:item.swappDate="{ item }">
				<app-text size="small">{{ MIX_formatDate(item.swappDate, 'short') }}</app-text>
				<app-text color="grey9" size="small">@ {{ MIX_formatDateTimeToTime(item.swappDate) }}</app-text>
			</template>

			<!--Site-->
			<template v-slot:item.swappSiteName="{ item }">
				<app-text size="small">{{ item.swappSiteName }}</app-text>
				<app-text color="grey9" size="small">{{ item.swappLocationName }}</app-text>
			</template>

			<!--Method-->
			<template v-slot:item.swappMethod="{ item }">
				<app-text size="small">{{ item.swappMethod }}</app-text>
			</template>

		</v-data-table>

	</div>

</template>

<script>

export default {

	name: "UserSwappHistory",

	props: ['userData'],

	data: () => ({
		isLoading: false,
		tableHeaders: [
			{text: 'ID', value: 'entityId', align: 'start', sortable: false, hidden: true},
			{text: 'Status', value: 'swappDirection', align: 'center', sortable: false, width: '80px'},
			{text: 'Date', value: 'swappDate', align: 'start', sortable: false, width: '128px'},
			{text: 'Site', value: 'swappSiteName', align: 'start', sortable: false},
			{text: 'Method', value: 'swappMethod', align: 'start', sortable: false}
		],

		// Data
		swappHistoryData: []
	}),

	computed: {

		computedTableData() {
			const t = this
			let tableData = t.swappHistoryData

			// Sort by date
			tableData.sort((a, b) => a.swappDate < b.swappDate ? 1 : -1)

			return tableData
		},

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this
			let headers = t.tableHeaders

			// Remove hidden headers
			headers = headers.filter(h => !h.hidden)

			return headers
		},

	},

	methods: {

		async loadData() {
			const t = this
			t.isLoading = true

			await Promise.all([
				t.loadSwappHistoryData()
			])

			t.isLoading = false
		},

		async loadSwappHistoryData() {
			const t = this
			const USER_DATA = t.$props.userData.userData

			const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields(
				'SwappHistory',
				[{whereKey: 'swappUserId', whereValue: USER_DATA.entityId}],
				[
					{field: 'swappDate'},
					{field: 'swappDirection'},
					{field: 'swappSiteName'},
					{field: 'swappLocationName'},
					{field: 'swappMethod'}
				]
			)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error loading Swapp History data: ', RESPONSE.errors)
				t.$sharedState.errorMessage = 'There was a problem loading the Swapp History data, please try again.'
				return
			}

			t.swappHistoryData = RESPONSE.data
		},

	},

	async mounted() {
		const t = this

		await t.loadData()
	}

}
</script>

<style scoped>

</style>
