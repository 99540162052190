<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<div v-else class="appGrey">

		<!--Add Photo Button-->
		<div class="d-flex justify-end">
			<image-upload v-if="formData.observationStatus !== 'Resolved'"
						  @emitImageUpload="emittedImageUpload"
						  folder="observations"
						  :id-for-image="selectedItem.entityId"
						  image-for="observation"
						  label="Add Photo"/>
		</div>

		<!--No Evidence Message-->
		<app-text v-if="!evidenceImages.length"
				  class="text-center mt-4" color="grey9">No evidence has been supplied
		</app-text>

		<!--Image Grid-->
		<div class="observationImageGrid mt-4">
			<div v-for="item in evidenceImages" :key="item.entityId">
				<v-img @click.native="handlePreview(item)"
					   class="rounded-lg"
					   :src="MIX_getImagePath(item.fileFolder, item.fileName, item.fileToken)"
					   style="aspect-ratio: 1/1">
				</v-img>
			</div>
		</div>

		<!--Preview Dialog-->
		<v-dialog max-width="75vw" v-model="isImagePreviewVisible">

			<!--Toolbar-->
			<v-toolbar color="appGrey">
				<v-spacer/>
				<delete-icon v-if="selectedItem.createdUserId === MIX_getCurrentUser().entityId && formData.observationStatus !== 'Resolved'"
							 @emitDeleteItem="deleteItem(selectedItem)"
							 itemName=""
							 :selectedItem="selectedItem"/>
				<close-icon @click.native="closeImagePreviewDialog"/>
			</v-toolbar>

			<!--Image-->
			<v-img :src="MIX_getImagePath(selectedItem.fileFolder, selectedItem.fileName, selectedItem.fileToken)"
				   cover/>

		</v-dialog>

	</div>

</template>

<script>
export default {

	name: "ObservationEvidenceForm",

	props: ['formData'],

	data: () => ({
		evidenceImages: [],
		isImagePreviewVisible: false,
		isLoading: true,
		isReadOnly: false,
		selectedItem: {},
	}),

	methods: {

		/**
		 * Delete Item
		 *
		 * Delete the selected location (only marks as deleted).
		 *
		 * @param itemData {object} the item to delete
		 */
		async deleteItem(itemData) {
			const t = this

			const RESPONSE = await t.MIX_redis_delete('file', itemData.entityId, itemData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error deleting Location: ', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Deleted Image'

			t.closeImagePreviewDialog()
			await t.loadData()
		},

		/**
		 * Emitted Image Upload
		 *
		 * When an image is uploaded, break apart the downloadURL and save what's required.
		 * Then, update the form with the image ID.
		 *
		 * @param payload - The image data and download URL
		 */
		async emittedImageUpload(payload) {
			const t = this

			const {FOLDER_NAME, IMAGE_NAME, TOKEN} = t.MIX_extractImageDataFromUrl(payload.downloadURL)

			// Save the image data to the DB (Files collection), and add the returned image ID to the form
			await t.saveImageData(
				t.$props.formData.entityId,
				FOLDER_NAME,
				IMAGE_NAME,
				payload.image.size,
				TOKEN,
				payload.image.type
			)

			await t.loadData()
		},

		handlePreview(image) {
			const t = this

			t.openImagePreviewDialog()

			t.selectedItem = image
		},

		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadEvidenceImages(),
			])

			t.isLoading = false
		},

		async loadEvidenceImages() {
			const t = this

			const RESPONSE = await t.MIX_redis_getFilesWhere({
				whereKey: 'fileDocLink',
				whereValue: t.$props.formData.entityId
			})

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error loading Evidence Images:', RESPONSE.errors)
				t.$sharedState.errorMessage = 'There was a problem loading the Evidence Images, please try again.'
				return
			}

			t.evidenceImages = RESPONSE.data
		},

		/**
		 * Save Image Data
		 *
		 * Save the image data to the DB (Files collection).
		 *
		 * @param fileDocLink {string} - The file's document link
		 * @param fileFolder {string} - The storage folder
		 * @param fileName {string} - The file name
		 * @param fileSize {number} - The file size
		 * @param fileToken {string} - The file token
		 * @param fileType {string} - The file type
		 * @returns {Promise<*>} - The image ID
		 */
		async saveImageData(fileDocLink, fileFolder, fileName, fileSize, fileToken, fileType) {
			const t = this

			const IMAGE_DATA = {
				fileDocLink,
				fileFolder,
				fileName,
				fileSize,
				fileToken,
				fileType,
				createdDateTime: 0,
				createdUserId: '',
				createdUserName: '',
				modifiedDateTime: 0,
				modifiedUserId: '',
				modifiedUserName: '',
				isDeleted: false,
				deletedDateTime: 0,
				deletedUserId: '',
				deletedUserName: '',
			}

			const RESPONSE = await t.MIX_redis_create('file', IMAGE_DATA)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error saving File Data to DB:', RESPONSE.errors)
				t.$sharedState.errorMessage = 'There was a problem uploading your file, please try again.'
				return
			}

			return RESPONSE.data?.entityId
		},

		openImagePreviewDialog() {
			const t = this

			t.isImagePreviewVisible = true
		},

		closeImagePreviewDialog() {
			const t = this

			t.selectedItem = {}
			t.isImagePreviewVisible = false
		},

	},

	created() {
		const t = this

		t.loadData()
	},

}
</script>

<style scoped>
.observationImageGrid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(343px, 1fr));
	grid-gap: 16px;
}
</style>
