<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<!--Page Content-->
	<div v-else>

		<!--Header-->
		<page-title :divider="true"
					icon="venueTasks"
					info="Create and administer ToDo lists, and assign to others."
					title="ToDo List"/>

		<!--Action Bar-->
		<div class="d-flex align-center mt-4">

			<!--Search-->
			<div @focusin="onSearchFocus" @focusout="onSearchBlur" style="width: 100%">
				<app-form-field form-type="textInput"
							append-icon="icons8-search"
							class="mr-4"
							:clearable="true"
							label="List Name"							
							v-model.trim="searchByListName"/>
			</div>
			

			<!--Add Button-->
			<app-btn v-if="!isSearchFocused"
					@click.native="openRightPanel" 
					class="mr-4" 
					icon="add" 
					:label="$vuetify.breakpoint.width < 600 ? '' : 'Add'"/>

			<!--Filter Button - with a numbered badge-->
			<div v-if="!isSearchFocused" style="position:relative;">
				<app-btn @click.native="toggleFiltersVisibility"
						 class="mr-4" color="appWhite" icon="filter" icon-color="primary"/>
				<span v-if="computedNumberOfActiveFilters > 0" class="badge">{{ computedNumberOfActiveFilters }}</span>
			</div>

			<!--More Actions Menu-->
			<more-actions-menu v-if="!isSearchFocused" @emitMenuSelection="handleEmittedMenuSelection" :menuList="moreActionsMenuOption"/>

		</div>

		<!--Clear Filters-->
		<div v-if="computedNumberOfActiveFilters > 0" class="d-flex justify-end mt-4">
			<app-btn @click.native="clearFilters" icon="close" label="Clear Filters"/>
		</div>

		<!--Table-->
		<v-data-table v-if="$vuetify.breakpoint.width >= 600"
					  class="appWhite rounded-lg mt-4"
					  :headers="computedHeaders"
					  :items="computedTableData">

			<!--ID-->
			<template v-slot:item.entityId="{ item }">
				<app-text size="small">{{ item.entityId }}</app-text>
			</template>

			<!--Status-->
			<template v-slot:item.toDoListStatus="{ item }">
				<div class="d-flex justify-center align-center">
					<app-icon v-if="item.toDoListStatus === true" color="green" icon="success" size="32"
							  :title="item.noticeVisibility"/>
					<app-icon v-if="item.toDoListStatus === false" color="orange" icon="pending" size="32"
							  :title="item.noticeVisibility"/>
				</div>
			</template>

			<!--Priority-->
			<template v-slot:item.toDoListPriority="{ item }">
				<div class="d-flex justify-center align-center">
					<v-chip v-if="item.toDoListPriority"
						:color="getToDoListColor(item.toDoListPriority)"
						class="white--text"
						label
						small>
							{{ item.toDoListPriority.toUpperCase() }}
					</v-chip>
					
				</div>
				<app-text v-if="!item.toDoListPriority"
						size="small"
						color="grey9">
							Not set
				</app-text>
			</template>

			<!--Privacy | List Name-->
			<template v-slot:item.toDoListName="{ item }">
				<div class="d-flex align-center">
					<app-icon v-if="item.toDoListIsPrivate"
							class="mr-2" 
							color="primary"
							icon="closedEye"
							size="large"/>
					<app-icon v-else class="mr-2"
							color="primary"
							icon="eye"
							size="large"/>
					<app-text size="small">
						{{ item.toDoListName }}
					</app-text>
				</div>

			</template>

			<!--Created By-->
			<template v-slot:item.createdUserName="{ item }">
				<app-text size="small">{{ item.createdUserName }}</app-text>
			</template>

			<!--Assigned to-->
			<template v-slot:item.toDoListAssignedTo="{ item }">
				<app-text size="small">
					{{ usersData.find(user => user.entityId === item.toDoListAssignedTo)?.userName }}
				</app-text>
				<app-text v-if="!item.toDoListAssignedTo" color="grey9" size="small">Not set</app-text>
			</template>

			<!--Due Date-->
			<template v-slot:item.toDoListDueDate="{ item }">
				<!--If list has a due date and it is not expired-->
				<app-text v-if="item.toDoListDueDate&& new Date().getTime() < item.toDoListDueDate" 
						size="small">
					{{ MIX_formatDate(item.toDoListDueDate, 'short') }}
				</app-text>
				<!--If list has a due date and it has expired-->
				<v-chip v-if="item.toDoListDueDate&& new Date().getTime() > item.toDoListDueDate" 
						class="px-1 white--text"
					:color="'red'"
						label
						small>
					{{ MIX_formatDate(item.toDoListDueDate, 'short') }}
				</v-chip>
				<!--If list has no due date-->
				<app-text v-if="!item.toDoListDueDate" color="grey9" size="small">
					Not set
				</app-text>
			</template>

			<!--Action Button-->
			<template v-slot:item.action="{ item }">
				<app-icon @click.native="handleListSelection(item)" class="cursorPointer" color="primary"
						  icon="arrowForward"/>
			</template>

		</v-data-table>

		<!--Mobile Cards-->
		<div v-if="$vuetify.breakpoint.width < 600">

			<!--No data message-->
			<app-text v-if="!computedTableData.length"
					  class="text-center mt-4"
					  color="grey9">You have no ToDo Lists to view
			</app-text>

			<to-do-list-mobile-card v-for="item in computedTableData" :key="item.entityId"
									@click.native="handleListSelection(item)"
									:cardData="item"
									:getToDoListColor="getToDoListColor"/>
		</div>

		<!--Filters Panel ------------------------------------------------------------------------------------------ -->
		<filter-panel :is-filters-panel-visible="isFiltersPanelVisible" @toggle="toggleFiltersVisibility">
			<div class="pa-4">

				<!--Statuses-->
				<page-break-title title="Statuses"/>
				<div class="mt-4"
					 style="display: grid; grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); grid-gap: 16px">
					<app-btn @click.native="handleFilterStatusSelection('Complete')"
							 :block="true"
							 color="green"
							 label="Complete"
							 :outlined="!filterByStatuses.includes('Complete')"/>
					<app-btn @click.native="handleFilterStatusSelection('Ongoing')"
							 :block="true"
							 color="orange"
							 label="Ongoing"
							 :outlined="!filterByStatuses.includes('Ongoing')"/>
				</div>

				<!--Due Date-->
				<page-break-title class="mt-8" title="Due Date"/>
				<div class="mt-4">
					<app-form-field form-type="autoComplete"
									:items="['Expired', 'Ongoing', 'Not set']"
									label="Due Date"
									v-model.trim="filterByDueDate"/>
				</div>

				<!--Created By-->
				<page-break-title class="mt-8" title="Created By"/>
				<div class="mt-4">
					<app-form-field form-type="textInput"
									label="Created By"
									v-model.trim="filterByCreatedBy"/>
				</div>

				<!--Assigned To-->
				<page-break-title class="mt-8" title="Assigned To"/>
				<div class="mt-4">
					<app-form-field form-type="textInput"
									label="Assigned To"
									v-model.trim="filterByAssignedTo"/>
				</div>

				<!--Privacy-->
				<page-break-title class="mt-8" title="Private List"/>
				<div class="mt-4">
					<app-form-field form-type="autoComplete"
									:deletable-chips="true"
									:items="['Private', 'Not Private']"
									label="Private List"
									:multiple="true"
									:small-chips="true"
									v-model.trim="filterByPrivacy"/>
				</div>

				<!--Priority-->
				<page-break-title class="mt-8" title="Priority"/>
				<div class="mt-4">
					<app-form-field form-type="autoComplete"
									:deletable-chips="true"
									:items="['High', 'Medium', 'Low']"
									label="Priority"
									:multiple="true"
									:small-chips="true"
									v-model.trim="filterByPriority"/>
				</div>

			</div>
		</filter-panel>

		<!--Right Side --------------------------------------------------------------------------------------------- -->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">
			<div v-if="isRightPanelVisible" class="appGrey formShadow"
				 style="position: absolute; top: 0; bottom: 0; right: 0; overflow: auto"
				 :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 75%'">

				<!--Header-->
				<div class="d-flex align-center pa-4">
					<app-text size="medium">{{ selectedItem?.toDoListName || 'Create a new list' }}</app-text>
					<v-spacer/>
					<edit-icon v-if="tabs === 'overview'" @click.native="editItem" :isActive="!isReadOnly"/>
					<delete-icon v-if="tabs === 'overview' && selectedItem?.entityId"
								 @emitDeleteItem="deleteItem(selectedItem)" itemName="toDoListName"
								 :selectedItem="selectedItem"/>
					<close-icon @click.native="closeRightPanel"/>
				</div>

				<!--Tabs-->
				<v-tabs v-model="tabs">

					<!--Overview-->
					<v-tab href="#overview">
						<app-text size="small">Overview</app-text>
					</v-tab>

					<!--Tasks-->
					<v-tab :disabled="!selectedItem?.entityId" href="#tasks">
						<app-text size="small">Tasks</app-text>
					</v-tab>

				</v-tabs>

				<!--Tabs Content-->
				<v-tabs-items v-model="tabs">

					<!--Overview-->
					<v-tab-item value="overview">
						<to-do-list-form @emitReloadPage="emittedReloadPage" 
										class="pa-4" 
										:formData="selectedItem"
										:isReadOnly="isReadOnly"
										:selectedListTasks="selectedListTasks"
										:usersData="usersData"/>
					</v-tab-item>

					<!--Tasks-->
					<v-tab-item value="tasks">
						<to-do-tasks @emitReloadData="emittedReloadData" 
									class="pa-4"
									:formData="selectedListTasks"
									:isReadOnly="isReadOnly"
									:selectedList="selectedItem"
									:usersData="usersData"/>
					</v-tab-item>

				</v-tabs-items>

			</div>
		</transition>

	</div>

</template>

<script>
import ToDoTasks from './toDoTasks/ToDoTasks.vue'
import ToDoListForm from './toDoListForm/ToDoListForm'
import ToDoListMobileCard from '@/views/toDoList/toDoListMobileCard/ToDoListMobileCard.vue'

export default {

	name: 'ToDoList',

	components: {ToDoListMobileCard, ToDoListForm, ToDoTasks},

	data: () => ({
		filterByAssignedTo: '',
		filterByCreatedBy: '',
		filterByDueDate: '',
		filterByPriority: [],
		filterByPrivacy: [],
		filterByStatuses: [],
		isFiltersPanelVisible: false,
		isLoading: false,
		isReadOnly: false,
		isSearchFocused: false,
		isRightPanelVisible: false,
		moreActionsMenuOption: [{name: 'Export', icon: 'export'}],
		searchByToDoListArea: '',
		searchByListName: '',
		selectedItem: {},
		tableHeaders: [
			{text: 'ID', value: 'id', align: 'left', sortable: false, hidden: true},
			{
				text: 'Status',
				value: 'toDoListStatus',
				align: 'center',
				sortable: false,
				width: '48px',
			},
			{
				text: 'Priority',
				value: 'toDoListPriority',
				align: 'center',
				sortable: false,
				width: '48px',
			},
			{
				text: 'List Name',
				value: 'toDoListName',
				align: 'left',
				sortable: false,
			},
			{
				text: 'Created By',
				value: 'createdUserName',
				align: 'left',
				sortable: false,
			},
			{
				text: 'Assigned To',
				value: 'toDoListAssignedTo',
				align: 'left',
				sortable: false,
			},
			{
				text: 'Due Date',
				value: 'toDoListDueDate',
				align: 'left',
				sortable: false,
			},
			{
				text: '',
				value: 'action',
				align: 'right',
				sortable: false,
				width: '48px',
			},
		],
		tabs: '',
		selectedListTasks: [],

		// Data
		toDoListData: [],
		usersData: [],
	}),

	computed: {

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this
			let headers = t.tableHeaders

			// Remove hidden headers
			headers = headers.filter((h) => !h.hidden)

			return headers
		},

		/**
		 * Computed Number of Active Filters
		 *
		 * Count the number of active filters to display in the filters button badge.
		 *
		 * @returns {*}
		 */
		computedNumberOfActiveFilters() {
			const t = this
			return [
				t.filterByCreatedBy ? 1 : 0,
				t.filterByAssignedTo ? 1 : 0,
				t.filterByPrivacy.length,
				t.filterByPriority.length,
			].reduce((acc, curr) => acc + curr, 0)
		},

		/**
		 * Computed Table Data
		 *
		 * Sort and return the table data.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedTableData() {
			const t = this
			const CURRENT_USER_DATA = t.MIX_getCurrentUser()
			let tableData = t.toDoListData
	

			// Search by List Name
			if (t.searchByListName) {
				const SEARCH_CRITERIA = t.searchByListName.toUpperCase()
				tableData = tableData.filter(item => item.toDoListName.toUpperCase().includes(SEARCH_CRITERIA))
			}

			// Filter by Statuses
			if (t.filterByStatuses.length) {
				const STATUS_MAP = {
					true: "Complete",
					false: "Ongoing",
				}
				tableData = tableData.filter(item => {
					const STATUS_VALUE = STATUS_MAP[item.toDoListStatus]
					return t.filterByStatuses.includes(STATUS_VALUE)
				})
			}

			//Filter by Due Date
			if (t.filterByDueDate) {
				if (t.filterByDueDate === 'Expired') {
					tableData = tableData.filter(item => item.toDoListDueDate && new Date().getTime() > item.toDoListDueDate)
				} else if (t.filterByDueDate === 'Ongoing') {
					tableData = tableData.filter(item => item.toDoListDueDate && new Date().getTime() < item.toDoListDueDate)
				} else if (t.filterByDueDate === 'Not set') {
					tableData = tableData.filter(item => !item.toDoListDueDate)
				}
			}

			// Filter by Created By
			if (t.filterByCreatedBy) {
				const SEARCH_CRITERIA = t.filterByCreatedBy.toUpperCase()
				tableData = tableData.filter(item => item.createdUserName.toUpperCase().includes(SEARCH_CRITERIA))
			}

			// Filter by Assigned To - use the usersData array to get the user name
			if (t.filterByAssignedTo) {
				const USERS_DATA = t.usersData
				const SEARCH_CRITERIA = t.filterByAssignedTo.toUpperCase()
				tableData = tableData.filter(item =>
					USERS_DATA.find(user => user.entityId === item.toDoListAssignedTo)
						?.userName?.toUpperCase()?.includes(SEARCH_CRITERIA))
			}

			// Filter by Privacy
			if (t.filterByPrivacy.length) {
				tableData = tableData.filter(item => {
					const PRIVACY_VALUE = item.toDoListIsPrivate ? 'Private' : 'Not Private'
					return t.filterByPrivacy.includes(PRIVACY_VALUE)
				})
			}

			// Filter by Priority
			if (t.filterByPriority.length) tableData = tableData.filter(item => t.filterByPriority.includes(item.toDoListPriority))

			// Sort by List Name
			tableData = tableData.sort((a, b) => (a.toDoListName > b.toDoListName ? 1 : -1))

			// //Check if all tasks are complete
			

			// Check current user to show private tasks
			tableData = tableData.filter((item) => item.toDoListIsPrivate === false || item.createdUserId === CURRENT_USER_DATA.entityId)

			return tableData
		},

	},

	methods: {

		/**
		 * Clear Filters
		 *
		 * Clear all the filters.
		 */
		clearFilters() {
			const t = this

			t.filterByCreatedBy = ''
			t.filterByAssignedTo = ''
			t.filterByPrivacy = []
			t.filterByPriority = []
		},

		/**
		 * Close Right Panel
		 *
		 * Close the right panel.
		 */
		closeRightPanel() {
			const t = this

			t.tabs = ''
			t.selectedItem = {}
			t.isRightPanelVisible = false
		},

		/**
		 *
		 * Delete Item
		 *
		 * Delete the selected document by its ID.
		 *
		 * @param itemData {object} the item to delete
		 */
		async deleteItem(itemData) {
			const t = this

			const RESPONSE = await t.MIX_redis_delete('toDoList', itemData.entityId, itemData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error deleting List', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem deleting the List, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Deleted List'

			// Reload the data
			await t.loadData()

			// Close the panel
			t.closeRightPanel()
		},

		/**
		 * Edit Item
		 *
		 * Toggle the editability of the form.
		 */
		editItem() {
			const t = this

			t.isReadOnly = !t.isReadOnly
		},

		/**
		 * Emitted Reload Page
		 *
		 * Close the panel and Reload the data to refresh the page.
		 */
		emittedReloadPage() {
			const t = this

			t.closeRightPanel()
			t.loadData()
		},

		/**
		 * Emitted Reload Data
		 *
		 * Assign the emitted data to the selected item, and reload the data.
		 * This will enable the form to be kept open and updated, rather than close the form and open it again.
		 *
		 * @param payload {object} the new Swapp Tasks List data
		 */
		 emittedReloadData(payload) {
			const t = this

			// Payload - when refreshing from creating a new List
			if (payload?.entityId) t.selectedItem = payload

			// SelectedItem - when refreshing from creating a new Task
			if (t.selectedItem?.entityId) t.loadTasksData(t.selectedItem)

			t.isReadOnly = true
			t.reloadData()
		},

		/**
		 * Get ToDo List Priority Color
		 *
		 * Return the color of the to do list based on its Priority.
		 *
		 * @param toDoListPriority {string} the priority of the to do list
		 * @returns {string} the color of the todo list
		 */
		getToDoListColor(toDoListPriority) {
		  let toDoListColor = ''

		  switch (toDoListPriority) {
		    case 'High':
		      toDoListColor = 'red'
		      break
		    case 'Medium':
		      toDoListColor = 'orange'
		      break
		    case 'Low':
		      toDoListColor = 'green'
		      break
		  }

		  return toDoListColor
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this
		},

		/**
		 * Handle Filter Status Selection
		 *
		 * Add or remove the selected status from the filterStatuses array.
		 *
		 * @param status {string} the status to add or remove
		 */
		handleFilterStatusSelection(status) {
			const t = this

			if (t.filterByStatuses.includes(status)) t.filterByStatuses = t.filterByStatuses.filter(s => s !== status)
			else t.filterByStatuses.push(status)
		},

		/**
		 * Handle Filter Expired Selection
		 *
		 * Add or remove the selected status from the filterStatuses array.
		 *
		 * @param status {string} the status to add or remove
		 */

		handleFilterExpiredSelection() {
			const t = this
		},

		/**
		 * Handle List Selection
		 *
		 * Handle the selection of a list from the table by fetching all the tasks that belong to this list.
		 *
		 * @param item the selected item
		 */

		async handleListSelection(item) {
			const t = this
			t.selectedItem = item
			await t.loadTasksData()
		},

		
		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadToDoListData(), 
				t.loadUsersData()
			])

			t.isLoading = false
		},

		/**
		 * Load Tasks Data
		 *
		 * Load the required data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadTasksData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getToDoTasksWhere({
				whereKey: 'toDoTaskListId',
				whereValue: t.selectedItem.entityId
			})

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting ToDo Tasks data: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem getting the ToDo Tasks data, please try again.'
				return
			}

			t.selectedListTasks = RESPONSE.data

			t.openRightPanel(t.selectedItem)
		},

		/**
		 * Load To Do List Data
		 *
		 * Load the required data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadToDoListData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getAll('toDoList')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting ToDo List data: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem getting the ToDo List data, please try again.'
				return
			}

			// Assign the data
			t.toDoListData = RESPONSE.data
		},

		/**
		 * Load Users Data
		 *
		 * Load the required data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadUsersData() {
			const t = this
			let usersData = []

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getAll('user')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Users data: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem getting the Users data, please try again.'
				return
			}

			// If the response is an object, return it as an array
			usersData = RESPONSE.data.entityId ? usersData.push(RESPONSE.data) : RESPONSE.data

			// Assign the data to the state
			t.usersData = usersData.sort((a, b) => (a.userName > b.userName ? 1 : -1))

			// // Assign the data
			// t.usersData = RESPONSE.data.sort((a, b) => (a.userName > b.userName ? 1 : -1))
		},

		/**
		 * Open Form
		 *
		 * Open the form dialog.
		 * If no item is passed in, the form will open as new (blank).
		 *
		 * @param item the selected key
		 */
		openRightPanel(item) {
			const t = this

			t.isReadOnly = !!item?.entityId

			t.isRightPanelVisible = true
		},

		/**
		 * On Search Blur
		 *
		 * When the search field is blurred, set the isSearchFocused flag to false.
		 */
		 onSearchBlur() {
			const t = this

			if (t.$vuetify.breakpoint.width < 600) t.isSearchFocused = false
		},

		/**
		 * On Search Focus
		 *
		 * When the search field is focused, set the isSearchFocused flag to true.
		 * This is used to expand the search when on smaller devices.
		 */
		onSearchFocus() {
			const t = this

			if (t.$vuetify.breakpoint.width < 600) t.isSearchFocused = true
		},

		reloadData() {
			const t = this

			t.loadData()
		},

		/**
		 * Toggle Filters Visibility
		 *
		 * Toggle the visibility of the filters panel.
		 */
		toggleFiltersVisibility() {
			const t = this

			t.isFiltersPanelVisible = !t.isFiltersPanelVisible
		},

	},

	async created() {
		const t = this

		await t.loadData()
	},

}
</script>
