<template>
	<div>

		<!--Header-->
		<div class="d-flex align-center justify-space-between">
			<page-title icon="payroll"
						info="View and administer your payroll."
						title="Payroll"/>

			<app-btn @click.native="handleViewTypeChange"
					 :label="computedSwitchButtonLabel"/>
		</div>

		<v-divider class="mt-4"/>

		<payroll v-if="viewType === 'Payroll'"/>

		<pay-rates v-if="viewType === 'Pay Rates'"/>

	</div>
</template>

<script>

import Payroll from "@/views/payroll/payRoll/Payroll.vue";
import PayRates from "@/views/payroll/payRates/PayRates.vue";

export default {

	name: "PayrollMainPage",

	components: {PayRates, Payroll},

	data: () => ({
		viewType: 'Payroll',
	}),

	computed: {

		computedSwitchButtonLabel() {
			const t = this
			let buttonLabel

			if (t.viewType === 'Payroll') {
				buttonLabel = t.$vuetify.breakpoint.width < 600 ? 'Pay Rates' : 'Switch to Pay Rates'
			}
			else {
				buttonLabel = t.$vuetify.breakpoint.width < 600 ? 'Payroll' : 'Switch to Payroll'
			}

			return buttonLabel
		},

	},

	methods: {

		handleViewTypeChange() {
			const t = this

			t.viewType = t.viewType === 'Payroll' ? 'Pay Rates' : 'Payroll'
		},

	},

}
</script>

<style scoped>

</style>
